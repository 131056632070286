<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Shopdial By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<!-- <img [src]="themeLogo" alt="logo"> -->
							<h3 style="color: black;">{{shop?.name}}</h3>
						</div>
						<p>{{ shop?.description | stripHtml }}</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="{{shopSocialmedia?.facebookUrl}}"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="{{shopSocialmedia?.googleUrl}}"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="{{shopSocialmedia?.twitterUrl}}"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
								
								<li>
                                    <a href="{{shopSocialmedia?.instaUrl}}"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="{{shopSocialmedia?.linkedinUrl}}"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Womens Fashion</a></li>
                                <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>store information</h4>
						</div>
						<div class="footer-contant" *ngFor="let s of shopLocation">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>{{shop?.name}} | {{s?.address}} | {{s?.postalCode}} | {{s?.city}} | {{s?.state}} | {{s?.country}} </li>
						        <li><i class="fa fa-phone"></i>Call Us: {{shop?.contact}}</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>{{shop?.email}}</a></li>
						        <li *ngIf="shop?.faxNumber !== ''"><i class="fa fa-fax"></i>Fax: {{shop?.faxNumber}}</li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} shopdial.in All rights reserved.</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->