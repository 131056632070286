import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocationService } from '../../services/location.service';
import { HomepageService } from '../../services/homepage.service';
import { ProductService } from '../../services/product.service';
import { ShopTypeService } from '../../services/shoptype.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {

  @Input() class: string;
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild('searchbar') searchbar: ElementRef;

  public products: any[] = [];
  public stick: boolean = false;
  @Input() topSelectedCountry: any;
  public shops: any = [];
  public shopType: any = [];

  @ViewChild('subMenu') subMenuRef!: ElementRef;
  public isSubMenuVisible = true;

  public countries: any;
  public states: any;
  public cities: any;
  public topSelectedState: any;
  public topSelectedCity: any;

  selectedTasks = [];
  dropdownSettings: IDropdownSettings;

  selectedCity = [];
  dropdownSetting: IDropdownSettings;

  public placeholder = '      Search shop with shopdial...';
  public keyword = 'name';
  public search: boolean = false;

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private elementRef: ElementRef,
    private translate: TranslateService,
    public productService: ProductService, public countryStateCitySrv: LocationService, private homeSrv: HomepageService, public shopTypeSrv: ShopTypeService, private router: Router, private renderer: Renderer2, private eRef: ElementRef) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    if (history.state.shop != null) {
      this.shops = history.state.shop;
    }

    //get All shopType
    this.getAllShopType();

    //change country 
    this.onChangeCountry();

    this.dropdownSettings = {
      singleSelection: true,
      textField: 'name',
      // selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };

    this.dropdownSetting = {
      singleSelection: true,
      textField: 'city',
      // selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };
  }

  changeLanguage(code: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
    if (!this.isSubMenuVisible && number >= 150 && window.innerWidth > 400) {
      this.toggleSubMenu();
    }
  }

  searchText = '';
  toggleSearch = false;

  openSearch = () => {
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  };

  searchClose = () => {
    this.searchText = '';
    this.toggleSearch = false;
  };

  /**
   * Navigates to the search page with the selected event name as a query parameter.
   *
   * @param item - The selected event.
   */
  selectEvent(item: any) {
    // Construct the URL with formatted shop name
    const url = `shops/search/${item.name.toLowerCase().replace(/\s+/g, '-').replace(/\/+$/, '')}`;
    // Navigate to the URL without the 'category' query parameter
    this.router.navigate([url], {
        queryParams: { category: null },
        queryParamsHandling: 'merge'
    });
}

  /**
   * Update the list of shops based on the search name.
   * @param {string} name - The name to search for.
   */
  onChangeSearch(name: string) {
    // Call the shopByName method of the homeSrv service and subscribe to the result
    this.homeSrv.shopByName(name).subscribe((data: any) => {
      // Update the shops array with the result data
      this.shops = data;
    });
  }

  onFocused(e: any) {
  }

  searchToggle() {
    this.search = !this.search;
  }

  /**
   * Retrieves all shop types from the server and stores
   * the first 10 shop types in this.shopType.
   */
  public getAllShopType() {
    // Check if shop types data is already stored in session storage
    const shopTypesData = sessionStorage.getItem('CATEGORIES');
    if (shopTypesData) {
      // If data exists, parse and use it directly
      this.shopType = JSON.parse(shopTypesData).slice(0, 10); // Store the first 10 shop types
    } else {
      // If data doesn't exist in session storage, make API call to get shop types
      this.shopTypeSrv.getAllShoptype().subscribe((data: any) => {
        // Store the first 10 shop types in this.shopType
        this.shopType = data.slice(0, 10);
        // Store the shop types data in session storage
      });
    }
  }

  replaceSpecialCharWithHyphen(categoryName: string): string {
    return categoryName.toLowerCase().replace(/\s+/g, '-');
  }

  public toggleSubMenu(): void {
    this.isSubMenuVisible = !this.isSubMenuVisible;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.isSubMenuVisible && !this.eRef.nativeElement.contains(event.target)) {
      this.toggleSubMenu();
    }
  }

  public onClick(target: any): void {
    if (!this.subMenuRef.nativeElement.contains(target)) {
      this.isSubMenuVisible = false;
    }
  }


  onDropdownClick(event: Event): void {
    event.stopPropagation();
  }

  onItemSelectCity(item: any) {
  }

  OnItemDeSelectCity(item: any) {
  }

  onDeSelectAllCity(items: any) { }

  public onDropDownCloseCity(item: any) { }

  public onDeSelectCity(item: any) { }

  onClickCity(item: any) { }

  OnItemDeSelect(item: any) { }

  onSelectAll(items: any) { }

  onDeSelectAll(items: any) { }

  public onDropDownClose(item: any) { }

  public onDeSelect(item: any) { }


  /**
   * Handles the change event of the country select input.
   * Retrieves the states for the selected country and gets the top shop by country.
   */
  public onChangeCountry() {
    this.countryStateCitySrv.getStatesByCountriesName().subscribe(data => {
      this.states = data;
      this.countryStateCitySrv.getTopShopByCountry();
    });
  }

  /**
   * Updates the selected state and retrieves cities based on the state name.
   * If a state name is provided, it also retrieves the top shop for the state.
   * If no state name is provided, it sets the cities to null.
   * 
   * @param name - The name of the state.
   */
  public onChangeState(name: any) {
    this.countryStateCitySrv.clearSessionShopByState();
    if (name) {
      // Retrieve cities based on the state name
      this.countryStateCitySrv.getCitiesByStateName(name).subscribe(data => this.cities = data);
      // Retrieve the top shop for the state
      this.countryStateCitySrv.getTopShopByState(name);
    } else {
      this.cities = null;
    }
  }

  /**
   * Updates the selected city and retrieves the top shop for the selected city.
   * 
   * @param {any} name - The name of the selected city.
   * @returns {void}
   */
  public onChangeCity(name: any) {
    this.countryStateCitySrv.clearSessionShopByCity();
    if (name) {
      this.countryStateCitySrv.getTopShopByCity(name);
    } else {
      this.cities = null;
    }
  }

}

