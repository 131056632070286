<owl-carousel-o [options]="BlogSliderConfig" class="no-arrow">
  <ng-container *ngFor="let blog of blogs">
    <ng-template carouselSlide>
      <div class="col-md-12">
        <a [routerLink]="['/pages/blog/details',blog?.urlPath]">
          <div class="classic-effect" *ngFor="let doc of blog?.documents | docfilter : 'sub-banner-img';index as i">
            <div *ngIf="i === 0">
              <img [src]="doc?.urls" class="img-fluid" alt="blog">
              <i class="fa fa-pencil-square-o fa-2x edit-icon" aria-hidden="true" *ngIf="isEditable()"></i>
              <span></span>
            </div>
          </div>
        </a>
        <div class="blog-details">
          <h4>{{blog.createdDate| dateFormat}}</h4>
          <a [routerLink]="['/pages/blog/details',blog?.urlPath]">
            <p>{{blog.blogTitle}}</p>
          </a>
          <hr class="style1">
          <h6> {{ 'By:' + blog.createdBy}}</h6>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>