<app-header-two [themeLogo]="themeLogo" *ngIf="!isOfferBannerFormOpen"></app-header-two>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isOfferBannerFormOpen">
  <app-slider [sliders]="sliders" [textClass]="'text-center p-center'" [category]="'bags'"
    [buttonText]="'save up to 50% off'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- Product Tab start-->
<section class="section-b-space">
  <div class="container">
    <div class="title2">
      <h4>new collection</h4>
      <h2 class="title-inner2">trending products</h2>
    </div>
    <div class="row">
      <div class="col">
        <div class="theme-tab">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
              <a ngbNavLink>
                {{ collection }}
              </a>
              <ng-template ngbNavContent>
                <div class="no-slider row">
                  <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                    <div class="product-box">
                      <app-product-box-three [product]="product" [currency]="productService?.Currency">
                      </app-product-box-three>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Tab end-->

<!-- category  -->
<div class="container category-button">
  <section class="section-b-space border-section border-bottom-0">
    <div class="row partition1">
      <div class="col" *ngFor="let category of categories">
        <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
          class="btn btn-outline btn-block">
          {{category}}
        </a>
      </div>
    </div>
  </section>
</div>
<!-- category end -->

<div *ngIf="filteredBackGroundDocuments?.length > 0 ;then content else other_content"></div>

<!-- Dynamic Parallax banner start-->
<ng-template class="p-0" #content>
  <div class="full-banner banner-layout-3 parallax text-center p-center" *ngFor="let doc of filteredBackGroundDocuments"
    [style.backgroundImage]="getEncodedImageUrl(doc?.urls)">
    <a class="fa-pull-right" *ngIf="isEditable()" (click)="openOfferForm()">
      <i class="fa fa-pencil-square-o fa-2x fa-pencil-background" aria-hidden="true"></i>
    </a>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h4 class="color pt-0">{{doc?.textSubTittle}}</h4>
            <h3>{{doc?.textTittle}}</h3>
            <a class="btn btn-solid">
              shop now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Popup Form -->
  <div class="popup-form" *ngIf="isOfferBannerFormOpen">
    <div class="confirmation-content" [formGroup]="documentForm">
      <!-- Add a small tag for displaying messages at the top -->
      <small class="text-danger">{{ errorMessage }}</small>
      <div class="container mt-3">
        <div class="row">
          <div class="card-body dropzone-custom p-0">
            <ngx-dropzone class="dropzone-border" (change)="onSelectBackGroundImage($event)">
              <ngx-dropzone-label>
                <div class="dz-message needsclick">
                  <i class="fa fa-cloud-upload"></i>
                  <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                  <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                    1920px X 1080px.</small>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
          <div class="row justify-content-around mt-4">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
              </label>
              <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
            </div>
            <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
              <label class="col-form-label pt-0">Text Subtittle</label>
              <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                formControlName="textSubTittle">
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
        <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
      </div>
    </div>
  </div>
  <!--Dynamic Parallax banner End -->
</ng-template>

<ng-template class="p-0" #other_content>
  <!-- default Parallax banner start-->
  <div class="full-banner banner-layout-3 parallax text-center p-center"
    [ngStyle]="{'background-image': 'url(assets/images/bagi-1.jpg)'}">
    <a class="fa-pull-right" *ngIf="isEditable()" (click)="openOfferForm()">
      <i class="fa fa-pencil-square-o fa-2x fa-pencil-background" aria-hidden="true"></i>
    </a>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h4 class="color pt-0">special offer for you</h4>
            <h3>leather bag</h3>
            <h4>extra 50% off</h4>
            <a class="btn btn-solid">
              shop now
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpen">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessage }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectBackGroundImage($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      1920px X 1080px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                  (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!--default Parallax banner End -->
</ng-template>

<!-- product slider-->
<section class="section-b-space" *ngIf="!isOfferBannerFormOpen">
  <div class="container">
    <div class="row partition3 partition_3">
      <div class="col-lg-4">
        <div class="theme-card card-border">
          <app-product-box-vertical-slider [title]="'New product'" [type]="'bags'">
          </app-product-box-vertical-slider>
        </div>
      </div>
      <div class="col-lg-4 center-slider border-0">
        <div>
          <div class="title2">
            <h4>on sale</h4>
            <h2 class="title-inner2">season sale</h2>
          </div>
          <owl-carousel-o class="offer-slider slide-1" [options]="ProductSliderOneConfig">
            <ng-container *ngFor="let product of products | slice:0:8">
              <ng-template carouselSlide>
                <div>
                  <div class="product-box product-wrap">
                    <app-product-box-five [product]="product" [currency]="productService?.Currency" [thumbnail]="false"
                      [onHowerChangeImage]="false" [cartModal]="true">
                    </app-product-box-five>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="theme-card card-border">
          <app-product-box-vertical-slider [title]="'Feature product'" [type]="'bags'">
          </app-product-box-vertical-slider>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- category -->
<div class="category-bg" *ngIf="!isOfferBannerFormOpen">
  <div class="container-fluid p-0">
    <div class="row order-section">
      <div *ngIf="filteredCategoryDocuments?.length > 0 ;then category_one_content else other_category_one_content">
      </div>
      <ng-template class="p-0" #category_one_content>
        <!-- Category One Start -->
        <div class="col-sm-4 p-0" *ngFor="let catOne of filteredCategoryDocuments">
          <!-- comment -->
          <a class="image-block">
            <img [src]="catOne?.urls" class="img-fluid" alt="">
          </a>
          <span class="fa-pull-right fa-image-span-one" *ngIf="isEditable()" (click)="openCategoryForm()">
            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" style="color: black;"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryOneImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner, f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4 p-0">
          <div class="contain-block even">
            <div *ngFor="let catOne of filteredCategoryDocuments">
              <h6>new products</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>{{catOne?.textTittle}}</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                {{catOne?.textSubTittle}}
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="p-0" #other_category_one_content>
        <!-- Category One Start -->
        <div class="col-sm-4 p-0">
          <!-- comment -->
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}" class="image-block">
            <img src="assets/images/cart-1.jpg" class="img-fluid" alt="">
          </a>
          <span class="fa-pull-right fa-image-span-one" *ngIf="isEditable()" (click)="openCategoryForm()">
            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" style="color: black;"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryOneImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4 p-0">
          <div class="contain-block even">
            <div>
              <h6>new products</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>zipper storage bag</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                80% off
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Category One End -->
      <!-- category two start-->
      <div *ngIf="filteredCategoryDocuments2?.length > 0 ;then category_two_content else other_category_two_content">
      </div>
      <ng-template class="p-0" #category_two_content>
        <div class="col-sm-4 p-0" *ngFor="let catTwo of filteredCategoryDocuments2">
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}" class="image-block">
            <img [src]="catTwo?.urls" class="img-fluid" alt="">
            <span class="fa-pull-right fa-image-span-two" *ngIf="isEditable()" (click)="openCategoryFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
            </span>
          </a>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen2">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryTwoImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner2" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner2, f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 p-0">
          <div class="contain-block">
            <div *ngFor="let catTwo of filteredCategoryDocuments2">
              <h6>on sale</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>{{catTwo?.textTittle}}</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                {{catTwo?.textSubTittle}}
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="p-0" #other_category_two_content>
        <div class="col-sm-4 p-0">
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}" class="image-block">
            <img src="assets/images/cart2.jpg" class="img-fluid" alt="">
            <span class="fa-pull-right fa-image-span-two" *ngIf="isEditable()" (click)="openCategoryFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
            </span>
          </a>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen2">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryTwoImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner2" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner2, f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 p-0">
          <div class="contain-block">
            <div>
              <h6>on sale</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>tucker bag</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                save 30% off
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- category two end-->
      <!-- Category Three Start -->
      <div
        *ngIf="filteredCategoryDocuments3?.length > 0 ;then category_three_content else other_category_three_content">
      </div>
      <ng-template class="p-0" #category_three_content>
        <div class="col-sm-4 p-0" *ngFor="let catThree of filteredCategoryDocuments3">
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
            class="image-block even">
            <img [src]="catThree?.urls" class="img-fluid" alt="">
            <span class="fa-pull-right fa-image-span-three" *ngIf="isEditable()" (click)="openCategoryFormThree()">
              <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
            </span>
          </a>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen3">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryThreeImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner3" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner3,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 p-0">
          <div class="contain-block">
            <div *ngFor="let catThree of filteredCategoryDocuments3">
              <h6>summer sale</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>{{catThree?.textTittle}}</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                {{catThree?.textSubTittle}}
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="p-0" #other_category_three_content>
        <div class="col-sm-4 p-0">
          <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
            class="image-block even">
            <img src="assets/images/cart3.jpg" class="img-fluid" alt="">
            <span class="fa-pull-right fa-image-span-three" *ngIf="isEditable()" (click)="openCategoryFormThree()">
              <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
            </span>
          </a>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isCategoryBannerFormOpen3">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryThreeImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                            450px X 450px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadCategoryBanner3" [removable]="true"
                        (removed)="onRemoveItem(uploadCategoryBanner3, f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitForm(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 p-0">
          <div class="contain-block">
            <div>
              <h6>summer sale</h6>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h2>gate check bag</h2>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}"
                class="btn btn-solid category-btn">
                minimum 50% off
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'bags'}">
                <h6><span>shop now</span></h6>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Category Three Start -->
    </div>
  </div>
</div>

<!-- product-box slider -->
<div class="container" *ngIf="!isOfferBannerFormOpen">
  <section class="section-b-space border-section border-top-0">
    <div class="row">
      <div class="col">
        <div class="title2">
          <h4>trend</h4>
          <h2 class="title-inner2">exclusive bag</h2>
        </div>
        <owl-carousel-o class="product-4 product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:8">
            <ng-template carouselSlide>
              <div class="product-box product-wrap">
                <app-product-box-five [product]="product" [currency]="productService?.Currency" [thumbnail]="false"
                  [onHowerChangeImage]="true" [cartModal]="true">
                </app-product-box-five>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </section>
</div>
<!-- product-box end -->

<!-- blog section start-->
<section class="blog section-b-space" *ngIf="!isOfferBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title2">
          <h4>recent story</h4>
          <h2 class="title-inner2">latest blog</h2>
        </div>
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section end-->
<app-alert-popup></app-alert-popup>
<app-footer-two [themeLogo]="themeLogoWhite" *ngIf="!isOfferBannerFormOpen"></app-footer-two>