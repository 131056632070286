<div class="navbar">
    <a href="javascript:void(0)" (click)="leftMenuToggle()">
        <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
    </a>
    <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
        <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()"
            (mouseover)="onHover(false)"></a>
        <nav id="unset">
            <div (click)="leftMenuToggle()">
                <div class="sidebar-back text-start"><i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Back
                </div>
            </div>
            <ul class="nav-menu">
                <li>
                    <a [routerLink]="'/onboarding'" class="nav-link fonts" (click)="leftMenuToggle()">Register</a>
                </li>
                <li>
                    <a class="nav-link fonts" [routerLink]="'/pages/comingsoon'" (click)="leftMenuToggle()">What's new</a>
                </li>
                <li>
                    <a class="nav-link fonts" (click)="leftMenuToggle()">Advertise</a>
                </li>
                <li>
                    <a class="nav-link fonts" (click)="leftMenuToggle()">24x7 CustomerCare</a>
                </li>
                <li>
                    <a [routerLink]="'/onboarding/faq'" class="nav-link fonts" (click)="leftMenuToggle()">FAQ's</a>
                </li>
                <li>
                    <a class="nav-link fonts" (click)="leftMenuToggle()">English</a>
                </li>
            </ul>
        </nav>
    </div>
</div>