import { Component, OnInit } from '@angular/core';
import { ProductSlider } from '../../../shared/data/slider';
import { ProductService } from '../../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-fashion-one',
  templateUrl: './fashion-one.component.html',
  styleUrls: ['./fashion-one.component.scss']
})
export class FashionOneComponent implements OnInit {

  public products: any[] = [];
  public productCollections: any[] = [];
  public productList: any = [];
  public Editor = ClassicEditor;
  public active;
  public documentForm: FormGroup;
  public generatedShopId: string;
  public filtereBackgroundDocuments: any;
  public documents: any;
  public isBackgroundBannerFormOpen: boolean = false;
  public errorMessageOffer: string;
  public uploadBackgroundBanner: any;
  public sections: any;
  public issectionFormOpen: boolean = false;
  public websiteSectionForm: FormGroup;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentsOne: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public uploadOfferBannerTwo: any;
  public filteredOfferDocumentsTwo: any;

  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        });
      });
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
    
    //websiteSectionForm form
    this.websiteSectionForm = this.fb.group({
      id: [''],
      shopId: [''],
      description: [''],
    });

  }

  public ProductSliderConfig: any = ProductSlider;

  public sliders = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/41.jpg'
  },
  {
    image: '	http://themes.pixelstrap.com/multikart/assets/images/home-banner/42.jpg'
  }
  ]

  // Collection banner
  public collections = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/game/banner/1.jpg',
    save: 'Off 15%',
    title: 'For Windows'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/game/banner/2.jpg',
    save: '',
    title: ''
  }];

  // Blog
  public blog = [{
    image: 'assets/images/blog/1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  // Logo
  public logo = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  ngOnInit(): void {

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();

    this.getSectionByShopId();
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the offer-banner identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });

      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });
      // Filter the documents based on the background-banner identifier
      this.filtereBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select two images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 705 && image.height === 250) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 705px and height 250px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select two images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 705 && image.height === 250) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 705px and height 250px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }

  closePopUpForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isBackgroundBannerFormOpen = false;
  }


  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.uploadOfferBannerOne.forEach((event: any) => {
        this.documentForm.reset();
        this.onRemoveItem(this.uploadOfferBannerOne,event);
      });
      this.uploadOfferBannerTwo.forEach((event: any) => {
        this.documentForm.reset();
        this.onRemoveItem(this.uploadOfferBannerTwo, event);
      });
      this.closePopUpForm();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  openBackgroundForm() {
    this.isBackgroundBannerFormOpen = true;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only .';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 1200) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 1200px`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 1200px';
      this.uploadBackgroundBanner = []; // Clear the array if the count is not equal to 2
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-two');
      }
    } else if (this.uploadBackgroundBanner && this.uploadBackgroundBanner.length > 0) {
      this.documentForm.get('identifier').setValue("category");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'category');
      }
    } 
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'category':
        this.addOfferDocument(document, this.uploadBackgroundBanner);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }

  public getSectionByShopId() {
    this.docSrv.findSectionByShopId(this.generatedShopId).subscribe((data: any) => {
      this.sections = data;
    })
  }

  public createSection(section: any): void {
    // consume add Shop api
    this.docSrv.addSection(this.generatedShopId, section).subscribe((data: any) => {
      this.toaster.success('Section created Successfully!');
      this.getSectionByShopId();
      this.closeSectionForm();
    }), (error: any) => {
      this.toaster.error('Shop not created, try again.');
    };
  }

  public handleSection(section: any): void {
    this.createSection(section);
  }

  public onSectionSubmit(form: any): void {
    this.handleSection(this.websiteSectionForm.value);
  }

  openSectionForm() {
    this.issectionFormOpen = true;
  }

  closeSectionForm() {
    this.issectionFormOpen = false;
  }
}
