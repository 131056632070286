<app-header-one [class]="'header-tools'"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen"></app-header-one>

<section class="p-0 height-85 tools_slider" *ngIf="!isBackgroundFormOpen && !isOfferFormOpen">
    <owl-carousel-o [options]="HomeSliderConfig" class="home-slider">
        <ng-template carouselSlide *ngIf="!isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen">
            <div *ngIf="filteredSlideOneDocuments?.length > 0 ;then slide_one_content else slide_one_static_content">
            </div>
            <!-- Dynamic Slide One Start-->
            <ng-template #slide_one_content>
                <div class="home text-center" *ngFor="let doc of filteredSlideOneDocuments"
                    [style.backgroundImage]="getEncodedImageUrl(doc?.urls)">
                    <span *ngIf="isEditable()" (click)="openSlideOneForm()">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-edit" style="color: white;"
                            aria-hidden="true"></i>
                    </span>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="slider-contain">
                                    <div>
                                        <h4>welcome to tools</h4>
                                        <h1>auto parts cars</h1>
                                        <a [routerLink]="['/shop/collection/left/sidebar']"
                                            [queryParams]="{ category: 'tools'}" class="btn btn-solid">shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tools-parts" id="tools-move">
                            <img src="assets/images/shokupu.png" class="img-fluid" alt="">
                        </div>
                        <div class="tools-parts1" id="tools-move1">
                            <img src="assets/images/shokupu2.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <!-- Popup Form -->
                    <div class="popup-form" *ngIf="isSlideOneBannerFormOpen">
                        <div class="confirmation-content" [formGroup]="documentForm">
                            <!-- Add a small tag for displaying messages at the top -->
                            <small class="text-danger">{{ errorMessage }}</small>
                            <div class="container mt-3">
                                <div class="row">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectSlideOneImage($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                                        file size of
                                                        1920x x 722px.
                                                    </div>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of uploadSlideOneBanner"
                                                [removable]="true" (removed)="onRemoveSlideOne(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                    <div class="row justify-content-around mt-4">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                                class="col-form-label pt-0">Text Tittle
                                            </label>
                                            <input class="form-control" id="textTittle" type="text" name="textTittle"
                                                formControlName="textTittle">
                                        </div>
                                        <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                            <label class="col-form-label pt-0">Text Subtittle</label>
                                            <input class="form-control" id="textSubTittle" type="text"
                                                name="textSubTittle" formControlName="textSubTittle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <button class="btn btn-success pull-right" type="submit"
                                    (click)="onSubmitSlideOne(documentForm)">Confirm</button>
                                <button class="btn btn-primary pull-right" (click)="closeSlideOneForm()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Dynamic Slide One End-->
            <!-- Static Slide One Start-->
            <ng-template #slide_one_static_content>
                <div class="home text-center" [ngStyle]="{'background-image': 'url(assets/images/backImageTool.jpg)'}">
                    <span *ngIf="isEditable()" (click)="openSlideOneForm()">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-edit" style="color: white;"
                            aria-hidden="true"></i>
                    </span>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="slider-contain">
                                    <div>
                                        <h4>welcome to tools</h4>
                                        <h1>auto parts cars</h1>
                                        <a [routerLink]="['/shop/collection/left/sidebar']"
                                            [queryParams]="{ category: 'tools'}" class="btn btn-solid">shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tools-parts" id="tools-move">
                            <img src="assets/images/shokupu.png" class="img-fluid" alt="">
                        </div>
                        <div class="tools-parts1" id="tools-move1">
                            <img src="assets/images/shokupu2.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <!-- Popup Form -->
                    <div class="popup-form" *ngIf="isSlideOneBannerFormOpen">
                        <div class="confirmation-content" [formGroup]="documentForm">
                            <!-- Add a small tag for displaying messages at the top -->
                            <small class="text-danger">{{ errorMessage }}</small>
                            <div class="container mt-3">
                                <div class="row">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectSlideOneImage($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                                        file size of
                                                        1920x x 722px.
                                                    </div>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of uploadSlideOneBanner"
                                                [removable]="true" (removed)="onRemoveSlideOne(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                    <div class="row justify-content-around mt-4">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                                class="col-form-label pt-0">Text Tittle
                                            </label>
                                            <input class="form-control" id="textTittle" type="text" name="textTittle"
                                                formControlName="textTittle">
                                        </div>
                                        <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                            <label class="col-form-label pt-0">Text Subtittle</label>
                                            <input class="form-control" id="textSubTittle" type="text"
                                                name="textSubTittle" formControlName="textSubTittle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <button class="btn btn-success pull-right" type="submit"
                                    (click)="onSubmitSlideOne(documentForm)">Confirm</button>
                                <button class="btn btn-primary pull-right" (click)="closeSlideOneForm()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Static Slide One End-->
        </ng-template>

        <ng-template carouselSlide *ngIf="!isSlideOneBannerFormOpen">
            <div *ngIf="filteredSlideTwoDocuments?.length > 0 ;then slide_two_content else slide_two_static_content">
            </div>
            <!-- Dynamic Slide Two Start-->
            <ng-template #slide_two_content>
                <div class="home text-center" *ngFor="let doc of filteredSlideTwoDocuments"
                    [style.backgroundImage]="getEncodedImageUrl(doc?.urls)">
                    <span *ngIf="isEditable()" (click)="openSlideTwoForm()">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-edit" style="color: white;"
                            aria-hidden="true"></i>
                    </span>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="slider-contain">
                                    <div>
                                        <h4>welcome to tools</h4>
                                        <h1>Cars Auto part</h1>
                                        <a [routerLink]="['/shop/collection/left/sidebar']"
                                            [queryParams]="{ category: 'tools'}" class="btn btn-solid">shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tools-parts" id="tools-move2">
                            <img src="assets/images/ringTool.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <!-- Popup Form -->
                    <div class="popup-form" *ngIf="isSlideTwoBannerFormOpen">
                        <div class="confirmation-content" [formGroup]="documentForm">
                            <!-- Add a small tag for displaying messages at the top -->
                            <small class="text-danger">{{ errorMessage }}</small>
                            <div class="container mt-3">
                                <div class="row">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectSlideTwoImage($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                                        file size of
                                                        1920x x 722px.
                                                    </div>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of uploadSlideTwoBanner"
                                                [removable]="true" (removed)="onRemoveSlideTwo(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                    <div class="row justify-content-around mt-4">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                                class="col-form-label pt-0">Text Tittle
                                            </label>
                                            <input class="form-control" id="textTittle" type="text" name="textTittle"
                                                formControlName="textTittle">
                                        </div>
                                        <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                            <label class="col-form-label pt-0">Text Subtittle</label>
                                            <input class="form-control" id="textSubTittle" type="text"
                                                name="textSubTittle" formControlName="textSubTittle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <button class="btn btn-success pull-right" type="submit"
                                    (click)="onSubmitSlideTwo(documentForm)">Confirm</button>
                                <button class="btn btn-primary pull-right" (click)="closeSlideTwoForm()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Dynamic Slide Two End-->
            <!-- Static Slide Two Start-->
            <ng-template #slide_two_static_content>
                <div class="home text-center" [ngStyle]="{'background-image': 'url(assets/images/backGroundTool.jpg)'}">
                    <span *ngIf="isEditable()" (click)="openSlideTwoForm()">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-edit" style="color: white;"
                            aria-hidden="true"></i>
                    </span>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="slider-contain">
                                    <div>
                                        <h4>welcome to tools</h4>
                                        <h1>Cars Auto part</h1>
                                        <a [routerLink]="['/shop/collection/left/sidebar']"
                                            [queryParams]="{ category: 'tools'}" class="btn btn-solid">shop now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tools-parts" id="tools-move2">
                            <img src="assets/images/ringTool.png" class="img-fluid" alt="">
                        </div>
                    </div>
                    <!-- Popup Form -->
                    <div class="popup-form" *ngIf="isSlideTwoBannerFormOpen">
                        <div class="confirmation-content" [formGroup]="documentForm">
                            <!-- Add a small tag for displaying messages at the top -->
                            <small class="text-danger">{{ errorMessage }}</small>
                            <div class="container mt-3">
                                <div class="row">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectSlideTwoImage($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                                        file size of
                                                        1920x x 722px.
                                                    </div>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of uploadSlideTwoBanner"
                                                [removable]="true" (removed)="onRemoveSlideTwo(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                    <div class="row justify-content-around mt-4">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                                class="col-form-label pt-0">Text Tittle
                                            </label>
                                            <input class="form-control" id="textTittle" type="text" name="textTittle"
                                                formControlName="textTittle">
                                        </div>
                                        <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                            <label class="col-form-label pt-0">Text Subtittle</label>
                                            <input class="form-control" id="textSubTittle" type="text"
                                                name="textSubTittle" formControlName="textSubTittle">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <button class="btn btn-success pull-right" type="submit"
                                    (click)="onSubmitSlideTwo(documentForm)">Confirm</button>
                                <button class="btn btn-primary pull-right" (click)="closeSlideTwoForm()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!-- Static Slide Two End-->
        </ng-template>
    </owl-carousel-o>
</section>
<!-- Home slider End -->

<!-- service section start -->
<section class="banner-padding absolute-banner pb-0 tools-service"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen !isBackgroundFormOpen">
    <div class="container absolute-bg">
        <div class="service p-0 ">
            <app-services></app-services>
        </div>
    </div>
</section>
<!-- service section end -->

<!-- about section start-->
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="title3">
                    <h2 class="title-inner3">welcome to tool store</h2>
                    <div class="line"></div>
                </div>
                <div class="about-text">
                    <p>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                        laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                        sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                        voluptatem sequi nesciunt.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->

<!-- category -->
<section class="pt-0 category-tools"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-collection [class]="'pt-0'" [categories]="categories" [category]="'tools'">
                </app-collection>
            </div>
        </div>
    </div>
</section>
<!-- category end -->

<!-- product slider start -->
<section class="section-b-space tools-grey"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title3">
                    <h2 class="title-inner3">Popular products</h2>
                    <div class="line"></div>
                </div>
                <owl-carousel-o [options]="ProductSliderConfig" class="product-5 product-m no-arrow">
                    <ng-container *ngFor="let product of products">
                        <ng-template carouselSlide>
                            <div class="product-box product-wrap">
                                <app-product-box-three [product]="product" [currency]="productService?.Currency">
                                </app-product-box-three>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- product slider end -->

<div *ngIf="filteredBackgroundDocuments?.length > 0 ;then background_content else background_static_content">
</div>
<!-- dynamic parallax section start -->
<ng-template #background_content>
    <section class="full-banner parallax-banner21 parallax small-slider tools-parallax-product"
        *ngFor="let doc of filteredBackgroundDocuments" [style.backgroundImage]="getEncodedImageUrl(doc?.urls)">
        <span *ngIf="isEditable()" (click)="openBackgroundForm()">
            <i class="fa fa-pencil-square-o fa-2x fa-pencil-background" style="color: black;" aria-hidden="true"></i>
        </span>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <div class="tools-description">
                        <div>
                            <h3>select your vehical</h3>
                            <div class="tools-form">
                                <div class="search-box">
                                    <select class="form-control">
                                        <option value="">Select Make</option>
                                        <option value="1">Audi</option>
                                        <option value="2">BMW</option>
                                        <option value="3">Fiat</option>
                                        <option value="4">Hyndai</option>
                                        <option value="5">Skoda</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="model" class="form-control">
                                        <option value="">Select Model</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="engine" class="form-control">
                                        <option value="">Select category</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="year" class="form-control">
                                        <option value="">Select Year</option>
                                    </select>
                                </div>
                                <div class="search-button">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}" class="btn btn-solid btn-find">find my
                                        part</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-12 tools-grey" *ngIf="!isBackgroundFormOpen && !isOfferFormOpen">
                    <owl-carousel-o [options]="ProductSliderConfig" class="tools-product-4 product-m">
                        <ng-container *ngFor="let product of products">
                            <ng-template carouselSlide>
                                <div class="product-box product-wrap">
                                    <app-product-box-three [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-three>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- Popup Form -->
        <div class="popup-form" *ngIf="isBackgroundFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
                <!-- Add a small tag for displaying messages at the top -->
                <small class="text-danger">{{ errorMessage }}</small>
                <div class="container mt-3">
                    <div class="row">
                        <div class="card-body dropzone-custom p-0">
                            <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                            file size of
                                            1920x x 1080px.
                                        </div>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                                    (removed)="onRemoveBackground(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <button class="btn btn-success pull-right" type="submit"
                        (click)="onSubmitBackground(documentForm)">Confirm</button>
                    <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<!-- dynamic parallax section end -->
<!-- parallax section start -->
<ng-template #background_static_content>
    <section class="full-banner parallax-banner21 parallax small-slider tools-parallax-product"
        [ngStyle]="{'background-image': 'url(assets/images/toolbanner.png)'}">
        <span *ngIf="isEditable()" (click)="openBackgroundForm()">
            <i class="fa fa-pencil-square-o fa-2x fa-pencil-background" style="color: black;" aria-hidden="true"></i>
        </span>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-12">
                    <div class="tools-description">
                        <div>
                            <h3>select your vehical</h3>
                            <div class="tools-form">
                                <div class="search-box">
                                    <select class="form-control">
                                        <option value="">Select Make</option>
                                        <option value="1">Audi</option>
                                        <option value="2">BMW</option>
                                        <option value="3">Fiat</option>
                                        <option value="4">Hyndai</option>
                                        <option value="5">Skoda</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="model" class="form-control">
                                        <option value="">Select Model</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="engine" class="form-control">
                                        <option value="">Select category</option>
                                    </select>
                                </div>
                                <div class="search-box">
                                    <select name="year" class="form-control">
                                        <option value="">Select Year</option>
                                    </select>
                                </div>
                                <div class="search-button">
                                    <a [routerLink]="['/shop/collection/left/sidebar']"
                                        [queryParams]="{ category: 'shoes'}" class="btn btn-solid btn-find">find my
                                        part</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-12 tools-grey" *ngIf="!isBackgroundFormOpen && !isOfferFormOpen">
                    <owl-carousel-o [options]="ProductSliderConfig" class="tools-product-4 product-m">
                        <ng-container *ngFor="let product of products">
                            <ng-template carouselSlide>
                                <div class="product-box product-wrap">
                                    <app-product-box-three [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-three>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- Popup Form -->
        <div class="popup-form" *ngIf="isBackgroundFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
                <!-- Add a small tag for displaying messages at the top -->
                <small class="text-danger">{{ errorMessage }}</small>
                <div class="container mt-3">
                    <div class="row">
                        <div class="card-body dropzone-custom p-0">
                            <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                            file size of 1920x x 1080px.
                                        </div>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                                    (removed)="onRemoveBackground(f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <button class="btn btn-success pull-right" type="submit"
                        (click)="onSubmitBackground(documentForm)">Confirm</button>
                    <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<!-- parallax section end -->

<!-- product section start -->
<section class="tools_product" *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen">
    <div class="container">
        <div class="row multiple-slider">
            <div class="col-xl-3 col-lg-4 col-md-12"  *ngIf="!isOfferFormOpen">
                <app-product-box-vertical-slider [title]="'new products'" [type]="'tools'">
                </app-product-box-vertical-slider>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-12 tools-grey">
                <div class="theme-tab">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                        class="tabs tab-title justify-content-center nav nav-pills">
                        <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                            <a ngbNavLink>
                                {{ collection }}
                            </a>
                            <ng-template ngbNavContent>
                                <div class="no-slider row">
                                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                                        <div class="product-box">
                                            <app-product-box-three [product]="product"
                                                [currency]="productService?.Currency">
                                            </app-product-box-three>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>

                <div *ngIf="filteredOfferDocuments?.length > 0 ;then offer_content else offer_static_content">
                </div>
                <!--Dynamic Offer Start-->
                <ng-template #offer_content>
                    <div *ngFor="let doc of filteredOfferDocuments">
                        <img [src]="doc?.urls" alt="banner" class="img-fluid">
                        <span class="fa-pencil-offer-edit" *ngIf="isEditable()" (click)="openOfferForm()">
                            <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
                        </span>
                        <!-- Popup Form -->
                        <div class="popup-form" *ngIf="isOfferFormOpen">
                            <div class="confirmation-content" [formGroup]="documentForm">
                                <!-- Add a small tag for displaying messages at the top -->
                                <small class="text-danger">{{ errorMessage }}</small>
                                <div class="container mt-3">
                                    <div class="row">
                                        <div class="card-body dropzone-custom p-0">
                                            <ngx-dropzone class="dropzone-border" (change)="onSelectOfferImage($event)">
                                                <ngx-dropzone-label>
                                                    <div class="dz-message needsclick">
                                                        <i class="fa fa-cloud-upload"></i>
                                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                        </h4>
                                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed,
                                                            Maximum
                                                            file size of 1170x x 252px.
                                                        </div>
                                                    </div>
                                                </ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of uploadOfferBanner"
                                                    [removable]="true" (removed)="onRemoveOffer(f)">
                                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button class="btn btn-success pull-right" type="submit"
                                        (click)="onSubmitOffer(documentForm)">Confirm</button>
                                    <button class="btn btn-primary pull-right"
                                        (click)="closeOfferForm()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--Dynamic Offer End-->
                <!--Static Offer Start-->
                <ng-template #offer_static_content>
                    <div>
                        <img src="assets/images/offer-banner-4.jpg" alt="banner" class="img-fluid">
                        <span class="fa-pencil-offer-edit" *ngIf="isEditable()" (click)="openOfferForm()">
                            <i class="fa fa-pencil-square-o fa-2x" style="color: black;" aria-hidden="true"></i>
                        </span>
                        <!-- Popup Form -->
                        <div class="popup-form" *ngIf="isOfferFormOpen">
                            <div class="confirmation-content" [formGroup]="documentForm">
                                <!-- Add a small tag for displaying messages at the top -->
                                <small class="text-danger">{{ errorMessage }}</small>
                                <div class="container mt-3">
                                    <div class="row">
                                        <div class="card-body dropzone-custom p-0">
                                            <ngx-dropzone class="dropzone-border" (change)="onSelectOfferImage($event)">
                                                <ngx-dropzone-label>
                                                    <div class="dz-message needsclick">
                                                        <i class="fa fa-cloud-upload"></i>
                                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum
                                                            file size of 1170x x 252px.
                                                        </div>
                                                    </div>
                                                </ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of uploadOfferBanner"
                                                    [removable]="true" (removed)="onRemoveOffer(f)">
                                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button class="btn btn-success pull-right" type="submit"
                                        (click)="onSubmitOffer(documentForm)">Confirm</button>
                                    <button class="btn btn-primary pull-right"
                                        (click)="closeOfferForm()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--Static Offer End-->
            </div>
        </div>
    </div>
</section>
<!-- product section end -->

<!--  logo section start-->
<section class="section-b-space tools-brand"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-logo [logos]="logos"></app-logo>
            </div>
        </div>
    </div>
</section>
<!--  logo section End-->

<!-- blog section start-->
<section class="blog p-t-0 section-b-space"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title1 section-t-space">
                    <h4>Recent Story</h4>
                    <h2 class="title-inner1">from the blog</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-blog [blogs]="blogs"></app-blog>
            </div>
        </div>
    </div>
</section>
<!-- blog section End -->

<!-- Cart Open To Top Side-->
<app-cart-variation [direction]="'top'"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen"></app-cart-variation>
<app-alert-popup
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen"></app-alert-popup>
<app-footer-one [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"
    *ngIf="!isSlideOneBannerFormOpen && !isSlideTwoBannerFormOpen && !isBackgroundFormOpen && !isOfferFormOpen"></app-footer-one>