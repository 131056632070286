<app-header-one [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree"></app-header-one>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'watch'" [buttonText]="'shop now'">
    </app-slider>
</section>
<!-- Home slider End -->

<!--  logo section start-->
<section *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree ">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-logo [logos]="logos"></app-logo>
            </div>
        </div>
    </div>
</section>
<!--  logo section End-->


<!-- timer banner -->
<section *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class="container">
        <div class="banner-timer" [ngStyle]="{'background-image': 'url(assets/images/offer-banner-2.jpg)'}">
            <div class="row">
                <div class="col-md-6">
                    <div class="banner-text">
                        <h2>Save <span>30% off</span> Digital Watch</h2>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="timer-box">
                        <div class="timer">
                            <p id="demo">
                                <span>
                                    11<span class='padding-l'>:</span><span class='timer-cal'>Days</span>
                                </span>
                                <span>
                                    11<span class='padding-l'>:</span><span class='timer-cal'>Hrs</span>
                                </span>
                                <span>
                                    11<span class='padding-l'>:</span><span class='timer-cal'>Min</span>
                                </span>
                                <span>
                                    11<span class='timer-cal'>Sec</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- timer default banner end -->

<!-- category -->
<section class="section-b-space"
    **ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-collection [categories]="categories" [category]="'watch'"></app-collection>
            </div>
        </div>
    </div>
</section>
<!-- category end -->

<!--  tab section  -->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class=" tab-bg">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="title4">
                        <h2 class="title-inner4">trending products</h2>
                        <div class="line">
                            <span></span>
                        </div>
                    </div>
                    <div class="theme-tab">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                            class="tabs tab-title justify-content-center nav nav-pills">
                            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                                <a ngbNavLink>
                                    {{ collection }}
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="no-slider row">
                                        <ng-container
                                            *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                            <div class="product-box">
                                                <app-product-box-one [product]="product"
                                                    [currency]="productService?.Currency">
                                                </app-product-box-one>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  tab section end -->

<!-- product section -->
<section *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title4">
                    <h2 class="title-inner4">trending products</h2>
                    <div class="line">
                        <span></span>
                    </div>
                </div>
                <owl-carousel-o [options]="ProductSliderConfig" class="product-4 product-m no-arrow">
                    <ng-container *ngFor="let product of products">
                        <ng-template carouselSlide>
                            <div class="product-box product-wrap">
                                <app-product-box-two [product]="product" [currency]="productService?.Currency">
                                </app-product-box-two>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->

<!-- collection banner -->
<ng-container>
    <div class="container mt-5">
        <div class="row partition3">
            <div *ngIf="filteredOfferDocumentOne?.length > 0 ;then contentOne else default_contentOne"></div>
            <ng-template #contentOne>
                <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentOne">
                    <a *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="doc.urls" class="img-fluid" alt="Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <ng-template #default_contentOne>
                <div class="col-md-4">
                    <a *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/banner1.jpg"
                                    class="img-fluid" alt=" Defualt Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <!-- Popup Form one start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessageOffer }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size
                                                of
                                                672px x 310px.
                                            </div>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerOne,f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitOffer(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form one end -->
            <div *ngIf="filteredOfferDocumentTwo?.length > 0; then contentTwo else default_contentTwo"></div>
            <ng-template #contentTwo>
                <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentTwo">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="doc.urls" class="img-fluid" alt="Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <ng-template #default_contentTwo>
                <div class="col-md-4">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/banner2.jpg"
                                    class="img-fluid" alt=" Defualt Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <!-- Popup Form two start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessageOffer }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size
                                                of
                                                672px x 310px.
                                            </div>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerTwo,f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitOffer(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form two end -->
            <div *ngIf="filteredOfferDocumentThree?.length > 0; then contentThree else default_contentThree"></div>
            <ng-template #contentThree>
                <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentThree">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="doc.urls" class="img-fluid" alt="Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <ng-template #default_contentThree>
                <div class="col-md-4">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
                        <div class="p-left">
                            <div class="image-container">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/banner.jpg"
                                    class="img-fluid" alt="Defualt Offer Banner">
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
            <!-- Popup Form three start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenThree">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessageOffer }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferThree($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                672px x 310px.
                                            </div>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerThree" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerThree,f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitOffer(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form three end -->
        </div>
    </div>
</ng-container>
<!-- collection banner end -->

<!-- product-box -->
<section class="section-b-space"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class="full-box">
        <div class="container">
            <div class="title4">
                <h2 class="title-inner4">special products</h2>
                <div class="line">
                    <span></span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:0:2">
                                    <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 center-slider">
                    <div>
                        <div class="offer-slider">
                            <div>
                                <div class="product-box product-wrap" *ngFor="let product of products | slice:4:5">
                                    <app-product-box-two [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-two>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:2:4">
                                    <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product-box end -->

<!-- blog section start-->
<section class="blog blog-bg section-b-space"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <div class="container">
        <div class="title4">
            <h2 class="title-inner4">Recent Story</h2>
            <div class="line">
                <span></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-blog [blogs]="blogs"></app-blog>
            </div>
        </div>
    </div>
</section>
<!-- blog section end -->

<!-- services start-->
<div class="container section-t-space section-b-space"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <section class="service border-section small-section">
        <app-services></app-services>
    </section>
</div>
<!-- services End -->


<!-- Cart Open To Left Side-->
<app-cart-variation [direction]="'left'"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree "></app-cart-variation>
<app-alert-popup></app-alert-popup>
<app-footer-one [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree "></app-footer-one>