import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {


  transform(value: string, visibleLength: number = 0, maskChar: string = 'X'): string {
    if (!value) {
      return value;
    }

    const maskedSection = maskChar.repeat(Math.max(0, value.length - visibleLength));
    const visibleSection = value.slice(0, visibleLength);
    return `${visibleSection}${maskedSection}`;
  }

}
