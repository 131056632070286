<!--modal popup start-->
<ng-template class="theme-modal" #alertpopup let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="offer-content">
                                <h2>Shop Inactive</h2>
                                <h5 style="text-align: center;">Your shop is currently inactive. Please contact our support team for assistance.</h5>
                                <form>
                                    <div class="form-group mx-sm-3">
                                        <a type="submit" class="btn btn-solid" href="http://localhost:50704">Home</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->