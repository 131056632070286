import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { LocationService } from '../../services/location.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-home-left-menu',
  templateUrl: './home-left-menu.component.html',
  styleUrls: ['./home-left-menu.component.scss']
})
export class HomeLeftMenuComponent implements OnInit {
  public menuItems: any=[];
  public countries: any;
  public states: any;
  public cities: any;
  public topSelectedState: any;
  public topSelectedCity: any;
  public shops: any = [];

  selectedTasks = [];
  dropdownSettings: IDropdownSettings;
  selectedCity = [];
  dropdownSetting: IDropdownSettings;

  public placeholder = 'Search any shop you want...';
  public keyword = 'name';
  public search: boolean = false;

  constructor(private router: Router, public navServices: NavService, public countryStateCitySrv: LocationService , private homeSrv: HomepageService) {
    this.navServices.leftMenuItems.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }



  ngOnInit(): void {

    //get countries
    this.countryStateCitySrv.getAllCountries().subscribe((data: any) => {
      this.countries = data;
    });

    this.onChangeCountry();

    this.dropdownSettings = {
      singleSelection: true,
      textField: 'name',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };

    this.dropdownSetting = {
      singleSelection: true,
      textField: 'city',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };

  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item:any) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  selectEvent(item: any) {
    this.router.navigate(['shops/search/', item.name], { queryParamsHandling: 'merge' });
  }

  onChangeSearch(name: string) {
    this.homeSrv.shopByName(name).subscribe((data: any) => {
      this.shops = data;
    });
  }

  onFocused(e: any) {
  }

  searchToggle() {
    this.search = !this.search;
  }

  onDropdownClick(event: Event): void {
    event.stopPropagation();
  }

  onItemSelectCity(item: any) {
  }

  OnItemDeSelectCity(item: any) {
  }

  onDeSelectAllCity(items: any) {
  }

  public onDropDownCloseCity(item: any) {
  }

  public onDeSelectCity(item: any) {
  }

  onClickCity(item: any) {

  }

  OnItemDeSelect(item: any) {
  }

  onSelectAll(items: any) {
  }

  onDeSelectAll(items: any) {
  }

  public onDropDownClose(item: any) {
  }

  public onDeSelect(item: any) {
  }

  public onChangeState(name: any) {
    this.topSelectedState = name;
    if (name) {
      this.countryStateCitySrv.getCitiesByStateName(name).subscribe(
        data => {
          this.cities = data
        }
      );
      this.countryStateCitySrv.getTopShopByState(this.topSelectedState);
    } else {
      this.cities = null;
    }
  }

  public onChangeCity(name: any) {
    this.topSelectedCity = name;
    if (name) {
      this.countryStateCitySrv.getTopShopByCity(this.topSelectedCity);

    } else {
      this.cities = null;
    }
  }

  public onChangeCountry() {
    this.countryStateCitySrv.getStatesByCountriesName().subscribe(
      data => {
        this.states = data;
        this.countryStateCitySrv.getTopShopByCountry();
      }
    );

  }

}
