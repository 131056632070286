<div class="container mb-3">
    <div class="row">
        <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 mt-4">
            <ng-multiselect-dropdown [placeholder]="'State'" [data]="states" [settings]="dropdownSettings"
                (onSelect)="onChangeState($event.name)" (onDropDownClose)="onDropDownClose($event)"
                (onDeSelect)="onDeSelect($event)">
                <ng-template ng-multiselect-dropdown-label-tmpl>
                    <i class="fa fa-map-marker"></i>
                    <span>Select State</span>
                </ng-template>
            </ng-multiselect-dropdown>
        </div>

        <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 mt-4">
            <ng-multiselect-dropdown [placeholder]="'City'" [data]="cities" [settings]="dropdownSetting"
                (onSelect)="onChangeCity($event.name)" (onDropDownClose)="onDropDownCloseCity($event)"
                (onDeSelect)="onDeSelectCity($event)">
                <ng-template ng-multiselect-dropdown-label-tmpl>
                </ng-template>
            </ng-multiselect-dropdown>
        </div>

        <div class="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-4">
            <button type="button" class="btn btn-primary">
                <i class="fa fa-search"></i>
            </button>
            <ng-autocomplete [data]="shops" [searchKeyword]="keyword" [placeholder]="placeholder"
                [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate"
                (selected)="selectEvent($event)" (inputChanged)="onChangeSearch($event)"
                (inputFocused)="onFocused($event)"></ng-autocomplete>
            <ng-template #itemTemplateStatic let-item>
                <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFoundShop>
                <div [innerHTML]="notFoundShop"></div>
            </ng-template>
        </div>
    </div>
</div>