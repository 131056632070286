<app-header-four [themeLogo]="themeLogo"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen"></app-header-four>

<!-- Home slider start-->
<section class="p-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'pets'" [buttonText]="'shop now'">
  </app-slider>
</section>
<!-- Home slider End -->

<!--  logo section start-->
<section class="section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logos"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End-->

<!-- Banner section -->
<section class="pt-0 banner-6" *ngIf="!isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row partition3">
      <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then content_one else default_content_one"></div>
      <ng-template #content_one>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsOne;">
          <a
            *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left" *ngIf="!isOfferBannerFormOpenOne">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_one>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenOne"
                (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/pet-col-2.jpg" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>Clothes</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>

      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only
                        JPG, JPEG , PNG files are allowed, Maximum file size of
                        440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                    <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->

      <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then content_two else default_content_two"></div>
      <ng-template #content_two>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsTwo;">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenTwo"
                (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_two>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenTwo"
                (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/pet-col-2.jpg" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>groom</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>

      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only
                        JPG, JPEG , PNG files are allowed, Maximum file size of
                        440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                    <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->

      <div *ngIf="filteredOfferDocumentsThree?.length > 0 ;then content_three else default_content_three"></div>
      <ng-template #content_three>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsThree; let j = index">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenThree"
                (click)="openOfferFormThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_three>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenThree"
                (click)="openOfferFormThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/pet-col-3.jpg" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>food</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenThree">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferThree($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only
                        JPG, JPEG , PNG files are allowed, Maximum file size of
                        440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerThree" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerThree, f)">
                    <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->
    </div>
  </div>
</section>
<!-- banner section End -->

<!-- Banner2  section -->
<section class="pt-0 banner-6" *ngIf="!isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row partition3 banner-top-cls">
      <div *ngIf="filteredOfferDocumentsFour?.length > 0 ;then content_four else default_content_four"></div>
      <ng-template #content_four>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsFour; let j = index">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenFour"
                (click)="openOfferFormFour()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_four>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenFour"
                (click)="openOfferFormFour()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/col2.png" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>home</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenFour">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferFour($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only
                        JPG, JPEG , PNG files are allowed, Maximum file size of
                        440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerFour" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerFour, f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->

      <div *ngIf="filteredOfferDocumentsFive?.length > 0 ;then content_five else default_content_five"></div>
      <ng-template #content_five>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsFive; let j = index">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenFive"
                (click)="openOfferFormFive()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_five>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenSix">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenFive"
                (click)="openOfferFormFive()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/col3.png" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>cats</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenFive">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferFive($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only
                        JPG, JPEG , PNG files are allowed, Maximum file size of
                        440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerFive" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerFive, f)">
                    <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->

      <div *ngIf="filteredOfferDocumentsSix?.length > 0 ;then content_six else default_content_six"></div>
      <ng-template #content_six>
        <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentsSix;">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenSix"
                (click)="openOfferFormSix()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>{{doc?.textTittle}}</h2>
                  <h4 class="fontcolor">{{doc?.textSubTittle}}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <ng-template #default_content_six>
        <div class="col-md-4">
          <a
            *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive">
            <div class="collection-banner p-left">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isOfferBannerFormOpenSix"
                (click)="openOfferFormSix()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="assets/images/col4.png" class="img-fluid" alt="pets">
              <div class="contain-banner banner-3">
                <div>
                  <h2>bowls</h2>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isOfferBannerFormOpenSix">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferSix($event)">
                  <ngx-dropzone-label accept=".jpeg,.jpg,.png">
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of 440px x 219px.
                      </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerSix" [removable]="true"
                    (removed)="onRemoveItem(uploadOfferBannerSix, f)">
                    <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Ending-->
    </div>
  </div>
</section>
<!-- banner2  section End -->

<!-- Product slider -->
<section class="section-b-space j-box pets-box"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 title5">
          <h4>special offer</h4>
          <h2 class="title-inner1">top collection</h2>
          <hr role="tournament6">
        </div>
        <owl-carousel-o class="product-4 product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:16">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-product-box-one [product]="product" [currency]="productService?.Currency">
                </app-product-box-one>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Product slider end -->

<!-- Parallax banner -->
<div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
<ng-template #bG_content>
  <section class="p-0 pet-parallax"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
    <div>
      <div class="full-banner parallax parallax-banner19  text-center p-center"
        *ngFor="let bgDoc of filtereBackgroundDocuments" [style.backgroundImage]="getEncodedImageUrl(bgDoc?.urls)">
        <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isBackgroundBannerFormOpen"
          (click)="openBackgroundForm()">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </a>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner-contain">
                <h4>choose what you love</h4>
                <h3>{{bgDoc?.textTittle}}</h3>
                <p>
                  {{bgDoc?.textSubTittle}}
                </p>
                <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'pets'}"
                  class="btn btn-solid black-btn">
                  shop now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="pet-decor">
          <img src="http://themes.pixelstrap.com/multikart/assets/images/dog.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      1920px X 600px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                  (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                  <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Description</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner end -->

<!-- Parallax default banner -->
<ng-template #defualt_content>
  <section class="p-0 pet-parallax"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix">
    <div>
      <div class="full-banner parallax parallax-banner19  text-center p-center"
        [ngStyle]="{'background-image': 'url(assets/images/parallerBannerPet.png)'}">
        <a class="fa-pull-right edit-icon" *ngIf="isEditable() && !isBackgroundBannerFormOpen"
          (click)="openBackgroundForm()">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </a>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner-contain">
                <h4>choose what you love</h4>
                <h3>get upto 70% off</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book.
                </p>
                <a class="btn btn-solid black-btn">shop now</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pet-decor">
          <img src="http://themes.pixelstrap.com/multikart/assets/images/dog.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      1920px X 600px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                  (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Description</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax default banner end -->

<!-- blog section start-->
<section class="section-b-space blog p-t-0 blog_box"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- Cart Open To Left Side-->
<app-cart-variation [direction]="'left'"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen"></app-cart-variation>
<app-alert-popup></app-alert-popup>
<div
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen">
  <app-footer-four [class]="'footer-light pet-layout-footer'" [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isOfferBannerFormOpenFive && !isOfferBannerFormOpenSix && !isBackgroundBannerFormOpen"></app-footer-four>
</div>