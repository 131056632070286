import { Component, OnInit } from '@angular/core';
import { ProductSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vegetable',
  templateUrl: './vegetable.component.html',
  styleUrls: ['./vegetable.component.scss']
})
export class VegetableComponent implements OnInit {

  public products: any[] = [];
  public ProductSliderConfig: any = ProductSlider;
  public isEditableMode: boolean = false;
  public isOfferBannerFormOpen: boolean = false;
  public documentForm: FormGroup;
  public generatedShopId: any;
  public documents: any;
  public errorMessageOffer: string;
  public uploadOfferBanner: any;
  public filteredOfferDocuments: any;

  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService,) {
    this.productService.getProducts.subscribe(response =>{
      this.products = response;
    });
    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: ['offer-banner'],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  // Sliders
  public sliders = [{
    title: 'save 10%',
    subTitle: 'fresh vegetables',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/39.jpg'
  }, {
    title: 'save 10%',
    subTitle: 'fresh vegetables',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/38.jpg'
  }];

  // Blogs
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/vegetables/blog/1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/vegetables/blog/2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/vegetables/blog/3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/vegetables/blog/4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }]

  ngOnInit(): void {

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  openOfferForm() {
    this.isOfferBannerFormOpen = true;
  }

  closeOfferForm() {
    this.isOfferBannerFormOpen = false;
  }

  onSelectOffer = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;

    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only .';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 1280) {
          if (!this.uploadOfferBanner) {
            this.uploadOfferBanner = [];
          }
          this.uploadOfferBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 1280px`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 1280px';
      this.uploadOfferBanner = []; // Clear the array if the count is not equal to 2
    }
  }

  onRemoveOffer(event) {
    const indexToRemove = this.uploadOfferBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadOfferBanner.splice(indexToRemove, 1);
    }
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner';
      });
    });
  }

  public onSubmitOffer(form: any): void {
    if (form.valid && this.uploadOfferBanner.length > 0) {
      this.handleOfferDocument(this.documentForm.value);
    }
  }

  public handleOfferDocument(document: any): void {
    this.addOfferDocument(document, this.uploadOfferBanner);
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }
}
