<div class="main-navbar">
    <div id="mainnav">
        <div class="toggle-nav" (click)="mainMenuToggle()">
            <i class="fa fa-bars sidebar-bar"></i>
        </div>
        <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

            <li class="back-btn">
                <div class="mobile-back text-end" (click)="mainMenuToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                </div>
            </li>
            <h4 class="categories">Categories</h4>
            <li *ngFor="let type of shopType">
                <a [routerLink]="['/browse/shops']" [queryParams]="{category: replaceSpecialCharWithHyphen(type.name) }" class="nav-link">
                    {{ type.name }}
                </a>
            </li>
        </ul>
    </div>
</div>