<app-header-three *ngIf="!isBackgroundBannerFormOpen"></app-header-three>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isBackgroundBannerFormOpen">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'fashion'" [buttonText]="'shop now'">
  </app-slider>
</section>

<!-- Home slider End -->
<div class="container box-layout bg-image" [ngStyle]="{'background-image': 'url()'}">
  <!-- Product slider start-->
  <section class="section-b-space">
    <div class="container">
      <div class="title1">
        <h4>special offer</h4>
        <h2 class="title-inner1">top collection</h2>
      </div>
      <div class="row">
        <div class="col-xl-6 offset-xl-3">
          <div class="product-para">
            <p class="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's
              standard dummy text ever since the 1500s,</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isBackgroundBannerFormOpen">
        <div class="col">
          <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
            <ng-container *ngFor="let product of products | slice:0:16">
              <ng-template carouselSlide>
                <div class="product-box">
                  <app-product-box-one [product]="product" [currency]="productService?.Currency"
                    [onHowerChangeImage]="true" [cartModal]="true">
                  </app-product-box-one>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  <!-- Product slider End -->


  <!-- Parallax banner start-->
  <div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
  <ng-template #bG_content>
    <section class="p-0">
      <div class="full-banner parallax-banner1 parallax text-center p-left"
        *ngFor="let bgDoc of filtereBackgroundDocuments" [style.backgroundImage]="getEncodedImageUrl(bgDoc?.urls)">
        <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </a>
        <!-- <img [src]="bgDoc?.urls" alt=""> -->
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner-contain">
                <h2>2023</h2>
                <h3 style="color:white">{{bgDoc?.textTittle}}</h3>
                <h4 style="color:white">{{bgDoc?.textSubTittle}}</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Popup Form -->
        <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
          <div class="confirmation-content" [formGroup]="documentForm">
            <!-- Add a small tag for displaying messages at the top -->
            <small class="text-danger">{{ errorMessageOffer }}</small>
            <div class="container mt-3">
              <div class="row">
                <div class="card-body dropzone-custom p-0">
                  <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                    <ngx-dropzone-label>
                      <div class="dz-message needsclick">
                        <i class="fa fa-cloud-upload"></i>
                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                          1920px x 1200px.
                      </div>
                      </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                      (removed)="onRemoveBackground(f)">
                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </ngx-dropzone>
                </div>
                <div class="row justify-content-around mt-4">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                    </label>
                    <input class="form-control" id="textTittle" type="text" name="textTittle"
                      formControlName="textTittle">
                  </div>
                  <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                    <label class="col-form-label pt-0">Text Subtittle</label>
                    <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                      formControlName="textSubTittle">
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <button class="btn btn-success pull-right" type="submit"
                (click)="onSubmitBackground(documentForm)">Confirm</button>
              <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <!-- Parallax banner End -->

  <!-- Parallax banner start-->
  <ng-template #defualt_content>
    <section class="p-0">
      <div class="full-banner parallax-banner1 parallax text-center p-left"
        [ngStyle]="{'background-image': 'url(assets/images/toys-background-image.png'}">
        <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
        </a>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="banner-contain">
                <h2>2024</h2>
                <h3 style="color:white">XBOX Games Toys</h3>
                <h4 style="color:white">special offer</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Popup Form -->
        <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
          <div class="confirmation-content" [formGroup]="documentForm">
            <!-- Add a small tag for displaying messages at the top -->
            <small class="text-danger">{{ errorMessageOffer }}</small>
            <div class="container mt-3">
              <div class="row">
                <div class="card-body dropzone-custom p-0">
                  <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                    <ngx-dropzone-label>
                      <div class="dz-message needsclick">
                        <i class="fa fa-cloud-upload"></i>
                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                          1920px x 1200px.
                      </div>
                      </div>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                      (removed)="onRemoveBackground(f)">
                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </ngx-dropzone>
                </div>
                <div class="row justify-content-around mt-4">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                    </label>
                    <input class="form-control" id="textTittle" type="text" name="textTittle"
                      formControlName="textTittle">
                  </div>
                  <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                    <label class="col-form-label pt-0">Text Subtittle</label>
                    <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                      formControlName="textSubTittle">
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <button class="btn btn-success pull-right" type="submit"
                (click)="onSubmitBackground(documentForm)">Confirm</button>
              <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <!-- Parallax banner End -->


  <!-- Tab product start-->
  <div class="title1 section-t-space" *ngIf="!isBackgroundBannerFormOpen">
    <h4>exclusive products</h4>
    <h2 class="title-inner1">special products</h2>
  </div>
  <section class="section-b-space p-t-0" *ngIf="!isBackgroundBannerFormOpen">
    <div class="container">
      <div class="row">
        <div class="col theme-tab">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
              <a ngbNavLink>
                {{ collection }}
              </a>
              <ng-template ngbNavContent>
                <div class="no-slider row">
                  <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                    <div class="product-box">
                      <app-product-box-one [product]="product" [currency]="productService?.Currency">
                      </app-product-box-one>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- Tab product End -->
</div>

<!-- blog section start-->
<section class="blog p-t-0 section-b-space" *ngIf="!isBackgroundBannerFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title1 section-t-space">
                    <h4>Recent Story</h4>
                    <h2 class="title-inner1">from the blog</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-blog [blogs]="blogs"></app-blog>
            </div>
        </div>
    </div>
</section>
<!-- blog section End -->

<app-newsletter *ngIf="!isBackgroundBannerFormOpen"></app-newsletter>
<app-alert-popup ></app-alert-popup>
<app-footer-one *ngIf="!isBackgroundBannerFormOpen"></app-footer-one>