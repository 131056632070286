<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class" [formGroup]="documentForm">
	<div *ngIf="filteredHomeDocuments?.length > 0 ;then content else other_content"></div>
	<ng-template #content>
		<ng-container *ngFor="let doc of filteredHomeDocuments">
			<ng-template carouselSlide>
				<div class="home" [ngClass]="textClass">
					<div class="image-container" style="position: relative;">
						<a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openForm()">
							<i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
						</a>
						<img [src]="doc.urls" class="img-fluid" alt="Offer Banner">
						<div class="container">
							<div class="row">
								<div class="col">
									<div class="slider-contain">
										<div class="text-overlay">
											<h4>{{doc?.textSubTittle}}</h4>
											<h1>{{doc?.textTittle}}</h1>
											<a [routerLink]="['/shop/collection/left/sidebar']"
												[queryParams]="{ category: category}" class="btn btn-solid"
												[ngClass]="buttonClass">
												{{buttonText}}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Popup Form -->
					<div class="popup-form" *ngIf="isBannerFormOpen">
						<div class="confirmation-content">
							<!-- Add a small tag for displaying messages at the top -->
							<small class="text-danger">{{ errorMessage }}</small>
							<div class="container mt-2">
								<div class="row">
									<div class="card-body dropzone-custom p-0">
										<ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
											<ngx-dropzone-label accept=".jpeg,.jpg,.png">
												<div class="dz-message needsclick">
													<i class="fa fa-cloud-upload"></i>
													<h4 class="mb-0 f-w-600">Drop files here or click to upload.
													</h4>
													<div class="jsx-20226804ddef3094 font16 fw400 color111 mt-14">Only
														JPG, JPEG , PNG files are allowed, Maximum file size of 1920px x
														718px.
													</div>
												</div>
											</ngx-dropzone-label>
											<ngx-dropzone-preview *ngFor="let f of filesToUpload" [removable]="true"
												(removed)="onRemove(f)">
												<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
											</ngx-dropzone-preview>
										</ngx-dropzone>
									</div>
									<div class="row justify-content-around mt-4">
										<div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
												class="col-form-label pt-0">Text Tittle
											</label>
											<input class="form-control" id="textTittle" type="text" name="textTittle"
												formControlName="textTittle">
										</div>
										<div class="cool-12 col-sm-12 col-md-6 col-lg-6">
											<label class="col-form-label pt-0">Text Subtittle</label>
											<input class="form-control" id="textSubTittle" type="text"
												name="textSubTittle" formControlName="textSubTittle">
										</div>
									</div>
								</div>
							</div>
							<div class="mt-3">
								<button class="btn btn-success pull-right" type="submit"
									(click)="onSubmitDocument(documentForm)">Confirm</button>
								<button class="btn btn-primary pull-right" (click)="closeForm()">Cancel</button>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</ng-template>
	<ng-template #other_content>
		<ng-container *ngFor="let slider of sliders">
			<ng-template carouselSlide>
				<div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+slider.image+')'}">
					<a class="fa-pull-right" *ngIf="isEditable()" (click)="openForm()">
						<i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
					</a>
					<div class="container">
						<div class="row">
							<div class="col">
								<div class="slider-contain">
									<div>
										<h4>{{slider.title}}</h4>
										<h1>{{slider.subTitle}}</h1>
										<a [routerLink]="['/shop/collection/left/sidebar']"
											[queryParams]="{ category: category}" class="btn btn-solid"
											[ngClass]="buttonClass">
											{{buttonText}}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Popup Form -->
					<div class="popup-form" *ngIf="isBannerFormOpen">
						<div class="confirmation-content">
							<!-- Add a small tag for displaying messages at the top -->
							<small class="text-danger">{{ errorMessage }}</small>
							<div class="container mt-3">
								<div class="row">
									<div class="card-body dropzone-custom p-0">
										<ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
											<ngx-dropzone-label accept=".jpeg,.jpg,.png">
												<div class="dz-message needsclick">
													<i class="fa fa-cloud-upload"></i>
													<h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
													<div class="mt-14">Only
														JPG, JPEG , PNG files are allowed, Maximum file size of
														1920 x 718.
													</div>
												</div>
											</ngx-dropzone-label>
											<ngx-dropzone-preview *ngFor="let f of filesToUpload" [removable]="true"
												(removed)="onRemove(f)">
												<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
											</ngx-dropzone-preview>
										</ngx-dropzone>
									</div>
									<div class="row justify-content-around mt-4">
										<div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
												class="col-form-label pt-0">Text Tittle
											</label>
											<input class="form-control" id="textTittle" type="text" name="textTittle"
												formControlName="textTittle">
										</div>
										<div class="cool-12 col-sm-12 col-md-6 col-lg-6">
											<label class="col-form-label pt-0">Text Subtittle</label>
											<input class="form-control" id="textSubTittle" type="text"
												name="textSubTittle" formControlName="textSubTittle">
										</div>
									</div>
								</div>
							</div>
							<div class="mt-5">
								<button class="btn btn-success pull-right" type="submit"
									(click)="onSubmitDocument(documentForm)">Confirm</button>
								<button class="btn btn-primary pull-right" (click)="closeForm()">Cancel</button>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</ng-template>
</owl-carousel-o>