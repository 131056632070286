import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class MenuItemService {

  menu_items = environment.base_url_menu_items_service;

  constructor(private httpClient: HttpClient) { }

  public getMenuItems() {
    return this.httpClient.get(`${this.menu_items}/menus-items`);``
  }
  //add one shop 

  public getMeutItemsByShopIdAndActive() {
    return this.httpClient.get(`${this.menu_items}/menus-items-main?shopId=${this.getShopId()}&tenantId=${this.getTenantId()}&active=true&mainMenu=true`);
  }

  public getLeftSideMenuByShopIdAndActive() {
    return this.httpClient.get(`${this.menu_items}/menus-items-left?shopId=${this.getShopId()}&tenantId=${this.getTenantId()}&active=true&leftMenuItems=true`);
}


  getTenantId(): any {
    return sessionStorage.getItem("TENANTID");
  }
  getShopId(): any {
    return sessionStorage.getItem("shopId")
  }

}