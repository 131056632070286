<div [ngSwitch]="selectedshopType">
    <div *ngSwitchCase="'Fashion'">
        <app-fashion-two></app-fashion-two>
    </div>
    <div *ngSwitchCase="'Toys'">
        <app-fashion-three></app-fashion-three>
    </div>
    <div *ngSwitchCase="'Games'">
        <app-fashion-one></app-fashion-one>
    </div>
    <div *ngSwitchCase="'Vegitable'">
        <app-vegetable></app-vegetable>
    </div>
    <div *ngSwitchCase="'Watch'">
        <app-watch></app-watch>
    </div>
    <div *ngSwitchCase="'Furniture'">
        <app-furniture></app-furniture>
    </div>
    <div *ngSwitchCase="'Flower'">
        <app-flower></app-flower>
    </div>
    <div *ngSwitchCase="'Beauty'">
        <app-beauty></app-beauty>
    </div>
    <div *ngSwitchCase="'Electronics'">
        <app-electronics></app-electronics>
    </div>
    <div *ngSwitchCase="'Pets'">
        <app-pets></app-pets>
    </div>
    <div *ngSwitchCase="'Gym'">
        <app-gym></app-gym>
    </div>
    <div *ngSwitchCase="'AutoMobile and Part'">
        <app-tools></app-tools>
    </div>
    <div *ngSwitchCase="'Shoes'">
        <app-shoes></app-shoes>
    </div>
    <div *ngSwitchCase="'Bags'">
        <app-bags></app-bags>
    </div>
    <div *ngSwitchCase="'Food'">
        <app-marijuana></app-marijuana>
    </div>
    <div *ngSwitchDefault><router-outlet></router-outlet></div>
</div>