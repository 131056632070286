<app-header-home [sticky]="true"></app-header-home>
<section class="bg-color">
    <div class="container">
        <div class="row">
            <div class="terms-des">
                <h3 class="text-heading-2 color-gray-1000 text-gradient">Terms and Conditions</h3>
                <p class="text-ad font15">
                    These terms and conditions apply to your use of Shopdial's Website, whether accessed online as a
                    website or e-commerce application. By using this website, you agree to comply with these terms and
                    conditions, which also incorporate our Privacy Statement and Disclaimer Notice. If you do not agree
                    with any aspect of these terms, please refrain from using our website. For inquiries about these
                    terms and conditions, please contact us using the information provided on the website.
                </p>
            </div>
            <div class="d-lg-flex d-md-flex flex-wrap">
                <div class="col-12 col-md-12 col-xl-4 col-lg-4 mb-4">
                    <div class="card pb-4">
                        <span class="fa fa-pie-chart mt-3"></span>
                        <p class="h4 pt-4 font16 fw600">Cookies</p>
                        <br>
                        <p class="p1">
                            Our website uses cookies to enhance your browsing experience. By accessing shopdial.in, you
                            agree to our use of cookies as outlined in Shopdial's Terms and Conditions and Privacy
                            Policy. Cookies are commonly used by interactive websites to retrieve user details for each
                            visit. These cookies help us provide access to the website and deliver a more personalized
                            and responsive service by storing information about your preferences.
                        </p>
                        <p class="p1"><b class="sub-heading"> Services Offered :</b><br>
                            Our platform uses cookies to enhance your browsing experience. Cookies enable personalized
                            product recommendations, smooth navigation, and secure checkout processes. By continuing to
                            use our website, you consent to our use of cookies in accordance with our Privacy Policy.
                            Enjoy a seamless shopping experience tailored to your preferences.
                        </p>
                        <p class="p1"> <b class="sub-heading">Platform Content and Cookie Usage :</b><br>
                            Content on this platform utilizes small text files known as cookies. These files contain
                            minimal information and are stored on your computer or device by our website's server. Each
                            time you revisit our site, your web browser sends these cookies back to us, allowing us to
                            recognize and recall details such as your user preferences. The content you engage with is
                            customized to your interests and preferences, ensuring a personalized and engaging
                            experience. This process enables us to continuously enhance our services.They streamline
                            your browsing, personalize your experience, and help us continuously improve. We empower you
                            with control over your cookies, ensuring you have a comfortable and engaging experience on
                            our platform.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-xl-4 col-lg-4 mb-4">
                    <div class="card pb-4">
                        <span class="fa fa-id-card-o mt-3"></span>
                        <p class="h4 pt-4 font16 fw600">License and Intellectual Property Rights</p><br>
                        <p class="p1">
                            Unless otherwise stated, the intellectual property rights for all material on shopdial.in
                            belong to its licensors. This website and its contents are protected by copyright,
                            trademark, and other laws in India and foreign countries. We and our licensors reserve all
                            rights not expressly granted under these Terms of Use. You may access this website for your
                            personal use, subject to the restrictions set forth in these terms and conditions.We and our
                            licensors hold all rights that aren't expressly granted to you through these Terms of Use.
                        </p>
                        <p class="p1"> <b class="sub-heading">Restrictions on Use :</b><br>
                            You may not use any of the names, marks, or logos mentioned here in any press release,
                            advertisement, or other promotional or marketing material, either alone or in combination
                            with other words or design elements, without prior written permission.This safeguards our
                            brand identity and prevents confusion.
                        </p>
                        <p class="p1"> <b class="sub-heading">Limitation of Liability :</b><br>
                            We and our licensors shall not be liable for any direct, indirect, incidental, special,
                            consequential, or punitive damages arising from or related to your use of this website or
                            the content herein. This includes damages for loss of profits, goodwill, use, data, or other
                            intangible losses, even if we have been advised of the possibility of such damages. It
                            covers
                            direct (immediate financial losses) and indirect damages (like lost business opportunities)
                            arising from various scenarios, including negligence on our part. Even if we are aware of
                            potential risks, this clause limits our liability. This protects both you, by clarifying
                            what to expect, and us, by managing our legal.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-xl-4 col-lg-4 mb-4">
                    <div class="card pb-4">
                        <span class="fa fa-leaf mt-3"></span>
                        <p class="h4 pt-4 font16 fw600">Disclaimers and Limitations of Liability</p><br>
                        <p class="p1">
                            The information on this website may not always be accurate or complete, and we do not
                            guarantee its correctness or availability. We do not promise to keep the website up to date.
                            This website, including its content, provides general information only, and it does not
                            constitute professional advice or services. Before making decisions that could impact your
                            finances or business, it is advisable to seek guidance from a qualified professional
                            advisor.
                        </p>
                        <p class="p1"> <b class="sub-heading">Contacting the Seller :</b><br>
                            This website is provided "as is," and we do not make any express or implied representations
                            or warranties about its reliability or performance. Specifically, we do not warrant that the
                            website will be secure, free from errors, viruses, or other harmful code, or that it will
                            meet any specific performance or quality criteria. The use of this website does not imply
                            the provision of professional advice or services. For matters affecting your finances or
                            business decisions, consulting with a qualified professional advisor is recommended.
                        </p>
                        <p class="p1"> <b class="sub-heading">Termination :</b> <br>
                            This website is provided on an "as is" basis, and we make no explicit or implicit
                            representations or warranties about its operation or reliability. We do not guarantee that
                            the website will be secure, free of errors, viruses, or other harmful components, or that it
                            will meet any specific performance or quality standards. The information provided on this
                            website is not intended to substitute professional advice or services. It is important to
                            consult with a qualified professional advisor before making any decisions that could affect
                            your finances.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-home-footer [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-home-footer>