import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { HomeSlider } from '../../../shared/data/slider';
import { UserService } from 'src/app/shared/services/user.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;
  public isEditableMode: boolean = false;
  public documentForm: FormGroup;
  public filesToUpload: any;
  public generatedShopId: any;
  public documentsBanner: any;
  public isBannerFormOpen: boolean = false;
  public errorMessage: string;
  public filteredHomeDocuments: any;


  constructor(private userSrv: UserService, private docSrv: ShopdocumentService, private toaster: ToastrService, private fb: FormBuilder, private sanitizer: DomSanitizer) {

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: ['home-banner'],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  ngOnInit(): void {

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  public HomeSliderConfig: any = HomeSlider;

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }


  onSelect = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if ((image.width === 1920 && image.height === 718) || (image.width === 1920 && image.height === 1080)) {
          if (!this.filesToUpload) {
            this.filesToUpload = [];
          }
          this.filesToUpload.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with size 1920px X 718px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }


  onRemove(event) {
    const indexToRemove = this.filesToUpload.indexOf(event);
    if (indexToRemove !== -1) {
      this.filesToUpload.splice(indexToRemove, 1);
    }
  }

  openForm(): void {
    this.isBannerFormOpen = true;
    // Toggle the value to show/hide the header
    this.docSrv.toggleHeaderVisibility(true);
  }

  closeForm() {
    // Close the popup form.
    this.isBannerFormOpen = false;
    this.docSrv.toggleHeaderVisibility(false);
  }

  public onSubmitDocument(form: any): void {
    if (form.valid && this.filesToUpload.length > 0) {
      this.handleDocument(this.documentForm.value);
    }
  }

  public handleDocument(document: any): void {
    this.addDocument(document, this.filesToUpload);
  }

  public addDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documentsBanner = data;
      // Filter the documents based on the identifier
      this.filteredHomeDocuments = this.documentsBanner.filter((document: any) => {
        return document.identifier === 'home-banner';
      });
    });
  }
}
