<app-header-three [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"></app-header-three>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
    <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'flower'" [buttonText]="'shop now'">
    </app-slider>
</section>
<!-- Home slider End -->

<!-- collection banner -->
<div class="container mt-4">
    <div class="row partition2">
        <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then content_one else default_content_one"></div>
        <ng-template class="banner-padding pb-0" #content_one>
            <div *ngFor="let doc of filteredOfferDocumentsOne ; let i = index" class="col-md-4">
                <a>
                    <div class="collection-banner p-right text-end">
                        <a class="fa-pull-right edit-icon"
                            *ngIf="isEditable() && !isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"
                            (click)="openOfferFormOne()">
                            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                        </a>
                        <img [src]="doc?.urls" class="img-fluid" alt="banner">
                        <div class="contain-banner">
                            <div>
                                <h4>{{doc?.textTittle}}</h4>
                                <h2>{{doc?.textSubTittle}}</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ng-template>
        <ng-template class="banner-padding pb-0" #default_content_one>
            <div class="col-md-4">
                <a>
                    <div class="collection-banner p-right text-end">
                        <a class="fa-pull-right edit-icon"
                            *ngIf="isEditable() && !isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"
                            (click)="openOfferFormOne()">
                            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                        </a>
                        <img src="http://themes.pixelstrap.com/multikart/assets/images/flower/sub-banner2.jpg"
                            class="img-fluid" alt="banner">
                        <div class="contain-banner">
                            <div>
                                <h4>save upto 20%</h4>
                                <h2>creative & decor</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ng-template>
        <!-- Popup Form Content One -->
        <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
            <div class="confirmation-content" [formGroup]="documentForm">
                <!-- Add a small tag for displaying messages at the top -->
                <small class="text-danger">{{ errorMessageOfferOne }}</small>
                <div class="container mt-3">
                    <div class="row">
                        <div class="card-body dropzone-custom p-0">
                            <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                            429px x 490px.
                                        </div>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                                    (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                        <div class="row justify-content-around mt-4">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                                    Tittle
                                </label>
                                <input class="form-control" id="textTittle" type="text" name="textTittle"
                                    formControlName="textTittle">
                            </div>
                            <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                <label class="col-form-label pt-0">Text Subtittle</label>
                                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                    formControlName="textSubTittle">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <button class="btn btn-success pull-right" type="submit"
                        (click)="onSubmitForm(documentForm)">Confirm</button>
                    <button class="btn btn-primary pull-right" (click)="closeOfferPopup()">Cancel</button>
                </div>
            </div>
        </div>
        <!-- Popup Form Content One End-->

        <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then content_two else default_content_two"></div>
        <ng-template class="banner-padding pb-0" #content_two>
            <div *ngFor="let doc of filteredOfferDocumentsTwo ; let i = index" class="col-md-8">
                <a>
                    <div class="collection-banner p-right text-end">
                        <a class="fa-pull-right edit-icon"
                            *ngIf="isEditable() && !isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"
                            (click)="openOfferFormTwo()">
                            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                        </a>
                        <img [src]="doc?.urls" class="img-fluid" alt="banner">
                        <div class="contain-banner">
                            <div>
                                <h4>{{doc?.textTittle}}</h4>
                                <h2>{{doc?.textSubTittle}}</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ng-template>
        <ng-template class="banner-padding pb-0" #default_content_two>
            <div class="col-md-8">
                <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
                    <div class="collection-banner p-right text-end">
                        <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                            <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                        </a>
                        <img src="http://themes.pixelstrap.com/multikart/assets/images/flower/sub-banner1.jpg"
                            class="img-fluid" alt="banner">
                        <div class="contain-banner">
                            <div>
                                <h4>save upto 10%</h4>
                                <h2>pre-made & custom</h2>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </ng-template>
        <!-- Popup Form Content Two -->
        <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
            <div class="confirmation-content" [formGroup]="documentForm">
                <!-- Add a small tag for displaying messages at the top -->
                <small class="text-danger">{{ errorMessageOfferTwo }}</small>
                <div class="container mt-3">
                    <div class="row">
                        <div class="card-body dropzone-custom p-0">
                            <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                        <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                            890px x 490px.
                                        </div>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                                    (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                        <div class="row justify-content-around mt-4">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                                    Tittle
                                </label>
                                <input class="form-control" id="textTittle" type="text" name="textTittle"
                                    formControlName="textTittle">
                            </div>
                            <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                <label class="col-form-label pt-0">Text Subtittle</label>
                                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                    formControlName="textSubTittle">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <button class="btn btn-success pull-right" type="submit"
                        (click)="onSubmitForm(documentForm)">Confirm</button>
                    <button class="btn btn-primary pull-right" (click)="closeOfferPopup()">Cancel</button>
                </div>
            </div>
        </div>
        <!-- Popup Form Content Two End-->
    </div>
</div>
<!-- collection banner end -->

<!-- product-box slider -->
<section class="section-b-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
    <div class="full-box">
        <div class="container">
            <div class="title4">
                <h2 class="title-inner4">best selling products</h2>
                <div class="line">
                    <span></span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:0:2">
                                    <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 center-slider">
                    <div>
                        <div class="offer-slider">
                            <div>
                                <div class="product-box product-wrap text-center"
                                    *ngFor="let product of products | slice:4:5">
                                    <app-product-box-two [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-two>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="theme-card center-align">
                        <div class="offer-slider">
                            <div class="sec-1">
                                <div class="product-box2" *ngFor="let product of products | slice:2:4">
                                    <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                                    </app-product-box-vertical>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product-box slider end -->

<!-- tab section  -->
<div class="bg-block"
    [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/flower/bg.jpg)'}"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
    <section class="p-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="title4">
                        <h2 class="title-inner4">trending products</h2>
                        <div class="line">
                            <span></span>
                        </div>
                    </div>
                    <div class="theme-tab">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                            class="tabs tab-title justify-content-center nav nav-pills">
                            <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                                <a ngbNavLink>
                                    {{ collection }}
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="row product-tab">
                                        <ng-container
                                            *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                            <div class="tab-box">
                                                <div class="product-box2">
                                                    <app-product-box-vertical [product]="product"
                                                        [currency]="productService?.Currency">
                                                    </app-product-box-vertical>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- tab section end -->

<!-- product section -->
<section class="section-b-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title4">
                    <h2 class="title-inner4">trending items</h2>
                    <div class="line">
                        <span></span>
                    </div>
                </div>
                <owl-carousel-o class="product-4 product-m no-arrow" [options]="ProductSliderConfig">
                    <ng-container *ngFor="let product of products">
                        <ng-template carouselSlide>
                            <div class="product-box product-wrap">
                                <app-product-box-two [product]="product" [currency]="productService?.Currency">
                                </app-product-box-two>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->

<!-- blog section start-->
<section class="blog flower-bg section-b-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title1 section-t-space">
                    <h4>Recent Story</h4>
                    <h2 class="title-inner1">from the blog</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-blog [blogs]="blogs"></app-blog>
            </div>
        </div>
    </div>
</section>
<!-- blog section End -->

<!-- services start-->
<div class="container section-t-space section-b-space">
    <section class="service border-section small-section">
        <app-services></app-services>
    </section>
</div>
<!-- services End -->


<!-- Cart Open To bottom Side-->
<app-cart-variation [direction]="'bottom'"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"></app-cart-variation>
<app-alert-popup></app-alert-popup>
<app-footer-one [themeLogo]="themeLogo" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo"></app-footer-one>