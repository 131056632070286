// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { baseUrl } from "./base_url";

export const environment = {
  production: false,
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  base_url_master_service: `${baseUrl.base_url}/api/master-service`,
  base_url_user_service: `${baseUrl.base_url}/api/tenant-service`,
  base_url_shop_service: `${baseUrl.base_url}/api/shop-service`,
  base_url_ecom_service: `${baseUrl.base_url}/api/ecom-service`,
  base_url_menu_items_service: `${baseUrl.base_url}/api/menu-item-service`,
  client_Secret: '7OdXEeH5sY2rNIskFho9UtQ7APZnAo9O',
  numberPattern: '^[0-9]*$'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
