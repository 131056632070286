import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductSlider, ProductOneSlider } from '../../shared/data/slider';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { BlogService } from 'src/app/shared/services/blog.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss']
})
export class BagsComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-7.png'; // Change Logo
  public themeLogoWhite: string = 'assets/images/icon/logo-8.png'; // Change Logo

  public products: any[] = [];
  public productCollections: any[] = [];
  public active;
  public blogs: any;

  public documents: any;
  public isEditableMode: boolean = false;
  public documentForm: FormGroup;
  public filesToUpload: any;
  public generatedShopId: any;
  public documentsBanner: any;
  public isBannerFormOpen: boolean = false;
  public errorMessage: string;
  public filteredHomeDocuments: any;
  public filteredOfferDocuments: any;
  public filteredBackGroundDocuments: any;
  public uploadOfferBanner: any;
  public uploadBackgroundBanner: any;
  public isOfferBannerFormOpen: boolean = false;
  public errorMessageOffer: string;

  public filteredCategoryDocuments: any;
  public uploadCategoryBanner: any;
  public isCategoryBannerFormOpen: boolean = false;

  public filteredCategoryDocuments2: any;
  public uploadCategoryBanner2: any;
  public isCategoryBannerFormOpen2: boolean = false;

  public filteredCategoryDocuments3: any;
  public uploadCategoryBanner3: any;
  public isCategoryBannerFormOpen3: boolean = false;


  public ProductSliderConfig: any = ProductSlider;
  public ProductSliderOneConfig: any = ProductOneSlider;

  constructor(public productService: ProductService, private blogService: BlogService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });
    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  // Sliders
  public sliders = [{
    title: 'summer sale',
    subTitle: 'the bag',
    image: 'assets/images/bag-collection-1.jpg'
  }, {
    title: 'summer sale',
    subTitle: 'the bag',
    image: 'assets/images/bag-collection-2.jpg'
  }, {
    title: 'summer sale',
    subTitle: 'the bag',
    image: 'assets/images/bag-collection-3.jpg'
  }];

  // Categories
  public categories = ['airbag', 'burn bag', 'briefcase', 'carpet', 'money bag', 'tucker'];

  // Blog


  ngOnInit(): void {

    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#f0b54d');
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
    this.blogService.getAllBlogs().subscribe((data) => {
      this.blogs = data;
    });

  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner';
      });
      this.filteredCategoryDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'category-one';
      });
      this.filteredCategoryDocuments2 = this.documents.filter((document: any) => {
        return document.identifier === 'category-two';
      });
      this.filteredCategoryDocuments3 = this.documents.filter((document: any) => {
        return document.identifier === 'category-three';
      });
      this.filteredBackGroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  openOfferForm() {
    this.isOfferBannerFormOpen = true;
  }

  openCategoryForm() {
    this.isCategoryBannerFormOpen = true;
  }

  openCategoryFormTwo() {
    this.isCategoryBannerFormOpen2 = true;
  }

  openCategoryFormThree() {
    this.isCategoryBannerFormOpen3 = true;
  }

  closePopupForm() {
    this.isOfferBannerFormOpen = false;
    this.isCategoryBannerFormOpen = false;
    this.isCategoryBannerFormOpen2 = false;
    this.isCategoryBannerFormOpen3 = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectOffer = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBanner) {
            this.uploadOfferBanner = [];
          }
          this.uploadOfferBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectBackGroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1920 && image.height === 1080) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920 and height 1080.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectCategoryOneImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 450 && image.height === 450) {
          if (!this.uploadCategoryBanner) {
            this.uploadCategoryBanner = [];
          }
          this.uploadCategoryBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 450 and height 450.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectCategoryTwoImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 450 && image.height === 450) {
          if (!this.uploadCategoryBanner2) {
            this.uploadCategoryBanner2 = [];
          }
          this.uploadCategoryBanner2.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 450 and height 450.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectCategoryThreeImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 450 && image.height === 450) {
          if (!this.uploadCategoryBanner3) {
            this.uploadCategoryBanner3 = [];
          }
          this.uploadCategoryBanner3.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 450 and height 450.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBanner && this.uploadOfferBanner.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner");
      if (form.valid) {
        this.handleDocument(form.value, 'offer');
      }
    } else if (this.uploadCategoryBanner && this.uploadCategoryBanner.length > 0) {
      this.documentForm.get('identifier').setValue("category-one");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'categoryOne');
      }
    } else if (this.uploadCategoryBanner2 && this.uploadCategoryBanner2.length > 0) {
      this.documentForm.get('identifier').setValue("category-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'categoryTwo');
      }
    } else if (this.uploadCategoryBanner3 && this.uploadCategoryBanner3.length > 0) {
      this.documentForm.get('identifier').setValue("category-three");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'categoryThree');
      }
    } else if (this.uploadBackgroundBanner && this.uploadBackgroundBanner.length > 0) {
      this.documentForm.get('identifier').setValue("background-banner");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'background');
      }
    }
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer':
        this.addOfferDocument(document, this.uploadOfferBanner);
        break;
      case 'background':
        this.addOfferDocument(document, this.uploadBackgroundBanner);
        break;
      case 'categoryOne':
        this.addOfferDocument(document, this.uploadCategoryBanner);
        break;
      case 'categoryTwo':
        this.addOfferDocument(document, this.uploadCategoryBanner2);
        break;
      case 'categoryThree':
        this.addOfferDocument(document, this.uploadCategoryBanner3);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopupForm();
      this.removeDocumentAfterUpload();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  // remove document after upload
  public removeDocumentAfterUpload(){
    this.uploadOfferBanner= [];
    this.uploadBackgroundBanner= [];
    this.uploadCategoryBanner= [];
    this.uploadCategoryBanner2= [];
    this.uploadCategoryBanner3= [];
  }

  // encode image url
  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }

}
