import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategorySlider, ProductSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-shoes',
  templateUrl: './shoes.component.html',
  styleUrls: ['./shoes.component.scss']
})
export class ShoesComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-6.png'; // Change Logo

  public products: any[] = [];
  public productCollections: any[] = [];
  public shop: any = [];
  public categoriesList: any = [];
  public active;
  public documentForm: FormGroup;
  public documents: any;
  public generatedShopId: any;
  public errorMessageOffer: string;
  public CategorySliderConfig: any = CategorySlider;
  public ProductSliderConfig: any = ProductSlider;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentsOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerTwo: any;
  public filteredOfferDocumentsTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;

  public isCategoryBannerFormOpenOne: boolean = false;
  public uploadCategoryBannerOne: any;
  public fileterCategoryBannerOne: any;

  public isCategoryBannerFormOpenTwo: boolean = false;
  public uploadCategoryBannerTwo: any;
  public fileterCategoryBannerTwo: any;

  public isCategoryBannerFormOpenThree: boolean = false;
  public uploadCategoryBannerThree: any;
  public fileterCategoryBannerThree: any;

  constructor(public productService: ProductService, private docSrv: ShopdocumentService, private userSrv: UserService, private toaster: ToastrService, private _sanitizer: DomSanitizer, private fb: FormBuilder) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    // document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''], 
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }
  // sliders
  public sliders = [{
    title: 'special offer',
    subTitle: 'men shoes',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/5.jpg'
  }, {
    title: 'special offer',
    subTitle: 'men shoes',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/4.jpg'
  }];


  public sizes = ['size 06', 'size 07', 'size 08', 'size 09', 'size 10'];


  // Categories
  public categories = [{
    image: 'assets/images/icon/cat1.png',
    title: 'sport shoes'
  }, {
    image: 'assets/images/icon/cat2.png',
    title: 'casual shoes'
  }, {
    image: 'assets/images/icon/cat3.png',
    title: 'formal shoes'
  }, {
    image: 'assets/images/icon/cat4.png',
    title: 'flat'
  }, {
    image: 'assets/images/icon/cat5.png',
    title: 'heels'
  }, {
    image: 'assets/images/icon/cat6.png',
    title: 'boots'
  }, {
    image: 'assets/images/icon/cat1.png',
    title: 'sport shoes'
  }];

  // Collection banner
  public collections1 = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/sub-banner.png',
    save: 'save 50%',
    title: 'men'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/sub-banner1.png',
    save: 'save 50%',
    title: 'women'
  }];

  // Collection banner
  public collections2 = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/shoes/cat1.jpg',
    title: 'men'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/shoes/cat2.jpg',
    title: 'women'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/shoes/cat3.jpg',
    title: 'kids'
  }];

  // Blog
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout2/2.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout2/3.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout2/4.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout2/1.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  // Logo
  public logos = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#3fdda7');
    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });

      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });

      this.fileterCategoryBannerOne = this.documents.filter((document: any) => {
        return document.identifier === 'category-banner-one';
      });

      this.fileterCategoryBannerTwo = this.documents.filter((document: any) => {
        return document.identifier === 'category-banner-two';
      });

      this.fileterCategoryBannerThree = this.documents.filter((document: any) => {
        return document.identifier === 'category-banner-three';
      });
    });
  }

  // offer banner form open 
  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }
  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }
  openCategoryForm() {
    this.isCategoryBannerFormOpenOne = true;
  }

  openCategoryFormTwo() {
    this.isCategoryBannerFormOpenTwo = true;
  }

  openCategoryFormThree() {
    this.isCategoryBannerFormOpenThree = true;
  }
  closePopupForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isCategoryBannerFormOpenOne = false;
    this.isCategoryBannerFormOpenTwo = false;
    this.isCategoryBannerFormOpenThree = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }
 
  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 705px and height 310px.';
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }
  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-two');
      }
    } else if (this.uploadCategoryBannerOne && this.uploadCategoryBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("category-one");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'category-one');
      }
    } else if (this.uploadCategoryBannerTwo && this.uploadCategoryBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("category-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'category-two');
      }
    } else if (this.uploadCategoryBannerThree && this.uploadCategoryBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("Category-Banner-Three");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'category-three');
      }
    }
  }
  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'category-one':
        this.addOfferDocument(document, this.uploadCategoryBannerOne);
        break;
      case 'category-two':
        this.addOfferDocument(document, this.uploadCategoryBannerTwo);
        break;
      case 'category-three':
        this.addOfferDocument(document, this.uploadCategoryBannerThree);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }
  public handleOfferDocumentOne(document: any): void {
    this.addOfferDocument(document, this.uploadOfferBannerOne);
  }
  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 705px and height 310px.';
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }
  public handleOfferDocumentTwo(document: any): void {
    this.addOfferDocument(document, this.uploadOfferBannerTwo);
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.closeOfferForm();
      this.getDocumentByShopId();
      this.removeDocumentAfterUpload();
      this.documentForm.reset();

    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  closeOfferForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
  }
// remove document after upload
public removeDocumentAfterUpload(){
  this.uploadOfferBannerOne= [];
  this.uploadOfferBannerTwo= [];
  this.uploadCategoryBannerOne= [];
  this.uploadCategoryBannerTwo= [];
  this.uploadCategoryBannerThree= [];
}
  //-----category-----image------

  // offer category banner form open 
  openCategoryBannerOne() {
    this.isCategoryBannerFormOpenOne = true;
    
  }
  // on select category banner
  onSelectCategoryOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadCategoryBannerOne) {
            this.uploadCategoryBannerOne = [];
          }
          this.uploadCategoryBannerOne.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 2) {
      this.errorMessageOffer = 'Please upload an image with width 705px and height 310px.';
      this.uploadCategoryBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }

  // handle category document 
  public handleCategoryDocumentOne(document: any): void {
    this.addCategoryDocument(document, this.uploadCategoryBannerOne);
  }

  openCategoryBannerTwo() {
    this.isCategoryBannerFormOpenTwo = true;
  }
  // on select category banner
  onSelectCategoryTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadCategoryBannerTwo) {
            this.uploadCategoryBannerTwo = [];
          }
          this.uploadCategoryBannerTwo.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 2) {
      this.errorMessageOffer = 'Please upload an image with width 705px and height 310px.';
      this.uploadCategoryBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }

  

  // handle category document 
  public handleCategoryDocumentTwo(document: any): void {
    this.addCategoryDocument(document, this.uploadCategoryBannerTwo);
  }

  openCategoryBannerThree() {
    this.isCategoryBannerFormOpenThree = true;
  }
  
  // on select category banner
  onSelectCategoryThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadCategoryBannerThree) {
            this.uploadCategoryBannerThree = [];
          }
          this.uploadCategoryBannerThree.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 2) {
      this.errorMessageOffer = 'Please upload an image with width 705px and height 310px.';
      this.uploadCategoryBannerThree = []; // Clear the array if the count is not equal to 2
    }
  }

  // handle category document 
  public handleCategoryDocumentThree(document: any): void {
    this.addCategoryDocument(document, this.uploadCategoryBannerThree);
  }

  // add category document
  public addCategoryDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addDiscountBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closeCategoryBanner();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  // close category banner 
  closeCategoryBanner() {
    this.isCategoryBannerFormOpenOne = false;
    this.isCategoryBannerFormOpenTwo = false;
    this.isCategoryBannerFormOpenThree = false;
  }

  // encode image url
  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }

  get filterByCategory() {
    const category = [...new Set(this.products.map(product => product?.categories?.name))]
    return category
  }

  get documentByCategory() {
    const document = [...new Set(this.products.map(product => product?.categories?.document))]
    return document
  }
  get filterBySize() {
    const sizes = [...new Set(this.products.flatMap(product => product?.variants.map(variant => variant?.size)))];
    return sizes;
  }
  
}

