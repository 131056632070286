import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeSlider, ProductSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from 'src/app/shared/services/blog.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-5.png';

  public products: any[] = [];
  public productCollections: any[] = [];
  public active;
  tabs = [1, 2, 3, 4, 5];

  public documentForm: FormGroup;
  public errorMessage: string;
  public isEditableMode: boolean = false;
  public generatedShopId: any;
  public documents: any;

  public filteredSlideOneDocuments: any;
  public uploadSlideOneBanner: any;
  public isSlideOneBannerFormOpen: boolean = false;

  public filteredSlideTwoDocuments: any;
  public uploadSlideTwoBanner: any;
  public isSlideTwoBannerFormOpen: boolean = false;

  public filteredBackgroundDocuments: any;
  public uploadBackgroundBanner: any;
  public isBackgroundFormOpen: boolean = false;

  public filteredOfferDocuments: any;
  public uploadOfferBanner: any;
  public isOfferFormOpen: boolean = false;
  blogs: any;

  constructor(private _sanitizer: DomSanitizer,
    public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService, private blogService: BlogService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public HomeSliderConfig: any = HomeSlider;
  public ProductSliderConfig: any = ProductSlider;

  // services
  public categories = [{
    image: 'assets/images/cat-tool.jpg',
    title: 'auto parts',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant parts</a></li><li><a href="#">Skeleton parts</a></li><li><a href="#">Slow parts</a></li><li><a href="#">Solar-powered parts</a></li>'),
  }, {
    image: 'assets/images/cat-tool-1.jpg',
    title: 'brakes & steering',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant parts</a></li><li><a href="#">Skeleton parts</a></li><li><a href="#">Slow parts</a></li><li><a href="#">Solar-powered parts</a></li>'),
  }, {
    image: 'assets/images/cat-tool-2.jpg',
    title: 'engine & drivetrain',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant parts</a></li><li><a href="#">Skeleton parts</a></li><li><a href="#">Slow parts</a></li><li><a href="#">Solar-powered parts</a></li>'),
  }, {
    image: 'assets/images/cat-tool-3.jpg',
    title: 'exterior accesories',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant parts</a></li><li><a href="#">Skeleton parts</a></li><li><a href="#">Slow parts</a></li><li><a href="#">Solar-powered parts</a></li>'),
  }, {
    image: 'assets/images/cat-tool-4.jpg',
    title: 'other parts',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant parts</a></li><li><a href="#">Skeleton parts</a></li><li><a href="#">Slow parts</a></li><li><a href="#">Solar-powered parts</a></li>'),
  }]

  // Logo
  public logos = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  ngOnInit(): void {

    this.blogService.getBlogByShopId().subscribe((data) => {
      this.blogs = data;
    });

    // Add class in body
    document.body.classList.add("tools-bg");
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove class in body
    document.body.classList.remove("tools-bg");
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredSlideOneDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'slide-one';
      });
      this.filteredSlideTwoDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'slide-two';
      });
      this.filteredBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
      this.filteredOfferDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner';
      });
    });
  }

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  openSlideOneForm() {
    this.isSlideOneBannerFormOpen = true;
  }

  closeSlideOneForm() {
    this.isSlideOneBannerFormOpen = false;
  }

  onRemoveSlideOne(event) {
    const indexToRemove = this.uploadSlideOneBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadSlideOneBanner.splice(indexToRemove, 1);
    }
  }

  openSlideTwoForm() {
    this.isSlideTwoBannerFormOpen = true;
  }

  closeSlideTwoForm() {
    this.isSlideTwoBannerFormOpen = false;
  }

  onRemoveSlideTwo(event) {
    const indexToRemove = this.uploadSlideTwoBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadSlideTwoBanner.splice(indexToRemove, 1);
    }
  }

  openBackgroundForm() {
    this.isBackgroundFormOpen = true;
  }

  closeBackgroundForm() {
    this.isBackgroundFormOpen = false;
  }

  onRemoveBackground(event) {
    const indexToRemove = this.uploadBackgroundBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadBackgroundBanner.splice(indexToRemove, 1);
    }
  }

  openOfferForm() {
    this.isOfferFormOpen = true;
  }

  closeOfferForm() {
    this.isOfferFormOpen = false;
  }

  onRemoveOffer(event) {
    const indexToRemove = this.uploadOfferBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadOfferBanner.splice(indexToRemove, 1);
    }
  }

  onSelectSlideOneImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1920 && image.height === 722) {
          if (!this.uploadSlideOneBanner) {
            this.uploadSlideOneBanner = [];
          }
          this.uploadSlideOneBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920 and height 1080.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectSlideTwoImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1920 && image.height === 722) {
          if (!this.uploadSlideTwoBanner) {
            this.uploadSlideTwoBanner = [];
          }
          this.uploadSlideTwoBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920 and height 1080.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1920 && image.height === 1080) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920 and height 1080.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectOfferImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1170 && image.height === 252) {
          if (!this.uploadOfferBanner) {
            this.uploadOfferBanner = [];
          }
          this.uploadOfferBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920 and height 1080.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  public onSubmitSlideOne(form: any): void {
    this.documentForm.get('identifier').setValue("slide-one");
    if (form.valid && this.uploadSlideOneBanner.length > 0) {
      this.handleSlideOneDocument(this.documentForm.value);
    }
  }

  public onSubmitSlideTwo(form: any): void {
    this.documentForm.get('identifier').setValue("slide-two");
    if (form.valid && this.uploadSlideTwoBanner.length > 0) {
      this.handleSlideTwoDocument(this.documentForm.value);
    }
  }

  public onSubmitBackground(form: any): void {
    this.documentForm.get('identifier').setValue("background-banner");
    if (form.valid && this.uploadBackgroundBanner.length > 0) {
      this.handleBackgroundDocument(this.documentForm.value);
    }
  }

  public onSubmitOffer(form: any): void {
    this.documentForm.get('identifier').setValue("offer-banner");
    if (form.valid && this.uploadOfferBanner.length > 0) {
      this.handleOfferDocument(this.documentForm.value);
    }
  }

  public handleSlideOneDocument(document: any): void {
    this.addSlideOneDocument(document, this.uploadSlideOneBanner);
  }

  public handleSlideTwoDocument(document: any): void {
    this.addSlideTwoDocument(document, this.uploadSlideTwoBanner);
  }

  public handleBackgroundDocument(document: any): void {
    this.addBackgroundDocument(document, this.uploadBackgroundBanner);
  }

  public handleOfferDocument(document: any): void {
    this.addOfferDocument(document, this.uploadOfferBanner);
  }

  public addSlideOneDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public addSlideTwoDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public addBackgroundDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  // encode image url
  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }
}