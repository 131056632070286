import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class OnboardingService {

    tenantRequest = environment.base_url_master_service;

    constructor(private httpClient: HttpClient) { }

    //add one shop 
    public addOnboarding(onboarding: any): any {
        return this.httpClient.post(`${this.tenantRequest}/tenants/request`, onboarding);
    }

    public validateRequest(tenantName:any,email:any){
    return this.httpClient.get(`${this.tenantRequest}/tenants/validate?tenantName=${tenantName}&email=${email}`);
    }
}