import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { ShopTypeService } from 'src/app/shared/services/shoptype.service';
import { CollectionSlider, HomeSlider, LogoSlider } from '../../shared/data/slider';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { JsonLDService } from 'src/app/shared/services/json-ldplus-service';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shop-type',
  templateUrl: './shop-type.component.html',
  styleUrls: ['./shop-type.component.scss']
})
export class ShopTypeComponent implements OnInit {

  public HomeSliderConfig: any = HomeSlider;
  public themeLogo: string = 'assets/images/footer-logo.png';
  public seletedShopType: any = '';
  @Input() class: string;
  public shopList: any = [];
  public socialMediaData: any = [];
  public logoSliderConfig: any = LogoSlider;
  public loader: boolean = true;
  public selectedCategoriesIcon: any;
  public categoryLabel: any;
  public showShopByCategory = false;
  public ImageSrc: string;
  public createShopInquiryForm: FormGroup;
  public tenantId: any;
  public isSubmited: boolean = false;
  public selectedShopId: any
  public selectedTenantId: any
  public isFinished: boolean = false;

  constructor(private fb: FormBuilder, public homeSrv: HomepageService, private router: Router, private activatedRoute: ActivatedRoute, private shopTypeSrv: ShopTypeService, private docSrv: ShopdocumentService, private metaDataService: MetadataService, private jsonLdService: JsonLDService, private toastr: ToastrService, private location: Location) {
    this.createShopInquiryForm = this.fb.group({
      id: [''],
      shopId: [''],
      phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      tenantId: ['']
    });
  }
  ngOnInit(): void {
    App.addListener('backButton', () => {
      this.location.back();
    });

    this.fetchShops();

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('category')) {
        this.seletedShopType = this.replaceHyphenWithSpace(queryParams.get('category'));
        this.shopTypeSrv.getIconConfig().subscribe(data => {
          this.categoryLabel = data;
          this.selectedCategoriesIcon = data.find(cat => cat.title === this.seletedShopType)?.data;
        });
      }
    });

    // Insert Json Ld Schema
    this.jsonLdService.insertSchema('Organization', this.jsonLdService.prepareLdJson('ShopCategory', null, null), 'structured-data');
  }

  ngOnDestroy() {
    App.removeAllListeners();
  }

  fetchShops() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('category')) {
        this.seletedShopType = this.replaceHyphenWithSpace(queryParams.get('category'));
        this.showShopByCategory = false; // Show loading spinner

        this.shopTypeSrv.getShopByShoptype(this.seletedShopType).subscribe(
          (data: any[]) => {
            this.shopList = data;
            if (this.shopList.length === 0) {
              this.showShopByCategory = true; // Hide loading spinner if no data
              return;
            }
            let documentFetches = [];
            let socialMediaData = [];
            this.shopList.forEach(shopItem => {
              let documentFetch = this.docSrv.getDocByShopId(shopItem.id).toPromise().then((documents: any[]) => {
                shopItem.documents = documents;
              });
              let socialMediaFetch = this.homeSrv.getSocialMediaByShopId(shopItem.id).subscribe((social: any) => {
                shopItem.socialMedia = social;
              });
              socialMediaData.push(socialMediaFetch);
              documentFetches.push(documentFetch);
            });

            Promise.all(documentFetches).then(() => {
              this.showShopByCategory = true; // Hide loading spinner after all documents are loaded
              if (this.shopList.length > 0) {
                this.metaDataService.updateMetaInfo(
                  `Browse ${this.shopList[0].shopType.name} shops easily with Shopdial`,
                  this.shopList[0].description
                );
                this.isFinished = true;
              }
            });
          },
          (error: any) => {
            console.error('Error fetching shops', error);
            this.showShopByCategory = true; // Hide pre loader even if an error occurs
            this.isFinished = true;
            this.shopList = [];
          }
        );
      } else {
        this.showShopByCategory = true; // Hide pre loader if no category is provided
        this.shopList = [];
      }
    });
  }

  public filterShopByShopDialRank() {
    // Filter the shop list to include only shops with rank 1 within each object
    const filteredShopList = this.shopList.map(item => {
      const filteredShops = item.shop.filter(shop => shop.shopdialRank);

      // Sort filtered shops based on rank in ascending order
      filteredShops.sort((a, b) => a.shopdialRank - b.shopdialRank);
      return { ...item, shop: filteredShops };
    });

    this.shopList = filteredShopList;
    return this.shopList;
  }

  replaceHyphenWithSpace(categoryName: string): string {
    return categoryName.replace(/-/g, ' ');
  }

  public CollectionSliderConfig: any = CollectionSlider;

  public showPopupForm(shopId: any, teannatd: any) {
    this.selectedShopId = shopId;
    this.selectedTenantId = teannatd;
    this.createShopInquiryForm.get('tenantId').setValue(this.selectedTenantId);
    var popupForm = document.getElementById("popupForm");
    popupForm.style.display = "block";
  }

  public closePopupForm() {
    var popupForm = document.getElementById("popupForm");
    popupForm.style.display = "none";
  }

  public onSubmitInquiry(form: any): void {
    if (form.valid) {
      this.isSubmited = true;
      this.handleShopInquiry(this.createShopInquiryForm.value);
    } else {
      this.validateForm(form);
    }
  }

  public handleShopInquiry(category: any): void {
    this.createShopInquiry(category);
  }
  public validateForm(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateForm(form);
      }
    });
  }
  public createShopInquiry(inquiry: any): void {
    this.homeSrv.createShopInquiry(inquiry, this.selectedShopId).subscribe((data: any) => {
      this.closePopupForm();
      this.toastr.success('Inquiry creation successfull.', 'SUCCESS');
    }, (error: any) => {
      this.toastr.error('Inquiry not creation, try again.', 'ERROR');
    });
  }
  public hasError(name: string) {
    const field = this.createShopInquiryForm.get(name);
    return (field?.invalid && field?.touched && field?.errors);
  }

  get form() {
    return this.createShopInquiryForm.controls;
  }

  get id() {
    return this.form['id'];
  }
  get name() {
    return this.form['name'];
  }

  get phoneNo() {
    return this.form['phoneNo'];
  }

  get email() {
    return this.form['email'];
  }
  get shopId() {
    return this.form['shopId']
  }

  get tenantID() {
    return this.form['tenantId']
  }
}

