<app-header-two [sticky]="true" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree"></app-header-two>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
    <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'fashion'" [buttonText]="'shop now'">
    </app-slider>
</section>
<!-- Home slider End -->

<!-- collection1 banner start-->
    <section class="banner-padding banner-furniture" *ngIf=" !isBackgroundBannerFormOpen">
        <div class="container-fluid">
            <div class="row partition2">
                <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then collection_one else default_collection_one"></div>
                <ng-template #collection_one>
                    <div class="col-md-6" *ngFor="let offerDoc of filteredOfferDocumentsOne">
                        <a>
                            <div class="collection-banner p-left text-start"
                                *ngIf="!isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="offerDoc.urls" class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>{{offerDoc?.textSubTittle}}</h4>
                                        <h2>{{offerDoc?.textTittle}}</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                <ng-template #default_collection_one>
                    <div class="col-md-6">
                        <a *ngIf="!isOfferBannerFormOpenOne  && !isOfferBannerFormOpenThree">
                            <div class="collection-banner p-left text-start">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/fashion/1.jpg"
                                    class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>save 30%</h4>
                                        <h2>Women</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                <!-- Popup Form One Open -->
                <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                                            (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
                <!-- Popup Form One Close -->

                <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then collection_two else default_collection_two"></div>
                <ng-template #collection_two>
                    <div class="col-md-6" *ngFor="let offerDoc of filteredOfferDocumentsTwo">
                        <a>
                            <div class="collection-banner p-left text-start"
                                *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="offerDoc.urls" class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>{{offerDoc?.textSubTittle}}</h4>
                                        <h2>{{offerDoc?.textTittle}}</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                 <!-- Collection Two Open -->
                <ng-template #default_collection_two>
                <div class="col-md-6">
                    <a *ngIf="!isOfferBannerFormOpenTwo">
                        <div class="collection-banner p-left text-start">
                            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                            </a>
                            <img src="http://themes.pixelstrap.com/multikart/assets/images/fashion/2.jpg"
                                class="img-fluid" alt="collection-banner">
                            <div class="contain-banner">
                                <div>
                                    <h4>save 50%</h4>
                                    <h2>Men</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                </ng-template>
                <!-- Popup Form Two Open -->
                
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                                            (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- collection section 1 default banner end-->

<!-- collection section 2 banner start-->
    <section class="banner-padding banner-furniture" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenTwo">
        <div class="container-fluid">
            <div class="row partition2">
                <div *ngIf="filteredOfferDocumentsThree?.length > 0 ;then collection_three else default_collection_three"></div>
                <ng-template #collection_three>
                    <div class="col-md-6" *ngFor="let offerDoc2 of filteredOfferDocumentsThree">
                        <a>
                            <div class="collection-banner p-left text-start" *ngIf="!isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="offerDoc2.urls" class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>{{offerDoc2?.textSubTittle}}</h4>
                                        <h2>{{offerDoc2?.textTittle}}</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                <ng-template #default_collection_three>
                    <div class="col-md-6">
                        <a>
                            <div class="collection-banner p-left text-start"
                                *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/fashion/3.jpg"
                                    class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>save 30%</h4>
                                        <h2>Sandle</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isOfferBannerFormOpenThree">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectOfferThree($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerThree" [removable]="true"
                                            (removed)="onRemoveItem(uploadOfferBannerThree, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="filteredOfferDocumentsFour?.length > 0 ;then collection_four else default_collection_four"></div>
                <ng-template #collection_four>
                    <div class="col-md-6" *ngFor="let offerDoc2 of filteredOfferDocumentsFour">
                        <a>
                            <div class="collection-banner p-left text-start" *ngIf="!isOfferBannerFormOpenThree">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormFour()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img [src]="offerDoc2.urls" class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>{{offerDoc2?.textSubTittle}}</h4>
                                        <h2>{{offerDoc2?.textTittle}}</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
                <ng-template #default_collection_four>
                    <div class="col-md-6">
                        <a>
                            <div class="collection-banner p-left text-start"
                                *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormFour()">
                                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                                </a>
                                <img src="http://themes.pixelstrap.com/multikart/assets/images/fashion/4.jpg"
                                    class="img-fluid" alt="collection-banner">
                                <div class="contain-banner">
                                    <div>
                                        <h4>save 10%</h4>
                                        <h2>Kids</h2>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>           
                </ng-template>
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isOfferBannerFormOpenFour">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectOfferFour($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerFour" [removable]="true"
                                            (removed)="onRemoveItem(uploadOfferBannerFour, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </section>
<!-- collection section 2 banner end-->

<!-- Tab product start-->
<div class="title1 section-t-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour">
    <div>
        <h4>exclusive products</h4>
        <h2 class="title-inner1">special products</h2>
    </div>
</div>
<section class="section-b-space p-t-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour">
    <div class="container">
        <div class="row">
            <div class="col theme-tab">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                    class="tabs tab-title justify-content-center nav nav-pills">
                    <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                        <a ngbNavLink>
                            {{ collection }}
                        </a>
                        <ng-template ngbNavContent>
                            <div class="no-slider row">
                                <ng-container *ngFor="let product of getCollectionProducts(collection)">
                                    <div class="product-box">
                                        <app-product-box-one [product]="product" [currency]="productService?.Currency">
                                        </app-product-box-one>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</section>
<!-- Tab product End -->

<!-- Parallax banner start-->
<div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
<ng-template #bG_content>
    <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour">
        <div>
            <div class="full-banner parallax-banner1 parallax text-center p-left"
                *ngFor="let bgDoc of filtereBackgroundDocuments"
                [style.backgroundImage]="getEncodedImageUrl(bgDoc?.urls)">
                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                </a>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="banner-contain">
                                <h2>2023</h2>
                                <h3>{{bgDoc?.textTittle}}</h3>
                                <h4>{{bgDoc?.textSubTittle}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner"
                                            [removable]="true" (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<!-- Parallax banner End -->

<!-- Parallax default banner start-->
<ng-template #defualt_content>
    <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour">
        <div>
            <div class="full-banner parallax-banner1 parallax text-center p-left"
                [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/parallax/16.jpg)'}">
                <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
                    <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
                </a>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="banner-contain">
                                <h2>2021</h2>
                                <h3>fashion trends</h3>
                                <h4>special offer</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                </h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner"
                                            [removable]="true" (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label
                                            class="col-form-label pt-0">Text
                                            Tittle
                                        </label>
                                        <input class="form-control" id="textTittle" type="text" name="textTittle"
                                            formControlName="textTittle">
                                    </div>
                                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                        <label class="col-form-label pt-0">Text Subtittle</label>
                                        <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                            formControlName="textSubTittle">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>
<!-- Parallax default banner End -->

<!-- product slider -->
<section class="section-b-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isBackgroundBannerFormOpen">
    <div class="container">
        <div class="row multiple-slider">
            <div class="col-lg-3 col-sm-6">
                <app-product-box-vertical-slider [title]="'New product'"></app-product-box-vertical-slider>
            </div>
            <div class="col-lg-3 col-sm-6">
                <app-product-box-vertical-slider [title]="'Sell product'"></app-product-box-vertical-slider>
            </div>
            <div class="col-lg-3 col-sm-6">
                <app-product-box-vertical-slider [title]="'Feature Product'"></app-product-box-vertical-slider>
            </div>
            <div class="col-lg-3 col-sm-6">
                <app-product-box-vertical-slider [title]="'Best Product'"></app-product-box-vertical-slider>
            </div>
        </div>
    </div>
</section>
<!-- product slider end -->

<!-- blog section start-->
<section class="blog p-t-0 section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->
<app-newsletter *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isBackgroundBannerFormOpen"></app-newsletter>
<app-alert-popup></app-alert-popup>
<div *ngIf="!isBackgroundBannerFormOpen">
    <app-footer-two [themeLogo]="themeLogo"
        *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isOfferBannerFormOpenFour && !isBackgroundBannerFormOpen"></app-footer-two>
</div>