import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductSlider } from '../../shared/data/slider';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pets',
  templateUrl: './pets.component.html',
  styleUrls: ['./pets.component.scss']
})
export class PetsComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-13.png'; // Change Logo

  public products: any[] = [];
  public documentForm: FormGroup;
  public generatedShopId: string;
  public documents: any;
  public ProductSliderConfig: any = ProductSlider;
  public filtereBackgroundDocuments: any;
  public isBackgroundBannerFormOpen: boolean = false;
  public errorMessageOffer: string;
  public uploadBackgroundBanner: any;
  public selectedImageIndex: number | null = null;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentsOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public filteredOfferDocumentsTwo: any;
  public uploadOfferBannerThree: any;
  public filteredOfferDocumentsThree: any;
  public isOfferBannerFormOpenThree: boolean = false;
  public uploadOfferBannerFour: any;
  public isOfferBannerFormOpenFour: boolean = false;
  public filteredOfferDocumentsFour: any;
  public uploadOfferBannerFive: any;
  public filteredOfferDocumentsFive: any;
  public isOfferBannerFormOpenFive: boolean = false;
  public uploadOfferBannerSix: any;
  public isOfferBannerFormOpenSix: boolean = false;
  public filteredOfferDocumentsSix: any;





  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public sliders = [{
    title: 'every pet is different',
    subTitle: 'lets shop',
    image: 'assets/images/bannerdog1.jpg'
  }, {
    title: 'Any thing that pets want',
    subTitle: 'save 20%',
    image: 'assets/images/bannerdog1.jpg'
  }];

  // Logo
  public logos = [{
    image: 'assets/images/logos/9.png',
  }, {
    image: 'assets/images/logos/10.png',
  }, {
    image: 'assets/images/logos/11.png',
  }, {
    image: 'assets/images/logos/12.png',
  }, {
    image: 'assets/images/logos/13.png',
  }, {
    image: 'assets/images/logos/14.png',
  }, {
    image: 'assets/images/logos/15.png',
  }, {
    image: 'assets/images/logos/16.png',
  }]

  // Collection banner
  public collections1 = [{
    image: 'assets/images/pet-col-2.jpg',
    title: 'Clothes'
  },
  {
    image: 'assets/images/pet-col-2.jpg ',
    title: 'Groom'
  },
  {
    image: 'assets/images/pet-col-3.jpg',
    title: 'food'
  }];

  public collections2 = [{
    image: 'assets/images/col2.png',
    title: 'Home'
  },
  {
    image: 'assets/images/col3.png',
    title: 'cats'
  },
  {
    image: 'assets/images/col4.png',
    title: 'bowls'
  }];

  // Blog
  public blogs = [{
    image: 'assets/images/blog-pet1.png',
    date: '25 January 2023',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    details: 'Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit',
    by: 'Mike Smit'
  }, {
    image: 'assets/images/blog-pet2.png',
    date: '26 January 2022',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    details: 'Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit',
    by: 'Hanrry Poter'
  }, {
    image: 'assets/images/blog-pet3.png',
    date: '27 January 2020',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    details: 'Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit',
    by: 'Elezabeth'
  }, {
    image: 'assets/images/blog-pet1.png',
    date: '28 January 2019',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    details: 'Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit',
    by: 'John Dio'
  }];

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#ff9944');

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the offer-banner identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier.includes('offer-banner-one');
      });

      // Filter the documents based on the offer-banner2 identifier
      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });

      // Filter the documents based on the offer-banner identifier
      this.filteredOfferDocumentsThree = this.documents.filter((document: any) => {
        return document.identifier.includes('offer-banner-three');
      });

      // Filter the documents based on the offer-banner2 identifier
      this.filteredOfferDocumentsFour = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-four';
      });

      // Filter the documents based on the offer-banner identifier
      this.filteredOfferDocumentsFive = this.documents.filter((document: any) => {
        return document.identifier.includes('offer-banner-five');
      });

      // Filter the documents based on the offer-banner2 identifier
      this.filteredOfferDocumentsSix = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-six';
      });

      // Filter the documents based on the background-banner identifier
      this.filtereBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  openOfferFormOne(): void {
    this.isOfferBannerFormOpenOne = true;
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select three images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height 219px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 3
    }
  }

  openOfferFormTwo(): void {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select three images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height 219px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 3
    }
  }

  openOfferFormThree(): void {
    this.isOfferBannerFormOpenThree = true;
  }

  onSelectOfferThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select three images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerThree) {
            this.uploadOfferBannerThree = [];
          }
          this.uploadOfferBannerThree.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height 219px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.uploadOfferBannerThree = []; // Clear the array if the count is not equal to 3
    }
  }

  openOfferFormFour(): void {
    this.isOfferBannerFormOpenFour = true;
  }

  onSelectOfferFour = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select three images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerFour) {
            this.uploadOfferBannerFour = [];
          }
          this.uploadOfferBannerFour.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height 219px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.uploadOfferBannerFour = []; // Clear the array if the count is not equal to 3
    }
  }

  openOfferFormFive(): void {
    this.isOfferBannerFormOpenFive = true;
  }

  onSelectOfferFive = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select three images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerFive) {
            this.uploadOfferBannerFive = [];
          }
          this.uploadOfferBannerFive.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height 219px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.uploadOfferBannerFive = []; // Clear the array if the count is not equal to 3
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopUpForm();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  openOfferFormSix() {
    this.isOfferBannerFormOpenSix = true;
  }

  onSelectOfferSix = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select one images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 440 && image.height === 219) {
          if (!this.uploadOfferBannerSix) {
            this.uploadOfferBannerSix = [];
          }
          this.uploadOfferBannerSix.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 440px and height Six19px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number ofvalid files after the loop
    if (validFileCount !== 3) {
      this.uploadOfferBannerSix = []; // Clear the array if the count is not equal to 3
    }
  }

  openBackgroundForm() {
    this.isBackgroundBannerFormOpen = true;
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only.';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 600) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 600px`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 600px.';
      this.uploadBackgroundBanner = []; // Clear the array if the count is not equal to 2
    }
  }


  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-two');
      }
    } else if (this.uploadOfferBannerThree && this.uploadOfferBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-three");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-three');
      }
    } else if (this.uploadOfferBannerFour && this.uploadOfferBannerFour.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-four");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-four');
      }
    } else if (this.uploadOfferBannerFive && this.uploadOfferBannerFive.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-five");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-five');
      }
    } else if (this.uploadOfferBannerSix && this.uploadOfferBannerSix.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-six");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-six');
      }
    } else if (this.uploadBackgroundBanner && this.uploadBackgroundBanner.length > 0) {
      this.documentForm.get('identifier').setValue("background-banner");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'background');
      }
    }
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'offer-three':
        this.addOfferDocument(document, this.uploadOfferBannerThree);
        break;
      case 'offer-four':
        this.addOfferDocument(document, this.uploadOfferBannerFour);
        break;
      case 'offer-five':
        this.addOfferDocument(document, this.uploadOfferBannerFive);
        break;
      case 'offer-six':
        this.addOfferDocument(document, this.uploadOfferBannerSix);
        break;
      case 'background':
        this.addOfferDocument(document, this.uploadBackgroundBanner);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  closePopUpForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isOfferBannerFormOpenThree = false;
    this.isOfferBannerFormOpenFour = false;
    this.isOfferBannerFormOpenFive = false;
    this.isOfferBannerFormOpenSix = false;
    this.isBackgroundBannerFormOpen = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }
}