<app-header-home></app-header-home>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="shopdial_top">
                <div class="deatils_gallbox">
                    <ul class="gallbox_items" *ngIf="shopTopContentLoaded">
                        <li *ngFor="let doc of shops?.document | docfilter : 'view-img'; index as i"
                            [class]="images[i].cssClass">
                            <div class="gallbox_imginner" *ngIf="shops?.document && shops?.document?.length > 0">
                                <a target="_blank" class="gallbox_imginner shop_img">
                                    <span class="shop_img2" *ngIf="doc?.identifier=='view-img'">
                                        <img [src]="doc?.urls" [class]="images[i].imgClass"
                                            [style]="images[i].imgStyle">
                                    </span>
                                </a>
                            </div>
                            <a>
                                <div class="gallbox_text pt-30 pb-30" *ngIf="images[i].text">
                                    <div class="gallbox_text_overlay">
                                        <div class="gallbox_inner gallbox_inner1 bounceOut">
                                            <div class="font30 fw700 colorFFF">+15</div>
                                            <div class="font18 fw500 colorFFF mt-5">More</div>
                                        </div>
                                        <div class="gallbox_inner gallbox_inner2 bounceIn"
                                            (click)="showGallaryPopupForm()">
                                            <div class="sdicon jd_gallery_icon"></div>
                                            <div class="font18 fw500 colorFFF mt-10">Gallery</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upload_image" *ngIf="images[i].upload"></div>
                                <div class="gallbox_text" *ngIf="images[i].upload">
                                    <div class="sdicon jd_upoad_icon"></div>
                                    <div class="font18 fw500 colorFFF mt-10">Add more photo</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class=" deatils_vendbox" *ngIf="shopTopContentLoaded">
                    <div class="vendbox_content">
                        <div style="min-height:28px" class="vendbox_titlebox">
                            <div class="vendbox_title">
                                <h1 class="font25 fw700 color111 shopname_mobile"><span
                                        class="vendbox_verfied_icon sdicon mr-10 pointer"></span>{{shops.name}}
                                </h1>
                            </div>
                        </div>
                        <div style="min-height:117px" class="vendbox_infobox">
                            <div class="vendbox_infoleft">
                                <div class="vendbox_badges mt-8">
                                    <div class="vendbox_rateabox pointer">
                                        <div class=" vendbox_rateavg font17 fw500 colorFFF mr-6 pointer">
                                            {{shops?.shopdialRank}}.0
                                        </div>
                                        <div class="vendbox_ratestar mr-6 pointer">
                                            <svg width="100%" height="100%" viewBox="0 0 1000 200">
                                                <polygon id="star9927" points="100,10 40,198 190,78 10,78 160,198"
                                                    fill="#FFE372"></polygon>
                                                <defs>
                                                    <clipPath id="stars9927">
                                                        <use xlink:href="#star9927"></use>
                                                        <use xlink:href="#star9927" x="20%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="40%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="60%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="80%">
                                                        </use>
                                                    </clipPath>
                                                </defs>
                                                <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                    style="fill:rgb(215, 215, 215);stroke:red;stroke-width:1;height:100%;width:100%">
                                                </rect>
                                                <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                    style="fill:rgb(255, 110, 0);height:100%">
                                                </rect>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="vendbox_claimed">
                                        <span class="claimed font15 fw500 color111">
                                            <span class="vendbox_claimed_icon sdicon mr-5">
                                            </span>Claimed
                                            <span class="popover">This business has been claimed by the owner</span>
                                        </span>
                                    </div>
                                </div>
                                <div *ngFor="let shopLocation of shops?.location; let i = index"
                                    class="vendbox_addres mt-8">
                                    <div class="adress font15 fw500 color555">
                                        Address {{i + 1}} : &nbsp;&nbsp; {{shopLocation?.address |
                                        sentenceCase}},{{shopLocation?.city | sentenceCase}}, {{shopLocation?.state |
                                        sentenceCase}} {{shopLocation?.postalCode}}, {{shopLocation?.country |
                                        sentenceCase}}
                                    </div>
                                </div>
                                <div>
                                    <div class="font15 fw400 color111 pointer mr-16 mt-2">
                                        Opens At: {{shops?.openingTime}} - Close At: {{shops?.closingTime}}
                                    </div>
                                </div>
                                <div class="vendbox_btnbox btnresponse font15 fw400 color111 mt-8">
                                    Responds in
                                    <span style="color: #EB690A" class=" btnresponse_green ml-4 fw500">2 Hours
                                    </span> <span class="detdot_1 mr-8 ml-8"></span><span
                                        class="fw500 mr-4">404</span>people recently enquired
                                </div>
                                <ul class="vendbox_action_items mt-8">
                                    <li id="dp_call_now" class="action_item" (click)="showPopupForm()">
                                        <button aria-label="Call" class="action_button_filled">
                                            <div class="sdicon vend_call_icon"></div>
                                            <a class="font15 fw500 colorFFF ml-5">{{shops.contact | mask:6 }}</a>
                                        </button>
                                    </li>
                                    <li class="action_item">
                                        <button class="action_button_unfilled">
                                            <a target="_blank" rel="noopener noreferrer" class=" button_unfilled_anchor"
                                                href="https://web.whatsapp.com/">
                                                <div class="sdicon vend_whatsapp_icon"></div>
                                                <div class="font15 fw500 color111 ml-5">Chat</div>
                                            </a>
                                        </button>
                                    </li>
                                    <li id="dp_write_a_review" class=" action_item d-none d-md-block"><button
                                            aria-label="Write Review" class="action_button_unfilled">
                                            <div class="sdicon vend_review_icon"></div>
                                            <div class="font15 fw500 color111  ml-8">Tap to Rate</div>
                                        </button></li>
                                    <li class="action_item">
                                        <button aria-label="Share" id="dp_share" class="action_button_unfilled">
                                            <div class="font15 fw500 color111 ml-5">
                                                <a class="address_link"
                                                    href="https://{{shops.registeredDomain}}.shopdial.in/home"
                                                    target="_blank">
                                                    <i class="fa fa-globe fonts-icon2 " aria-hidden="true"></i>
                                                    <div class="font15 fw500 color111 ml-15 visit-mobile">
                                                        Visit our Website
                                                    </div>
                                                </a>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="d-flex justify-content-between mt-3 d-md-none">
                                    <div class="text-center">
                                        <div class="card cursor" (click)="showPopupForm()">
                                            <button class="btn btn-outline-secondary btn-radius-4">
                                                <i class="fa fa-phone fonts-icon-color cursor" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div class="mt-1">Call Now</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="card cursor">
                                            <a href="https://web.whatsapp.com" target="_blank">
                                                <button class="btn btn-outline-secondary btn-radius-4">
                                                    <i class="fa fa-whatsapp fonts-icon-color cursor"
                                                        aria-hidden="true"></i>
                                                </button>
                                            </a>
                                        </div>
                                        <a href="https://web.whatsapp.com" target="_blank">
                                            <div class="color555 mt-1">Whatsapp</div>
                                        </a>

                                    </div>
                                    <div class="text-center">
                                        <div class="card cursor" (click)="getDirections()">
                                            <button class="btn btn-outline-secondary btn-radius-4">
                                                <i class="fa fa-location-arrow fonts-icon-color cursor"
                                                    aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div class="mt-1">Direction</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="card cursor" (click)="showShareItPopupForm()">
                                            <button class="btn btn-outline-secondary btn-radius-4">
                                                <i class="fa fa-share fonts-icon-color" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <div class="mt-1">Share</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="gallaryForm" class="gallary-popup-form">
            <div class="gallary_content galary_width modal_animation fadeInUpTop">
                <div class="sd_modal_header pt-10 mpt-8 mpl-8 pl-62 pr-30">
                    <div class="modal_head_right">
                        <span class="sd_modal_close sdicon" (click)="closeGallaryPopupForm()"></span>
                    </div>
                </div>
                <div class="sd_modal_body p-30">
                    <div *ngIf="this.shops.document && this.shops.document.length > 0" class="image-container">
                        <div *ngFor="let imageUrl of this.shops.document | docfilter : 'view-img';"
                            class="gallery-image">
                            <img [src]="imageUrl.urls" alt="Gallery Image" class="small-image">
                        </div>
                    </div>
                    <div *ngIf="!this.shops.document || this.shops.document.length === 0">
                        <p>No images available</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!shopTopContentLoaded">
            <app-loading [inputSourceLayout]="'shop-detail-top.component'"></app-loading>
        </div>
    </div>
</div>

<!-- Sticky portion  -->
<div id="shopDetailsSection">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="d-flex justify-content-start">
                                    <div class="vendbox_title">
                                        <h1 class="font20 fw700 color111 shopname_mobile">
                                            <span class="thumb_icon sdicon mr-10 pointer"></span>{{shops.name}}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-end">
                                    <div class="vendbox_rateabox pointer">
                                        <div class="vendbox_rateavg font17 fw500 colorFFF mr-6 pointer">
                                            {{shops?.shopdialRank}}.0
                                        </div>
                                        <div class="vendbox_ratestar mr-6 pointer">
                                            <svg width="80%" height="80%" viewBox="0 0 1000 200">
                                                <polygon id="star9927" points="100,10 40,198 190,78 10,78 160,198"
                                                    fill="#FFE372"></polygon>
                                                <defs>
                                                    <clipPath id="stars9927">
                                                        <use xlink:href="#star9927"></use>
                                                        <use xlink:href="#star9927" x="20%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="40%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="60%">
                                                        </use>
                                                        <use xlink:href="#star9927" x="80%">
                                                        </use>
                                                    </clipPath>
                                                </defs>
                                                <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                    style="fill:rgb(215, 215, 215);stroke:red;stroke-width:1;height:100%;width:100%">
                                                </rect>
                                                <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                    style="fill:rgb(255, 110, 0);height:100%">
                                                </rect>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start">
                            <div 
                                class="vendbox_addres mt-8">
                                <div class="adress font15 fw500 color555">
                                    Address : &nbsp;&nbsp; {{shops?.location[0]?.address |
                                    sentenceCase}},{{shops?.location[0]?.city | sentenceCase}}, {{shops?.location[0]?.state |
                                    sentenceCase}} {{shops?.location[0]?.postalCode}}, {{shops?.location[0]?.country |
                                    sentenceCase}}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start mt-3">
                            <div class="row row-cols-auto">
                                <div class="col" (click)="showPopupForm()">
                                    <button aria-label="Call" class="action_button_filled">
                                        <div class="sdicon vend_call_icon"></div>
                                        <a class="font15 fw500 colorFFF ml-5">{{shops.contact | mask:6 }}</a>
                                    </button>
                                </div>
                                <div class="col" (click)="showPopupForm()">
                                    <button class="enquiry_button_filled">
                                        <div class="sdicon enquire_white_icon"></div>
                                        <a class="font15 fw500 colorFFF ml-5">Enquire now</a>
                                    </button>
                                </div>
                                <div class="col">
                                    <button aria-label="Share" id="dp_share" class="action_button_unfilled">
                                        <div class="font15 fw500 color111 ml-5">
                                            <a class="address_link"
                                                href="https://{{shops.registeredDomain}}.shopdial.in/home"
                                                target="_blank">
                                                <i class="fa fa-globe fonts-icon2" aria-hidden="true"></i>
                                                <div class="font15 fw500 color111 ml-5 visit-mobile">
                                                    Visit our website
                                                </div>
                                            </a>
                                        </div>
                                    </button>
                                </div>
                                <div class="col" (click)="showShareItPopupForm()">
                                    <button class="action_button_unfilled">
                                        <a class="button_unfilled_anchor">
                                            <i class="fa fa-share fonts-icon-color" aria-hidden="true"></i>
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Sticky portion end -->

<!-- shop Information section start -->
<div class="container">
    <div class="row mt-20" *ngIf="shopMiddleContentLoaded">
        <div class="col-12 col-sm-12 col-lg-9">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title font20 fw600 color111 pointer mb-3">Shop Information</h5>
                            <div class="row">
                                <div class="col-6 col-lg-3 col-md-3 col-xl-3 col-sm-6">
                                    <h3 class="d-flex justify-content-start font16 fw500 color111 mb-5">Mode of payment
                                    </h3>
                                    <span *ngFor="let payMode of shops.paymentMode; let isLast = last">
                                        <span class="color555 font15">{{payMode| sentenceCase}}</span><span
                                            *ngIf="!isLast">, </span>
                                    </span>
                                </div>
                                <div class="col-6 col-lg-3 col-md-3 col-xl-3 col-sm-6">
                                    <h3 class="d-flex justify-content-start font16 fw500 color111 mb-5">Year of
                                        Establish</h3>
                                    <span class="color555 font15">{{shops.yearOfStablished}}</span>
                                </div>
                                <div class="col-6 col-lg-3 col-md-3 col-xl-3 col-sm-6 mt-mob-2">
                                    <h3 class="d-flex justify-content-start font16 fw500 color111 mb-5">Time</h3>
                                    <span>{{shops?.openingTime}} - {{shops?.closingTime}}<br>
                                    </span>
                                </div>
                                <div class="col-6 col-lg-3 col-md-3 col-xl-3 col-sm-6 mt-mob-2">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <h3 class="d-flex justify-content-start font16 fw500 color111 mb-5">Open
                                                Days</h3>
                                            <span>
                                                <span *ngIf="shops?.openDay?.length">
                                                    <!-- Display the first element -->
                                                    <span class="color555 font15">{{ shops.openDay[0] | sentenceCase
                                                        }}</span>

                                                    <!-- Display hyphen if there are more than one elements -->
                                                    <span *ngIf="shops.openDay.length > 1"> - </span>

                                                    <!-- Display the last element -->
                                                    <span *ngIf="shops.openDay.length > 1" class="color555 font15">{{
                                                        shops.openDay[shops.openDay.length - 1] | sentenceCase }}</span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <h3 class="d-flex justify-content-start font16 fw500 color111 mb-5">Close
                                                Days</h3>
                                            <span *ngFor="let clDay of shops?.closeDay; let isLast = last">
                                                <span class="color555 font15">{{clDay | sentenceCase}}</span><span
                                                    *ngIf="!isLast">- </span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card mt-20">
                        <div class="card-body">
                            <h5 class="card-title font20 fw600 color111 pointer mb-3">Our Services</h5>
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="d-flex justify-content-start font16 fw500 color111 mb-3">Services</h3>
                                    <span class="sel_amenities_item" *ngFor="let service of shops?.services">
                                        <div class="d-flex">
                                            <div>
                                                <span class="del_tick_icon sdicon"></span>
                                            </div>
                                            <div class="flex-fill">
                                                <span class="font15 color555">{{ service }}</span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-6">
                                    <h3 class="d-flex justify-content-start font16 fw500 color111 mb-3">Supporter Info
                                    </h3>
                                    <div class="d-flex">
                                        <div>
                                            <span class="del_tick_icon sdicon"></span>
                                        </div>
                                        <div class="flex-fill">
                                            <span class="font15 color555">Support Contact : {{shops.name}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3 cursor" (click)="showPopupForm()">
                                        <div>
                                            <span class="del_tick_icon sdicon"></span>
                                        </div>
                                        <div class="flex-fill">
                                            <span class="font15 color555">Support Email : <span
                                                    class="text-transform-lowercase">{{shops.email | mask:10}}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3 cursor" (click)="showPopupForm()">
                                        <div>
                                            <span class="del_tick_icon sdicon"></span>
                                        </div>
                                        <div class="flex-fill">
                                            <span class="font15 color555">Support Phone : {{shops.contact |
                                                mask:6}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-lg-3">
            <div class="card mt-mob-30">
                <div class="card-body">
                    <h5 class="card-title font20 fw600 color111 pointer mb-3">Address</h5>
                    <div class="d-flex">
                        <address class="font15 fw500 color111" *ngFor="let loct of shops.location">
                            <span>{{loct?.address | sentenceCase}},{{loct?.city | sentenceCase}}, {{loct?.state |
                                sentenceCase}} {{loct?.postalCode}}, {{loct?.country | sentenceCase}}</span>
                        </address>
                    </div>
                    <div class="row">
                        <div (click)="getDirections()" class="col-8">
                            <div class="d-flex">
                                <div class="mr-10"><i class="fa fa-location-arrow fonts-icon2 cursor"
                                        aria-hidden="true"></i></div>
                                <div class="flex-grow-1 cursor ml-5 font15 fw500 color111">Get Directions</div>
                            </div>
                        </div>
                        <div ngxClipboard [cbContent]="getCopyAddress()" class="col-4">
                            <div class="d-flex">
                                <div class="mr-10"><i class="fa fa-clone fonts-icon3 cursor" aria-hidden="true"></i>
                                </div>
                                <div class="flex-grow-1 cursor ml-5 mr-3 font15 fw500 color111">Copy</div>
                            </div>
                        </div>
                    </div>
                    <ul class="vendorinfo_address_items pr-10">
                        <li class="address_item"><a rel="nofollow" aria-label="Send Enquiry by Email" target="_blank"
                                class="address_link">
                                <div class="email_enquiry sdicon mr-10"></div>
                                <img src="assets/images/icon/GST_icon.png" alt="not found" class="img-fluid max-width">
                                <div class="font15 fw500 color111 mr-10"><span class="ml-8"> GST :
                                        {{shops?.verifications?.gstNumber | mask:10}}</span></div>
                            </a></li>
                        <li class="address_item">
                            <a aria-label="Get info via SMS / Email" class="address_link">
                                <i class="fa fa-envelope-o fonts-icon2 mr-5" aria-hidden="true"></i>
                                <div class="font15 fw500 color111 ml-8 getInfo_mobile" (click)="showPopupForm()">Get
                                    info
                                    via SMS/Email</div>
                            </a>
                        </li>
                        <li class="address_item">
                            <div class="address_item_inner"><a class="address_link"
                                    href="https://{{shops.registeredDomain}}.shopdial.in/home" target="_blank">
                                    <i class="fa fa-globe fonts-icon2" aria-hidden="true"></i>
                                    <div class="font15 fw500 color111 ml-15">Visit our Website</div>
                                </a>
                            </div>
                        </li>
                        <li class="address_item">
                            <div class="address_item_inner"><a class="address_link" target="_blank"
                                    (click)="showShareItPopupForm()">
                                    <i class="fa fa-share fonts-icon2" aria-hidden="true"></i>
                                    <div class="font15 fw500 color111 ml-15">Share this</div>
                                </a>
                            </div>
                        </li>
                        <li class="address_item">
                            <div class="address_item_inner"><a class="address_link"
                                    href="{{shops?.socialMedia?.facebookUrl}}" target="_blank">
                                    <i class="fa fa-facebook-square fonts-icon2" aria-hidden="true"></i>
                                    <div class="font15 fw500 color111 ml-15">Facebook</div>
                                </a>
                            </div>
                        </li>
                        <li class="address_item">
                            <div class="address_item_inner"><a class="address_link"
                                    href="{{shops?.socialMedia?.linkedUrl}}" target="_blank">
                                    <i class="fa fa-linkedin fonts-icon2" aria-hidden="true"></i>
                                    <div class="font15 fw500 color111 ml-15">Linked In</div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- popup form  start-->
        <div id="popupForm" class="popup-form">
            <form [formGroup]="createShopInquiryForm" autocomplete="off">
                <i class="fa fa-times fa-pull-right fonts-icon" aria-hidden="true" (click)="closePopupForm()"
                    style="cursor: pointer;"></i>
                <div class="sd_modal_header pl-80">
                    <div class=" modal_head_center">
                        <div class=" sdwrapper font22 fw500 color111 text_left info_mobile">Get information
                            by SMS/Email</div>
                    </div>
                </div>
                <div class="form-group mt-20">
                    <input type="text" class="form-control" id="name" aria-describedby="emailHelp" placeholder="Name"
                        name="name" formControlName="name">
                    <div *ngIf="hasError('name')" [class]="hasError('name') ? 'error' : ''">
                        <div *ngIf="name?.errors">
                            <span *ngIf="name?.errors?.['required']">Name is a required field.</span>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-20">
                    <input type="text" class="form-control" id="phoneNo" placeholder="Mobile number" name="phoneNo"
                        formControlName="phoneNo" minlength="10" maxlength="10" pattern="[0-9]*">
                    <div *ngIf="hasError('phoneNo')" [class]="hasError('phoneNo') ? 'error' : ''">
                        <div *ngIf="phoneNo?.errors">
                            <span *ngIf="phoneNo?.errors?.['required']"> Phone number is a required field.</span>
                            <span *ngIf="!phoneNo?.errors?.['required'] && phoneNo?.errors?.['minlength']"> Phone
                                number should
                                be at least 10 characters long.</span>
                            <span
                                *ngIf="!phoneNo?.errors?.['required'] && !phoneNo?.errors?.['minlength'] && phoneNo?.errors?.['pattern']">
                                Phone number should contain only numeric characters.</span>
                        </div>
                    </div>
                </div>

                <div class="form-group mt-20">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Email" name="email" formControlName="email">
                    <div *ngIf="hasError('email')" [class]="hasError('email') ? 'error' : ''">
                        <div *ngIf="email?.errors">
                            <span *ngIf="email?.errors?.['required']">Email is a required field.</span>
                            <span *ngIf="email?.errors?.['email']">Email should be email type</span>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary"
                    (click)="onSubmitInquiry(createShopInquiryForm)">Submit</button>
            </form>
        </div>
        <!-- popup form  end-->

        <!-- Shaare it form  start-->
        <div id="shareForm" class="share_modal">
            <div class="share_modal_content share_modal_medium share_fadeInUpTop">
                <div class="share_modal_header pt-25 pl-25 pr-25">
                    <div class="share_modal_head_center"><span
                            class="sharewrapper font22 fw600 color111 text_left">Share With Friends</span>
                        <span class="sharewrapper font16 fw500 color555 text_left">Select from the options
                            below</span>
                    </div>
                    <div class="share_modal_head_right" (click)="closeShareItPopupForm()"><span tabindex="-1"
                            class="jd_modal_close share_icon"></span>
                    </div>
                </div>
                <div class="jd_modal_body pl-25 pr-25">
                    <div class="jd_share_box pt-40 spb-30">
                        <ul class="share_linklist">
                            <li class="share_tab"><a [href]="facebookShareUrl" rel="nofollow" target="_blank"
                                    class="share_anchor"><span class="share_icon"><img
                                            src="assets/images/facebook_share_icon.png" alt="Share on Facebook"
                                            class="share_icon_img"></span><span
                                        class="share_icon_text color111 fw500 font14 mt-14">Facebook</span></a>
                            </li>
                            <li class="share_tab"><a [href]="whatsappShareUrl" rel="nofollow" target="_blank"
                                    class="share_anchor"><span class="share_icon"><img
                                            src="assets/images/whatsapp_share_icon.svg" alt="Share on Whatsup"
                                            class="share_icon_img"></span><span
                                        class="share_icon_text color111 fw500 font14 mt-14">Whatsapp</span></a>
                            </li>
                            <li class="share_tab" (click)="copyToClipboard(currentUrl)"><a class="share_anchor"><span
                                        class="share_icon"><img src="assets/images/copy_share_icon.svg" alt="Copy Link"
                                            class="share_icon_img"></span><span
                                        class="share_icon_text color111 fw500 font14 mt-14">Copy
                                        Link</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!shopMiddleContentLoaded">
            <app-loading [inputSourceLayout]="'shop-detail-middle.component'"></app-loading>
        </div>
    </div>
</div>
<!-- shop Information section end -->

<!-- map section start -->
<div class="container">
    <div class="row mt-20" *ngIf="shopMiddleContentLoaded">
        <div class="col-12 col-lg-9 col-sm-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title font20 fw600 color111 pointer mb-3">Visit Our Shop</h5>
                    <div class="d-flex mapframe">
                        <ng-container>
                            <span *ngIf="shops?.mappedLink; else defaultMap">
                                <iframe [src]="getSanitizedUrl(shops?.mappedLink)" width="975" height="300"
                                    allowfullscreen class="map_mobile">
                                </iframe>
                            </span>
                        </ng-container>
                        <ng-template #defaultMap>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"
                                width="975" height="300" allowfullscreen class="map_mobile"></iframe>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3 col-sm-12">
            <div class="card p-27 mt-mob-30">
                <div class="card-body">
                    <h5 class="card-title font20 fw600 color111 mb-3">Listed In</h5>
                    <div class="list" *ngFor="let type of shopSubCategory | docfilter : 'cat-list';">
                        <span class="font15 pointer color555" [routerLink]="['/browse/shops']"
                            [queryParams]="{category: replaceSpecialCharWithHyphen(type.title) }">{{type.category}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!shopMiddleContentLoaded">
    <app-loading [inputSourceLayout]="'shop-detail-middle-map.component'"></app-loading>
</div>
<!-- map section end -->

<!-- Write review section start -->
<div class="container" *ngIf="shopMiddleContentLoaded">
    <div class="row mt-20">
        <div class="col-12 col-lg-9 col-sm-12">
            <div class="card pb-50">
                <div class="card-body">
                    <div class="d-flex">
                        <div><button type="button" class="btn rating">{{shops?.shopdialRank}}.0</button></div>
                        <div class="flex-grow-1 ml-5">
                            <h5 class="card-title font20 fw600 color111">Reviews & Ratings</h5>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start mt-3">
                        <span class="fw500 font16 recentRate_mobile color111">Recent Rating</span>
                    </div>
                    <ul class="rate_Star mt-3">
                        <li *ngFor="let rating of starRatings">
                            <button type="button" class="btn rating-button"
                                [class.highlighted]="selectedShopRating === rating" (click)="setShopRating(rating)">
                                <span class="color111 font_Star_mobile hover-text">{{rating}}.0</span>
                                <i class="fa fa-star star-icon px-2 rate_star_icon" aria-hidden="true"></i>
                            </button>
                        </li>
                    </ul>
                    <div class="d-flex justify-content-start mt-3">
                        <h3 class="card-title font16 fw500 color555">Post Reviews</h3>
                    </div>
                    <div>
                        <div class="container">
                            <div class="form-row" [formGroup]="shopReviewForm">
                                <div class="row">
                                    <div class="col-md-6 col-12 ml-14">
                                        <div class="form-group mt-3">
                                            <label class="col-form-label pt-0">Name</label><span style="color: red;">
                                                *</span>
                                            <input class="form-control" id="name" type="text" name="name"
                                                placeholder="Name" formControlName="name">
                                            <div *ngIf="hasError('name')" [class]="hasError('name') ? 'error' : ''">
                                                <div *ngIf="reviewName?.errors">
                                                    <span *ngIf="reviewName?.errors?.required">Name is a
                                                        required field</span>
                                                    <span *ngIf="reviewName?.errors?.['minlength']">
                                                        Name should min 3 char long </span>
                                                    <span *ngIf="reviewName?.errors?.['maxlength']">
                                                        Name should be max 30 char long </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 ml-14">
                                        <div class="form-group mt-3">
                                            <label class="col-form-label pt-0">Email</label><span style="color: red;">
                                                *</span>
                                            <input class="form-control" id="email" type="email" name="email"
                                                placeholder="Email" formControlName="email">
                                            <div *ngIf="hasError('email')" [class]="hasError('email') ? 'error' : ''">
                                                <div *ngIf="reviewEmail?.errors">
                                                    <span *ngIf="reviewEmail?.errors?.['required']">
                                                        Email is a required field.</span>
                                                    <span *ngIf="reviewEmail?.errors?.['email']">Email
                                                        should be email type.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-12 ml-14">
                                        <div class="form-group mt-3">
                                            <label class="col-form-label pt-0">Phone number</label><span
                                                style="color: red;"> *</span>
                                            <input class="form-control" id="phoneNumber" type="text" name="phoneNumber"
                                                placeholder="Phone number" formControlName="phoneNumber" maxlength="10">
                                            <div *ngIf="hasError('phoneNumber')"
                                                [class]="hasError('phoneNumber') ? 'error' : ''">
                                                <div *ngIf="phoneNumber?.errors">
                                                    <span *ngIf="phoneNumber?.errors?.['required']">Phone
                                                        number is a required field </span>
                                                    <span
                                                        *ngIf="!phoneNumber?.errors?.['required'] && !phoneNumber?.errors?.['pattern'] && phoneNumber?.errors?.['minlength']">
                                                        Phone number should min 10 char long </span>
                                                    <span *ngIf="phoneNumber?.errors?.['pattern']">Please enter
                                                        numbers only</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 ml-14">
                                        <div class="form-group mt-3">
                                            <label class="col-form-label pt-0">Write Review</label><span
                                                style="color: red;"> *</span>
                                            <textarea class="form-control" id="shopReview" placeholder="Write review"
                                                name="shopReview" formControlName="shopReview" rows="3"></textarea>
                                            <div *ngIf="hasError('shopReview')"
                                                [class]="hasError('shopReview') ? 'error' : ''">
                                                <div *ngIf="shopReview?.errors">
                                                    <span *ngIf="shopReview?.errors?.['required']">
                                                        Review is a required field </span>
                                                    <span *ngIf="shopReview?.errors?.['minlength']">
                                                        Review should min 100 char long </span>
                                                    <span *ngIf="shopReview?.errors?.['maxlength']">
                                                        Review should be max 1000 char long </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-grid col-md-12 col-12 mt-3">
                                    <button class="btn btn-primary review_button" type="submit"
                                        (click)="onSubmitReview(shopReviewForm)">Submit Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-3 col-sm-12">
            <div class="card mt-mob-30">
                <div class="card-body">
                    <div class="report-error">
                        <h5 class="card-title font20 fw600 color111">Question & Answers</h5>
                        <div class="vendorinfo_content font15 fw500 color555 mb-20">Would you like to ask a question?</div>
                        <button type="button" class="reporterror_button font18 fw500 color007 blue_whitefill_animate"
                            style="position: relative;" (click)="showQuestionForm()">Post your quetion</button>
                    </div>
                    <div class="report-error">
                        <div class="font20 fw600 color111 mb-10">Report an error</div>
                        <div class="vendorinfo_content font15 fw500 color555 mb-20">Assist us in enhancing
                            Shopdial to ensure it stays current and aligns better with your needs.</div>
                        <button type="button" class="reporterror_button font18 fw500 color007 blue_whitefill_animate"
                            style="position: relative;" (click)="showReportPopupForm()">Report Now</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!--Shop report popup form start-->
    <div id="reportForm" class="popup-form">
        <section [formGroup]="shopReportForm">
            <div class="sd_modal_content sd_modal_medium modal_animation fadeInUpTopReport">
                <div class="sd_modal_header pt-30 pl-30 pr-30 mobile-pl-10">
                    <div class="modal_head_center">
                        <span class="sdwrapper font22 fw500 color111 text_left ">Report an Error</span>
                    </div>
                    <div class="modal_head_right"><span class="sd_modal_close sdicon"
                            (click)="closeReportPopupForm()"></span></div>
                </div>
                <div class="sd_modal_body p-30">
                    <div class="font18 fw700 color111 mb-20 mt-10">What's Wrong?
                    </div>
                    <ul class="report_linklist">
                        <!-- Checkbox for Phone Number -->
                        <li class="report_linktab">
                            <label for="Phone" class="animcheck">
                                <input type="checkbox" id="Phone" name="Phone" class="animinput"
                                    [checked]="shopReportForm.get('comment').value === 'Phone Number'"
                                    (change)="onCheckboxChange($event, 'Phone Number')">
                                <div class="animlabel"><span class="animtext">Phone number</span>
                                    <span class="animicon"></span>
                                </div>
                            </label>
                        </li>
                        <!-- Checkbox for Address -->
                        <li class="report_linktab">
                            <label for="Supplier2" class="animcheck">
                                <input type="checkbox" id="Supplier2" name="Supplier2" class="animinput"
                                    [checked]="shopReportForm.get('comment').value === 'Address'"
                                    (change)="onCheckboxChange($event, 'Address')">
                                <div class="animlabel"><span class="animtext">Address</span><span
                                        class="animicon"></span>
                                </div>
                            </label>
                        </li>
                        <!-- Checkbox for Closed Down -->
                        <li class="report_linktab">
                            <label for="Supplier" class="animcheck">
                                <input type="checkbox" id="Supplier" name="Supplier" class="animinput"
                                    [checked]="shopReportForm.get('comment').value === 'This Place has Closed Down'"
                                    (change)="onCheckboxChange($event, 'This Place has Closed Down')">
                                <div class="animlabel"><span class="animtext">This place has closed
                                        Down</span><span class="animicon"></span></div>
                            </label>
                        </li>

                        <!-- Checkbox for Other -->
                        <li class="report_linktab">
                            <label for="Others" class="animcheck">
                                <input type="checkbox" id="Others" name="Others" class="animinput"
                                    [checked]="shopReportForm.get('comment').value === 'Other'"
                                    (change)="onCheckboxChange($event, 'Other')">
                                <div class="animlabel"><span class="animtext">Other</span><span class="animicon"></span>
                                </div>
                            </label>
                        </li>
                    </ul>

                    <button class="bluefill_animate font18 fw500 colorFFF mb-20"
                        (click)="onSubmitReport(shopReportForm)">Submit</button>
                </div>
            </div>
        </section>

    </div>
    <!-- report popup form  end-->
     <!-- Shop question popup form  start-->
    <div id="questionForm" class="popup-form">
        <section>
            <div class="sd_modal_content sd_modal_medium modal_animation fadeInUpTopReport">
                <div class="sd_modal_header pt-30 pl-30 pr-30 mobile-pl-10">
                    <div class="modal_head_center">
                        <span class="sdwrapper font22 fw500 color111 text_left ">Post your question</span>
                    </div>
                    <div class="modal_head_right"><span class="sd_modal_close sdicon"
                            (click)="closeQuestionForm()"></span></div>
                </div>
                <div class="sd_modal_body p-30" [formGroup]="shopQuestionForm">
                    <textarea class="form-control" formControlName="question" placeholder="Type your question*" maxlength="200"></textarea>
                    <div *ngIf="hasError('question')" [class]="hasError('question') ? 'error' : ''">
                        <div *ngIf="question?.errors">
                            <span *ngIf="question?.errors?.['required']">
                            Question is a required field </span>
                            <span *ngIf="question?.errors?.['minlength']">
                            Question should min 10 char long </span>
                            <span *ngIf="question?.errors?.['maxlength']">
                            Question should be max 200 char long </span>
                        </div>
                    </div>
                    <ul class="report_linklist mt-3 instructions">
                        <li class="sd-width mb-3">
                            Questions should be relevant to the company and its services, clear, concise, non-marketing, and without links or URLs.
                        </li>
                        <li class="sd-width mb-3">
                            Questions should be appropriate for the Custodial platform and follow all applicable terms of service.
                        </li>
                    </ul>
                    <button class="bluefill_animate font18 fw400 colorFFF mb-20"
                        (click)="onSubmitQuestion(shopQuestionForm)">Post you question</button>
                </div>
            </div>
        </section>
    </div>
    <!--Question popup form end-->
</div>
<div *ngIf="!shopMiddleContentLoaded">
    <app-loading [inputSourceLayout]="'shop-detail-middle-review.component'"></app-loading>
</div>
<!-- Write review section end -->

<!-- user review section start  -->
<div class="container" *ngIf="shopMiddleContentLoaded">
    <div class="row mt-20" *ngIf="reviews && reviews.length > 0">
        <div class="col-12 blog-detail-page">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-start mt-3 mb-3">
                        <h3 class="card-title font20 fw600 color111">User Reviews</h3>
                    </div>
                    <ul class="comment-section">
                        <ng-container>
                            <li *ngFor=" let review of reviews">
                                <div class="media"><img src="assets/images/icon/Review_DP.png"
                                        alt="Generic placeholder image" class="img-fluid">
                                    <div class="media-body">
                                        <h6 class="mobile-ml15 font15 fw600">{{review?.name | sentenceCase}}
                                            <span class="color555">( {{review?.createdDate | date:'MMMM d,y
                                                h:ma'}})</span>
                                            <span>
                                                <i class="fa fa-star star-rating"
                                                    *ngFor="let star of getStarArray(review?.shopRating)"></i>
                                            </span>
                                        </h6>
                                        <p class="color555 fw500 reivew-para font15">{{review?.shopReview}}</p>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!shopMiddleContentLoaded">
        <app-loading [inputSourceLayout]="'shop-detail-middle-review-list.component'"></app-loading>
    </div>
</div>

<!-- Most Searched section -->
<div class="container">
    <div class="row mt-20">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-start mt-3 mb-3">
                        <h3 class="card-title font20 fw600 color111">Most Searched</h3>
                    </div>
                    <ul class="comment-section">
                        <ng-container>
                            <div *ngFor="let type of shopSubCategory | docfilter : 'cat-icon';">
                                <a class="color555 font15 fw500 line_height" [routerLink]="['/browse/shops']"
                                    [queryParams]="{category: replaceSpecialCharWithHyphen(type.title) }">{{type.mostSearch}}</a>
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row mt-20">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-start mt-3 mb-3">
                        <h3 class="card-title font20 fw600 color111">Recent keyword</h3>
                    </div>
                    <ul class="comment-section">
                        <ng-container>
                            <div *ngFor="let type of shopSubCategory | docfilter : 'cat-icon';">
                                <a class="color555 font15 fw500 line_height" [routerLink]="['/browse/shops']"
                                    [queryParams]="{category: replaceSpecialCharWithHyphen(type.title) }">{{type.recentkeyword}}</a>
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row mt-20">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-start mt-3 mb-3">
                        <h3 class="card-title font20 fw600 color111">Popular keyword</h3>
                    </div>
                    <ul class="comment-section">
                        <ng-container>
                            <div *ngFor="let type of shopSubCategory | docfilter : 'cat-icon';">
                                <a class="color555 font15 fw500 line_height" [routerLink]="['/browse/shops']"
                                    [queryParams]="{category: replaceSpecialCharWithHyphen(type.title) }">{{type.popularKeyword}}</a>
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row mt-20">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-start mt-3 mb-3">
                        <h3 class="card-title font20 fw600 color111">Frequently Asked Questions</h3>
                    </div>
                    <ul class="comment-section">
                        <ng-container>
                            <p class="card-text font15 fw500">We offer a wide range of products and services tailored to
                                meet your
                                needs. Please visit our <span>{{shops.shopType?.name}}</span> called
                                <span>{{shops.name}}</span> or contact us directly to learn more about what we have to
                                offer.
                            </p>

                            <h5 class="card-title font-mob-14 fw500">1. For what occasions can I use {{shops.name}}
                                products or
                                services?
                            </h5>
                            <p class="card-text font15 fw500">{{shops.name}} products and services are suitable for
                                various
                                occasions, including everyday use, special events, and specific requirements. Feel free
                                to consult with us for personalized recommendations.</p>

                            <h5 class="card-title font-mob-14 fw500">2. Can I customize my order at {{shops.name}}?</h5>
                            <p class="card-text font15 fw500">Yes, {{shops.name}} offers customization options for many
                                of our
                                products and services. Please discuss your preferences with us, and we will do our best
                                to accommodate your needs.</p>

                            <h5 class="card-title font-mob-14 fw500">3. Do I need to book {{shops.name}} services in
                                advance?</h5>
                            <p class="card-text font15 fw500">To ensure availability, we recommend booking
                                {{shops.name}} services in
                                advance. This helps us provide the best possible service on your preferred date and
                                time.</p>

                            <h5 class="card-title font-mob-14 fw500">4. What are your operational hours?</h5>
                            <p class="card-text font15 fw500">Our shop is open during the following hours: Monday to
                                Friday from
                                {{shops?.openingTime}} to {{shops?.closingTime}}. Please note that our hours may vary on
                                holidays.</p>

                            <span *ngFor="let shopLocation of shops?.location; let i = index">
                                <h5 class="card-title font-mob-14">5. Where are you located?</h5>
                                <p class="card-text font15 fw500" id="full-address">We are conveniently located at <span
                                        id="address">{{shopLocation?.address}}, {{shopLocation?.postalCode}},
                                        {{shopLocation?.city}}, {{shopLocation?.state}},
                                        {{shopLocation?.country}}</span>. You can find us easily using any map service,
                                    and ample parking is available for our customers.</p>
                            </span>
                            <h5 class="card-title font-mob-14 mt-3 fw500">6. How do you ensure a comfortable experience
                                for clients
                                or
                                customers at {{ shops.name }}?</h5>
                            <p class="card-text font15 fw500">At {{ shops.name }}, we prioritize your comfort. Our
                                spacious
                                facilities are designed to cater to a diverse range of needs. Whether you're visiting
                                alone or with a group, we strive to provide a welcoming environment. Contact us at <span
                                    (click)="showPopupForm()" class="cursor">{{shops.contact | mask:6 }},</span>
                                for special accommodations or group bookings.</p>

                            <h5 class="card-title font-mob-14 fw500">7. Do you offer delivery services at {{ shops.name
                                }}?</h5>
                            <p class="card-text font15 fw500">Yes, {{ shops.name }} offers delivery services for your
                                convenience.
                                Please
                                contact us at <span (click)="showPopupForm()" class="cursor">{{shops.contact | mask:6
                                    }},</span>
                                to arrange delivery and to find out if there are any associated fees.</p>

                            <h5 class="card-title font-mob-14 fw500">8. Are there any special promotions or discounts
                                available at
                                {{shops.name}}?</h5>
                            <p class="card-text font15 fw500">{{ shops.name }} regularly offers special promotions and
                                discounts.
                                Visit our
                                website at https://{{ shops.registeredDomain }}.shopdial.in or contact us directly at
                                <span (click)="showPopupForm()" class="cursor">{{shops.contact | mask:6 }},</span>to
                                learn about our current offers.
                            </p>

                            <h5 class="card-title font-mob-14 fw500">9. How can I contact you for further inquiries?
                            </h5>
                            <p class="card-text font15 fw500"> You can contact us by phone <span
                                    (click)="showPopupForm()" class="cursor">{{shops.contact | mask:6 }},</span> or
                                through our
                                website's contact form.
                                Our customer service team is here to help with any questions or concerns you may have
                            </p>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row mt-20">
        <div class="col-12" style="margin-bottom: 20px;">
            <img src="assets/images/banner/Register-Business.png" alt="Static Image 2" class="img-fluid">
        </div>
    </div>
</div>
<app-home-footer></app-home-footer>