import { Component, OnInit } from '@angular/core';
import { HomeSlider } from '../../../shared/data/slider';
import { Product } from '../../../shared/classes/product';
import { ProductService } from '../../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';
import { BlogService } from 'src/app/shared/services/blog.service';

@Component({
  selector: 'app-fashion-two',
  templateUrl: './fashion-two.component.html',
  styleUrls: ['./fashion-two.component.scss']
})
export class FashionTwoComponent implements OnInit {

  public themeLogo: string = 'assets/images/icon/logo-5.png'; // Change Logo

  public products: any[] = [];
  public productCollections: any[] = [];
  public active: any;
  public documentForm: FormGroup;
  public generatedShopId: string;
  public filteredOfferDocumentsOne: any;
  public filteredOfferDocumentsTwo: any;
  public filteredOfferDocumentsThree: any;
  public filteredOfferDocumentsFour: any;
  public filtereBackgroundDocuments: any;
  public documents: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public isOfferBannerFormOpenTwo: boolean = false;
  public isOfferBannerFormOpenThree: boolean = false;
  public isOfferBannerFormOpenFour: boolean = false;
  public isBackgroundBannerFormOpen: boolean = false;
  public errorMessageOffer: string;
  public uploadOfferBannerOne: any;
  public uploadOfferBannerTwo: any;
  public uploadOfferBannerThree: any;
  public uploadOfferBannerFour: any;
  public uploadBackgroundBanner: any;
  // Blog
  public blogs: any[] = [];

  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService, private blogService: BlogService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item, i) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public HomeSliderConfig: any = HomeSlider;

  public sliders = [{
    title: 'welcome to fashion',
    subTitle: 'Men fashion',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/19.jpg'
  }, {
    title: 'Save Upto 50%',
    subTitle: 'Women fashion',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/20.jpg'
  }, {
    title: 'Save Upto 50%',
    subTitle: 'Women fashion',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/63.jpg'
  }
  ]

  // Collection banner
  public collections1 = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/fashion/1.jpg',
    save: 'save 30%',
    title: 'Women'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/fashion/2.jpg',
    save: 'save 50%',
    title: 'Men'
  }];

  public collections2 = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/fashion/3.jpg',
    save: 'save 30%',
    title: 'Sandle'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/fashion/4.jpg',
    save: 'save 10%',
    title: 'Kids'
  }];

  ngOnInit(): void {

    this.blogService.getBlogByShopId().subscribe((data) => {
      this.blogs = data;
    });

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the offer-banner identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });

      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });

      this.filteredOfferDocumentsThree = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-three';
      });

      this.filteredOfferDocumentsFour = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-four';
      });

      // Filter the documents based on the background-banner identifier
      this.filtereBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }
  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  closePopUpForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isOfferBannerFormOpenThree = false;
    this.isOfferBannerFormOpenFour = false;
    this.isBackgroundBannerFormOpen = false;
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select one images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select one images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormThree() {
    this.isOfferBannerFormOpenThree = true;
  }

  onSelectOfferThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select one images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerThree) {
            this.uploadOfferBannerThree = [];
          }
          this.uploadOfferBannerThree.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerThree = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormFour() {
    this.isOfferBannerFormOpenFour = true;
  }

  onSelectOfferFour = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select Four images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerFour) {
            this.uploadOfferBannerFour = [];
          }
          this.uploadOfferBannerFour.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerFour = []; // Clear the array if the count is not equal to 2
    }
  }

  
  openBackgroundForm() {
    this.isBackgroundBannerFormOpen = true;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only with width 1920px and height 614px.';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 614) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 614px`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 614px.';
      this.uploadBackgroundBanner = []; // Clear the array if the count is not equal to 2
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-two');
      }
    }else if (this.uploadOfferBannerThree && this.uploadOfferBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-three");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-three');
      }
    }else if (this.uploadOfferBannerFour && this.uploadOfferBannerFour.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-four");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-four');
      }
    } else if (this.uploadBackgroundBanner && this.uploadBackgroundBanner.length > 0) {
      this.documentForm.get('identifier').setValue("background-banner");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'background');
      }
    } 
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
        case 'offer-three':
        this.addOfferDocument(document, this.uploadOfferBannerThree);
        break;
      case 'offer-four':
        this.addOfferDocument(document, this.uploadOfferBannerFour);
        break;
      case 'background':
        this.addOfferDocument(document, this.uploadBackgroundBanner);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }
  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopUpForm();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }
}
