import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Product } from '../../shared/classes/product';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss']
})
export class GymComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-2.png'; // Change Logo
  public products: any[] = [];

  public documentForm: FormGroup;
  public errorMessage: string;
  public isEditableMode: boolean = false;
  public generatedShopId: any;
  public documents: any;

  public filteredBannerOneDocuments: any;
  public uploadBannerOne: any;
  public isBannerOneFormOpen: boolean = false;


  public filteredBannerTwoDocuments: any;
  public uploadBannerTwo: any;
  public isBannerTwoFormOpen: boolean = false;

  public filteredBackgroundDocuments: any;
  public uploadBackgroundBanner: any;
  public isBackgroundFormOpen: boolean = false;

  public isHeaderHidden = false;

  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public sliders = [{
    title: 'summer sale',
    subTitle: 'Protien Powder',
    image: 'assets/images/gymhome-1.jpg'
  }, {
    title: 'summer sale',
    subTitle: 'Protien Powder',
    image: 'assets/images/gymhome-2.jpg'
  }];

  // Blog
  public blogs = [{
    image: 'assets/images/blog-1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog-2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog-3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog-4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  // Logo
  public logos = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  },
  // {
  //   image: 'assets/images/logos/7.png',
  // }, 
  {
    image: 'assets/images/logos/8.png',
  }];


  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#3d72f1');
    document.documentElement.style.setProperty('--theme-gradient1', '#01effc');
    document.documentElement.style.setProperty('--theme-gradient2', '#485ff2');
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();

    // Subscribe to changes in header visibility
    this.docSrv.isHeaderHidden$.subscribe(isHidden => {
      this.isHeaderHidden = isHidden;
    });
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
    document.documentElement.style.removeProperty('--theme-gradient1');
    document.documentElement.style.removeProperty('--theme-gradient2');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredBannerOneDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });
      this.filteredBannerTwoDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });
      this.filteredBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  openBannerOneForm() {
    this.isBannerOneFormOpen = true;
  }

  closeBannerForm() {
    this.isBannerOneFormOpen = false;
    this.isBannerTwoFormOpen = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  openBannerTwoForm() {
    this.isBannerTwoFormOpen = true;
  }


  openBackgroundForm() {
    this.isBackgroundFormOpen = true;
  }

  closeBackgroundForm() {
    this.isBackgroundFormOpen = false;
  }

  onRemoveBackground(event) {
    const indexToRemove = this.uploadBackgroundBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadBackgroundBanner.splice(indexToRemove, 1);
    }
  }

  onSelectBannerOneImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 604 && image.height === 486) {
          if (!this.uploadBannerOne) {
            this.uploadBannerOne = [];
          }
          this.uploadBannerOne.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 604px and height 486px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectBannerTwoImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1275 && image.height === 500) {
          if (!this.uploadBannerTwo) {
            this.uploadBannerTwo = [];
          }
          this.uploadBannerTwo.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1275px and height 500px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 1920 && image.height === 1080) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessage = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessage = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 1080px.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  public onSubmitBanner(form: any): void {
    if (this.uploadBannerOne && this.uploadBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid && this.uploadBannerOne.length > 0) {
        this.handleBannerDocument(form.value, 'offer-one');
      }
    } else if (this.uploadBannerTwo && this.uploadBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid && this.uploadBannerTwo.length > 0) {
        this.handleBannerDocument(form.value, 'offer-two');
      }
    }
  }

  public onSubmitBackground(form: any): void {
    this.documentForm.get('identifier').setValue("background-banner");
    if (form.valid && this.uploadBackgroundBanner.length > 0) {
      this.handleBackgroundDocument(this.documentForm.value);
    }
  }

  public handleBannerDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addBannerOneDocument(document, this.uploadBannerOne);
        break;
      case 'offer-two':
        this.addBannerTwoDocument(document, this.uploadBannerTwo);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  public handleBackgroundDocument(document: any): void {
    this.addBackgroundDocument(document, this.uploadBackgroundBanner);
  }

  public addBannerOneDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.closeBannerForm();
      this.removeDocumentAfterUpload();
      this.getDocumentByShopId();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public removeDocumentAfterUpload() {
    this.uploadBannerOne = [];
    this.uploadBannerTwo = [];
  }

  public addBannerTwoDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public addBackgroundDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  // encode image url
  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }

}
