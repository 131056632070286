import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopdocumentService {


  shopUrl = environment.base_url_shop_service;

  private isHeaderHiddenSource = new BehaviorSubject<boolean>(false);
  isHeaderHidden$ = this.isHeaderHiddenSource.asObservable();

  constructor(private httpClient: HttpClient) { }

  public addBannerDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("home-banner", files[i], files[i].name);
    }
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/home-banner`, formData, { headers: headers });
  }

  public addOfferBannerDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("offer-banner", files[i], files[i].name);
    }
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/offer-banner`, formData, { headers: headers });
  }

  public addDiscountBannerDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("discount-banner", files[i], files[i].name);
    }

    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/discount-banner`, formData, { headers: headers });
  }

  public addBackgroundBannerDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files?.length; i++) {
      formData.append("background-banner", files[i], files[i].name);
    }

    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/background-banner`, formData, { headers: headers });
  }


  public addMediumBannerDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files?.length; i++) {
      formData.append("medium-banner", files[i], files[i].name);
    }

    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/medium-banner`, formData, { headers: headers });
  }

  addVideoDocument(document: any, shopId: any, files: any) {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files?.length; i++) {
      formData.append("shop-video", files[i], files[i].name);
    }

    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/shop-video`, formData, { headers: headers });
  }

  public getDocByShopId(shopId: any) {
    return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/documents`)
  }

  public findSectionByShopId(shopId: any) {
    return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/sections`)
  }

  public addSection(shopId: any, section: any) {
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.put(`${this.shopUrl}/shops/${shopId}/sections`, section, { headers: headers })
  }

  public updateSection(shopId: any, section: any) {
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.put(`${this.shopUrl}/shops/${shopId}/sections`, section, { headers: headers })
  }

  // About Us Document
  public updateAboutUsDocument(document: any, shopId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("about-banner", files[i], files[i].name);
    }
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.post(`${this.shopUrl}/shops/${shopId}/about`, formData, { headers: headers });
  }

  toggleHeaderVisibility(isHidden: boolean) {
    this.isHeaderHiddenSource.next(isHidden);
  }

  public updateAboutUsBannerDocument(document: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("about-us-docs", files[i], files[i].name);
    }
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient.put(`${this.shopUrl}/shops/aboutus/docs`, formData, { headers: headers });
  }
}
