<app-header-one [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen"></app-header-one>

<!-- home banner start -->
<div class="container banner-slider">
    <div class="row">
        <div *ngIf="filteredBigDocuments?.length > 0 ;then content else other_content"></div>
        <ng-template #content>
            <div class="col-md-7" *ngFor="let doc of filteredBigDocuments">
                <i class="fa fa-pencil-square-o fa-2x fa-pencil-lg" aria-hidden="true" *ngIf="isEditable()"
                    (click)="openBigBannerForm()"></i>
                <img [src]="doc?.urls" class="img-fluid height-banner" alt="electronics">
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isDiscountBannerFormOpen">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectBigBanner($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                    787px X 789px.</small>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadBigBanner" [removable]="true"
                                            (removed)="onRemoveItem(uploadBigBanner, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #other_content>
            <div class="col-md-7">
                <i class="fa fa-pencil-square-o fa-2x fa-pencil-lg" aria-hidden="true" *ngIf="isEditable()"
                    (click)="openBigBannerForm()"></i>
                <img src="assets/images/slider1.jpg" class="img-fluid height-banner" alt="electronics">
                <!-- Popup Form -->
                <div class="popup-form" *ngIf="isDiscountBannerFormOpen">
                    <div class="confirmation-content" [formGroup]="documentForm">
                        <!-- Add a small tag for displaying messages at the top -->
                        <small class="text-danger">{{ errorMessageOffer }}</small>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelectBigBanner($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                    787px X 789px.</small>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of uploadBigBanner" [removable]="true"
                                            (removed)="onRemoveItem(uploadBigBanner, f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button class="btn btn-success pull-right" type="submit"
                                (click)="onSubmitForm(documentForm)">Confirm</button>
                            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div *ngIf="filteredMediumDocuments?.length > 0 ;then med_content else other_med_content"></div>
        <ng-template #med_content>
            <div class="col-md-5">
                <div class="row home-banner" *ngFor="let doc of filteredMediumDocuments">
                    <div class="col-12">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-medium" aria-hidden="true"
                            *ngIf="isEditable() && !isDiscountBannerFormOpen" (click)="openMedBannerForm()"></i>
                        <a>
                            <img [src]="doc?.urls" class="img-fluid" alt="electronics">
                        </a>
                        <!-- Popup Form -->
                        <div class="popup-form" *ngIf="isMedBannerFormOpen">
                            <div class="confirmation-content" [formGroup]="documentForm">
                                <!-- Add a small tag for displaying messages at the top -->
                                <small class="text-danger">{{ errorMessageOffer }}</small>
                                <div class="container mt-3">
                                    <div class="row">
                                        <div class="card-body dropzone-custom p-0">
                                            <ngx-dropzone class="dropzone-border" (change)="onSelectMedBanner($event)">
                                                <ngx-dropzone-label>
                                                    <div class="dz-message needsclick">
                                                        <i class="fa fa-cloud-upload"></i>
                                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                                                        </h4>
                                                    </div>
                                                </ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of uploadMediumBanner"
                                                    [removable]="true" (removed)="onRemoveItem(uploadMediumBanner, f)">
                                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button class="btn btn-success pull-right" type="submit"
                                        (click)="onSubmitForm(documentForm)">Confirm</button>
                                    <button class="btn btn-primary pull-right"
                                        (click)="closePopUpForm()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #other_med_content>
            <div class="col-md-5">
                <div class="row home-banner">
                    <div class="col-12">
                        <i class="fa fa-pencil-square-o fa-2x fa-pencil-medium" aria-hidden="true"
                            *ngIf="isEditable() && !isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree"
                            (click)="openMedBannerForm()"></i>
                        <a>
                            <img src="assets/images/slider2.jpg" class="img-fluid" alt="electronics">
                        </a>
                        <!-- Popup Form -->
                        <div class="popup-form" *ngIf="isMedBannerFormOpen">
                            <div class="confirmation-content" [formGroup]="documentForm">
                                <!-- Add a small tag for displaying messages at the top -->
                                <small class="text-danger">{{ errorMessageOffer }}</small>
                                <div class="container mt-3">
                                    <div class="row">
                                        <div class="card-body dropzone-custom p-0">
                                            <ngx-dropzone class="dropzone-border" (change)="onSelectMedBanner($event)">
                                                <ngx-dropzone-label>
                                                    <div class="dz-message needsclick">
                                                        <i class="fa fa-cloud-upload"></i>
                                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                        <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                            559px X 384px.</small>
                                                    </div>
                                                </ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of uploadMediumBanner"
                                                    [removable]="true" (removed)="onRemoveItem(uploadMediumBanner, f)">
                                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button class="btn btn-success pull-right" type="submit"
                                        (click)="onSubmitForm(documentForm)">Confirm</button>
                                    <button class="btn btn-primary pull-right"
                                        (click)="closePopUpForm()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <a>
                            <img src="assets/images/slider3.jpg" class="img-fluid" alt="electronics">
                        </a>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<!-- home banner end  -->
<!-- collection banner dynamic -->

<section class="banner-padding banner-goggles" *ngIf="!isMedBannerFormOpen && !isDiscountBannerFormOpen">
    <div class="container">
        <div class="row partition3">
            <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then content_one else default_content_one"></div>
            <ng-template #content_one>
                <div class="col-md-4" *ngFor="let collection of filteredOfferDocumentsOne">
                    <a *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                        <div class="collection-banner p-right text-end">
                            <img [src]="collection?.urls" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>{{collection?.textTittle}}</h4>
                                    <h2>{{collection?.textSubTittle}}</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormOne()"></i>
                    </span>
                </div>
            </ng-template>
            <ng-template #default_content_one>
                <div class="col-md-4" *ngIf="!isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree">
                    <a>
                        <div class="collection-banner p-right text-end">
                            <img src="assets/images/electronic-col-1.jpg" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>10% off</h4>
                                    <h2>speaker</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormOne()"></i>
                    </span>
                </div>
            </ng-template>
            <!-- Popup Form One Start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessage }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                437px X 219px.</small>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                            <div class="row justify-content-around mt-4">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                                        Tittle
                                    </label>
                                    <input class="form-control" id="textTittle" type="text" name="textTittle"
                                        formControlName="textTittle">
                                </div>
                                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                    <label class="col-form-label pt-0">Text Subtittle</label>
                                    <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                        formControlName="textSubTittle">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitForm(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form One Ending-->
            <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then content_two else default_content_two"></div>
            <ng-template #content_two>
                <div class="col-md-4" *ngFor="let collection of filteredOfferDocumentsTwo">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree">
                        <div class="collection-banner p-right text-end">
                            <img [src]="collection?.urls" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>{{collection?.textTittle}}</h4>
                                    <h2>{{collection?.textSubTittle}}</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span" *ngIf="!isOfferBannerFormOpenOne">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormTwo()"></i>
                    </span>
                </div>
            </ng-template>
            <ng-template #default_content_two>
                <div class="col-md-4" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenThree">
                    <a>
                        <div class="collection-banner p-right text-end">
                            <img src="assets/images/electronic-col-2.jpg" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>10% off</h4>
                                    <h2>earplug</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormTwo()"></i>
                    </span>
                </div>
            </ng-template>
            <!-- Popup Form Two Start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessage }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                437px X 219px.</small>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                            <div class="row justify-content-around mt-4">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                                        Tittle
                                    </label>
                                    <input class="form-control" id="textTittle" type="text" name="textTittle"
                                        formControlName="textTittle">
                                </div>
                                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                    <label class="col-form-label pt-0">Text Subtittle</label>
                                    <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                        formControlName="textSubTittle">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitForm(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form Two Ending-->
            <div *ngIf="filteredOfferDocumentsThree?.length > 0 ;then content_three else default_content_three"></div>
            <ng-template #content_three>
                <div class="col-md-4" *ngFor="let collection of filteredOfferDocumentsThree">
                    <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
                        <div class="collection-banner p-right text-end">
                            <img [src]="collection?.urls" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>{{collection?.textTittle}}</h4>
                                    <h2>{{collection?.textSubTittle}}</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormThree()"></i>
                    </span>
                </div>
            </ng-template>
            <ng-template #default_content_three>
                <div class="col-md-4" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
                    <a>
                        <div class="collection-banner p-right text-end">
                            <img src="assets/images/electronic-col-3.jpg" alt="">
                            <div class="contain-banner banner-3">
                                <div>
                                    <h4>10% off</h4>
                                    <h2>best deal</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                    <span class="fa-pencil-offer-span">
                        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" *ngIf="isEditable()"
                            (click)="openOfferFormThree()"></i>
                    </span>
                </div>
            </ng-template>
            <!-- Popup Form Three Start -->
            <div class="popup-form" *ngIf="isOfferBannerFormOpenThree">
                <div class="confirmation-content" [formGroup]="documentForm">
                    <!-- Add a small tag for displaying messages at the top -->
                    <small class="text-danger">{{ errorMessage }}</small>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="card-body dropzone-custom p-0">
                                <ngx-dropzone class="dropzone-border" (change)="onSelectOfferThree($event)">
                                    <ngx-dropzone-label>
                                        <div class="dz-message needsclick">
                                            <i class="fa fa-cloud-upload"></i>
                                            <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                                                437px X 219px.</small>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerThree" [removable]="true"
                                        (removed)="onRemoveItem(uploadOfferBannerThree,f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </div>
                            <div class="row justify-content-around mt-4">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                                        Tittle
                                    </label>
                                    <input class="form-control" id="textTittle" type="text" name="textTittle"
                                        formControlName="textTittle">
                                </div>
                                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                                    <label class="col-form-label pt-0">Text Subtittle</label>
                                    <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                                        formControlName="textSubTittle">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <button class="btn btn-success pull-right" type="submit"
                            (click)="onSubmitForm(documentForm)">Confirm</button>
                        <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
                    </div>
                </div>
            </div>
            <!-- Popup Form Three Ending-->
        </div>
    </div>
    <!-- collection banner dynamic end -->
</section>


<!-- collection banner static -->


<!-- blog section start-->
<section class="blog p-t-0 section-b-space"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title1 section-t-space">
                    <h4>Recent Story</h4>
                    <h2 class="title-inner1">from the blog</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-blog [blogs]="blogs"></app-blog>
            </div>
        </div>
    </div>
</section>
<!-- blog section End -->

<!-- slider tab  -->
<section class="section-b-space"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="theme-tab">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active"
                        class="tabs tab-title justify-content-center nav nav-pills">
                        <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                            <a ngbNavLink>
                                {{ collection }}
                            </a>
                            <ng-template ngbNavContent>
                                <div class="no-slider row">
                                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                        <div class="product-box">
                                            <app-product-box-three [product]="product"
                                                [currency]="productService?.Currency">
                                            </app-product-box-three>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- slider tab end -->

<!-- Cart Open To bottom Side-->
<app-cart-variation [direction]="'left'"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen"></app-cart-variation>
<app-alert-popup
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen"></app-alert-popup>
<app-footer-one [themeLogo]="themeLogo"
    *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isOfferBannerFormOpenThree && !isMedBannerFormOpen && !isDiscountBannerFormOpen"></app-footer-one>