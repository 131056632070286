import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionSlider } from 'src/app/shared/data/slider';
import { LocationService } from 'src/app/shared/services/location.service';
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';

@Component({
  selector: 'app-home-collection',
  templateUrl: './home-collection.component.html',
  styleUrls: ['./home-collection.component.scss']
})
export class HomeCollectionComponent implements OnInit {

  @Input() categories: any[];
  @Input() category: string;
  @Input() class: string;
  public ImageSrc: string
  public rank: any = [];
  public contentLoaded = false;
  public inputSourceLayout = "";
  public imageLoaded: boolean = false;

  constructor(private homeSrv: HomepageService, public locationSrv: LocationService,
    private router: Router, private docSrv: ShopdocumentService) { }

  ngOnInit(): void {

    //get shop by rank 
    let serializedShopByShopdialRank = sessionStorage.getItem("SHOP-BY-RANK");
    this.rank = JSON.parse(serializedShopByShopdialRank);
    this.imageLoaded = true;
    if (this.rank == null || Object.keys(this.rank).length === 0) {
      this.getShopbyRank();
    } else if (this.rank != null || Object.keys(this.rank).length === 1) {
      this.contentLoaded = true;
    }
  }

  /**
   * Navigates to the view details page for a shop.
   * 
   * @param shopData - The data of the shop to view.
   */
  public onViewDetails(shopData: any): void {
    // Construct the route to the view details page and pass the shop data as state.
    this.router.navigate(['/shops/view', shopData.id], { state: { shopData: shopData } });
  }

  /**
   * Retrieves the shop by rank number.
   */
  public getShopbyRank() {
    this.homeSrv.getShopByRankNumber()
      .subscribe((data: any) => {
        this.rank = data;
        let documentsLoadedCount = 0;
        const totalShops = this.rank.length;
        this.rank.forEach(shop => {
          this.docSrv.getDocByShopId(shop.id).subscribe((docs: any) => {
            shop.documents = docs;
            if (documentsLoadedCount === totalShops) {
              this.imageLoaded = true;
              sessionStorage.setItem("SHOP-BY-RANK", JSON.stringify(this.rank));
            }
          });
          this.contentLoaded = true;
        });
      });

  }

  public CollectionSliderConfig: any = CollectionSlider;
}
