import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-flower',
  templateUrl: './flower.component.html',
  styleUrls: ['./flower.component.scss']
})
export class FlowerComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-11.png'; // Change Logo
  public products: any[] = [];
  public productCollections: any[] = [];
  public active;
  public documentForm: FormGroup;
  public generatedShopId: string;
  public documents: any;
  public errorMessageOfferOne: string;
  public errorMessageOfferTwo: string;
  public filteredOfferDocumentsOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentsTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public uploadOfferBannerTwo: any;

  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: ['offer-banner'],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public ProductSliderConfig: any = ProductSlider;

  public sliders = [{
    title: 'save upto 20%',
    subTitle: 'creative & decor',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/30.jpg'
  }, {
    title: 'save upto 10%',
    subTitle: 'pre-made & custom',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/31.jpg'
  }]

  // Blog
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/blog/3.jpg',
    date: '25 January 2022',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/blog/2.jpg',
    date: '26 January 2023',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Neo will'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/blog/1.jpg',
    date: '27 January 2019',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Mike Hanri'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/blog/1.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }]

  // collection
  public collections = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/sub-banner2.jpg',
    tittle: "hot deal",
    subTittle: "save 30%"
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/flower/sub-banner1.jpg',
    tittle: "Hot deal",
    subTittle: "save 40%"
  }]

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#fa869b');

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });

      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });
    });
  }

  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  closeOfferPopup() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOfferOne = 'Please select one images only .';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOfferOne = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 429 && image.height === 490) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
        } else {
          this.errorMessageOfferOne = `Invalid dimensions for ${file.name}. Please upload an image with width 429 and height 490.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOfferOne = 'Please upload an image with width 429 and height 490.';
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOfferTwo = 'Please select one images only .';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOfferTwo = '';

    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 890 && image.height === 490) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
        } else {
          this.errorMessageOfferTwo = `Invalid dimensions for ${file.name}. Please upload an image with width 890 and height 490.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOfferTwo = 'Please upload an image with width 890 and height 490.';
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-banner-1');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-banner-2');
      }
    } 
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-banner-1':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-banner-2':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closeOfferPopup();
      this.documentForm.reset();
      this.removeDocumentAfterUpload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  // remove document after upload
  public removeDocumentAfterUpload(){
    this.uploadOfferBannerOne= [];
    this.uploadOfferBannerTwo= [];
  }
}
