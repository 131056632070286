import { Component, OnInit, Input } from '@angular/core';
import { TenantService } from 'src/app/tenant/tenant.service';
import { EcomService } from '../../services/ecom.service';

@Component({
  selector: 'app-footer-three',
  templateUrl: './footer-three.component.html',
  styleUrls: ['./footer-three.component.scss']
})
export class FooterThreeComponent implements OnInit {

  @Input() class: string; // Default class 
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo

  public today: number = Date.now();
  public shopLocation: any;
  public shopSocialmedia: any;

  constructor(private tenantSrv: TenantService, private ecomSrv: EcomService) { }

  public shop: any = [];

  ngOnInit(): void {
    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
    
    this.getLocationByShopId();
    this.getSocialmediaByShopId();
  }
  public getLocationByShopId() {
    this.ecomSrv.getLocationByShopId().subscribe((data: any) => {
      this.shopLocation = data;
    });
  }

  public getSocialmediaByShopId() {
    this.ecomSrv.getSocialmediaByShopId().subscribe((data) => {
      this.shopSocialmedia = data;
    });
  }
}
