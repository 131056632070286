import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthTokenInterceptorService implements HttpInterceptor {
  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.userService.getToken();
    const tenantId = this.userService.getTenantId(); // Assumes you have a method to get tenantId

    let headers = {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    } else {
      headers['X-CLIENT-SECRET-KEY'] = environment.client_Secret;
    }
    if (tenantId) {
      headers['X-TENANT-ID'] = tenantId;
    }
    const clonedRequest = request.clone({ setHeaders: headers });
    return next.handle(clonedRequest);
  }
}