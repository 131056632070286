<app-header-one [themeLogo]="themeLogo"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen"></app-header-one>

<!-- Home slider start-->
<section class="p-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'furniture'" [buttonText]="'shop now'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- collection  banner -->
<div class="container-fluid mt-3">
  <div class="row partition3">
    <div *ngIf="filteredOfferDocumentOne?.length > 0 ;then contentOne else other_contentOne"></div>
    <ng-template class="banner-padding banner-furniture" #contentOne>
      <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentOne; let i = index">
        <a *ngIf="!isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="banner">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">{{doc?.textTittle}}</h4>
                <h2>{{doc?.textSubTittle}}</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="banner-padding banner-furniture" #other_contentOne>
      <div class="col-md-4">
        <a *ngIf="!isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner1.jpg" class="img-fluid"
                alt="banner-default">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">save 50%</h4>
                <h2>Sofa</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form start-->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form end-->

    <div *ngIf="filteredOfferDocumentTwo?.length > 0 ;then contentTwo else other_contentTwo"></div>
    <ng-template class="banner-padding banner-furniture" #contentTwo>
      <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentTwo; let i = index">
        <a *ngIf="!isOfferBannerFormOpenOne ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="banner">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">{{doc?.textTittle}}</h4>
                <h2>{{doc?.textSubTittle}}</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="banner-padding banner-furniture" #other_contentTwo>
      <div class="col-md-4">
        <a *ngIf="!isOfferBannerFormOpenOne ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner2.jpg" class="img-fluid"
                alt="banner-default">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">save 50%</h4>
                <h2>Bean Bag</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form start-->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form end-->

    <div *ngIf="filteredOfferDocumentThree?.length > 0 ;then contentThree else other_contentThree"></div>
    <ng-template class="banner-padding banner-furniture" #contentThree>
      <div class="col-md-4" *ngFor="let doc of filteredOfferDocumentThree; let i = index">
        <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="doc?.urls" class="img-fluid" alt="banner">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">{{doc?.textTittle}}</h4>
                <h2>{{doc?.textSubTittle}}</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="banner-padding banner-furniture" #other_contentThree>
      <div class="col-md-4">
        <a *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isBackgroundBannerFormOpen">
          <div class="p-right text-end" style="position: relative;">
            <div class="image-container">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner3.jpg" class="img-fluid"
                alt="banner">
            </div>
            <div class="contain-banner banner-3">
              <div class="text-overlay">
                <h4 class="offerSave">save 50%</h4>
                <h2>Chair</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form start-->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenThree">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferThree($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerThree" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerThree,f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form end-->
  </div>
</div>
<!-- </ng-template> -->
<!-- collection banner end -->

<!-- Tab product start-->
<div class="title1 section-t-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
  <h4>exclusive products</h4>
  <h2 class="title-inner1">special products</h2>
</div>
<section class="section-b-space p-t-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
          <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
            <a ngbNavLink>
              {{ collection }}
            </a>
            <ng-template ngbNavContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one [product]="product" [currency]="productService?.Currency">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->

<!-- Parallax banner -->
<div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
<ng-template #bG_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree">
    <div class="full-banner parallax-banner3 parallax text-center p-left"
      *ngFor="let bgDoc of filtereBackgroundDocuments" [ngStyle]="{'background-image': 'url('+bgDoc?.urls+')'}">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="banner-contain">
              <h2>2023</h2>
              <h3>{{bgDoc?.textTittle}}</h3>
              <h4>{{bgDoc?.textSubTittle}}</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                        1920px X 1280px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner end -->

<!-- Parallax default banner -->
<ng-template #defualt_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree">
    <div class="full-banner parallax-banner3 parallax text-center p-left"
      [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/parallax/3.jpg)'}">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="banner-contain">
              <h2>2023</h2>
              <h3>interior design in home</h3>
              <h4>special offer</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG , PNG files are allowed, Maximum file size of
                        1920px X 1280px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax default banner end -->

<!-- blog section start-->
<section class="blog p-t-0 section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- Cart Open To Top Side-->
<app-cart-variation [direction]="'top'"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen"></app-cart-variation>
<app-alert-popup></app-alert-popup>
<app-footer-one [themeLogo]="themeLogo"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo ! && !isOfferBannerFormOpenThree && !isBackgroundBannerFormOpen"></app-footer-one>