import { Component, OnInit, Input } from '@angular/core';
import { TenantService } from 'src/app/tenant/tenant.service';
import { EcomService } from '../../services/ecom.service';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.scss']
})
export class FooterTwoComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // default Logo
  @Input() mainFooter: boolean = true; // Default true 
  @Input() subFooter: boolean = false; // Default false 

  public today: number = Date.now();
  public shopLocation: any;

  constructor(private tenantSrv: TenantService, private ecomSrv: EcomService) { }

  public shop: any = [];

  ngOnInit(): void {
    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.getLocationByShopId();
  }

  public getLocationByShopId() {
    this.ecomSrv.getLocationByShopId().subscribe((data: any) => {
      this.shopLocation = data;
    });
  }

}
