import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  masterUrl = environment.base_url_master_service;
  shopUrl = environment.base_url_shop_service;
  ecomUrl = environment.base_url_ecom_service;

  constructor(private httpClient: HttpClient) { }

  public getAllShops(): any {
    return this.httpClient.get(`${this.shopUrl}/shops`);
  }

  public getAllMenu() {
    return this.httpClient.get(`${this.shopUrl}/shops/menuitemse`)
  }

  public getAllTenantDomains(tenant: any) {
    return this.httpClient.get(`${this.masterUrl}/tenants/domains`, tenant);
  }

  public getTenantByDomain() {
    return this.httpClient.get(`${this.masterUrl}/tenants/domain?domain=${this.getDomain()}`);
  }

  public getShopDetailsByDomain(tenantId:any=this.getTenantId()) {
    return this.httpClient.get(`${this.shopUrl}/shops/registered?domain=${this.getDomain()}`);

  }

  public getAboutUsByShopId() {
    return this.httpClient.get(`${this.shopUrl}/shops/${this.getShopId()}/about`)

  }

  /* Get subdomain for hostname */
  getSubdomainHostname(hostname: string): any {
    return window.location.hostname.split(".")[0];
  }

  getDomain(): any {
    return this.getSubdomainHostname(window.location.hostname);
  }

  getTenantId(): any {
    return sessionStorage.getItem("TENANTID");
  }

  getShopId(): any {
    return sessionStorage.getItem("shopId")
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append("domain", this.getDomain());
  }

}

