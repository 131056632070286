import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { HomeComponent } from './home/home.component';
import { ShopResolver } from './shared/services/shop-resolver.service';
import { ShopwalaHomeComponent } from './home/shopwala-home/shopwala-home.component';
import { ShopwaalaOnboardingComponent } from './home/shopwaala-onboarding/shopwaala-onboarding.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app-shopwala-home',
    pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent,
    resolve: { data: ShopResolver },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },

  {
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'elements',
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'app-shopwala-home',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
