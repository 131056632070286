import { Component, OnInit, Input } from '@angular/core';
import { BlogSlider } from '../../../shared/data/slider';
import { UserService } from 'src/app/shared/services/user.service';
import { BlogService } from 'src/app/shared/services/blog.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CommentService } from 'src/app/shared/services/comment.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  @Input() blogs: any = [];
  public isEditableMode: boolean = false;
  public page: number = 0;
  public totalSize: number;
  public pageSize: number = 10;

  constructor(private blogService: BlogService, private router: Router, private userSrv: UserService, private commentService: CommentService) { }

  ngOnInit(): void {
    this.getBlogs();
  }

  public getBlogs() {
    this.blogService.getBlogByShopId().subscribe((data) => {
      this.blogs = data;
      this.blogs.forEach(blog => {
        this.blogService.getDocumentsByBlog(blog.id).subscribe((docs: any) => {
          blog.documents = docs;
        });
      });
    });
  }

  public blogId(blogId: number): void {
    this.blogService.getBlog(blogId).subscribe((data: any) => {
    });
  }

  public getDocumentsByBlogId(blogId: number): void {
    return this.blogService.getDocumentsByBlog(blogId).subscribe((data: any) => {
    });
  }

  public BlogSliderConfig: any = BlogSlider;

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }
}
