import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: any[] = [];
  public shops: any;
  public collapse: boolean = true;

  constructor(public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);
    this.productService.getShops.subscribe((data: any) => {
      this.shops = data;
    });
  }


  ngOnInit(): void {
  }

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product?.categories?.name))]
    return category
  }

  public get filterShopbyCategory() {
    const shopCategory = [...new Set(this.shops.map((data: any) => data.shopType.name))]
    return shopCategory;
  }

}
