import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BlogService {

  shopUrl = environment.base_url_shop_service;

  constructor(private httpClient: HttpClient) { }

  public getAllBlogs(): any {
    return this.httpClient.get(`${this.shopUrl}/shops/blogs`);
  }

  public getBlog(blogId: any): any {
    return this.httpClient.get(`${this.shopUrl}/shops/blogs/${blogId}`);
  }

  public getBlogByUrlPath(urlPath: any) {
    return this.httpClient.get(`${this.shopUrl}/shops/blogs/url-path?urlPath=${urlPath}`)
  }

  public getBlogByShopId(): any {
    return this.httpClient.get(`${this.shopUrl}/shops/${this.getShopId()}/blogs`);
  }

  public getDocumentsByBlog(blogId: any): any {
    return this.httpClient.get(`${this.shopUrl}/shops/blogs/documents/${blogId}`);
  }

  public getShopId(): any {
    return sessionStorage.getItem("shopId");
  }

  public addBlogBanner(document: any, blogId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("banner-img", files[i], files[i].name);
    }
    return this.httpClient.post(`${this.shopUrl}/shops/blogs/${blogId}/banner-img`, formData);
  }

  public addBlogSubBanner(document: any, blogId: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("identifier", new Blob([JSON.stringify(document)], { type: "application/json" }));
    for (let i = 0; i < files.length; i++) {
      formData.append("sub-banner-img", files[i], files[i].name);
    }
    return this.httpClient.post(`${this.shopUrl}/shops/blogs/${blogId}/sub-banner-img`, formData);
  }

}
