import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class CommentService {

  shopUrl = environment.base_url_shop_service;

  constructor(private httpClient: HttpClient) { }

  public getAllComments( page?: number, size: number = 10): any {
    return this.httpClient.get(`${this.shopUrl}/shops/comments`);
  }

  public getComment(commentId: any): any{
    return this.httpClient.get(`${this.shopUrl}/shops/comments/${commentId}`);
  }

  public getCommentsByBlogId(blogId:any, page?: number, size: number = 10 ): any{
    return this.httpClient.get(`${this.shopUrl}/shops/blogs/${blogId}/comments?page=${page}&size=${size}`, { observe: "response" });
  }

  public getCommentsByBlogIdWithoutPagination(blogId:any): any{
    return this.httpClient.get(`${this.shopUrl}/shops/blogs/${blogId}/comments`, { observe: "response" });
  }

  public createComment(comment: any): any {
    return this.httpClient.post(`${this.shopUrl}/shops/comments`, comment);
  }

public getShopId(): any {
  return sessionStorage.getItem("shopId");
}

}
