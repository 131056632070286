import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class JsonLDService {

  static scriptType = 'application/ld+json';
  constructor(@Inject(DOCUMENT) private _document: Document) { }

  public removeStructuredData(): void {
    const els: Element[] = [];
    ['structured-data', 'structured-data-org'].forEach(c => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach(el => this._document.head.removeChild(el));
  }

  public getObject(type: string, rawData?: any) {
    let object = {
      '@context': 'http://schema.org/',
      '@type': type,
    };
    if (rawData) {
      object = Object.assign({}, object, rawData);
    }
    return object;
  }

  public insertSchema(type: string, schema: Record<string, any>, className = 'structured-data'): void {
    let script: any;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(className).length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = JsonLDService.scriptType;
    let jsonLd = this.getObject(type, schema);
    script.text = JSON.stringify(jsonLd);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }


  public prepareLdJson(type: any, url: any, data: any) {
    url = (url == undefined || url == null) ? this._document.URL : url;
    let jsonLd = {};
    if (type == 'BlogPosting') {
      jsonLd = this.getBlogLdjson(url, data);
    } if (type == 'ShopSearch') {
      jsonLd = this.getSearchShop(url, data);
    } else if (type == 'QAPage') {
      jsonLd = this.getQALdjson(url, data);
    } else if (type == 'AllBlog') {
      jsonLd = this.getAllBlogLdjson(url, data);
    } else if (type == 'EducationalOrganization' || type == 'WebSite') {
      jsonLd = this.getAboutUsLdjson(url, data);
    } else if (type == 'ShopDetail') {
      jsonLd = this.getShopDetailLdjson(url, data);
    } else if (type == 'ShopCategory') {
      jsonLd = this.getShopCategory(url, data);
    } else if (type == 'Product') {
      jsonLd = this.getProductLDJson(url, data);
    } else {
      jsonLd = this.getAboutUsLdjson(url, data);
    }
    return jsonLd;
  }

  getSearchShop(url: any, data: any): {} {
    let searchShop: any;
    data.forEach(element => {
      searchShop = element;
    });
    return {
      "name": searchShop?.name,
      "description": searchShop?.description,
      "url": url,
      "publisher": {
        "@type": "Organization",
        "name": "ShopDial Admin",
        "logo": {
          "@type": "ImageObject",
          "url": "assets/images/shopDial-logo-3.png",
        }
      },
      "mainEntity": {
        "@type": "CreativeWork",
        "name": searchShop?.name,
        "description": searchShop?.description,
        "url": url,
        "author": {
          "@type": "Person",
          "name": "ShopDial Admin"
        },
      }
    }
  }
  getAboutUsLdjson(url: string, data: any): {} {
    return {
      "name": "ShopDial",
      "legalName": "ShopDial",
      "url": "http://www.shopdial.com",
      "logo": "assets/images/shopDial-logo-3.png",
      "foundingDate": "2021",
      "founders": [
        {
          "@type": "Person",
          "name": "ShopDial Admin"
        },
        {
          "@type": "Person",
          "name": "ShopDial Admin"
        }],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Block No 8, Room 2 , 2nd Floor, B Wing, Mangalwari complex",
        "addressLocality": "Sadar",
        "addressRegion": "MH",
        "postalCode": "440001",
        "addressCountry": "IN"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer support",
        "telephone": "[07123 5152 06]",
        "email": "help@shopdial.in"
      },
      "sameAs": [
        "http://www.freebase.com/m/0_h96pq",
        "http://www.facebook.com/elitestrategies",
        "https://twitter.com/shopdial",
        "http://pinterest.com/elitestrategies/",
        "http://elitestrategies.tumblr.com/",
        "http://www.linkedin.com/company/elite-strategies"
      ]
    }
  }

  getShopDetailLdjson(url: string, data: any): {} {
    return {
      "name": data?.name,
      "description": data?.description,
      "url": url,
      "publisher": {
        "@type": "Organization",
        "name": "ShopDial Admin",
        "logo": {
          "@type": "ImageObject",
          "url": "assets/images/shopDial-logo-3.png",
        }
      },
      "mainEntity": {
        "@type": "CreativeWork",
        "name": data?.name,
        "description": data?.description,
        "url": url,
        "author": {
          "@type": "Person",
          "name": "ShopDial Admin"
        },
        // "datePublished": data.createdAt,
        // "image": data.document[0].urls
      }
    }
  }

  getAllBlogLdjson(url: string, data: any): {} {
    return {
      "name": 'ETM Academy Blog',
      "description": "Welcome to our hub of insightful technical blogs! Here, you'll find a treasure trove of knowledge covering a wide array of topics in the tech world. Our team of experts and enthusiasts consistently churn out informative and engaging content to keep you up-to-date with the latest trends, innovations, and best practices in the industry.",
      "url": url,
      "publisher": {
        "@type": "Organization",
        "name": "ETM Academy",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.etmacademy.in/assets/img/logo/logo-black-1.gif",
        }
      }
    }
  }

  getShopCategory(url: string, data: any): {} {
    return {
      "name": "Browse Shops By Categories",
      "description": "Discover a wide selection of shops across various categories on our platform, ranging from fashion to electronics, beauty to entertainment and etc. Find tailored choices that match your preferences with ease, thanks to our carefully curated collection. Experience unparalleled convenience in finding exactly what you're looking for.",
      "url": url,
      "publisher": {
        "@type": "Organization",
        "name": "ShopDial Admin",
        "logo": {
          "@type": "ImageObject",
          "url": "assets/images/shopDial-logo-3.png",
        }
      }
    }
  }

  public getQALdjson(url: any, data: any): {} {
    return {
      "mainEntity": {
        "@type": "Question",
        "name": data.question,
        // "text": null,
        "answerCount": 1,
        "dateCreated": data.createdDate,
        "author": {
          "@type": "Person",
          "name": "ETM Academy Admin"
        },
        "acceptedAnswer": {
          "@type": "Answer",
          "text": data.answer,
          "upvotecount": 1,
          "dateCreated": data.createdDate,
          "url": url,
          "author": {
            "@type": "Person",
            "name": "ETM Academy Admin"
          }
        }
      }
    }
  }
  public getBlogLdjson(url: any, data: any): {} {
    return {
      "headline": data?.blogTitle,
      "alternativeHeadline": data?.subBlogDesc[0]?.subTitle,
      "image": data?.imgeUrl,
      "editor": data?.author,
      "keywords": data?.tags.toString(),
      "wordcount": "1120",
      "publisher": "ETM Academy",
      "url": url,
      "datePublished": data?.createdDate,
      "dateCreated": data?.createdDate,
      "dateModified": data.createdDate,
      "description": data?.mainDesc[0],
      "articleBody": data?.subBlogDesc?.map((obj: any) => obj?.description).toString().replaceAll(".,", ".").replaceAll(",,", ","),
      "author": {
        "@type": "Person",
        "name": data?.author
      }
    }
  }

  public getProductLDJson(url: any, data: any): {} {
    let imageUrl = "";
    data.document.forEach(element => {
      imageUrl = element.urls;
    });
    return {
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "3.5",
        "reviewCount": "11"
      },
      "description": data.description,
      "name": data.title,
      "image": imageUrl,
      "url": url,
      "offers": {
        "@type": "Offer",
        "availability": data.available,
        "price": data.price,
        "priceCurrency": "INR"
      }
      // ,
      // "review": [
      //   {
      //     "@type": "Review",
      //     "author": "Ellie",
      //     "datePublished": "2011-04-01",
      //     "reviewBody": "The lamp burned out and now I have to replace it.",
      //     "name": "Not a happy camper",
      //     "reviewRating": {
      //       "@type": "Rating",
      //       "bestRating": "5",
      //       "ratingValue": "1",
      //       "worstRating": "1"
      //     }
      //   },
      //   {
      //     "@type": "Review",
      //     "author": "Lucas",
      //     "datePublished": "2011-03-25",
      //     "reviewBody": "Great microwave for the price. It is small and fits in my apartment.",
      //     "name": "Value purchase",
      //     "reviewRating": {
      //       "@type": "Rating",
      //       "bestRating": "5",
      //       "ratingValue": "4",
      //       "worstRating": "1"
      //     }
      //   }
      // ]
    }
  }
}



