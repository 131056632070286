import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-electronics',
  templateUrl: './electronics.component.html',
  styleUrls: ['./electronics.component.scss']
})
export class ElectronicsComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-10.png'; // Change Logom

  public products: any[] = [];
  public productCollections: any[] = [];
  public active;

  public isEditableMode: boolean = false;
  public documentForm: FormGroup;
  public generatedShopId: any;
  public documents: any;
  public isDiscountBannerFormOpen: boolean = false;
  public isMedBannerFormOpen: boolean = false;
  public errorMessage: string;
  public errorMessageOffer: string;
  public filesToUpload: any;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentsOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerTwo: any;
  public filteredOfferDocumentsTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public uploadOfferBannerThree: any;
  public filteredOfferDocumentsThree: any;
  public isOfferBannerFormOpenThree: boolean = false;

  public uploadBigBanner: any;
  public uploadMediumBanner: any;

  public filteredBigDocuments: any;
  public filteredMediumDocuments: any;

  constructor(public productService: ProductService, private _sanitizer: DomSanitizer, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  // Collection banner
  public collections = [{
    image: 'assets/images/electronic-col-1.jpg',
    save: '10% off',
    title: 'speaker'
  }, {
    image: 'assets/images/electronic-col-2.jpg',
    save: '10% off',
    title: 'earplug'
  },
  {
    image: 'assets/images/electronic-col-3.jpg',
    save: '10% off',
    title: 'best deal'
  }]

  // Blogs
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/3.jpg',
    date: '25 January 2022',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/4.jpg',
    date: '26 January 2023',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Mike Stark'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/1.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Scot Lang'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/2.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Mark Hanri'
  }]

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#6d7e87');
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocumentsOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });
      this.filteredOfferDocumentsTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });
      this.filteredOfferDocumentsThree = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-three';
      });
      this.filteredBigDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'discount-banner';
      });
      this.filteredMediumDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'medium-banner';
      });
    });
  }

  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  openOfferFormThree() {
    this.isOfferBannerFormOpenThree = true;
  }

  openBigBannerForm() {
    this.isDiscountBannerFormOpen = true;
  }

  openMedBannerForm() {
    this.isMedBannerFormOpen = true;
  }

  closePopUpForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isOfferBannerFormOpenThree = false;
    this.isDiscountBannerFormOpen = false;
    this.isMedBannerFormOpen = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 437 && image.height === 219) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 437 and height 219.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 437 && image.height === 219) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 437 and height 219.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectOfferThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 437 && image.height === 219) {
          if (!this.uploadOfferBannerThree) {
            this.uploadOfferBannerThree = [];
          }
          this.uploadOfferBannerThree.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 437 and height 219.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectBigBanner = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 787 && image.height === 789) {
          if (!this.uploadBigBanner) {
            this.uploadBigBanner = [];
          }
          this.uploadBigBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 787 and height 789.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectMedBanner = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 559 && image.height === 384) {
          if (!this.uploadMediumBanner) {
            this.uploadMediumBanner = [];
          }
          this.uploadMediumBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 559 and height 384.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
    if (form.valid && this.uploadOfferBannerOne.length > 0) {
      this.handleDocument(form.value, 'offer-one');
    }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid && this.uploadOfferBannerTwo.length > 0) {
        this.handleDocument(form.value, 'offer-two');
      }
    } else if (this.uploadOfferBannerThree && this.uploadOfferBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-three");
    if (form.valid && this.uploadOfferBannerThree.length > 0) {
      this.handleDocument(form.value, 'offer-three');
    }
    } else if (this.uploadBigBanner && this.uploadBigBanner.length > 0) {
      this.documentForm.get('identifier').setValue("discount-banner");
    if (form.valid && this.uploadBigBanner.length > 0) {
      this.handleDocument(form.value, 'discount');
    }
    } else if (this.uploadMediumBanner && this.uploadMediumBanner.length > 0) {
      this.documentForm.get('identifier').setValue("medium-banner");
    if (form.valid && this.uploadMediumBanner.length > 0) {
      this.handleDocument(form.value, 'medium');
    }
    }
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'offer-three':
        this.addOfferDocument(document, this.uploadOfferBannerThree);
        break;
      case 'discount':
        this.addOfferDocument(document, this.uploadBigBanner);
        break;
      case 'medium':
        this.addOfferDocument(document, this.uploadMediumBanner);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopUpForm();
      this.removeDocumentAfterUpload();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }
   // remove document after upload
   public removeDocumentAfterUpload(){
    this.uploadOfferBannerOne= [];
    this.uploadOfferBannerTwo= [];
    this.uploadOfferBannerThree= [];
    this.uploadBigBanner= [];
    this.uploadMediumBanner= [];
  }
}
