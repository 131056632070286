import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductSlider, CollectionSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss']
})
export class WatchComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-14.png'; // Change Logo
  public products: any[] = [];
  public productCollections: any[] = [];
  public ProductSliderConfig: any = ProductSlider;
  public CollectionSliderConfig: any = CollectionSlider;
  public active;
  public isEditableMode: boolean = false;
  public documentForm: FormGroup;
  public generatedShopId: any;
  public documents: any;
  public isDiscountBannerFormOpen: boolean = false;
  public errorMessage: string;
  public errorMessageOffer: string;
  public filesToUpload: any;
  public uploadOfferBannerOne: any;
  public filteredOfferDocumentOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public uploadOfferBannerTwo: any;
  public filteredOfferDocumentTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public uploadOfferBannerThree: any;
  public filteredOfferDocumentThree: any;
  public isOfferBannerFormOpenThree: boolean = false;

  constructor(private _sanitizer: DomSanitizer, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService,
    public productService: ProductService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: ['']
    });
  }

  public sliders = [{
    title: 'every time',
    subTitle: 'mittnalier',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/9.jpg'
  }, {
    title: 'welcome to watch',
    subTitle: 'Men Watch',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/10.jpg'
  }];

  // Logo
  public logos = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  // Collection
  public categories = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/watch/cat1.png',
    title: 'watch models',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">d1 milano</a></li><li><a href="#">damaskeening</a></li><li><a href="#">diving watch</a></li><li><a href="#">dollar watch</a></li>'),
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/watch/cat2.png',
    title: 'calculator watch',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Shock-resistant watch</a></li><li><a href="#">Skeleton watch</a></li><li><a href="#">Slow watch</a></li><li><a href="#">Solar-powered watch</a></li>'),
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/watch/cat3.png',
    title: 'Antimagnetic watch',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Watchmaking conglomerates</a></li><li><a href="#">Breitling SA</a></li><li><a href="#">Casio watches</a></li><li><a href="#">Citizen Watch</a></li>'),
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/watch/cat2.png',
    title: 'History of watches',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">Manufacture dhorlogerie</a></li><li><a href="#">Mechanical watch</a></li><li><a href="#">Microbrand watches</a></li><li><a href="#">MIL-W-46374</a></li>'),
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/watch/cat3.png',
    title: 'watch models',
    text: this._sanitizer.bypassSecurityTrustHtml('<li><a href="#">d1 milano</a></li><li><a href="#">damaskeening</a></li><li><a href="#">diving watch</a></li><li><a href="#">dollar watch</a></li>'),
  }]

  // collection
  public collections = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/banner1.jpg',
    title: 'minimum 10% off',
    text: 'new watch'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/banner2.jpg',
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/banner.jpg',
    title: 'minimum 10% off',
    text: 'gold watch`'
  }]

  // Blog
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout4/4.jpg',
    date: '25 January 2022',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout4/3.jpg',
    date: '26 January 2023',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Oliver '
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout4/2.jpg',
    date: '27 January 2020',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'James Lie'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/blog/layout4/5.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Daniel Michael'
  }]

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#e4604a');

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocumentOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });
      this.filteredOfferDocumentTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });
      this.filteredOfferDocumentThree = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-three';
      });
    });
  }

  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  closeOfferForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isOfferBannerFormOpenThree = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  public onSubmitOffer(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid && this.uploadOfferBannerOne.length > 0) {
        this.handleOfferDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid && this.uploadOfferBannerTwo.length > 0) {
        this.handleOfferDocument(form.value, 'offer-two');
      }
    } else if (this.uploadOfferBannerThree && this.uploadOfferBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-three");
      if (form.valid && this.uploadOfferBannerThree.length > 0) {
        this.handleOfferDocument(form.value, 'offer-three');
      }
    }

  }

  public handleOfferDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'offer-three':
        this.addOfferDocument(document, this.uploadOfferBannerThree);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;

    }
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  openOfferFormThree() {
    this.isOfferBannerFormOpenThree = true;
  }

  onSelectOfferThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerThree) {
            this.uploadOfferBannerThree = [];
          }
          this.uploadOfferBannerThree.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672 and height 310.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }



  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.closeOfferForm();
      this.getDocumentByShopId();
      this.removeDocumentAfterUpload();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  public removeDocumentAfterUpload() {
    this.uploadOfferBannerOne = [];
    this.uploadOfferBannerTwo = [];
    this.uploadOfferBannerThree = [];
  }
}
