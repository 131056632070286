import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { ShopTypeService } from 'src/app/shared/services/shoptype.service';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { JsonLDService } from 'src/app/shared/services/json-ldplus-service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss']
})
export class ShopDetailsComponent implements OnInit, OnDestroy {

  public grid: string = 'col-xl-3 col-md-6';
  public layoutView: string = 'grid-view';
  public shops: any = [];
  public shopType: any;
  public createShopInquiryForm: FormGroup;
  public shopQuestionForm: FormGroup;
  public shopReviewForm: FormGroup;
  public shopReportForm: FormGroup;
  public generatedShopId: any;
  public tenantId: any;
  public isSubmited: boolean = false;
  public selectedShopRating: number = 5;
  public reviews: any;
  public viewImageDoc: any;
  public shopTopContentLoaded = false;
  public shopMiddleContentLoaded = false;
  public inputSourceLayout = "";
  public generatedUrlPath: any;
  public showSection = false;
  public starRatings: number[] = [5, 4, 3, 2, 1];
  public shopSubCategory: any[] = [];
  public shopTypeName: any;
  public currentUrl: string = window.location.href;
  previousScroll = 0;

  images = [
    {
      cssClass: 'gallbox_item',
      imgClass: 'gallbox_image',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: false,
      upload: false
    },
    {
      cssClass: 'gallbox_item animated fadeIn slower gbox gallbox_image_1',
      imgClass: 'gallbox_image_1 gboxout',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: false,
      upload: false
    },
    {
      cssClass: 'gallbox_item animated fadeIn slower gbox gallbox_image_2',
      imgClass: 'gallbox_image_2 gboxout',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: false,
      upload: false
    },
    {
      cssClass: 'gallbox_item animated fadeIn slower gbox gallbox_image_3',
      imgClass: 'gallbox_image_3 gboxout',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: true,
      upload: false
    },
    {
      cssClass: 'gallbox_item animated fadeIn slower gbox gallbox_image_4',
      imgClass: 'gallbox_image_4 gboxout',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: false,
      upload: false
    },
    {
      cssClass: 'gallbox_item animated fadeIn slower gbox gallbox_image_5',
      imgClass: 'gallbox_image_5 gboxout',
      imgStyle: 'position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;',
      text: false,
      upload: true
    }
  ];

  constructor(private activateRoute: ActivatedRoute, private homeSrv: HomepageService, public shopTypeSrv: ShopTypeService, private sanitizer: DomSanitizer, private fb: FormBuilder, private toastr: ToastrService, private metaDataService: MetadataService, private jsonLdService: JsonLDService, private location: Location) {
    this.createShopInquiryForm = this.fb.group({
      id: [''],
      shopId: [''],
      phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      tenantId: ['']
    });

    this.shopReviewForm = this.fb.group({
      id: [''],
      shopId: [''],
      tenantId: [''],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.pattern(environment.numberPattern)]],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email]],
      shopRating: [this.selectedShopRating],
      shopReview: ['', [Validators.required, Validators.minLength(100), Validators.maxLength(1000)]],
    });

    this.shopReportForm = this.fb.group({
      id: [''],
      shopId: [''],
      tenantId: [''],
      comment: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(500)]]
    });

    this.shopQuestionForm = this.fb.group({
      id: [''],
      shopId: [''],
      tenantId: [''],
      question: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(200)]]
    });
  }

  ngOnInit(): void {
    this.joinShopData();
    // get shoptype 
    this.getAllShopType();

    App.addListener('backButton', () => {
      this.location.back();
    });

  }

  public getShopCategoryConfig(type: any): void {
    this.shopTypeSrv.getCategoryConfig().subscribe(data => {
      this.shopSubCategory = data.find(cat => cat.title === type)?.data || [];
    });
  }

  ngOnDestroy() {
    App.removeAllListeners();
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    this.layoutView = value;
    if (value == 'list-view')
      this.grid = 'col-lg-12';
    else
      this.grid = 'col-xl-3 col-md-6';
  }

  /**
   * Retrieves all shop types from the server.
   * 
   * @returns {void}
   */
  public getAllShopType() {
    this.shopTypeSrv.getAllShoptype().subscribe((data: any) => {
      this.shopType = data.slice(0, 6); // Retrieve the first 6 shop types
    });
  }

  /**
   * Joins shop data by calling the necessary API endpoints.
   */
  public joinShopData(): void {
    // Get the shop ID from the URL parameter
    this.generatedUrlPath = this.activateRoute.snapshot.paramMap.get('urlPath');
    // Call the API to get the shop data
    this.homeSrv.getShopByUrlPath(this.generatedUrlPath).subscribe((data: any) => {
      this.generatedShopId = data.id;
      // Assign the retrieved shop data to the 'shops' property
      this.tenantId = data.tenantId;
      // Set the value of tenantId in the Inquery form
      this.createShopInquiryForm.get('tenantId').setValue(this.tenantId);

      // Set the value of tenantId in the Review form
      this.shopReviewForm.get('tenantId').setValue(this.tenantId);

      // Set the value of tenantId in the shop Report form
      this.shopReportForm.get('tenantId').setValue(this.tenantId);

      this.shops = data;
      const shopTypeName = this.shops.shopType.name;

      // get shop sub category config
      this.getShopCategoryConfig(shopTypeName);

      // Call the API to get the verifications for the shop
      this.homeSrv.getVerificationByShopId(this.generatedShopId).subscribe((verfn: any) => {
        // Assign the retrieved verifications to the 'verifications' property of the shop
        this.shops.verifications = verfn;

        // Call the API to get the location for the shop
        this.homeSrv.getLocationByShopId(this.generatedShopId).subscribe((loc: any) => {
          // Assign the retrieved location to the 'location' property of the shop
          this.shops.location = loc;
          this.metaDataService.updateMetaInfo(` Visit ${this.shops?.name} for Unique Buys in ${this.shops?.location[0]?.city}`, this.shops.description);
          // Call the API to get the document for the shop
          this.homeSrv.findDocShopId(this.generatedShopId).subscribe((doc: any) => {
            // Assign the retrieved document to the 'document' property of the shop
            this.shops.document = doc;

            // Call the API to get the social media for the shop
            this.homeSrv.getSocialMediaByShopId(this.generatedShopId).subscribe((social: any) => {
              // Assign the retrieved social media to the 'socialMedia' property of the shop
              this.shops.socialMedia = social;
              this.shopTopContentLoaded = true;
              this.shopMiddleContentLoaded = true;
            });
          });
        });
      });
      if (this.generatedShopId != null || this.generatedShopId != '') {
        this.getReviewByShopId();
      }
      this.jsonLdService.insertSchema('Organization', this.jsonLdService.prepareLdJson('ShopDetail', null, this.shops), 'structured-data');
    });
  }

  getSanitizedUrl(url: string | undefined): SafeResourceUrl | undefined {
    return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : undefined;
  }

  getDirections() {
    // Access the maplinked property dynamically
    const embeddedMapLink = this.shops?.mappedLink;
    // Open the Google Maps link in a new tab
    if (embeddedMapLink) {
      window.open(embeddedMapLink, '_blank');
    }
  }

  public getDocByShopId() {
    // Assuming this.generatedUrlPath is available
    if (this.generatedUrlPath) {
      this.homeSrv.findDocShopId(this.generatedShopId).subscribe((doc: any) => {
        if (doc.length > 0) {
          this.viewImageDoc = doc.filter((docItem: any) => docItem.identifier === 'view-img')
            .map((docItem: any) => docItem.urls);
        }
      });
    }
  }

  public onSubmitInquiry(form: any): void {
    if (form.valid) {
      this.isSubmited = true;
      this.handleShopInquiry(this.createShopInquiryForm.value);
    } else {
      this.validateForm(form);
    }
  }

  public onSubmitQuestion(form: any): void {
    if (form.valid) {
      this.isSubmited = true;
      this.handleShopQuestion(form.value);
    } else {
      this.validateForm(form);
    }
  }

  public handleShopInquiry(category: any): void {
    this.createShopInquiry(category);
  }

  public handleShopQuestion(question: any): void {
    this.createShopQuestion(question);
  }

  public createShopInquiry(inquiry: any): void {
    this.homeSrv.createShopInquiry(inquiry, this.generatedShopId).subscribe((data: any) => {
      this.closePopupForm();
      this.createShopInquiryForm.reset();
      this.toastr.success('Inquiry submitted successfull.', 'SUCCESS');
    }), (error: any) => {
      this.toastr.error('Inquiry not submitted, try again.', 'ERROR');
    };
  }

  public createShopQuestion(question: any): void {
    this.homeSrv.createShopQuestion(question, this.generatedShopId).subscribe((data: any) => {
      this.closeQuestionForm();
      this.shopQuestionForm.reset();
      this.toastr.success('Question submitted successfull.', 'SUCCESS');
    }), (error: any) => {
      this.toastr.error('Questiono not submitted, try again.', 'ERROR');
    };
  }

  public createShopReview(review: any): void {
    this.homeSrv.createShopReview(review, this.generatedShopId).subscribe((data: any) => {
      // window.location.reload();
      this.toastr.success('Review submitted successfull.', 'SUCCESS');
      // Fetch updated reviews after creating a new review
      this.getReviewByShopId();
      this.shopReviewForm.reset();
    }), (error: any) => {
      this.toastr.error('Review not submitted, try again.', 'ERROR');
    };
  }

  public handleShopReview(review: any): void {
    this.createShopReview(review);
  }

  public sendReviewFormData() {
    const convertData = this.shopReviewForm.value;
    // Convert numerical fields to strings
    convertData.phoneNumber = String(convertData.phoneNumber);
  }
  public onSubmitReview(form: any): void {
    if (form.valid) {
      this.sendReviewFormData()
      this.isSubmited = true;
      this.handleShopReview(form.value);
    } else {
      this.validateForm(form);
    }
  }
  public createShopReport(report: any): void {
    this.homeSrv.createShopReport(report, this.generatedShopId).subscribe((data: any) => {
      this.toastr.success('Report submited successfull.', 'SUCCESS');
      this.closeReportPopupForm();
    }); (error: any) => {
      this.toastr.error('Report not submited, try again.', 'ERROR');
    }
  }

  public handleShopReport(report: any): void {
    this.createShopReport(report);
  }

  onCheckboxChange(event: any, value: string) {
    const commentControl = this.shopReportForm.get('comment');
    if (event.target.checked) {
      // Checkbox is checked, update the comment control
      commentControl.setValue(value);
    } else {
      // Checkbox is unchecked, reset the comment control to an empty string
      commentControl.setValue('');
    }
  }

  public onSubmitReport(report: any): void {
    if (report.valid) {
      this.isSubmited = true;
      this.handleShopReport(report.value);
    } else {
      this.validateForm(report);
    }
  }

  public showPopupForm() {
    this.toggleSectionVisibility('popupForm', true);
}

public closePopupForm() {
    this.toggleSectionVisibility('popupForm', false);
}

public showShareItPopupForm() {
    this.toggleSectionVisibility('shareForm', true);
}

public closeShareItPopupForm() {
    this.toggleSectionVisibility('shareForm', false);
}

public showReportPopupForm() {
    this.toggleSectionVisibility('reportForm', true);
}

public closeReportPopupForm() {
    this.toggleSectionVisibility('reportForm', false);
}

public showGallaryPopupForm() {
    this.toggleSectionVisibility('gallaryForm', true);
}

public closeGallaryPopupForm() {
    this.toggleSectionVisibility('gallaryForm', false);
}

public showQuestionForm() {
  this.toggleSectionVisibility('questionForm', true);
}

public closeQuestionForm() {
  this.toggleSectionVisibility('questionForm', false);
}

// Helper method to handle show/hide logic
private toggleSectionVisibility(elementId: string, show: boolean) {
    const element = document.getElementById(elementId);
    const shopDetailsSection = document.getElementById("shopDetailsSection");

    if (element) {
        element.style.display = show ? "block" : "none";
    }

    if (shopDetailsSection) {
        if (show) {
            shopDetailsSection.classList.add("hide-for-popup");
        } else {
            shopDetailsSection.classList.remove("hide-for-popup");
        }
    }
}


  public validateForm(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateForm(form);
      }
    });
  }

  public hasError(name: string) {
    const field = this.createShopInquiryForm.get(name);
    const field2 = this.shopReviewForm.get(name);
    const field3 = this.shopReportForm.get(name);
    const field4 = this.shopQuestionForm.get(name);
    return (field?.invalid && field?.touched && field?.errors || field2?.invalid && field2?.touched && field2?.errors || field3?.invalid && field3?.touched && field3?.errors || field4?.invalid && field4?.touched && field4?.errors);
  }

  getStarArray(rating: number): number[] {
    return Array.from({ length: rating }, (_, index) => index);
  }
  // set rating 
  public setShopRating(value: number) {
    this.selectedShopRating = value;
    this.shopReviewForm.get('shopRating').setValue(value);
  }

  public getReviewByShopId() {
    this.homeSrv.findReviewByShopId(this.generatedShopId).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.reviews = data;
          // Sort reviews based on the 'id' property in descending order
          this.reviews.sort((a, b) => b.id - a.id);
          // Take only the first 15 reviews
          this.reviews = this.reviews.slice(0, 15);
        } else {
          // If no reviews are available, initialize this.reviews as an empty array
          this.reviews = [];
        }
      },
      (error: any) => {
        if (error.status === 404) {
          // If the error is 404 (Not Found), set this.reviews to an empty array
          this.reviews = [];
        } else {
          console.error("Error fetching reviews:", error);
        }
      }
    );
  }

  // Function to get the content to be copied
  getCopyAddress(): string {
    // Modify this based on the content you want to copy
    const address = this.shops?.location?.map(loct => loct?.address).join('\n');
    return `${address}`;
  }

  replaceSpecialCharWithHyphen(categoryName: string): string {
    return categoryName.toLowerCase().replace(/\s+/g, '-');
  }

  get facebookShareUrl(): string {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.currentUrl)}&description=Check out this on Shopdial!`;
  }

  get whatsappShareUrl(): string {
    return `https://web.whatsapp.com/send?phone=&text=Check out this on Shopdial! ${encodeURIComponent(this.currentUrl)}`;
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.closeShareItPopupForm();
      this.toastr.success('Link copied to clipboard !', 'SUCCESS');
    }).catch(err => {
      this.toastr.error('Failed to copy link', 'ERROR');
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const shopDetailsSection = document.getElementById('shopDetailsSection');
      const popupForm = document.getElementById('popupForm');
  
      if (popupForm && popupForm.style.display === 'block') {
          // If popup form is open, do not show the fixed section
          return;
      }
  
      if (currentScroll > 100) {
          shopDetailsSection?.classList.add('fixed');
      } else if (currentScroll <= 100) {
          shopDetailsSection?.classList.remove('fixed');
      }
  
      this.previousScroll = currentScroll <= 0 ? 0 : currentScroll;
  }

  get form() {
    return this.createShopInquiryForm.controls;
  }

  get id() {
    return this.form['id'];
  }
  get name() {
    return this.form['name'];
  }

  get phoneNo() {
    return this.form['phoneNo'];
  }

  get email() {
    return this.form['email'];
  }
  get shopId() {
    return this.form['shopId']
  }

  get tenantID() {
    return this.form['tenantId']
  }

  get reviewform() {
    return this.shopReviewForm.controls;
  }

  get reviewId() {
    return this.reviewform['id'];
  }
  get reviewName() {
    return this.reviewform['name'];
  }

  get reviewEmail() {
    return this.reviewform['email'];
  }

  get phoneNumber() {
    return this.reviewform['phoneNumber'];
  }
  get shopRating() {
    return this.reviewform['shopRating'];
  }

  get shopReview() {
    return this.reviewform['shopReview'];
  }

  get reviewShopId() {
    return this.reviewform['shopId'];
  }
  get reviewTenantId() {
    return this.reviewform['tenantId'];
  }

  get reportForm() {
    return this.shopReportForm.controls;
  }

  get questionForm() {
    return this.shopQuestionForm.controls;
  }

  get question() {
    return this.questionForm['question'];
  }

  get reportShopId() {
    return this.reportForm['shopId'];
  }
  get reportTenantId() {
    return this.reportForm['tenantId'];
  }

  get comment() {
    return this.reportForm['comment']
  }

}

