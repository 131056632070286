import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, catchError, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    masterUrl = environment.base_url_master_service;
    shopUrl = environment.base_url_shop_service;

    public shopSearch = new BehaviorSubject<any>("");
    public topShopsByCountry = new BehaviorSubject<any>("");
    public topShopsByState = new BehaviorSubject<any>("");
    public topShopsByCity = new BehaviorSubject<any>("");
    // private topShopsByCity = new Subject<any>();


    constructor(private httpClient: HttpClient) { }

    public getAllCountries(): any {
        return this.httpClient.get(`${this.masterUrl}/countries`);
    }

    public getStatesByCountriesName() {
        return this.httpClient.get(`${this.masterUrl}/countries/states?name=India`);
    }

    public getCitiesByStateName(name: any) {
        return this.httpClient.get(`${this.masterUrl}/states/cities?name=${name}`);
    }

    public getShopByCountry(country: string) {
        let url = `${this.shopUrl}/country?country=${country}`;
        this.httpClient.get(url).subscribe(
            (res: any) => {
                this.shopSearch.next(res)
            }
        )
    }

    public getShopByState(state: string) {
        let url = `${this.shopUrl}/states?state=${state}`;
        this.httpClient.get(url).subscribe(
            (res: any) => {
                this.shopSearch.next(res)
            }
        )
    }

    public getShopByCity(city: string) {
        let url = `${this.shopUrl}/cities?city=${city}`;
        this.httpClient.get(url).subscribe(
            (res: any) => {
                this.shopSearch.next(res)
            }
        )
    }

    public getTopShopByCountry() {
        this.httpClient.get(`${this.shopUrl}/shops/top/country`).subscribe(
            (res: any) => {
                this.topShopsByCountry.next(res)
            })
    }

    public getTopShopByState(state: string) {
        this.httpClient.get(`${this.shopUrl}/shops/top/state?state=${state}`).subscribe(
            (res: any) => {
                this.topShopsByState.next(res)
            });
    }

    getTopShopByCity(city: string) {
        this.httpClient.get(`${this.shopUrl}/shops/top/city?city=${city}`).pipe(
          catchError(err => {
            console.error('Error fetching top shops by city', err);
            this.topShopsByCity.next([]);
            return throwError(err);
          })
        ).subscribe(
          (res: any) => {
            this.topShopsByCity.next(res);
          }
        );
      }
    
      get topShopsByCity$() {
        return this.topShopsByCity.asObservable();
      }
    

    public clearSessionShopByState() {
        if (sessionStorage.getItem('SHOPS_BY_STATE')) {
            sessionStorage.removeItem('SHOPS_BY_STATE');
        }
    }

    public clearSessionShopByCity() {
        if (sessionStorage.getItem('SHOPS_BY_CITY')) {
            sessionStorage.removeItem('SHOPS_BY_CITY');
        }
    }
}