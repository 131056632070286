<app-header-home [class]="'header-tools'"></app-header-home>
<section class="p-0 height-85 shopdial">
    <div *ngFor="let bgImage of selectedCategoriesIcon | docfilter : 'banner-img';" class="resfilter_banner">
        <span>
            <img title="{{bgImage.title}}" [src]="bgImage.imageURL" class="resfilter_banner_image">
        </span>
        <div class="resfilter_banner_text fw500 colorFFF" style="color: rgb(255, 255, 255);">
            {{bgImage.subTitle}}</div>
    </div>
    <div class="resfilter_tabbox">
        <a class="resfilter_tabitem gray_whitefill_animate"
            *ngFor="let icn of selectedCategoriesIcon | docfilter : 'cat-icon';">
            <div class="resfilter_tabimg">
                <img class="sd-cat-icon" [src]="icn.imageURL" alt="What's new">
            </div>
            <div class="resfilter_tabtext">
                <div class="resfilter_tabtxt_smll font12 color777"><span class="resfilter_arrow_icon jdicon"></span>
                </div>
                <div class="resfilter_tabtxt_main font18 color111 ">{{icn.desc | sentenceCase}}</div>
            </div>
        </a>
    </div>
</section>

<div class="container text-center mt-2 mb-container" *ngFor="let list of shopList">
    <div class="row upRow" *ngIf="list?.length">
        <div class="col-6 col-sm-6"></div>
        <div class="col-2 col-sm-2 ml-top-rank">
            <button class="filter-Verified" (click)="filterShopByShopDialRank()"><span class="fa fa-check"></span>TOP
                RANK
            </button>
        </div>

        <div class="col-2 col-sm-2 ml-top-rated">
            <button class="filter-Verified "><span class="fa fa-star"></span>TOP RATED</button>
        </div>
        <div class="col-2 col-sm-2"></div>
    </div>
</div>

<div class="container">
    <div class="row sd-mt-100 d-flex .flex-sm-column" *ngIf="showShopByCategory">
        <div class="col-12 col-lg-12 mb-two" *ngFor="let list of shopList; let i = index">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div class="card-body" >
                        <div *ngFor="let doc of list?.documents | docfilter: 'home-img'; let first = first">
                            <div *ngIf="first">
                                <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                                    [lazyLoad]="ImageSrc ? ImageSrc : doc?.urls" class="img-fluid lazy-loading img-ad"
                                    alt="not found">
                                <div class="heart-icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h6 class="font14 fw500 color555">{{seletedShopType | sentenceCase}}</h6>
                                </div>
                                <div class=".justify-content-end"><button type="button" class="btno"
                                        style="color: white;">Assured</button></div>
                                 </div>
                            <div class="d-flex justify-content-start">
                                <h4 [routerLink]="['/shops/view', list.urlPath]" class="fw700 color111 deskstop-head">{{list.name}}
                                </h4>
                            </div>
        
                            <div class="d-flex flex-row mb-3 mt-3 fw600 color111">
                                <div >Opens At:
                                    <span class="color555 font14">{{list.openingTime}}</span></div>
                                <div class="ml-2">Close At:
                                    <span class="color555 font14">{{list.closingTime}}</span></div>
                            </div>
                            <div class="d-flex flex-row mb-3">
                                <div>
                                    <button class="btnC colorFFF">
                                        <a style="color: white;" class="colorFFF ml-5"><i class="fa fa-phone"
                                                aria-hidden="true"></i>
                                            <span (click)="showPopupForm(list.id, list.tenantId)">{{list.contact |
                                                mask:6}}</span>
                                        </a>
                                    </button>
                                </div>
                                <div>
                                    <button class="btnV colorFFF">
                                        <a [routerLink]="['/shops/view', list.urlPath]" style="color: white;">
                                            <span>View More</span>
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <h4 class="font16 fw600 p_8 color111">Description:</h4>
                            <p class="description_font2 fw500 font14 txt-cap color555">{{list?.description | stripHtml}}
                            </p>
                            <div class="d-flex justify-content-start" (click)="showPopupForm(list.id, list.tenantId)" style="cursor: pointer;">
                                <p class="color111 fw600 font16 mobi">Email:
                                    <a class="color555 font14 fw500">
                                        {{list?.email | mask:10}}
                                    </a>
                                </p>
                            </div>
                            <div class="row icon-20">
                                <div class="col-auto">
                                    <a href="{{list.socialMedia?.facebookUrl}}">
                                        <i class="fa fa-facebook icon_mobile" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <a href="{{list.socialMedia?.googleUrl}}">
                                        <i class="fa fa-google icon_mobile" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <a href="{{list.socialMedia?.twitterUrl}}">
                                        <i class="fa fa-times icon_mobile" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <a href="{{list.socialMedia?.instaUrl}}">
                                        <i class="fa fa-instagram icon_mobile" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFinished && shopList.length > 0 && i === 0" class="col-3 .d-none .d-xl-block display-mobile">
                    <div class="card co-card">
                        <div class="card-body co-content">
                            <div class="d-flex flex-column" *ngFor="let filter of categoryLabel">
                                <span class="txt-cap mt-3" *ngIf="filter.title === seletedShopType">
                                    <div *ngFor="let label of filter.labels" class="mb-3">
                                        <span class="del_tick_icon sdicon"></span><span class="color555 fw500 font14">{{label | sentenceCase}}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div *ngIf="!showShopByCategory">
        <app-loading [inputSourceLayout]="'shop-type.component'"></app-loading>
    </div>
    <div class="col-sm-12 mb-5 empty-cart-cls text-center sd-mt-1" *ngIf="isFinished && shopList.length == 0">
        <div>
            <img src="assets/images/icon/no-shops-1.png" class="img-fluid mb-4">
            <h3><strong>Sorry, there are no shops listed for this category.</strong></h3>
        </div>
    </div>
</div>

<!-- popup form  start-->
<div id="popupForm" class="popup-form">
    <form [formGroup]="createShopInquiryForm" autocomplete="off">
        <i class="fa fa-times fa-pull-right fonts-icon" aria-hidden="true" (click)="closePopupForm()"
            style="cursor: pointer;"></i>
        <div class="sd_modal_header">
            <div class=" modal_head_center">
                <div class=" sdwrapper font22 fw500 color111 text_left info_mobile">Get information
                    by SMS/Email</div>
            </div>
        </div>
        <div class="form-group mt-20">
            <input type="text" class="form-control" id="name" aria-describedby="emailHelp" placeholder="Name"
                name="name" formControlName="name">
            <div *ngIf="hasError('name')" [class]="hasError('name') ? 'error' : ''">
                <div *ngIf="name?.errors">
                    <span *ngIf="name?.errors?.['required']">Name is a required field.</span>
                </div>
            </div>
        </div>
        <div class="form-group mt-20">
            <input type="text" class="form-control" id="phoneNo" placeholder="Mobile number" name="phoneNo"
                formControlName="phoneNo" minlength="10" maxlength="10" pattern="[0-9]*">
            <div *ngIf="hasError('phoneNo')" [class]="hasError('phoneNo') ? 'error' : ''">
                <div *ngIf="phoneNo?.errors">
                    <span *ngIf="phoneNo?.errors?.['required']"> Phone number is a required field.</span>
                    <span *ngIf="!phoneNo?.errors?.['required'] && phoneNo?.errors?.['minlength']"> Phone number should
                        be at least 10 characters long.</span>
                    <span
                        *ngIf="!phoneNo?.errors?.['required'] && !phoneNo?.errors?.['minlength'] && phoneNo?.errors?.['pattern']">
                        Phone number should contain only numeric characters.</span>
                </div>
            </div>
        </div>
        <div class="form-group mt-20">
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Email" name="email" formControlName="email">
            <div *ngIf="hasError('email')" [class]="hasError('email') ? 'error' : ''">
                <div *ngIf="email?.errors">
                    <span *ngIf="email?.errors?.['required']">Email is a required field.</span>
                    <span *ngIf="email?.errors?.['email']">Email should be email type</span>
                </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary" (click)="onSubmitInquiry(createShopInquiryForm)">Submit</button>
    </form>
</div>

<app-home-footer></app-home-footer>