import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReviewService } from 'src/app/shared/services/review-products.service';
import { NewProductSlider } from '../../../data/slider';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  public products : any=[];

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion
  @Output() productSelected: EventEmitter<any> = new EventEmitter<any>();

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService,private reviewSrv : ReviewService) { 
    this.productService.getProducts.subscribe(response => 
      this.products = response
    );
  }

  ngOnInit(): void {
  }
    
  getRouterLink(product:any): string[] {
    return ['/shop/product/', product?.urlPath || ''];
  }
  
}
