// metadata.service.ts

import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private meta: Meta, private titleService: Title, @Inject(DOCUMENT) private dom:any) { }

  // Method to set meta tags dynamically
  updateMetaInfo(title?: any, description?: any, keywords?: any[], robots?: any): void {
    if (title !== undefined) {
      this.titleService.setTitle(title);
      this.meta.updateTag({ name: 'title', content: title });
      this.meta.updateTag({ name: 'twitter:title', content: title});
      this.meta.updateTag({ property: 'og:title', content: title });
      // this.meta.updateTag({name: 'twitter:image:alt', content: title});
      // this.meta.updateTag({property: 'og:image:alt', content: title});
    }
    if (description !== undefined) {
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'og:description', content: description });
      this.meta.updateTag({ name: 'twitter:description', content: description });
    }
    if (keywords !== undefined) {
      this.meta.updateTag({ name: 'keywords', content: keywords.join(', ') });
    }
    if (robots !== undefined) {
      this.meta.updateTag({ name: 'robots', content: robots });
    }
    this.meta.updateTag({ name: 'author', content: 'ShopDial' });
    this.meta.addTag({ name: 'robots', content: 'index,follow' });
    this.setCanonicalURL();
  }

  addTag() {
    this.meta.addTag({ name: 'description', content: 'ShopDial thrives on clear, frequent communication for strong customer relationships. | ShopDial' });
    this.meta.addTag({ name: 'robots', content: 'index,follow' });
    this.meta.addTag({ property: 'og:title', content: 'ShopDial thrives on clear, frequent communication for strong customer relationships. | ShopDial' });
 }

  // Method to set canonical link
  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',canURL)
    this.meta.updateTag({ property: 'og:url', content: canURL });
  }

}
