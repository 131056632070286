<div class="container">
    <div class="row desktop-layout mt-3  scroller" *ngIf="inputSourceLayout === 'shopdial-home-cat.component'">
        <div class="col-md-2 col-lg-2 col-3 col-xl-2 col-sm-3" *ngFor="let i of [].constructor(24);">
            <div class="loading-card is-loading loading-card-cat mb-3">
                <div class="content-cat">
                    <p class="loading-p-cat"></p>
                    <h2 class="loading-p-cat"></h2>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shopdial-home-city.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-12 col-md-4 col-xl-3 col-lg-3 col-sm-6 " *ngFor="let i of [].constructor(4);">
                <div class="loading-card is-loading">
                    <div class="content">
                        <p class="loading-card-sd-top-rank"></p>
                        <h2 class="shopdial-top-rank-h" *ngFor="let i of [].constructor(3);"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shopdial-home-state.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-12 col-md-4 col-xl-3 col-lg-3 col-sm-6 " *ngFor="let i of [].constructor(4);">
                <div class="loading-card is-loading">
                    <div class="content">
                        <p class="loading-card-sd-top-rank"></p>
                        <h2 class="shopdial-top-rank-h" *ngFor="let i of [].constructor(3);"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shopdial-home-country.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-12 col-md-4 col-xl-3 col-lg-3 col-sm-12 " *ngFor="let i of [].constructor(4);">
                <div class="loading-card is-loading">
                    <div class="content">
                        <p class="loading-card-sd-top-rank"></p>
                        <h2 class="shopdial-top-rank-h" *ngFor="let i of [].constructor(3);"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shop-detail-top.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-12">
                <div class="loading-card is-loading">
                    <div class="content">
                        <div class="row">
                            <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <div class="loading-card is-loading sp-gallery-card-mb">
                                    <div class="content">
                                        <p class="img-one"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <div class="loading-card is-loading sp-gallery-card-mb">
                                    <div class="content">
                                        <p class="img-one"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <div class="row">
                                    <div class="col-6 col-md-12 col-lg-6 col-xl-6">
                                        <div class="loading-card is-loading sp-gallery-card-mb">
                                            <div class="content">
                                                <p class="img-two"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-12 col-lg-6 col-xl-6">
                                        <div class="loading-card is-loading sp-gallery-card-mb">
                                            <div class="content">
                                                <p class="img-two"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row sp-mob-hide">
                                    <div class="col-6 col-md-12 col-lg-6 col-xl-6">
                                        <div class="loading-card is-loading">
                                            <div class="content">
                                                <p class="img-two"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-12 col-lg-6 col-xl-6">
                                        <div class="loading-card is-loading">
                                            <div class="content">
                                                <p class="img-two"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="row">
                            <h2 class="sp-like col-2 sp-mob-hide"></h2>
                            <h2 class="sp-heading col-6 ml-3"></h2>
                            <h1 class="col-3"></h1>
                        </div>
                        <div class="row">
                            <h2 class="sp-rating col-2 sp-mob-hide"></h2>
                            <h2 class="sp-star col-6 ml-3"></h2>
                            <h2 class="col-3 sp-claim"></h2>
                        </div>
                        <div class="row">
                            <h2 class="sp-address"></h2>
                        </div>
                        <div class="row">
                            <h2 class="sp-enquire"></h2>
                        </div>
                        <div class="row">
                            <h2 class="sp-contact-no col-3"></h2>
                            <h2 class="sp-chat ml-3 col-1 sp-mob-hide"></h2>
                            <h2 class="sp-top-rated col-2 sp-mob-hide"></h2>
                            <h2 class="sp-visit col-3 sp-mob-hide"></h2>
                            <h1 class="col-3 sp-mob-hide"></h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shop-detail-middle.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-md-12 col-sm-12 col-12 col-lg-9">
                <div class="loading-card is-loading">
                    <div class="content">
                        <div class="row sp-info">
                            <div class="col-4 col-lg-4 col-sm-12 sp-reduce-col-mb">
                                <h2 class="sp-visit mb-5"></h2>
                                <h2 class="sp-visit" *ngFor="let i of [].constructor(2);"></h2>
                            </div>
                            <div class="col-4 col-lg-4 col-sm-12">
                                <h2 class="sp-visit mt-sp-disburtion"></h2>
                                <h2 class="sp-visit"></h2>
                            </div>
                            <div class="col-4 col-lg-4 col-sm-12">
                                <h2 class="sp-visit" *ngFor="let i of [].constructor(4);"></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loading-card is-loading mt-sp-info">
                    <div class="content">
                        <div class="row sp-info">
                            <div class="col-6 col-lg-6 col-sm-12">
                                <h2 class="sp-visit" *ngFor="let i of [].constructor(4);"></h2>
                            </div>
                            <div class="col-6 col-lg-6 col-sm-12">
                                <h2 class="sp-visit" *ngFor="let i of [].constructor(2);"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-12 col-sm-12">
                <div class="loading-card is-loading">
                    <div class="content">
                        <h2 class="sp-add" *ngFor="let i of [].constructor(9);"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shop-detail-middle-map.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-md-9 col-sm-12 col-12">
                <div class="loading-card is-loading">
                    <div class="content">
                        <h2 class="map-visit"></h2>
                        <p class="sp-map"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 col-sm-12">
                <div class="loading-card is-loading">
                    <div class="content">
                        <h2 class="sp-visit-address mb-3" *ngFor="let i of [].constructor(6);"></h2>
                        <h2 class="sp-visit-address"></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shop-detail-middle-review.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-md-12 col-lg-9 col-sm-12 col-12">
                <div class="loading-card is-loading report-height">
                    <div class="content">
                        <!--  -->
                        <h2 class="review-heading mb-3"></h2>
                        <h2 class="recent-rating"></h2>
                        <div class="row sp-mob-hide">
                            <h2 class="recent-rating-star me-3 mb-3" *ngFor="let i of [].constructor(5);"></h2>
                        </div>
                        <h2 class="post-review-heading mb-3"></h2>
                        <div class="row sp-info" *ngFor="let i of [].constructor(2);">
                            <div class="col-6" *ngFor="let i of [].constructor(2);">
                                <h2 class="input-title"></h2>
                                <h2 class="input"></h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 sp-mob-hide">
                                <h1></h1>
                            </div>
                            <div class="col-4">
                                <h2 class="submit-review mt-4"></h2>
                            </div>
                            <div class="col-4 sp-mob-hide">
                                <h1></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 col-12 col-sm-12">
                <div class="loading-card is-loading report-height">
                    <div class="content">
                        <h2 class="report-head mb-3"></h2>
                        <h2 class="sp-cat"></h2>
                        <h2 class="report-head"></h2>
                        <h2 class="report-head"></h2>
                        <h2 class="get-top-list mt-3"></h2>
                    </div>
                    <div class="loading-card is-loading">
                        <div class="content">
                            <h2 class="under-report-head mb-3"></h2>
                            <p class="under-report-decs"></p>
                            <h2 class="under-report mb-3"></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="inputSourceLayout === 'shop-detail-middle-review-list.component'">
        <div class="row desktop-layout mt-3  scroller">
            <div class="col-12">
                <div class="loading-card is-loading">
                    <div class="content">
                        <p class="shopdial-shop-detail"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div *ngIf="inputSourceLayout === 'home-collection.component'">
            <div class="row desktop-layout mt-3  scroller">
                <div class="col-12 col-md-4 col-xl-3 col-lg-3 col-sm-6 " *ngFor="let i of [].constructor(4);">
                    <div class="loading-card is-loading">
                        <div class="content">
                            <p class="loading-card-sd-top-rank"></p>
                            <h2 class="shopdial-top-rank-h" *ngFor="let i of [].constructor(3);"></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="inputSourceLayout === 'shop-type.component'">
        <div class="row">
            <div class="col-12 col-lg-6 col-md-12 col-xl-6 col-sm-12 card-width">
                <div class="loading-card is-loading">
                    <div class="content">
                        <div class="row sp-info">
                            <div class="col-3 col-md-12 col-lg-3 col-xl-3">
                                <p class="sp-images"></p>
                            </div>
                            <div class="col-6 col-md-12 col-lg-6 col-xl-6">
                                <h2 class="sd-cat-page"></h2>
                                <p class="sp-data"></p>
                                <div class="row sp-info sp-mob-hide">
                                    <div class="col-4">
                                        <h2 class="sd-open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <p class="desc-heading"></p>
                                <p class="desc"></p>
                                <h2 class="sd-email"></h2>
                            </div>
                            <div class="col-3 col-lg-3 col-xl-3 sp-mob-hide">
                                <h2 class="mb-1" *ngFor="let i of [].constructor(10);"></h2>
                            </div>
                        </div>
                        <div class="row sp-info sp-info mt-tablet" *ngFor="let i of [].constructor(3);">
                            <div class="col-3">
                                <p class="sp-images"></p>
                            </div>
                            <div class="col-6">
                                <h2 class="sd-cat-page"></h2>
                                <p class="sp-data"></p>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <p class="desc-heading"></p>
                                <p class="desc"></p>
                                <h2 class="sd-email"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="inputSourceLayout === 'shop-search.component'">
        <div class="row mb-3">
            <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 search-sp-img">
                <div class="loading-card is-loading">
                    <div class="content">
                        <div class="row sp-search-info">
                            <div class="col-6">
                                <p class="shop-search-image"></p>
                            </div>
                            <div class="col-6">
                                <p class="shop-search-image"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 search-sp-data">
                <div class="loading-card is-loading">
                    <div class="content">
                        <div class="row">
                            <h2 class="sp-fire"></h2>
                            <p class="sp-name"></p>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-search_open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-search_close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-search_star"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-search_rating"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <h2 class="sd-search_open-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h2 class="sd-search_close-time"></h2>
                                    </div>
                                    <div class="col-4">
                                        <h1></h1>
                                    </div>
                                </div>
                            <h2 class="sd-search-desc-head"></h2>
                            <p class="sp-search-desc"></p>
                            <h2 class="sd-search-desc-head"></h2>
                            <h2 class="sd-search-social"></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>