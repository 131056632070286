import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CollectionSlider, HomeSlider } from '../../shared/data/slider';
import { HomepageService } from '../../shared/services/homepage.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shop-search',
  templateUrl: './shop-search.component.html',
  styleUrls: ['./shop-search.component.scss']
})
export class ShopSearchComponent implements OnInit {

  @Input() class: string;
  public HomeSliderConfig: any = HomeSlider;
  public themeLogo: string = 'assets/images/footer-logo.png';
  public searchResult: any;
  public showShopBySearch = false;
  public ImageSrc: string;
  public createShopInquiryForm: FormGroup;
  isSubmited: boolean;
  public generatedShopId: any;

  constructor(private homeSrv: HomepageService, private activatedRoute: ActivatedRoute, private docSrv: ShopdocumentService, private location: Location, private fb: FormBuilder, private toastr: ToastrService) {

    this.createShopInquiryForm = this.fb.group({
      id: [''],
      shopId: [''],
      phoneNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      tenantId: ['']
    });
  }

  ngOnInit(): void {
    // Subscribe to route params
    this.activatedRoute.paramMap.subscribe(params => {
      let shopName = params.get('shopName');
      if (shopName) {
        this.loadShopData(shopName);
      }
    });

    //back button
    App.addListener('backButton', () => {
      this.location.back();
    });
  }


  loadShopData(shopName: string) {
    // Replace hyphens with spaces in the shop name
    const originalShopName = shopName.replace(/-/g, ' ');
    this.homeSrv.shopByName(originalShopName).subscribe((data: any) => {
      if (data && data.length > 0) {
        this.generatedShopId = data[0].id; // Extract shopId from the response data
        this.createShopInquiryForm.get('tenantId').setValue(data[0].tenantId);
        this.searchResult = data;
        this.docSrv.getDocByShopId(this.generatedShopId).subscribe((documents: any[]) => {
          this.searchResult.documents = documents;
          this.showShopBySearch = true;
        });
      } else {
        // Handle case where no shop data is returned
        this.searchResult = null;
      }
    });
  }

  ngOnDestroy(): void {
    // Remove class in body
    document.body.classList.remove("tools-bg");

    // back button
    App.removeAllListeners();
  }
  public hasError(name: string) {
    const field = this.createShopInquiryForm.get(name);
    return (field?.invalid && field?.touched && field?.errors);
  }

  public validateForm(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validateForm(form);
      }
    });
  }

  public onSubmitInquiry(form: any): void {
    if (form.valid) {
      this.isSubmited = true;
      this.handleShopInquiry(form.value);
    } else {
      this.validateForm(form);
    }
  }

  public handleShopInquiry(category: any): void {
    this.createShopInquiry(category);
  }

  public createShopInquiry(inquiry: any): void {
    this.homeSrv.createShopInquiry(inquiry, this.generatedShopId).subscribe((data: any) => {
      this.closePopupForm();
      this.createShopInquiryForm.reset();
      this.toastr.success('Inquiry submitted successfull.', 'SUCCESS');
    }), (error: any) => {
      this.toastr.error('Inquiry not submitted, try again.', 'ERROR');
    };
  }

  public showPopupForm() {
    var popupForm = document.getElementById("popupForm");
    popupForm.style.display = "block";
  }

  public closePopupForm() {
    var popupForm = document.getElementById("popupForm");
    popupForm.style.display = "none";
  }

  public CollectionSliderConfig: any = CollectionSlider;

  get form() {
    return this.createShopInquiryForm.controls;
  }

  get id() {
    return this.form['id'];
  }
  get name() {
    return this.form['name'];
  }

  get phoneNo() {
    return this.form['phoneNo'];
  }

  get email() {
    return this.form['email'];
  }
  get shopId() {
    return this.form['shopId']
  }

  get tenantID() {
    return this.form['tenantId']
  }
}
