import { Component, OnInit, Input } from '@angular/core';
import { TenantService } from 'src/app/tenant/tenant.service';
import { EcomService } from '../../services/ecom.service';

@Component({
  selector: 'app-footer-four',
  templateUrl: './footer-four.component.html',
  styleUrls: ['./footer-four.component.scss']
})
export class FooterFourComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo

  public today: number = Date.now();
  public shopLocation: any;
  public shopSocialmedia: any;
  constructor(private tenantSrv: TenantService, private ecomSrv: EcomService) { }

  public shop: any = [];

  ngOnInit(): void {
    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.getLocationByShopId();
    this.getSocialmediaByShopId();
  }

  public getLocationByShopId() {
    this.ecomSrv.getLocationByShopId().subscribe((data: any) => {
      this.shopLocation = data;
    });
  }

  public getSocialmediaByShopId() {
    this.ecomSrv.getSocialmediaByShopId().subscribe((data) => {
      this.shopSocialmedia = data;
    });
  }
}
