import { Component, OnInit, Inject,PLATFORM_ID,TemplateRef,ViewChild} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {

  @ViewChild("alertpopup", { static: false }) AlertPopupModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if(sessionStorage.getItem("SHOP_STATUS") === 'inactive')
       this.openModal();
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.AlertPopupModal, { 
        size: 'lg',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        backdrop: 'static',
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      });
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
