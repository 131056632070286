import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  ecomUrl = environment.base_url_ecom_service;

  private httpClient2: HttpClient;
  constructor(private httpClient: HttpClient, handler: HttpBackend) {
    this.httpClient2 = new HttpClient(handler);
  }

  public getAllReview(productId: any) {
    return this.httpClient.get(`${this.ecomUrl}/review?tenantId=${this.getTenantId()}&shopId=${this.getShopId()}&productId=${productId}`);
  }

  public createReview(Review: any,productId:any): any {
    return this.httpClient2.post(`${this.ecomUrl}/review?tenantId=${this.getTenantId()}&shopId=${this.getShopId()}&productId=${productId}`, Review);
  }

  getTenantId(): any {
    return sessionStorage.getItem("TENANTID");
  }

  getShopId(): any {
    return sessionStorage.getItem("shopId")
  }

}






