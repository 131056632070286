import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { TenantService } from 'src/app/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class ShopResolver implements Resolve<any> {

  public shopInfo:any;
  public tenantInfo:any;

  constructor(private tenantService: TenantService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.shopInfo = this.tenantService.getTenantByDomain().pipe(
      tap(tenantInfo => this.tenantInfo = tenantInfo),
      switchMap(tenantInfo => {
        return this.tenantService.getShopDetailsByDomain(tenantInfo['tenantId']).pipe(
            map(shopInfo => {
                    return {
                        tenantInfo:tenantInfo,
                        shopInfo: shopInfo
                    }
                }
            )
        )
      }),
      catchError(errorForFirstOrSecondCall => {
        console.error('An error occurred: ', errorForFirstOrSecondCall);
        throw new Error('Error: ' + errorForFirstOrSecondCall.message);
      })
    );

    //   tenantInfo => {
    //   sessionStorage.setItem("TENANTID", tenantInfo['tenantId']);
    //   sessionStorage.setItem("TENANTNAME", tenantInfo['tenantName']);
    //   this.tenantService.getShopDetailsByDomain().subscribe(shopInfo=>{
    //     this.shopInfo = shopInfo ;
    //   });
    // });
    return this.shopInfo;
  }
  
}
