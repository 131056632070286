<app-header-one [sticky]="true" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"></app-header-one>

<!-- Home slider start-->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'fashion'" [buttonText]="'shop now'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- collection banner start-->
<div class="container mt-3" *ngIf="!issectionFormOpen">
  <div class="row partition2">
    <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then banner_one else default_banner_one"></div>
    <!-- Offer Banner One -->
    <ng-template class="pb-0" #banner_one>
      <div class="col-md-6" *ngFor="let offer of filteredOfferDocumentsOne; let i = index">
        <a>
          <div class="p-right  text-center">
            <div class="image-container position-relative" *ngIf="!isOfferBannerFormOpenTwo">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="offer.urls" class="img-fluid" alt="banner">
            </div>
            <div class="contain-banner">
              <div class="text-overlay">
                <h2 style="color: #38ffbb;">{{offer?.textTittle}}</h2>
                <h4 class="fontColor">{{offer?.textSubTittle}}</h4>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-template>

    <ng-template class="pb-0" #default_banner_one>
      <div class="col-md-6">
        <a>
          <div class="p-right text-center">
            <div class="image-container position-relative" *ngIf="!isOfferBannerFormOpenTwo">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/sub-banner.png" class="img-fluid"
                alt="banner">
            </div>
            <div class="contain-banner">
              <div class="text-overlay">
                <h2>men</h2>
                <h4 class="fontColor">save 50%</h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      705px x 250px.
                  </div>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerOne, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close -->
    <!-- Offer Banner One -->
    <!-- Offer Banner Two -->
    <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then banner_two else default_banner_two"></div>
    <ng-template class="pb-0" #banner_two>
      <div class="col-md-6" *ngFor="let offer of filteredOfferDocumentsTwo; let i = index">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="p-right  text-center">
            <div class="image-container position-relative">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img [src]="offer.urls" class="img-fluid" alt="banner">
            </div>
            <div class="contain-banner">
              <div class="text-overlay">
                <h2 style="color: #38ffbb;">{{offer?.textTittle}}</h2>
                <h4 class="fontColor">{{offer?.textSubTittle}}</h4>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="pb-0" #default_banner_two>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="p-right text-center">
            <div class="image-container position-relative">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/sub-banner1.png" class="img-fluid"
                alt="banner">
            </div>
            <div class="contain-banner">
              <div class="text-overlay">
                <h2>women</h2>
                <h4 class="fontColor">save 50%</h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      705px x 250px.
                  </div>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerTwo, f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close -->
    <!-- Offer Banner Two -->
  </div>
</div>
<!-- collection banner End -->

<!-- Product slider start-->
<div class="title1 section-t-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen">
  <h4>special offer</h4>
  <h2 class="title-inner1">top collection</h2>
</div>
<div class="container">
  <div class="row">
    <a class="fa-pull-left" *ngIf="isEditable()" (click)="openSectionForm()">
      <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" style="color:#717386;margin-left: 96%;"></i>
    </a>
    <div class="col-lg-6 offset-lg-3" *ngFor=" let section of sections">
      <div class="product-para">
        <p class="text-center">
          {{section?.description | stripHtml}}
        </p>
      </div>
    </div>
  </div>
  <!-- Popup Form -->
  <div class="popup-form" *ngIf="issectionFormOpen">
    <div class="confirmation-content" [formGroup]="websiteSectionForm">
      <div class="container text-center mt-3">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 align-self-center">
            <label for="discription" class="col-form-label pt-0">Text Description</label>
            <ckeditor [editor]="Editor" data="Hello, world!" formControlName="description">
            </ckeditor>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-success pull-right" type="submit"
          (click)="onSectionSubmit(websiteSectionForm)">Confirm</button>
        <button class="btn btn-primary pull-right" (click)="closeSectionForm()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<section class="section-b-space p-t-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:16">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-product-box-one [product]="product" [currency]="productService?.Currency" [thumbnail]="true"
                  [cartModal]="true">
                </app-product-box-one>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Product slider End -->

<!-- Parallax banner start-->
<div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
<ng-template #bG_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
    <div class="full-banner parallax-banner1 parallax text-center p-left"
      *ngFor="let bgDoc of filtereBackgroundDocuments" [style.backgroundImage]="getEncodedImageUrl(bgDoc?.urls)">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <!-- <img [src]="bgDoc?.urls" alt=""> -->
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="banner-contain">
              <h2>2023</h2>
              <h3 style="color:white">{{bgDoc?.textTittle}}</h3>
              <h4 style="color:white">{{bgDoc?.textSubTittle}}</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        1920px x 1200px.
                    </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner End -->

<!-- Parallax banner start-->
<ng-template #defualt_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
    <div class="full-banner parallax-banner1 parallax text-center p-left"
      [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/parallax/20.jpg)'}">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="banner-contain">
              <h2>20019</h2>
              <h3 style="color:white">XBOX Games</h3>
              <h4 style="color:white">special offer</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        1920px x 1200px.
                    </div>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveItem(uploadBackgroundBanner, f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopUpForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner End -->

<!-- Tab product start-->
<div class="title1 section-t-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <h4>exclusive products</h4>
  <h2 class="title-inner1">special products</h2>
</div>
<section class="section-b-space p-t-0">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
          <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
            <a ngbNavLink>
              {{ collection }}
            </a>
            <ng-template ngbNavContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one [product]="product" [currency]="productService?.Currency">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->

<!-- services start-->
<div class="container" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <section class="service border-section small-section">
    <app-services></app-services>
  </section>
</div>
<!-- services End -->

<!-- blog section start-->
<section class="blog p-t-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blog"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!--  logo section start-->
<section class="section-b-space" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logo"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End-->

<app-newsletter *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"></app-newsletter>
<app-alert-popup></app-alert-popup>
<app-footer-one *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"></app-footer-one>

