<app-header-one [class]="'header-gym'" [themeLogo]="themeLogo"
  *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen && !isHeaderHidden"></app-header-one>

<!-- Home slider start-->
<section class="p-0 gym-slider">
  <app-slider [sliders]="sliders" [textClass]="'text-end p-right'" [category]="'gym'" [buttonText]="'shop now'"
    *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- Banner start-->
<section class="banner-padding banner-furniture gym-banner">
  <div class="container-fluid">
    <div class="row partition2">
      <div *ngIf="filteredBannerOneDocuments?.length > 0; then banner_one_content else banner_one_static_content">
      </div>
      <!-- Dynamic Banner One Start-->
      <ng-template #banner_one_content>
        <div class="col-md-4" *ngIf="!isBannerTwoFormOpen">
          <a>
            <div class="collection-banner p-left text-start" *ngFor="let banOne of filteredBannerOneDocuments">
              <img [src]="banOne?.urls" alt="not found">
              <div class="contain-banner">
                <div>
                  <h4>{{banOne?.textSubTittle}}</h4>
                  <h2>{{banOne?.textTittle}}</h2>
                </div>
              </div>
            </div>
          </a>
          <span class="edit-span" *ngIf="isEditable()" (click)="openBannerOneForm()">
            <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isBannerOneFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectBannerOneImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                            604px X 486px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadBannerOne" [removable]="true"
                        (removed)="onRemoveItem(uploadBannerOne,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitBanner(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closeBannerForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Dynamic Banner One End-->
      <!-- Static Banner One Start-->
      <ng-template #banner_one_static_content>
        <div class="col-md-4" *ngIf="!isBannerTwoFormOpen">
          <a>
            <div class="collection-banner p-left text-start">
              <img src="assets/images/gym-smile-banner.jpg" alt="Gym Default">
              <div class="contain-banner">
                <div>
                  <h4>save 30%</h4>
                  <h2>Gym</h2>
                </div>
              </div>
            </div>
          </a>
          <span class="edit-span" *ngIf="isEditable()" (click)="openBannerOneForm()">
            <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isBannerOneFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectBannerOneImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                            604px X 486px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadBannerOne" [removable]="true"
                        (removed)="onRemoveItem(uploadBannerOne,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitBanner(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closeBannerForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Static Banner One End-->

      <div *ngIf="filteredBannerTwoDocuments?.length > 0 ;then banner_two_content else banner_two_static_content">
      </div>
      <!-- Dynamic Banner Two Start-->
      <ng-template #banner_two_content>
        <div class="col-md-8" *ngIf="!isBannerOneFormOpen">
          <a>
            <div class="collection-banner p-left text-start" *ngFor="let banTwo of filteredBannerTwoDocuments">
              <img [src]="banTwo?.urls" alt="Gym">
              <div class="contain-banner">
                <div>
                  <h4>{{banTwo?.textSubTittle}}</h4>
                  <h2>{{banTwo?.textTittle}}</h2>
                </div>
              </div>
            </div>
          </a>
          <span class="edit-span-two" *ngIf="isEditable()" (click)="openBannerTwoForm()">
            <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isBannerTwoFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectBannerTwoImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                            1275px X 500px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadBannerTwo" [removable]="true"
                        (removed)="onRemoveItem(uploadBannerTwo,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitBanner(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closeBannerForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Dynamic Banner Two End-->
      <!-- Static Banner Two Start-->
      <ng-template #banner_two_static_content>
        <div class="col-md-8" *ngIf="!isBannerOneFormOpen">
          <a>
            <div class="collection-banner p-left text-start">
              <img src="assets/images/bigGymBanner.jpg" alt="">
              <div class="contain-banner">
                <div>
                  <h4>save 30%</h4>
                  <h2>Protien Powder</h2>
                </div>
              </div>
            </div>
          </a>
          <span class="edit-span-two" *ngIf="isEditable()" (click)="openBannerTwoForm()">
            <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
          </span>
          <!-- Popup Form -->
          <div class="popup-form" *ngIf="isBannerTwoFormOpen">
            <div class="confirmation-content" [formGroup]="documentForm">
              <!-- Add a small tag for displaying messages at the top -->
              <small class="text-danger">{{ errorMessage }}</small>
              <div class="container mt-3">
                <div class="row">
                  <div class="card-body dropzone-custom p-0">
                    <ngx-dropzone class="dropzone-border" (change)="onSelectBannerTwoImage($event)">
                      <ngx-dropzone-label>
                        <div class="dz-message needsclick">
                          <i class="fa fa-cloud-upload"></i>
                          <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                          <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                            1275px X 500px.</small>
                        </div>
                      </ngx-dropzone-label>
                      <ngx-dropzone-preview *ngFor="let f of uploadBannerTwo" [removable]="true"
                        (removed)="onRemoveItem(uploadBannerTwo,f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                      </ngx-dropzone-preview>
                    </ngx-dropzone>
                  </div>
                  <div class="row justify-content-around mt-4">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                      </label>
                      <input class="form-control" id="textTittle" type="text" name="textTittle"
                        formControlName="textTittle">
                    </div>
                    <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                      <label class="col-form-label pt-0">Text Subtittle</label>
                      <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                        formControlName="textSubTittle">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitBanner(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closeBannerForm()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Static Banner Two End-->
    </div>
  </div>
</section>
<!-- Banner End-->

<!-- Product Box Start-->
<div class="title1 title-gradient section-t-space"
  *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen !isBackgroundFormOpen">
  <h4>special offer</h4>
  <h2 class="title-inner1">top collection</h2>
</div>
<section class="p-t-0 section-b-space gym-product"
  *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen !isBackgroundFormOpen">
  <div class="container">
    <div class="row partition-cls" *ngIf="!isBackgroundFormOpen">
      <div class="col-xl-3 col-sm-6" *ngFor="let product of products | slice:0:8">
        <div class="product-box ">
          <app-product-box-one [product]="product" [currency]="productService?.Currency" [cartModal]="true">
          </app-product-box-one>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Product Box End -->

<div *ngIf="filteredBackgroundDocuments?.length > 0 ;then background_content else background_static_content">
</div>
<!-- Product slider Dynamic Start-->
<ng-template #background_content>
  <div *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen">
    <div class="full-banner gym-parallax parallax-banner25 parallax p-0" *ngFor="let doc of filteredBackgroundDocuments"
      [style.backgroundImage]="getEncodedImageUrl(doc?.urls)">
      <span class="edit-span-background" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
      </span>
      <div class="section-t-space section-b-space gym-product">
        <div class="container">
          <div class="title1 title-gradient">
            <h4>special offer</h4>
            <h2 class="title-inner1">top collection</h2>
          </div>
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="product-para">
                <p class="text-center">
                  💪 Exclusive deal! Buy now and enjoy incredible savings on our premium range of protein powders - your
                  path to a stronger, fitter you awaits.🎉
                </p>
              </div>
            </div>
          </div>
          <div class="row part-cls" *ngIf="!isBackgroundFormOpen">
            <div class="col-xl-3 col-sm-6" *ngFor="let product of products | slice:0:4">
              <div class="product-box">
                <app-product-box-one [product]="product" [currency]="productService?.Currency" [cartModal]="true">
                </app-product-box-one>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessage }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        1920px X 1080px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveBackground(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitBackground(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Product slider Dynamic end -->
</ng-template>
<!-- Product slider Static Start-->
<ng-template #background_static_content>
  <div class="full-banner gym-parallax parallax-banner25 parallax p-0"
    [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/parallax/25.jpg)'}"
    *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen">
    <span class="edit-span-background" *ngIf="isEditable()" (click)="openBackgroundForm()">
      <i class="fa fa-pencil-square-o fa-2x" style="color: gray;" aria-hidden="true"></i>
    </span>
    <div class="section-t-space section-b-space gym-product">
      <div class="container">
        <div class="title1 title-gradient">
          <h4>special offer</h4>
          <h2 class="title-inner1">top collection</h2>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div class="product-para">
              <p class="text-center">
                💪 Exclusive deal! Buy now and enjoy incredible savings on our premium range of protein powders - your
                path to a stronger, fitter you awaits.🎉
              </p>
            </div>
          </div>
        </div>
        <div class="row part-cls" *ngIf="!isBackgroundFormOpen">
          <div class="col-xl-3 col-sm-6" *ngFor="let product of products | slice:0:4">
            <div class="product-box">
              <app-product-box-one [product]="product" [currency]="productService?.Currency" [cartModal]="true">
              </app-product-box-one>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isBackgroundFormOpen">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessage }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      1920px X 1080px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                  (removed)="onRemoveBackground(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitBackground(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Product slider Static end -->
</ng-template>


<!-- blog section start-->
<section class="blog p-t-0 gym-blog" *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1  title-gradient section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->


<!--  logo section start-->
<section class="section-b-space" *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logos"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End-->

<app-alert-popup *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen"></app-alert-popup>
<app-footer-two [class]="'bg-img-gym bg-size'" [themeLogo]="themeLogo"
  *ngIf="!isBannerOneFormOpen && !isBannerTwoFormOpen && !isBackgroundFormOpen"></app-footer-two>