<header class="header-style-5 color-style back-color" id="sticky-header">
  <div class="mobile-fix-option"></div>

  <!-- Top Header Start-->
  <div class="top-header top-header-theme mobile-hide">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="header-contact text-end">
            <ul>
              <li style="color: #24265d">
                <i class="fa fa-info" aria-hidden="true" style="color: #24265d;"></i><span class="pointer" [routerLink]="'/pages/comingsoon'">What's new</span>
              </li>
              <li style="color: #24265d">
                <i class="fa fa-bullhorn" aria-hidden="true" style="color: #24265d;"></i><span class="pointer">Advertise</span>
              </li>
              <li style="color: #24265d">
                <i class="fa fa-phone" aria-hidden="true" style="color: #24265d;"></i><span class="pointer">24x7 CustomerCare</span>
              </li>
              <li style="color: #24265d">
                <i class="fa fa-question-circle faq-icon" aria-hidden="true" style="color: #24265d;"></i>
                <span style="color: #24265d;" class="pointer" [routerLink]="'/onboarding/faq'">FAQ's</span>
              </li>
              <li style="color: #24265d"><i class="fa fa-user-plus" aria-hidden="true" style="color: #24265d;"></i><span style="color: #24265d;" class="pointer" [routerLink]="'/onboarding'">Sign Up</span>
              </li>
              <li class="onhover-dropdown mobile-account" style="color: #24265d">
                <i class="fa fa-language px-8" aria-hidden="true" style="color: #24265d"></i>
                <span style="color: #24265d" class="pointer"> English</span>
                <ul class="onhover-show-div">
                  <li>
                    <a href="/" data-lng="en" style="color: #24265d">
                      English
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Top Header Close-->

  <!-- Menu Start-->
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="hide_left_menu">
              <app-home-left-menu></app-home-left-menu>
            </div>
            <div class="brand-logo mt20">
              <a href="index.html"><img [defaultImage]="'assets/images/Defaultshop/238x54default-img.png'"
                  [lazyLoad]="'assets/images/shopDial-logo-3.png'" class="img-fluid lazy-loading"
                  alt="Shopdial Logo" /></a>
            </div>
          </div>

          <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 mt20 mobile-hide state-dropdown">
            <ng-multiselect-dropdown [placeholder]="'State'" [data]="states" [settings]="dropdownSettings"
              (onSelect)="onChangeState($event.name)" (onDropDownClose)="onDropDownClose($event)"
              (onDeSelect)="onDeSelect($event)">
              <ng-template ng-multiselect-dropdown-label-tmpl>
                <i class="fa fa-map-marker"></i>
                <span>Select State</span>
              </ng-template>
            </ng-multiselect-dropdown>
          </div>

          <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 mt20 mobile-hide city-dropdown sd-city-drop-down">
            <ng-multiselect-dropdown [placeholder]="'City'" [data]="cities" [settings]="dropdownSetting"
              (onSelect)="onChangeCity($event.city)" (onDropDownClose)="onDropDownCloseCity($event)"
              (onDeSelect)="onDeSelectCity($event)">
              <ng-template ng-multiselect-dropdown-label-tmpl> </ng-template>
            </ng-multiselect-dropdown>
          </div>

          <div class="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-4 mt25 mobile-hide src-input">
            <ng-autocomplete [data]="shops" [searchKeyword]="keyword" [placeholder]="placeholder"
              [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate" (selected)="selectEvent($event)"
              (inputChanged)="onChangeSearch($event)" (inputFocused)="onFocused($event)"></ng-autocomplete>
            <ng-template #itemTemplateStatic let-item>
              <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFoundShop>
              <div [innerHTML]="notFoundShop"></div>
            </ng-template>
            <button type="button" class="btn btn-primary mobile-hide text-capitalize src-button">
              Search
            </button>
          </div>
          <div class="menu-right pull-right">
            <div class="hide_right_menu">
              <app-home-right-menu></app-home-right-menu>
            </div>
            <div class="icon-nav mobile-search">
              <ul>
                <li class="onhover-div d-xl-none d-inline-block mobile-search">
                  <div>
                <li class="onhover-div mobile-search search-widgets" id="search-widgets">
                  <div (click)="searchToggle()">
                    <i class="ti-search"></i>
                  </div>
                  <div class="col-xl-3">
                    <div class="header-options ">
                      <div class="d-grid col-8 mx-auto">
                        <button class="btn btn-success seller-btn" [routerLink]="'/onboarding'" type="button">Become a Seller</button>
                      </div>
                    </div>
                  </div>
                  <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                    <div>
                      <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                      <div class="overlay-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-8 col-lg-8 col-xl-8 col-sm-10 col-10 state-dropdown">
                              <ng-multiselect-dropdown [placeholder]="'State'" [data]="states"
                                [settings]="dropdownSettings" (onSelect)="onChangeState($event.name)"
                                (onDropDownClose)="onDropDownClose($event)" (onDeSelect)="onDeSelect($event)">
                                <ng-template ng-multiselect-dropdown-label-tmpl>
                                  <i class="fa fa-map-marker"></i>
                                  <span>Select State</span>
                                </ng-template>
                              </ng-multiselect-dropdown>
                            </div>

                            <div class="col-md-8 col-lg-8 col-xl-8 col-sm-10 col-10 city-dropdown city-tab">
                              <ng-multiselect-dropdown [placeholder]="'City'" [data]="cities"
                                [settings]="dropdownSetting" (onSelect)="onChangeCity($event.name)"
                                (onDropDownClose)="onDropDownCloseCity($event)" (onDeSelect)="onDeSelectCity($event)">
                                <ng-template ng-multiselect-dropdown-label-tmpl> </ng-template>
                              </ng-multiselect-dropdown>
                            </div>

                            <div class="col-md-10 col-lg-10 col-xl-10 col-sm-10 col-10 mt-1 search_mobile">
                              <ng-autocomplete [data]="shops" [searchKeyword]="keyword" [placeholder]="placeholder"
                                [itemTemplate]="itemTemplateStatic" [notFoundTemplate]="notFoundTemplate"
                                (selected)="selectEvent($event)" (inputChanged)="onChangeSearch($event)"
                                (inputFocused)="onFocused($event)"></ng-autocomplete>
                              <ng-template #itemTemplateStatic let-item>
                                <a [innerHTML]="item.name"></a>
                              </ng-template>

                              <ng-template #notFoundTemplate let-notFoundShop>
                                <div [innerHTML]="notFoundShop"></div>
                              </ng-template>
                              <button type="button" class="btn btn-primary">
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
            </div>
            </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!--Menu End-->

  <div class="bottom-part">
    <div class="container">
      <div class="row">
        <div class="col-xl-3" (click)="onDropdownClick($event)">
          <div class="category-menu d-none d-xl-block d-md-block h-100">
            <div id="toggle-sidebar" class="toggle-sidebar" (click)="toggleSubMenu()">
              <i class="fa fa-bars sidebar-bar"></i>
              <h5 class="mb-0">shop's category</h5>
            </div>
          </div>
          <div class="sidenav fixed-sidebar marketplace-sidebar" #subMenu [hidden]="isSubMenuVisible">
            <nav>
              <ul class="sm pixelstrap sm-vertical hover-unset"  >
                <li *ngFor="let cat of shopType">
                  <a class="has-submenu" [id]="'sub-menu-' + cat.id"
                    [attr.aria-controls]="'sub-menu-' + cat.id + '-content'" [routerLink]="['/browse/shops']"
                    [queryParams]="{category: replaceSpecialCharWithHyphen(cat.name) }">
                    {{ cat.name | sentenceCase}}
                    <span class="sub-arrow"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-xl-3 d-none d-xl-inline-block">
          <div class="header-options ">
            <div class="d-grid col-8 mx-auto">
              <button class="btn btn-success seller-btn" [routerLink]="'/onboarding'" type="button">Become a Seller</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>