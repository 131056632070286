<app-header-three></app-header-three>

<!-- Home slider start-->
<section class="p-0">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'vegetables'" [buttonText]="'shop now'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- Services -->
<section class="banner-padding absolute-banner pb-0">
  <div class="container absolute-bg">
    <div class="service p-0 ">
      <app-services></app-services>
    </div>
  </div>
</section>
<!-- Services end -->

<!-- product section -->
<section class="section-b-space">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title4">
          <h2 class="title-inner4">trending products</h2>
          <div class="line">
            <span></span>
          </div>
        </div>
        <owl-carousel-o class="product-5 product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products">
            <ng-template carouselSlide>
              <div class="product-box product-wrap">
                <app-product-box-two [product]="product" [currency]="productService?.Currency">
                </app-product-box-two>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- product section end -->

<!-- Parallax banner -->
<div *ngIf="filteredOfferDocuments?.length > 0 ;then content else other_content"></div>
<ng-template class="p-0" #content>
  <div class="full-banner parallax-banner15 parallax text-start p-left" *ngFor="let doc of filteredOfferDocuments"
    [ngStyle]="{'background-image': 'url('+doc?.urls+')'}">
    <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferForm()">
      <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
    </a>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h2>2023</h2>
            <h3>{{doc?.textTittle}}</h3>
            <h4>{{doc?.textSubTittle}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Popup Form -->
  <div class="popup-form" *ngIf="isOfferBannerFormOpen">
    <div class="confirmation-content" [formGroup]="documentForm">
      <!-- Add a small tag for displaying messages at the top -->
      <small class="text-danger">{{ errorMessageOffer }}</small>
      <div class="container mt-3">
        <div class="row">
          <div class="card-body dropzone-custom p-0">
            <ngx-dropzone class="dropzone-border" (change)="onSelectOffer($event)">
              <ngx-dropzone-label>
                <div class="dz-message needsclick">
                  <i class="fa fa-cloud-upload"></i>
                  <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                  <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of 1920px x 1280px.
                  </div>
                </div>
              </ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of uploadOfferBanner" [removable]="true" (removed)="onRemoveOffer(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
          <div class="row justify-content-around mt-4">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
              </label>
              <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
            </div>
            <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
              <label class="col-form-label pt-0">Text Subtittle</label>
              <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                formControlName="textSubTittle">
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-success pull-right" type="submit" (click)="onSubmitOffer(documentForm)">Confirm</button>
        <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Parallax banner end -->

<!-- Parallax default banner -->
<ng-template class="p-0" #other_content>
  <div class="full-banner parallax-banner15 parallax text-start p-left"
    [ngStyle]="{'background-image': 'url(http://themes.pixelstrap.com/multikart/assets/images/parallax/15.jpg)'}">
    <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferForm()">
      <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
    </a>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <h2>2023</h2>
            <h3>food market</h3>
            <h4>special offer</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpen">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOffer($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <div class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of 1920px x 1280px.
                    </div>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBanner" [removable]="true"
                  (removed)="onRemoveOffer(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitOffer(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Parallax default banner end -->

<!-- product-box slider -->
<section>
  <div class="full-box">
    <div class="container">
      <div class="title4">
        <h2 class="title-inner4">special products</h2>
        <div class="line">
          <span></span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="theme-card center-align">
            <div class="offer-slider">
              <div class="sec-1">
                <div class="product-box2" *ngFor="let product of products | slice:0:2">
                  <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                  </app-product-box-vertical>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 center-slider">
          <div>
            <div class="offer-slider">
              <div>
                <div class="product-box product-wrap" *ngFor="let product of products | slice:1:2">
                  <app-product-box-two [product]="product" [currency]="productService?.Currency">
                  </app-product-box-two>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="theme-card center-align">
            <div class="offer-slider">
              <div class="sec-1">
                <div class="product-box2" *ngFor="let product of products | slice:2:4">
                  <app-product-box-vertical [product]="product" [currency]="productService?.Currency">
                  </app-product-box-vertical>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- product-box slider end -->

<!-- blog section start-->
<section class="blog section-b-space pt-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section end -->

<!-- Cart Open To Right Side-->
<app-cart-variation [direction]="'right'"></app-cart-variation>
<app-alert-popup></app-alert-popup>
<app-footer-one></app-footer-one>