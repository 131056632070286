import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { BlogService } from 'src/app/shared/services/blog.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-furniture',
  templateUrl: './furniture.component.html',
  styleUrls: ['./furniture.component.scss']
})
export class FurnitureComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-12.png'; // Change Logo

  public products: any[] = [];
  public productCollections: any[] = [];
  public active;
  public documentForm: FormGroup;
  public generatedShopId: string;
  public filtereBackgroundDocuments: any;
  public documents: any;
  public isBackgroundBannerFormOpen: boolean = false;
  public uploadBackgroundBanner: any;
  public errorMessageOffer: string;
  public filteredOfferDocumentOne: any;
  public uploadOfferBannerOne: any;
  public isOfferBannerFormOpenOne: boolean = false;
  public filteredOfferDocumentTwo: any;
  public uploadOfferBannerTwo: any;
  public isOfferBannerFormOpenTwo: boolean = false;
  public filteredOfferDocumentThree: any;
  public uploadOfferBannerThree: any;
  public isOfferBannerFormOpenThree: boolean = false;

  constructor(public productService: ProductService, private blogService: BlogService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public sliders = [{
    title: 'furniture sofa',
    subTitle: 'harmony sofa',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/13.jpg'
  }, {
    title: 'furniture sofa',
    subTitle: 'harmony sofa',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/12.jpg'
  }];

  // Collection banner
  public collections = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner1.jpg',
    save: 'save 50%',
    title: 'Sofa',
    link: '/home/left-sidebar/collection/furniture'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner2.jpg',
    save: 'save 50%',
    title: 'Bean Bag',
    link: '/home/left-sidebar/collection/furniture'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/furniture/2banner3.jpg',
    save: 'save 50%',
    title: 'Chair',
    link: '/home/left-sidebar/collection/furniture'
  }]

  // Blog
  public blogs: any[] = [];

  ngOnInit(): void {
    this.blogService.getBlogByShopId().subscribe((data) => {
      this.blogs = data;
    });
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#d4b196');

    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocumentOne = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-one';
      });

      this.filteredOfferDocumentTwo = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-two';
      });

      this.filteredOfferDocumentThree = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner-three';
      });
      // Filter the documents based on the identifier
      this.filtereBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  openOfferFormOne() {
    this.isOfferBannerFormOpenOne = true;
  }

  onSelectOfferOne = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerOne) {
            this.uploadOfferBannerOne = [];
          }
          this.uploadOfferBannerOne.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerOne = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormTwo() {
    this.isOfferBannerFormOpenTwo = true;
  }

  onSelectOfferTwo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerTwo) {
            this.uploadOfferBannerTwo = [];
          }
          this.uploadOfferBannerTwo.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerTwo = []; // Clear the array if the count is not equal to 2
    }
  }

  openOfferFormThree() {
    this.isOfferBannerFormOpenThree = true;
  }

  onSelectOfferThree = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One images only .';
      return; // Abort further processing
    }
    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 672 && image.height === 310) {
          if (!this.uploadOfferBannerThree) {
            this.uploadOfferBannerThree = [];
          }
          this.uploadOfferBannerThree.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 672px and height 310px.`;
        }
      };

      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }

    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = '';
      this.uploadOfferBannerThree = []; // Clear the array if the count is not equal to 2
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopupForm();
      this.documentForm.reset();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  openBackgroundForm() {
    this.isBackgroundBannerFormOpen = true;
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only .';
      return; // Abort further processing
    }

    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 1280) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 1280px`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 1280px';
      this.uploadBackgroundBanner = []; // Clear the array if the count is not equal to 2
    }
  }

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBannerOne && this.uploadOfferBannerOne.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-one");
      if (form.valid) {
        this.handleDocument(form.value, 'offer-one');
      }
    } else if (this.uploadOfferBannerTwo && this.uploadOfferBannerTwo.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-two");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-two');
      }
    } else if (this.uploadOfferBannerThree && this.uploadOfferBannerThree.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner-three");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'offer-three');
      }
    } else if (this.uploadBackgroundBanner && this.uploadBackgroundBanner.length > 0) {
      this.documentForm.get('identifier').setValue("background-banner");
      if (form.valid) {
        this.handleDocument(this.documentForm.value, 'background');
      }
    } 
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer-one':
        this.addOfferDocument(document, this.uploadOfferBannerOne);
        break;
      case 'offer-two':
        this.addOfferDocument(document, this.uploadOfferBannerTwo);
        break;
      case 'offer-three':
        this.addOfferDocument(document, this.uploadOfferBannerThree);
        break;
      case 'background':
        this.addOfferDocument(document, this.uploadBackgroundBanner);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  closePopupForm() {
    this.isOfferBannerFormOpenOne = false;
    this.isOfferBannerFormOpenTwo = false;
    this.isOfferBannerFormOpenThree = false;
    this.isBackgroundBannerFormOpen = false;
  }
}
