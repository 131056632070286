import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TenantService } from 'src/app/tenant/tenant.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;

  constructor(private tenantSrv: TenantService, private userService:UserService, private router:Router) { }

  public shop: any = [];
  public isLoggedIn: boolean = false;
 
  

  ngOnInit(): void {
    this.isLoggedIn = this.userService.isLoggedIn();
    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  public logout(): void {
    this.userService.logout();
    // After logout, navigate to the login page
    this.router.navigateByUrl("/pages/login");
  }

}
