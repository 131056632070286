<app-header-home [topSelectedCountry]="selectedCountry"></app-header-home>
<!-- Home slider start-->
<section class="body">
    <div class="container mt-3 mb-3 d-sm-none">
        <div class="row">
            <div class="col-12 col-sm-12">
                <a href="">
                    <img [src]="'assets/images/shopdial-apk.svg'" alt="Static Image 3"
                    class="img-fluid lazy-loading">
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="carouselItems" class="container mt-3">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12 fa-pull-left">
                <owl-carousel-o [options]="HomeSliderConfig" class="home-slider">
                    <ng-container *ngFor="let item of carouselItems">
                        <ng-template carouselSlide>
                            <img class="img-fluid" alt="" [src]="item.imageUrl" [alt]="item.title">
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 col-sm-12">
                <div class="col-12 col-sm-12 ">
                    <img [src]="'assets/images/banner-image-676-180/home_decor-676-180.jpg'"
                        class="img-fluid lazy-loading" alt="Static Image 2">
                </div>
                <div class="col-12 col-sm-12 mt-4">
                    <img [src]="'assets/images/banner-image-676-180/furniture.png'" alt="Static Image 3"
                        class="img-fluid lazy-loading">
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img [src]="'assets/images/big banner-images1376-321/big-fashin-banner-images1376-321.png'"
                    class="img-fluid lazy-loading home_mobile_img" alt="Static Image 2">
            </div>
        </div>
    </div>
    <div class="container mt-4 tablet-view">
        <div class="row">
            <div class="title3">
                <h2 class="title-inner3 cat_mobile_heading d-flex justify-content-center">Shop categories</h2>
                <div class="line"></div>
            </div>
            <div class="col-4 col-sm-4 col-xl-2 col-lg-2 col-md-3 mb-3" *ngFor="let type of shopType">
                <div class="card animate__animated cat-category" (mouseenter)="onCardHover(true)"
                    (mouseleave)="onCardHover(false)" [routerLink]="['browse/shops']"
                    [queryParams]="{category: replaceSpecialCharWithHyphen(type.name) }">
                    <img *ngIf="type.imageURL" [src]="type.imageURL" alt="Image" class="img-fluid card-image">
                </div>
                <div class="mt-2 text-center desk-margin_left">
                    <a class="shopName custom-hover-color" [routerLink]="['browse/shops']"
                        [queryParams]="{category: replaceSpecialCharWithHyphen(type.name) }">{{type.name}}
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="!categoryContentLoaded">
            <app-loading [inputSourceLayout]="'shopdial-home-cat.component'"></app-loading>
        </div>
    </div>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 col-md-12 col-xl-6 col-sm-12 repair-serivce">
                    <h5 class="repair-heading">Repairs & Services</h5>
                    <div class="row">
                        <div class="col-4 col-sm-4" *ngFor="let cat of serviceCategories">
                            <div class="card service-card">
                                <div class="card-body service-body">
                                    <img [src]="cat.imageURL" alt="Image" class="img-fluid cat-image pointer zoom-in"
                                        [routerLink]="['browse/shops']"
                                        [queryParams]="{category: replaceSpecialCharWithHyphen(cat.title) }">
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt_15">
                                <a class="cat-name-repair pointer custom-hover-color" [routerLink]="['browse/shops']"
                                    [queryParams]="{category: replaceSpecialCharWithHyphen(cat.title) }">
                                    {{ cat.title }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-12 col-xl-6 col-sm-12 Daily-need">
                    <h4 class="needs-heading">Daily Needs</h4>
                    <div class="row">
                        <div class="col-4" *ngFor="let cat of dailyNeedsCategories">
                            <div class="card service-card">
                                <div class="card-body service-body">
                                    <img [src]="cat.imageURL" alt="Image" class="img-fluid cat-image pointer zoom-in"
                                        [routerLink]="['browse/shops']"
                                        [queryParams]="{category: replaceSpecialCharWithHyphen(cat.title) }">
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt_15">
                                <a class="cat-name pointer custom-hover-color" [routerLink]="['browse/shops']"
                                    [queryParams]="{category: replaceSpecialCharWithHyphen(cat.title) }">
                                    {{ cat.title }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12">
                    <div class="product-box">
                        <div class="img-wrapper">
                            <div class="img-block">
                                <div class="front">
                                    <a>
                                        <img [src]="'assets/images/default_ad_676-480/perfume_default1.png'"
                                            class="img-fluid lazy-loading" alt="Not Found">
                                        <div class="back">
                                            <img [src]="'assets/images/default_ad_676-480/perfume_default.png'"
                                                class="img-fluid lazy-loading" alt="Not found">
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 fa-pull-right mt-mobile">
                    <div class="product-box">
                        <div class="img-wrapper">
                            <div class="img-block">
                                <div class="front">
                                    <a>
                                        <img [src]="'assets/images/default_ad_676-480/headphones_deault.png'"
                                            class="img-fluid lazy-loading" alt="">
                                        <div class="back">
                                            <img [src]="'assets/images/default_ad_676-480/airbuds_default_ads.png'"
                                                class="img-fluid lazy-loading" alt="">
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- end filter shop by shoptype -->

    <!-- category -->
    <section class="category-tools">
        <div class="title3">
            <h2 class="title-inner3 rank_mobile_heading">Shopdial Top Rank Shops</h2>
            <div class="line"></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <app-home-collection [class]="'pt-0'">
                    </app-home-collection>
                </div>
            </div>
        </div>
    </section>
    <!-- category end -->

    <!--default top 30 shop  based on state city -->
    <section *ngIf="shopsByCity.length == 0">
        <div class="container extra-space">
            <div class="title3">
                <h2 class="title-inner3 fontSize_mobile">City's Top 30 Shops</h2>
                <div class="line"></div>
            </div>
            <div class="row" *ngIf="contentLoaded">
                <div class="col">
                    <owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
                        <ng-container *ngFor="let shop of shopList">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <div class="img-wrapper">
                                        <div class="img-block">
                                            <div class="lable-block">
                                                <span class="lable3">new</span>
                                                <!-- <span class="lable4" >on sale</span> -->
                                            </div>
                                            <div class="front"
                                                *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                                                <a *ngIf="doc.identifier=='home-img'"
                                                    [routerLink]="['/shops/view',shop?.urlPath]">
                                                    <div *ngIf="imageLoaded; else placeholder">
                                                        <img *ngIf="i == 0" [src]="doc.urls" class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="doc.urls" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <ng-template #placeholder>
                                                        <img *ngIf="i == 0"
                                                            [src]="'assets/images/product/placeholder.jpg'"
                                                            class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="'assets/images/product/placeholder.jpg'"
                                                                class="img-fluid" alt="">
                                                        </div>
                                                    </ng-template>
                                                </a>
                                            </div>
                                            <div class="cart-info cart-wrap cart-up">
                                                <button title="Add to cart">
                                                    <i class="ti-shopping-cart"></i>
                                                </button>
                                                <a href="javascript:void(0)" title="Add to Wishlist">
                                                    <i class="ti-heart" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Quick View">
                                                    <i class="ti-search" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Compare">
                                                    <i class="ti-reload" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 class="topshopName">
                                        {{shop.name}} </h3>
                                    <!-- Rating -->
                                    <div class="pointer">
                                        <h6 class="rating">
                                            <bar-rating class="text-center pro-rating" [max]="5"
                                                [rate]="shop?.shopdialRank" [readOnly]="true">
                                            </bar-rating>
                                        </h6>
                                    </div>
                                    <div class="socal-media">
                                        <ul>
                                            <li>
                                                <a href={{shop?.socialMedia?.facebookUrl}}><i
                                                        class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.googleUrl}}><i
                                                        class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.twitterUrl}}><i
                                                        class="fa fa-times ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.instaUrl}}><i
                                                        class="fa fa-instagram ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.linkedinUrl}}><i
                                                        class="fa fa-rss ff-social" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- View -->
                                    <div class="d-flex justify-content-center">
                                        <a [routerLink]="['/shops/view',shop?.urlPath]"
                                            class="btn btn-outline CityViewButton">
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div *ngIf="!contentLoaded && !isGetAllShopApiFinished">
                <app-loading [inputSourceLayout]="'shopdial-home-city.component'"></app-loading>
            </div>
        </div>
    </section>
    <!-- top 30 shop end city -->

    <!--dynamic top 30 shop  based on state city -->
    <section *ngIf="shopsByCity.length > 0">
        <div class="title3">
            <h2 class="title-inner3 fontSize_mobile">City's Top 30 Shops</h2>
            <div class="line"></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow category-carousel">
                        <ng-container *ngFor="let shop of shopsByCity">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <div class="img-wrapper">
                                        <div class="img-block">
                                            <div class="lable-block">
                                                <span class="lable3">new</span>
                                                <!-- <span class="lable4" >on sale</span> -->
                                            </div>
                                            <div class="front"
                                                *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                                                <a *ngIf="doc.identifier=='home-img'"
                                                    [routerLink]="['/shops/view',shop?.urlPath]">
                                                    <div *ngIf="cityImageLoaded; else placeholder">
                                                        <img *ngIf="i == 0" [src]="doc.urls" class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="doc.urls" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <ng-template #placeholder>
                                                        <img *ngIf="i == 0"
                                                            [src]="'assets/images/product/placeholder.jpg'"
                                                            class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="'assets/images/product/placeholder.jpg'"
                                                                class="img-fluid" alt="">
                                                        </div>
                                                    </ng-template>
                                                </a>
                                            </div>
                                            <div class="cart-info cart-wrap cart-up">
                                                <button title="Add to cart">
                                                    <i class="ti-shopping-cart"></i>
                                                </button>
                                                <a href="javascript:void(0)" title="Add to Wishlist">
                                                    <i class="ti-heart" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Quick View">
                                                    <i class="ti-search" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Compare">
                                                    <i class="ti-reload" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="topshopName">
                                    {{shop.name}} </h3>
                                <!-- Rating -->
                                <div class="mr-33 pointer"><svg width="50%" height="50%" viewBox="0 0 1000 200">
                                        <polygon id="star9927" points="100,10 40,198 190,78 10,78 160,198"
                                            fill="#FFE372"></polygon>
                                        <defs>
                                            <clipPath id="stars9927">
                                                <use xlink:href="#star9927"></use>
                                                <use xlink:href="#star9927" x="20%">
                                                </use>
                                                <use xlink:href="#star9927" x="40%">
                                                </use>
                                                <use xlink:href="#star9927" x="60%">
                                                </use>
                                                <use xlink:href="#star9927" x="80%">
                                                </use>
                                            </clipPath>
                                        </defs>
                                        <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                            style="fill:rgb(215, 215, 215);stroke:red;stroke-width:1;height:100%;width:100%">
                                        </rect>
                                        <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                            style="fill:rgb(255, 110, 0);height:100%">
                                        </rect>
                                    </svg>
                                </div>
                                <div class="socal-media">
                                    <ul>
                                        <li>
                                            <a href={{shop?.socialMedia?.facebookUrl}}><i
                                                    class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href={{shop?.socialMedia?.googleUrl}}><i
                                                    class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href={{shop?.socialMedia?.twitterUrl}}><i class="fa fa-times ff-social"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href={{shop?.socialMedia?.instaUrl}}><i class="fa fa-instagram ff-social"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                        <li>
                                            <a href={{shop?.socialMedia?.linkedinUrl}}><i class="fa fa-rss ff-social"
                                                    aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- View -->
                                <div class="d-flex justify-content-center">
                                    <a [routerLink]="['/shops/view',shop?.urlPath]"
                                        class="btn btn-outline CityViewButton">
                                        View More
                                    </a>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div *ngIf="!cityContentLoaded">
                <app-loading [inputSourceLayout]="'shopdial-home-city.component'"></app-loading>
            </div>
        </div>
    </section>

    <!-- top 30 shop end city -->

    <!--default top 30 shop  based on state start -->
    <section *ngIf="shopsByState.length == 0">
        <div class="container">
            <div class="title3">
                <h2 class="title-inner3 fontSize_mobile">State's Top 30 Shops</h2>
                <div class="line"></div>
            </div>
            <div class="row" *ngIf="contentLoaded">
                <div class="col">
                    <owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
                        <ng-container *ngFor="let shop of shopList">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <div class="img-wrapper">
                                        <div class="img-block">
                                            <div class="lable-block">
                                                <span class="lable3">new</span>
                                                <!-- <span class="lable4" >on sale</span> -->
                                            </div>
                                            <div class="front"
                                                *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                                                <a *ngIf="doc.identifier=='home-img'"
                                                    [routerLink]="['/shops/view',shop?.urlPath]">
                                                    <div *ngIf="imageLoaded; else placeholder">
                                                        <img *ngIf="i == 0" [src]="doc.urls" class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="doc.urls" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <ng-template #placeholder>
                                                        <img *ngIf="i == 0"
                                                            [src]="'assets/images/product/placeholder.jpg'"
                                                            class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="'assets/images/product/placeholder.jpg'"
                                                                class="img-fluid" alt="">
                                                        </div>
                                                    </ng-template>
                                                </a>
                                            </div>

                                            <div class="cart-info cart-wrap cart-up">
                                                <button title="Add to cart">
                                                    <i class="ti-shopping-cart"></i>
                                                </button>

                                                <a href="javascript:void(0)" title="Add to Wishlist">
                                                    <i class="ti-heart" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Quick View">
                                                    <i class="ti-search" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Compare">
                                                    <i class="ti-reload" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="topshopName">
                                        {{shop.name}} </h3>

                                    <!-- Rating -->
                                    <div class="pointer">
                                        <h6 class="rating">
                                            <bar-rating class="text-center pro-rating" [max]="5"
                                                [rate]="shop?.shopdialRank" [readOnly]="true">
                                            </bar-rating>
                                        </h6>
                                    </div>
                                    <div class="socal-media-state">
                                        <ul>
                                            <li>
                                                <a href={{shop?.socialMedia?.facebookUrl}}><i
                                                        class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.googleUrl}}><i
                                                        class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.twitterUrl}}><i
                                                        class="fa fa-times ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.instaUrl}}><i
                                                        class="fa fa-instagram ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.linkedinUrl}}><i
                                                        class="fa fa-rss ff-social" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- View -->
                                    <div class="d-flex justify-content-center">
                                        <a [routerLink]="['/shops/view',shop?.urlPath]"
                                            class="btn btn-outline StateViewButton">
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div *ngIf="!contentLoaded">
                <app-loading [inputSourceLayout]="'shopdial-home-state.component'"></app-loading>
            </div>
        </div>
    </section>
    <!--default top 30 shop end state -->

    <!-- top 30 shop  based on state start -->
    <section *ngIf="shopsByState.length > 0">
        <div class="container">
            <div class="title3">
                <h2 class="title-inner3 fontSize_mobile">State's Top 30 Shops</h2>
                <div class="line"></div>
            </div>
            <div class="row">
                <div class="col">
                    <owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
                        <ng-container *ngFor="let shop of shopsByState">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <div class="img-wrapper">
                                        <div class="img-block">
                                            <div class="lable-block">
                                                <span class="lable3">new</span>
                                                <!-- <span class="lable4" >on sale</span> -->
                                            </div>
                                            <div class="front"
                                                *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                                                <a *ngIf="doc.identifier=='home-img'"
                                                    [routerLink]="['/shops/view',shop?.urlPath]">
                                                    <div *ngIf="stateImageLoaded; else placeholder">
                                                        <img *ngIf="i == 0" [src]="doc.urls" class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="doc.urls" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <ng-template #placeholder>
                                                        <img *ngIf="i == 0"
                                                            [src]="'assets/images/product/placeholder.jpg'"
                                                            class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="'assets/images/product/placeholder.jpg'"
                                                                class="img-fluid" alt="">
                                                        </div>
                                                    </ng-template>
                                                </a>
                                            </div>

                                            <div class="cart-info cart-wrap cart-up">
                                                <button title="Add to cart">
                                                    <i class="ti-shopping-cart"></i>
                                                </button>

                                                <a href="javascript:void(0)" title="Add to Wishlist">
                                                    <i class="ti-heart" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Quick View">
                                                    <i class="ti-search" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Compare">
                                                    <i class="ti-reload" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="topshopName">
                                        {{shop.name}} </h3>

                                    <!-- Rating -->
                                    <div class="mr-33 pointer"><svg width="50%" height="50%" viewBox="0 0 1000 200">
                                            <polygon id="star9927" points="100,10 40,198 190,78 10,78 160,198"
                                                fill="#FFE372"></polygon>
                                            <defs>
                                                <clipPath id="stars9927">
                                                    <use xlink:href="#star9927"></use>
                                                    <use xlink:href="#star9927" x="20%">
                                                    </use>
                                                    <use xlink:href="#star9927" x="40%">
                                                    </use>
                                                    <use xlink:href="#star9927" x="60%">
                                                    </use>
                                                    <use xlink:href="#star9927" x="80%">
                                                    </use>
                                                </clipPath>
                                            </defs>
                                            <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                style="fill:rgb(215, 215, 215);stroke:red;stroke-width:1;height:100%;width:100%">
                                            </rect>
                                            <rect width="100%" height="100%" clip-path="url(#stars9927)"
                                                style="fill:rgb(255, 110, 0);height:100%">
                                            </rect>
                                        </svg>
                                    </div>
                                    <div class="socal-media-state">
                                        <ul>
                                            <li>
                                                <a href={{shop?.socialMedia?.facebookUrl}}><i
                                                        class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.googleUrl}}><i
                                                        class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.twitterUrl}}><i
                                                        class="fa fa-times ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.instaUrl}}><i
                                                        class="fa fa-instagram ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.linkedinUrl}}><i
                                                        class="fa fa-rss ff-social" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- View -->
                                    <div class="d-flex justify-content-center">
                                        <a [routerLink]="['/shops/view',shop?.urlPath]"
                                            class="btn btn-outline StateViewButton">
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div *ngIf="!stateContentLoaded">
                <app-loading [inputSourceLayout]="'shopdial-home-state.component'"></app-loading>
            </div>
        </div>
    </section>

    <!-- top 30 shop  based on Country start -->
    <section>
        <div class="container">
            <div class="title3">
                <h2 class="title-inner3 fontSize_mobile">Country's Top 30 Shops</h2>
                <div class="line"></div>
            </div>
            <div class="row" *ngIf="countryContentLoaded">
                <div class="col">
                    <owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
                        <ng-container *ngFor="let shop of shopsByCountry">
                            <ng-template carouselSlide>
                                <div class="product-box">
                                    <div class="img-wrapper">
                                        <div class="img-block">
                                            <div class="lable-block">
                                                <span class="lable3">new</span>
                                            </div>
                                            <div class="front"
                                                *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                                                <a *ngIf="doc.identifier=='home-img'"
                                                    [routerLink]="['/shops/view',shop?.urlPath]">
                                                    <div *ngIf="countryImageLoaded; else placeholder">
                                                        <img *ngIf="i == 0" [src]="doc.urls" class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="doc.urls" class="img-fluid" alt="">
                                                        </div>
                                                    </div>
                                                    <ng-template #placeholder>
                                                        <img *ngIf="i == 0"
                                                            [src]="'assets/images/product/placeholder.jpg'"
                                                            class="img-fluid" alt="">
                                                        <div class="back" *ngIf="i == 1">
                                                            <img [src]="'assets/images/product/placeholder.jpg'"
                                                                class="img-fluid" alt="">
                                                        </div>
                                                    </ng-template>
                                                </a>
                                            </div>
                                            <div class="cart-info cart-wrap cart-up">
                                                <button title="Add to cart">
                                                    <i class="ti-shopping-cart"></i>
                                                </button>
                                                <a href="javascript:void(0)" title="Add to Wishlist">
                                                    <i class="ti-heart" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Quick View">
                                                    <i class="ti-search" aria-hidden="true"></i>
                                                </a>
                                                <a href="javascript:void(0)" title="Compare">
                                                    <i class="ti-reload" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="topshopName">
                                        {{shop.name}} </h3>
                                    <!-- Rating -->
                                    <div class="pointer">
                                        <h6 class="rating">
                                            <bar-rating class="text-center pro-rating" [max]="5"
                                                [rate]="shop?.shopdialRank" [readOnly]="true">
                                            </bar-rating>
                                        </h6>
                                    </div>
                                    <div class="socal-media-country">
                                        <ul>
                                            <li>
                                                <a href={{shop?.socialMedia?.facebookUrl}}><i
                                                        class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.googleUrl}}><i
                                                        class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.twitterUrl}}><i
                                                        class="fa fa-times ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.instaUrl}}><i
                                                        class="fa fa-instagram ff-social" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href={{shop?.socialMedia?.linkedinUrl}}><i
                                                        class="fa fa-rss ff-social" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- View -->
                                    <div class="d-flex justify-content-center">
                                        <a [routerLink]="['/shops/view',shop?.urlPath]"
                                            class="btn btn-outline CountryViewButton">
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
            <div *ngIf="!countryContentLoaded && !isCountryApiFinished">
                <app-loading [inputSourceLayout]="'shopdial-home-country.component'"></app-loading>
            </div>
        </div>
    </section>
    <!-- top 30 shop end -->

    <div class="container">
        <div class="row py-5">
            <div class="col-12 col-lg-12 col-sm-12 col-md-12">
                <h3 class="brand-color">Explore more from<strong class="premium-color"> Premium</strong>
                    Brands</h3>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <owl-carousel-o [options]="logoSliderConfig">
                <ng-container>
                    <ng-template carouselSlide>
                        <div class="col-md-12 mb-3">
                            <app-logo [logos]="logos"></app-logo>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>

<app-newsletter></app-newsletter>
<app-home-footer></app-home-footer>