import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable } from 'rxjs';
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-home-setting',
  templateUrl: './home-setting.component.html',
  styleUrls: ['./home-setting.component.scss']
})
export class HomeSettingComponent implements OnInit {
  public products: any = [];
  public stick: boolean = false;
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild('searchbar') searchbar: ElementRef;

  public countries: any;
  public states: any;
  public cities: any;
  public topSelectedState: any;
  public topSelectedCity: any;
  public shops: any = [];

  selectedTasks = [];
  dropdownSettings: IDropdownSettings;

  selectedCity = [];
  dropdownSetting: IDropdownSettings;

  public placeholder = 'Search any shop you want...';
  public keyword = 'name';
  public search: boolean = false;

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService, private countryStateCitySrv: LocationService, private homeSrv: HomepageService, private router: Router) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {

    if (history.state.shop != null) {
      this.shops = history.state.shop;
    }

    //get countries
    this.countryStateCitySrv.getAllCountries().subscribe((data: any) => {
      this.countries = data;
    });

    this.onChangeCountry();

    this.dropdownSettings = {
      singleSelection: true,
      textField: 'name',
      // selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };


    this.dropdownSetting = {
      singleSelection: true,
      textField: 'city',
      // selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      maxHeight: 197,
      closeDropDownOnSelection: false,
      allowSearchFilter: true
    };
  }

  onItemSelectCity(item: any) { }

  OnItemDeSelectCity(item: any) { }

  onDeSelectAllCity(items: any) { }

  public onDropDownCloseCity(item: any) { }

  public onDeSelectCity(item: any) { }

  onClickCity(item: any) { }

  OnItemDeSelect(item: any) { }

  onSelectAll(items: any) { }

  onDeSelectAll(items: any) { }

  public onDropDownClose(item: any) { }

  public onDeSelect(item: any) { }

  onClick(item: any) { }



  /**
   * This function is called when the country selection changes.
   * It retrieves the states for the selected country and performs
   * some additional actions.
   */
  public onChangeCountry() {
    // Retrieve the states for the selected country
    this.countryStateCitySrv.getStatesByCountriesName().subscribe(
      data => {
        this.states = data;
        this.countryStateCitySrv.getTopShopByCountry();
      }
    );
  }

  /**
   * Updates the selected state and retrieves cities based on the state name.
   * If the state name is empty, sets the cities to null.
   * @param name - The name of the state.
   */
  public onChangeState(name: any) {
    // Update the selected state
    this.topSelectedState = name;
    if (name) {
      // Retrieve cities based on the state name
      this.countryStateCitySrv.getCitiesByStateName(name).subscribe(
        data => {
          this.cities = data;
        }
      );
      // Retrieve top shop by state
      this.countryStateCitySrv.getTopShopByState(this.topSelectedState);
    } else {
      // If the state name is empty, set cities to null
      this.cities = null;
    }
  }

  /**
   * Updates the selected city and retrieves the top shops by city.
   * If no city is selected, clears the list of cities.
   * 
   * @param name - The name of the selected city.
   */
  public onChangeCity(name: any) {
    this.topSelectedCity = name;
    if (name) {
      this.countryStateCitySrv.getTopShopByCity(this.topSelectedCity);
    } else {
      this.cities = null;
    }
  }

  changeLanguage(code: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  searchText = '';
  toggleSearch: boolean = false;


  openSearch() {
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }

  selectEvent(item) {
    this.router.navigate(['shops/search/', item.name], { queryParamsHandling: 'merge' });
  }

  onChangeSearch(name: string) {
    this.homeSrv.shopByName(name).subscribe((data: any) => {
      this.shops = data;
    });
  }

  onFocused(e) {
    // do something when input is focused
  }

  searchToggle() {
    this.search = !this.search;
  }

}
