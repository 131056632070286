import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class UserService {

    private userUrl = environment.base_url_user_service;

    constructor(private httpClient: HttpClient, private httpClient2: HttpClient) { }

    public registerUser(user: any, files: any): Observable<any> {
      const formData: FormData = new FormData();
      // Append the shop object as a JSON string
      formData.append("user_data", new Blob([JSON.stringify(user)], { type: "application/json" }));
      // for (let i = 0; i < files.length; i++) {
      //     formData.append("user_docs", files[i], files[i].name);
      // }
      return this.httpClient.post(`${this.userUrl}/register`,  formData);
  }

  public updateUser(user: any, files: any): Observable<any> {
    const formData: FormData = new FormData();
    // Append the shop object as a JSON string
    formData.append("user_data", new Blob([JSON.stringify(user)], { type: "application/json" }));
    // for (let i = 0; i < files.length; i++) {
    //     formData.append("user_docs", files[i], files[i].name);
    // }
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
    return this.httpClient2.put(`${this.userUrl}/user`,  formData , { headers: headers});
}
  
    public signInUser(user: any) {
      return this.httpClient.post(`${this.userUrl}/login`, user);
    }

    public getUserByUsername(): any {
    const token = sessionStorage.getItem('TOKEN');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set your desired content type
      'Authorization': `Bearer ${token}` // Set your authorization token if needed
    });
      // return observable
      return this.httpClient2.get(`${this.userUrl}/user?username=${this.getUsername()} `, { headers: headers });
  }
  
    public isLogin(): boolean {
      const isLoggedIn = this.getRoles() && sessionStorage.getItem('TOKEN') != null;
      if (isLoggedIn) {
        this.autoLogoutIfTokenExpired();
      }
      return isLoggedIn;
    }

    public isLoggedIn(): boolean {
      const isLoggedIn = sessionStorage.getItem('ROLES') && sessionStorage.getItem('TOKEN') != null;
      if (isLoggedIn) {
        this.autoLogoutIfTokenExpired();
      }
      return isLoggedIn;
    }

    public logout(): any {
      sessionStorage.setItem('TOKEN', '');
      sessionStorage.setItem('ROLES', '');
    }

    public setRoles(roles: []): any {
      sessionStorage.setItem('ROLES', JSON.stringify(roles));
    }
  
    public setToken(jwtToken: string) {
      sessionStorage.setItem('TOKEN', jwtToken);
    }

    public getToken(): any {
      return sessionStorage.getItem('TOKEN');
    }

    public getRoles(): any {
      return JSON.parse(sessionStorage.getItem('ROLES'));
    }
  
    public getTenantId(): any {
      return sessionStorage.getItem('TENANTID');
    }

    public getTenantIdFromLocalStorage(): any {
      return localStorage.getItem("TENANTID");
    }
    public getUsernameFromLocalStorage(): any {
      return localStorage.getItem("USERNAME");
    }
    public getPasswordFromLocalStorage(): any {
      return localStorage.getItem("PASSWORD");
    }
    public getRememberMeFromLocalStorage(): any {
      return localStorage.getItem("REMEMBER_ME");
    }

    public getShopInfo(): any {
      return sessionStorage.getItem('shopInfo');
    }

    public getUsername(): any {
      return sessionStorage.getItem('USERNAME');
  }

    // public roleMatch(allowedRoles: any): boolean {
    //   let isMatch = false;
    //   const userRoles: any = this.getRoles();
    //   return allowedRoles.some((ai: any) => userRoles.includes(ai));
    // }
  
    //auto logout user
    public isTokenExpired(): boolean {
      const token = this.getToken();
      if (!token) {
        return true; // Token is not available, consider it as expired
      }
  
      // Parse the JWT token to get the expiration timestamp
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationDate = new Date(tokenPayload.exp * 1000); // Convert to milliseconds
  
      // Compare the expiration date with the current date
      return expirationDate <= new Date();
    }
  
    // Add a method to handle auto-logout if the token is expired
    public autoLogoutIfTokenExpired(): void {
      if (this.isTokenExpired()) {
        this.logout();
      }
    }
  }