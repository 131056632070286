<app-header-home [sticky]="true"></app-header-home>
<section class="faq-section">
    <div class="container">
        <div class="row">
            <div class="card-div d-flex flex-column">
                <h2 class="font-fam FAQs-text">Frequently Asked Questions</h2>
                <span class="d-block text-center px-3 font-fam color555">If you are new to Q-Answer or looking for
                    answer to your
                    questions. this guide will <br> help you to learn more about our service and their features.</span>
            </div>

            <div class="px-1">
                <div class="search">
                    <i class="fa fa-search"></i>
                    <input type="text" class="form-control color555" placeholder="Have a question? Ask Now">
                    <button class="btn btn-primary font-fam">Search</button>
                </div>

            </div>
            <div class="col-sm-12">
                <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion theme-accordion color111"
                    id="accordionExample">
                    <ngb-panel id="static-1" title="1. What is a customer onboarding process">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">
                                A good customer onboarding process is designed to provide your customers with a clear,
                                easy-to-follow guide of what they need to do in order to get the most out of their
                                product. A successful customer onboarding process can help new users save time and money
                                by eliminating the guesswork when it comes to getting started</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2" title="2. Why do I need to fill out the information requested?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">
                                We will always keep your personal information safe.
                                We ask for your information in exchange for a valuable resource in order to <br>
                                (a) improve your browsing experience by personalizing the HubSpot site to your needs
                                <br>
                                (b) send information to you that we think may be of interest to you by email or
                                othermeansbr <br>
                                (c) send you marketing communications that we think may be of value to you. You can read
                                more about our privacy policy here.
                            </p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3" title="3. Why should I join Shopdial?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">Shopdial is an exclusive B2B portal that offers a new
                                wholesale experience.
                                It connects bulk buyers with wholesale sellers across product categories. As a seller,
                                you will have many prospects of discovering quality vendors offering several products
                                spread across millions of business domains for all your B2B needs.</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-4"
                        title="4. I have a huge range of products to offer. Can I show them all on Shopdial?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">
                                Absolutely! Shopdial imposes no cap on the number of products that a seller can
                                display.</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-5"
                        title="5. Is it important to mention all the fields during my product listing?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">
                                Yes, we highly recommend that you fill in as many fields as possible while listing your
                                products on Shopdial. The following fields—Product Name, Product Category, Price,
                                Minimum Order Quantity and Specifications—are mandatory to fill.</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-6"
                        title="6. What are the guidelines that should be followed by new businesses registering on Shopdial?">
                        <ng-template ngbPanelContent>
                            <p class="mb-0 font-fam color555">
                                Since Shopdial is exclusively a B2B portal, newly registered businesses should follow
                                the guidelines mentioned below:
                                <br>
                                1. Provide complete data of business and owner. <br>
                                2. Select keywords that are relatable to your business (B2B keywords are important).<br>
                                3. Select areas where you specifically want to approach buyers.<br>
                                4. Upload proper images of your business.<br>
                                5. Submit the right documents for authentication.
                            </p>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</section>
<!-- </section> -->
<!--Section ends-->

<app-home-footer [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-home-footer>