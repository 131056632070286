<app-header-home></app-header-home>
<!-- <app-breadcrumb ></app-breadcrumb> -->
<div class="title3 adjust" *ngFor="let list of searchResult">
    <h2 class="title-inner3 text_capitalize">Explore Shop By Name : {{list.name}} </h2>
    <div class="line"></div>
</div>
<section class="section-b-space up-section">
    <div class="collection-wrapper ratio_asos">
        <div class="container" *ngIf="showShopBySearch">
            <div class="row" *ngFor="let list of searchResult">
                <div class="col-lg-6 col-sm-12">
                    <div class="row product_image_4">
                        <div class="col-6"
                            *ngFor="let doc of searchResult?.documents | docfilter : 'home-img'">
                            <div class="bg-size blur-up lazyloaded">
                                <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
                                    [lazyLoad]="ImageSrc ? ImageSrc : doc?.urls" [src]="doc?.urls" alt="Not Found"
                                    class="img-fluid blur-up lazyloaded img-heigth border_radius">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 rtl-text">
                    <div class="card">
                        <div class="card-body">
                            <div class="product-right">
                                <div class="product-count">
                                    <div class="d-flex bd-highlight">
                                        <div class="flex-grow-1 bd-highlight">
                                            <h2 class="text_capitalize">
                                                <span class="pointer color111 font23"
                                                    [routerLink]="['/shops/view', list.urlPath]">{{list.name}}</span>
                                            </h2>
                                        </div>
                                        <div class="bd-highlight">
                                            <span class="badge badge-grey-color assured-text">Assured</span>
                                        </div>
                                      </div>
                                    <ul>
                                        <li>
                                            <img src="assets/images/homepage-img/fire.gif" class="img-fluid" alt="image">
                                            <span class="p-counter">37</span>&nbsp;
                                            <span class="lang color555">Orders in last 24 hours</span>
                                        </li>
                                        <li class="mt-10-mob">
                                            <img src="assets/images/homepage-img/person.gif" class="img-fluid"
                                                alt="image">
                                            <span class="p-counter">44</span>&nbsp;
                                            <span class="lang color555">Active view this</span>
                                        </li>
                                    </ul>
                                </div>
                                <i class="fa fa-hourglass-half fa-lg"></i>
                                <span class="shopTime color555"> Opens at : {{list.openingTime}} &nbsp; Close at :
                                    {{list.closingTime}}
                                </span>
                                <div class="rating-section">
                                    <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                            class="fa fa-star"></i> <i class="fa fa-star"></i> <i class="fa fa-star"></i>
                                    </div>
                                    <h6>{{list.shopdialRank}}.0 Rating</h6>
                                </div>
                                <div class="product-buttons mt-3">
                                    <a class="btn btn-light ft-16" (click)="showPopupForm()">
                                        <i class="fa fa-phone " aria-hidden="true"></i>
                                        {{list.contact | mask:6}}
                                    </a>
                                    <a [routerLink]="['/shops/view', list.urlPath]"
                                        class="btn btn-light text_capitalize ft-16">View more</a>
                                </div>
                                <div class="border-product mt-3 mb-3">
                                    <h6 class="product-title color111">Description</h6>
                                    <p class="color555 description_font2">{{list.description | sentenceCase }}</p>
                                </div>
                                <div class="border-product">
                                    <h6 class="product-title color111">share it</h6>
                                    <div class="product-icon">
                                        <ul class="footer-social">
                                            <li>
                                                <a href="https://www.facebook.com/">
                                                    <i class="fa fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://plus.google.com/discover">
                                                    <i class="fa fa-google-plus mr-left-social"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/">
                                                    <i class="fa fa-times mr-left-social"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/">
                                                    <i class="fa fa-instagram mr-left-social"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    
                </div>
            </div>
        </div>
        <div *ngIf="!showShopBySearch">
            <app-loading [inputSourceLayout]="'shop-search.component'"></app-loading>
        </div>
    </div>

    <!-- popup form  start-->
    <div id="popupForm" class="popup-form">
        <form [formGroup]="createShopInquiryForm" autocomplete="off">
            <i class="fa fa-times fa-pull-right fonts-icon" aria-hidden="true" (click)="closePopupForm()"
                style="cursor: pointer;"></i>
            <div class="sd_modal_header pl-80">
                <div class=" modal_head_center">
                    <div class="sdwrapper font22 fw500 color111 text_left info_mobile">Get information
                        by SMS/Email</div>
                </div>
            </div>
            <div class="form-group mt-20">
                <input type="text" class="form-control" id="name" aria-describedby="emailHelp" placeholder="Name"
                    name="name" formControlName="name">
                <div *ngIf="hasError('name')" [class]="hasError('name') ? 'error' : ''">
                    <div *ngIf="name?.errors">
                        <span *ngIf="name?.errors?.['required']">Name is a required field.</span>
                    </div>
                </div>
            </div>

            <div class="form-group mt-20">
                <input type="text" class="form-control" id="phoneNo" placeholder="Mobile number" name="phoneNo"
                    formControlName="phoneNo" minlength="10" maxlength="10" pattern="[0-9]*">
                <div *ngIf="hasError('phoneNo')" [class]="hasError('phoneNo') ? 'error' : ''">
                    <div *ngIf="phoneNo?.errors">
                        <span *ngIf="phoneNo?.errors?.['required']"> Phone number is a required field.</span>
                        <span *ngIf="!phoneNo?.errors?.['required'] && phoneNo?.errors?.['minlength']"> Phone
                            number should
                            be at least 10 characters long.</span>
                        <span
                            *ngIf="!phoneNo?.errors?.['required'] && !phoneNo?.errors?.['minlength'] && phoneNo?.errors?.['pattern']">
                            Phone number should contain only numeric characters.</span>
                    </div>
                </div>
            </div>

            <div class="form-group mt-20">
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Email" name="email" formControlName="email">
                <div *ngIf="hasError('email')" [class]="hasError('email') ? 'error' : ''">
                    <div *ngIf="email?.errors">
                        <span *ngIf="email?.errors?.['required']">Email is a required field.</span>
                        <span *ngIf="email?.errors?.['email']">Email should be email type</span>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary"
                (click)="onSubmitInquiry(createShopInquiryForm)">Submit</button>
        </form>
    </div>
    <!-- popup form  end-->

</section>

<app-cart-variation [direction]="'top'"></app-cart-variation>
<app-home-footer [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-home-footer>