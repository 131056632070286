<app-header-three [themeLogo]="themeLogo"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
</app-header-three>

<!-- Home slider start-->
<section class="p-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <app-slider [sliders]="sliders" [textClass]="'text-center'" [category]="'shoes'" [buttonClass]="'black-btn'"
    [buttonText]="'shop now'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- Categories start-->
<div class="container"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <section class="section-b-space border-section border-top-0">
    <div class="row">
      <div class="col">
        <div class="slide-6 no-arrow">
          <owl-carousel-o [options]="CategorySliderConfig">
            <ng-container *ngFor="let category of filterByCategory">
              <ng-template carouselSlide>
                <div class="category-block">
                  <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: category}" *ngFor="let doc of documentByCategory">
                    <div class="category-image" *ngFor="let url of doc">
                      <img *ngIf="doc.length > 0" [src]="url.urls" alt="" class="w-auto" >
                    </div>
                  </a>
                  <div class="category-details">
                    <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: category}">
                      <h5>{{ category }}</h5>
                    </a>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- categories End -->

<!-- about section-->
<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="title3">
          <h4>about us</h4>
          <h2 class="title-inner3">welcome to {{shop.name}}</h2>
          <div class="line"></div>
        </div>
        <div class="about-text">
          <p>
            {{shop.description | stripHtml}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- about section end -->

<!-- collection banner start-->
<div class="container mt-3" *ngIf="!isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="row partition2">
    <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then banner_one else default_banner_one"></div>
    <!-- Offer Banner One -->
    <ng-template class="pb-0" #banner_one>
      <div class="col-md-6" *ngFor="let offer of filteredOfferDocumentsOne; let i = index">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner p-right text-center" *ngIf="!isOfferBannerFormOpenTwo">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img [src]="offer.urls" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div>
                <h4 class="text-white">{{offer?.textSubTittle}}</h4>
                <h2 style="color: #38ffbb;">{{offer?.textTittle}}</h2>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-template>

    <ng-template class="pb-0" #default_banner_one>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner p-right text-center" *ngIf="!isOfferBannerFormOpenTwo">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img src="http://themes.pixelstrap.com/multikart/assets/images/sub-banner.png" class="img-fluid"
              alt="banner">
            <div class="contain-banner">
              <div>
                <h4 class="text-white">save 50%</h4>
                <h2>men</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerOne,f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close -->
    <!-- Offer Banner One -->
    <!-- Offer Banner Two -->
    <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then banner_two else default_banner_two"></div>
    <ng-template class="pb-0" #banner_two>
      <div class="col-md-6" *ngFor="let offer of filteredOfferDocumentsTwo; let i = index">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner p-right text-center">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img [src]="offer.urls" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div class="text-overlay">
                <h4 class="text-white">{{offer?.textSubTittle}}</h4>
                <h2>{{offer?.textTittle}}</h2>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="pb-0" #default_banner_two>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner p-right text-center">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img src="http://themes.pixelstrap.com/multikart/assets/images/sub-banner1.png" class="img-fluid"
              alt="banner">
            <div class="contain-banner">
              <div>
                <h4 class="text-white">save 50%</h4>
                <h2>women</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerTwo,f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit" (click)="onSubmitForm(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close -->
    <!-- Offer Banner Two -->
  </div>
</div>
<!-- collection banner End -->

<!-- Product slider start-->
<section class="section-b-space p-t-3"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="title3">
      <h4>our collection</h4>
      <h2 class="title-inner3">special products</h2>
      <div class="line"></div>
    </div>
    <div class="row">
      <div class="col">
        <owl-carousel-o class="product-4 product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-product-box-four [product]="product" [currency]="productService?.Currency"
                  [onHowerChangeImage]="true" [cartModal]="true">
                </app-product-box-four>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- Product slider End -->


<!-- ---------------------------------------------------------------------------------------------- -->

<!--content collection 2 banner start -->
<section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
  <div class="container-fluid">
    <div class="row category-border">
      <div *ngIf="fileterCategoryBannerOne?.length > 0 ;then category_one else default_category_one"></div>
      <ng-template class="pb-0" #category_one>
        <div class="col-sm-4 border-padding" *ngFor="let offer of fileterCategoryBannerOne; let i = index">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
            <div>
              <img [src]="offer.urls" class="img-fluid" alt="">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>{{offer?.textTittle}}</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="pb-0" #default_category_one>
        <div class="col-sm-4 border-padding">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
            <div>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/shoes/cat1.jpg" class="img-fluid" alt="">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerOne()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>men</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isCategoryBannerFormOpenOne">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryOne($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        672px X 310px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadCategoryBannerOne" [removable]="true"
                    (removed)="onRemoveItem(uploadCategoryBannerOne,f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Close -->
      <div *ngIf="fileterCategoryBannerTwo?.length > 0 ;then category_two else default_category_two"></div>
      <ng-template class="pb-0" #category_two>
        <div class="col-sm-4 border-padding" *ngFor="let offer of fileterCategoryBannerTwo; let i = index">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenThree">
            <div>
              <img [src]="offer.urls" class="img-fluid" alt="Not Found">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>{{offer?.textTittle}}</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="pb-0" #default_category_two>
        <div class="col-sm-4 border-padding">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenThree">
            <div>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/shoes/cat2.jpg" class="img-fluid" alt="">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerTwo()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>women</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isCategoryBannerFormOpenTwo">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryTwo($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        672px X 310px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadCategoryBannerTwo" [removable]="true"
                    (removed)="onRemoveItem(uploadCategoryBannerTwo,f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Close -->

      <div *ngIf="fileterCategoryBannerThree?.length > 0 ;then category_three else default_category_three"></div>
      <ng-template class="pb-0" #category_three>
        <div class="col-sm-4 border-padding" *ngFor="let offer of fileterCategoryBannerThree; let i = index">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenThree">
            <div>
              <img [src]="offer.urls" class="img-fluid" alt="">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>{{offer?.textTittle}}</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template class="pb-0" #default_category_three>
        <div class="col-sm-4 border-padding">
          <div class="category-banner"
            *ngIf="!isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenThree">
            <div>
              <img src="http://themes.pixelstrap.com/multikart/assets/images/shoes/cat3.jpg" class="img-fluid" alt="">
            </div>
            <div class="category-box">
              <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openCategoryBannerThree()">
                <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
              </a>
              <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ category: 'shoes'}">
                <h2>kids</h2>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isCategoryBannerFormOpenThree">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectCategoryThree($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                      <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                        672px X 310px.</small>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadCategoryBannerThree" [removable]="true"
                    (removed)="onRemoveItem(uploadCategoryBannerThree,f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                    Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitForm(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closePopupForm()">Cancel</button>
          </div>
        </div>
      </div>
      <!-- Popup Form Close -->
    </div>
  </div>
</section>
<!--content collection 2 banner start -->

<!-- -------------------------------------------------------------------------------------------------- -->

<!-- product slider -->
<section class="section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="row multiple-slider">
      <div class="col-lg-3 col-sm-6">
        <app-product-box-vertical-slider [title]="'New product'" [type]="'shoes'">
        </app-product-box-vertical-slider>
      </div>
      <div class="col-lg-3 col-sm-6">
        <app-product-box-vertical-slider [title]="'Sell product'" [type]="'shoes'">
        </app-product-box-vertical-slider>
      </div>
      <div class="col-lg-3 col-sm-6">
        <app-product-box-vertical-slider [title]="'Feature Product'" [type]="'shoes'">
        </app-product-box-vertical-slider>
      </div>
      <div class="col-lg-3 col-sm-6">
        <app-product-box-vertical-slider [title]="'Best Product'" [type]="'shoes'">
        </app-product-box-vertical-slider>
      </div>
    </div>
  </div>
</section>
<!-- product slider end -->

<!-- category size -->
<section class="p-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="row background">
      <div class="col" *ngFor="let size of filterBySize">
        <a [routerLink]="['/shop/collection/left/sidebar']" [queryParams]="{ size: size}">
          <div class="contain-bg">
            <h4>{{size}}</h4>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- category size end-->

<!-- Tab product start-->
<div
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="title1 section-t-space">
    <h4>exclusive products</h4>
    <h2 class="title-inner1">special products</h2>
  </div>
</div>
<section class="section-b-space p-t-0"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
          <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
            <a ngbNavLink>
              {{ collection }}
            </a>
            <ng-template ngbNavContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                  <div class="product-box">
                    <app-product-box-three [product]="product" [currency]="productService?.Currency">
                    </app-product-box-three>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</section>
<!-- Tab product End -->

<!-- blog section start-->
<section class="blog blog-bg section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!-- service -->
<div class="container"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <section class="service border-section small-section border-top-0">
    <app-services></app-services>
  </section>
</div>
<!-- service end -->


<!--  logo section start-->
<section class="section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logos"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End-->
<app-alert-popup
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree"></app-alert-popup>
<app-footer-three
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isCategoryBannerFormOpenOne && !isCategoryBannerFormOpenTwo && !isCategoryBannerFormOpenThree"
  [themeLogo]="themeLogo" [subFooter]="true"></app-footer-three>