import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBlogId',
})
export class FilterByBlogIdPipe implements PipeTransform {
  transform(documents: any[], blogId: number): any[] {
    if (!documents || !blogId) {
      return [];
    }
    return documents.filter((doc) => doc.blogId === blogId);
  }
}