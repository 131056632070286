import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ShopTypeService } from '../../services/shoptype.service';

@Component({
  selector: 'app-home-right-menu',
  templateUrl: './home-right-menu.component.html',
  styleUrls: ['./home-right-menu.component.scss']
})
export class HomeRightMenuComponent implements OnInit {

  public menuItemss: any = [];
  public menuItems: Menu[];
  shopType: any;

  constructor(private router: Router, public navServices: NavService, public shopTypeSrv: ShopTypeService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {

    //get All shopType
    this.getAllShopType();
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  public getAllShopType() {
    this.shopTypeSrv.getAllShoptype().subscribe((data: any) => {
      this.shopType = data.slice(0, 10); // Get the first 10 shop types
    });
  }

  replaceSpecialCharWithHyphen(categoryName: string): string {
    return categoryName.toLowerCase().replace(/\s+/g, '-');
  }
}

