import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ReviewService } from 'src/app/shared/services/review-products.service';

@Component({
  selector: 'app-product-box-three',
  templateUrl: './product-box-three.component.html',
  styleUrls: ['./product-box-three.component.scss']
})
export class ProductBoxThreeComponent implements OnInit {

  @Input() product: any = [];
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False

  reviewsData: any[] = []; // Assuming your reviewsData is an array
  averageRating: number = 0;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  constructor(private productService: ProductService,private reviewSrv: ReviewService) { }

  ngOnInit(): void {
    this.getAllReviewsByShopIdAndProductId(this.product.id);
  }


  getAllReviewsByShopIdAndProductId(productId: any): void {
    this.reviewSrv.getAllReview(productId).subscribe((data: any) => {
      this.reviewsData = data;
      // Check if there are reviews before calculating the average rating
      if (this.reviewsData && this.reviewsData.length > 0) {
        const totalRating = this.reviewsData.reduce((sum, review) => sum + review.rating, 0);
        this.averageRating = totalRating / this.reviewsData.length;
      } else {
      }
    });
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  
  getRouterLink(): string[] {
    return ['/shop/product/', this.product?.urlPath || ''];
  }

}
