import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class EcomService {

  ecomUrl = environment.base_url_ecom_service;
  shopUrl = environment.base_url_shop_service;

  constructor(private httpClient: HttpClient) { }

  public getAllProducts() {
    return this.httpClient.get(`${this.ecomUrl}/products?tenantId=${this.getTenantId()}&shopId=${this.getShopId()}`);
  }

  public getLocationByShopId() {
    return this.httpClient.get(`${this.shopUrl}/shops/${this.getShopId()}/locations`)
  }

  public getSocialmediaByShopId(){
    return this.httpClient.get(`${this.shopUrl}/shops/${this.getShopId()}/socialmedia`)
  }

  getTenantId(): any {
    return sessionStorage.getItem("TENANTID");
  }
  getShopId(): any {
    return sessionStorage.getItem("shopId")
  }
}