import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { FashionOneComponent } from './fashion/fashion-one/fashion-one.component';
import { FashionTwoComponent } from './fashion/fashion-two/fashion-two.component';
import { FashionThreeComponent } from './fashion/fashion-three/fashion-three.component';
import { VegetableComponent } from './vegetable/vegetable.component';
import { WatchComponent } from './watch/watch.component';
import { FurnitureComponent } from './furniture/furniture.component';
import { FlowerComponent } from './flower/flower.component';
import { BeautyComponent } from './beauty/beauty.component';
import { ElectronicsComponent } from './electronics/electronics.component';
import { PetsComponent } from './pets/pets.component';
import { GymComponent } from './gym/gym.component';
import { ToolsComponent } from './tools/tools.component';
import { ShoesComponent } from './shoes/shoes.component';
import { BagsComponent } from './bags/bags.component';
import { MarijuanaComponent } from './marijuana/marijuana.component';
// Widgest Components
import { SliderComponent } from './widgets/slider/slider.component';
import { BlogComponent } from './widgets/blog/blog.component';
import { LogoComponent } from './widgets/logo/logo.component';
import { ServicesComponent } from './widgets/services/services.component';
import { CollectionComponent } from './widgets/collection/collection.component';
import { HomeComponent } from './home.component';
import { ShopwalaHomeComponent } from './shopwala-home/shopwala-home.component';
import { HomeCollectionComponent } from './widgets/collection/home-collection/home-collection.component';
import { ShopTypeComponent } from './shop-type/shop-type.component';
import { ShopSearchComponent } from './shop-search/shop-search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShopDetailsComponent } from './shop-details/shop-details.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ShopwaalaOnboardingComponent } from './shopwaala-onboarding/shopwaala-onboarding.component';
import { ShopwaalaFaqComponent } from './shopwaala-onboarding/shopwaala-faq/shopwaala-faq.component';
import { ShopwaalaThanksComponent } from './shopwaala-onboarding/shopwaala-thanks/shopwaala-thanks.component';
import { TermsConditionsComponent } from './shopwaala-onboarding/terms-conditions/terms-conditions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [
    FashionOneComponent,
    FashionTwoComponent,
    FashionThreeComponent,
    VegetableComponent,
    WatchComponent,
    FurnitureComponent,
    FlowerComponent,
    BeautyComponent,
    ElectronicsComponent,
    PetsComponent,
    GymComponent,
    ToolsComponent,
    ShoesComponent,
    BagsComponent,
    MarijuanaComponent,
    // Widgest Components
    SliderComponent,
    BlogComponent,
    LogoComponent,
    ServicesComponent,
    CollectionComponent,
    HomeComponent,
    ShopwalaHomeComponent,
    HomeCollectionComponent,
    ShopTypeComponent,
    ShopSearchComponent,
    ShopDetailsComponent,
    ShopwaalaOnboardingComponent,
    ShopwaalaFaqComponent,
    ShopwaalaThanksComponent,
    TermsConditionsComponent,
  ],
  imports: [
    HomeRoutingModule,
    SharedModule,
    InfiniteScrollModule,
    Ng5SliderModule,
    ReactiveFormsModule,
    ClipboardModule,
    FormsModule,
    NgxDropzoneModule,
    CKEditorModule
    // ShopwaalaOnboardingModule,
  ]
})
export class HomeModule { }
