import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { carouselItems, dailyNeedsCategories, logos, serviceCategories, shopCategories } from 'src/app/shared/data/shopwaala_data';
import { CollectionSlider, HomeSlider, LogoSlider } from 'src/app/shared/data/slider';
import { HomepageService } from 'src/app/shared/services/homepage.service';
import { JsonLDService } from 'src/app/shared/services/json-ldplus-service';
import { LocationService } from 'src/app/shared/services/location.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { ShopTypeService } from 'src/app/shared/services/shoptype.service';

@Component({
  selector: 'app-shopwala-home',
  templateUrl: './shopwala-home.component.html',
  styleUrls: ['./shopwala-home.component.scss']
})
export class ShopwalaHomeComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/footer-logo.png';
  public products: any[] = [];
  public active;
  public ImageSrc: string
  public shopsByCountry: any;
  public shopsByState: any = [];
  public shopsByCity: any = [];
  public shopName: any
  @Input() class: string;
  @Input() selectedCountry: string;
  currency: string = '';
  currencysymbol: string = '';
  shopType: any = [];
  public selectedshopType: any;
  public shopList: any;
  public isCardHovered: boolean = false;
  public contentLoaded = false;
  public categoryContentLoaded = false;
  public cityContentLoaded = false;
  public stateContentLoaded = false;
  public countryContentLoaded = false;
  public inputSourceLayout = "";
  public imageLoaded: boolean = false;
  public cityImageLoaded: boolean = false;
  public stateImageLoaded: boolean = false;
  public countryImageLoaded: boolean = false;
  public isGetAllShopApiFinished: boolean = false;
  public isCountryApiFinished: boolean = false;

  constructor(public productService: ProductService, public homeSrv: HomepageService, public locationSrv: LocationService, private router: Router, public shopTypeSrv: ShopTypeService, private docSrv: ShopdocumentService, private jsonLdService: JsonLDService) {
  }

  public HomeSliderConfig: any = HomeSlider;
  public CollectionSliderConfig: any = CollectionSlider;
  public logoSliderConfig: any = LogoSlider;

  // getting data from shopwaala_data.ts
  public carouselItems = carouselItems;
  public shopCategories = shopCategories;
  public serviceCategories = serviceCategories;
  public dailyNeedsCategories = dailyNeedsCategories;
  // Logo
  public logos = logos;

  ngOnInit(): void {

    // Add class in body
    document.body.classList.add("tools-bg");

    //Get all shop
    this.getAllShops();

    //Get all category
    this.getAllCategory();

    //Top 30 Shops Based On Country
    this.getTopShopByCountry();

    //Top 30 Shops Based On City
    this.getTopShopByCity();

    //Top 30 Shops Based On state
    this.getTopShopByState();

    this.jsonLdService.insertSchema('WebSite', this.jsonLdService.prepareLdJson('WebSite', null, null), 'structured-data');

    App.addListener('backButton', () => {
      navigator['app'].exitApp();
    });
  }

  ngOnDestroy() {
    App.removeAllListeners();
  }

  getAllShops() {
    this.locationSrv.clearSessionShopByCity();
    this.locationSrv.clearSessionShopByState();
    let serializedShopList = sessionStorage.getItem("shops");
    this.shopList = JSON.parse(serializedShopList);
    this.cityContentLoaded = true;
    this.stateContentLoaded = true;
    this.imageLoaded = true;
    this.cityImageLoaded = true;
    this.stateImageLoaded = true;
    this.countryImageLoaded = true;
    if (this.shopList != null && Object.keys(this.shopList).length > 0) {
      this.contentLoaded = true;
    } else {
      this.getAllShopsByAPI();
    }
  }

  public getAllCategory() {
    //get All shopType
    let serializedShopType = sessionStorage.getItem("CATEGORIES");
    this.shopType = JSON.parse(serializedShopType);
    if (this.shopType == null || Object.keys(this.shopType).length === 0) {
      this.getAllShopType();
    } else if (this.shopType != null || Object.keys(this.shopType).length === 1) {
      this.categoryContentLoaded = true;
    }
  }

  /**
   * Retrieves all shops from the productService.
   */
  public getAllShopsByAPI() {
    // Subscribe to the getShops observable to retrieve the data
    this.productService.getShops.subscribe((data: any) => {
      // Store the retrieved data in the shopList variable
      this.shopList = data;
      if (this.shopList != null && Object.keys(this.shopList).length > 0) {
        this.cityContentLoaded = true;
        this.stateContentLoaded = true;
        this.fetchDocumentsForShops(this.shopList).then(() => {
          // Store the updated shopList with documents in session storage
          this.imageLoaded = true;
          this.cityImageLoaded = true;
          this.stateImageLoaded = true;
          this.countryImageLoaded = true;
          sessionStorage.setItem('shops', JSON.stringify(this.shopList));
          this.imageLoaded = true;
          this.contentLoaded = true;
          this.isGetAllShopApiFinished = true;
        });
      }
    });
  }


  /**
   * Fetches documents for each shop in the provided list.
   * @param shops List of shops
   */
  public async fetchDocumentsForShops(shops: any[]): Promise<void> {
    const fetchDocPromises: Promise<void>[] = [];
    shops?.forEach(shop => {
      // Check if documents are already fetched for the shop
      if (!shop?.documents) {
        const fetchDocPromise = new Promise<void>((resolve) => {
          this.docSrv.getDocByShopId(shop?.id).subscribe((docs: any) => {
            shop.documents = docs;
            resolve(); // Resolve the promise once documents are fetched
          });
        });
        fetchDocPromises.push(fetchDocPromise);
      }
    });

    // Wait for all document fetch requests to complete
    await Promise.all(fetchDocPromises);
  }

  public getAllShopType() {
    // Call the getAllShoptype method of the shopTypeSrv service
    this.shopTypeSrv.getAllShoptype().subscribe((data: any) => {
      this.shopType = data.map(type => {
        const category = this.shopCategories.find(cat => cat.title === type.name);
        return {
          ...type,
          imageURL: category ? category.imageURL : null
        };
      });
      sessionStorage.setItem("CATEGORIES", JSON.stringify(this.shopType));
      this.categoryContentLoaded = true;
    });
  }

  /**
   * Navigates to the view details page for a shop.
   * @param shopData - The data of the shop.
   */
  public onViewDetails(shopData: any): void {
    // Navigate to the view details page of the shop with the specified ID.
    this.router.navigate(['/shops/view', shopData.urlPath], { state: { shopData: shopData } });
  }

  /**
   * Sets the value of `isCardHovered` based on the `isHovered` parameter.
   * 
   * @param isHovered - Indicates whether the card is being hovered or not.
   */
  onCardHover(isHovered: boolean): void {
    this.isCardHovered = isHovered;
  }

  getTopShopByCountry() {
    let serializedShopList = sessionStorage.getItem("SHOPS_BY_COUNTRY");
    this.shopsByCountry = JSON.parse(serializedShopList);
    if (this.shopsByCountry == null || Object.keys(this.shopsByCountry).length === 0) {
      this.getTopShopByCountryAPI();
    } else if (this.shopsByCountry != null || Object.keys(this.shopsByCountry).length === 1) {
      this.countryContentLoaded = true;
      this.countryImageLoaded = true;
    }
  }

  getTopShopByCountryAPI() {
    this.locationSrv.topShopsByCountry.subscribe(data => {
      this.shopsByCountry = data;
      this.fetchDocumentsForShops(this.shopsByCountry).then(() => {
        // Store the updated shopsByCountry with documents in session storage
        sessionStorage.setItem('SHOPS_BY_COUNTRY', JSON.stringify(this.shopsByCountry));
        this.isCountryApiFinished = true;
        this.countryContentLoaded = true;
      });
    });
  }

  /**
     * Updates shopList to show top shops by state.
     */
  getTopShopByState() {
    let serializedShopList = sessionStorage.getItem("SHOPS_BY_STATE");
    this.shopsByState = JSON.parse(serializedShopList);
    if (this.shopsByState == null || Object.keys(this.shopsByState).length === 0) {
      this.locationSrv.topShopsByState.subscribe(data => {
        this.shopsByState = data;
        this.stateContentLoaded = true;
        // Check if SHOPS_BY_CITY is present and remove it if it exists
        if (this.shopsByState.length > 0) {
          this.fetchDocumentsForShops(this.shopsByState);
          // Set the new value for SHOPS_BY_CITY
          sessionStorage.setItem('SHOPS_BY_STATE', JSON.stringify(this.shopsByState));
          this.stateImageLoaded = true;
        }
      });
    }
  }

  getTopShopByCity() {
    let serializedShopList = sessionStorage.getItem("SHOPS_BY_CITY");
    this.shopsByCity = serializedShopList ? JSON.parse(serializedShopList) : [];
    this.cityImageLoaded = true;
    if (this.shopsByCity == null || this.shopsByCity.length === 0) {
      this.locationSrv.topShopsByCity$.subscribe(data => {
        this.shopsByCity = data;
        this.cityContentLoaded = true;
        if (this.shopsByCity.length > 0) {
          this.fetchDocumentsForShops(this.shopsByCity);
          sessionStorage.setItem('SHOPS_BY_CITY', JSON.stringify(this.shopsByCity));
          this.cityImageLoaded = true;
        } else {
          this.getAllShops(); // Fallback to showing all shops
          this.cityContentLoaded = true; // Handle error state
        }
      }, () => {
        this.getAllShops(); // Fallback to showing all shops
        this.cityContentLoaded = true; // Handle error state
      });

    }
  }

  replaceSpecialCharWithHyphen(categoryName: string): string {
    return categoryName.toLowerCase().replace(/\s+/g, '-');
  }

}

