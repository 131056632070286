<!--footer section -->
<footer [ngClass]="class">
    <div class="dark-layout" *ngIf="mainFooter">
        <div>
            <section class="section-b-space border-b footer-bg-color">
                <div class="row footer-theme2">
                    <div class="col-lg-3 col-md-12 ">
                        <div class="footer-title footer-mobile-title">
                            <h4  class="text-math">About</h4>
                        </div>
                        <div class="footer-contant">
                            <div class="footer-logo" id="footerlogo">
                                <img [src]="themeLogo" alt="logo">
                            </div>
                            <div class="container">
                                <div class="row">
                                    <p class="footer-font-color line_height">At Shopdial, we specialize in empowering small retail
                                        businesses by bringing their products to the online marketplace. Our
                                        comprehensive services include creating user-friendly e-commerce websites,
                                        optimizing online presence, and providing ongoing technical support.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook fonts-icon"
                                            aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google fonts-icon"
                                            aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-times fonts-icon"
                                        aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram fonts-icon"
                                            aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss fonts-icon"
                                            aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="col-lg-6 col-md-12 subscribe-wrapper ml6">
                        <div class="subscribe-block">
                            <h2 class="text-math">Newsletter</h2>
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleFormControlInput3"
                                        placeholder="Enter your email">
                                    <button type="submit" class="btn btn-solid ">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 change-possition-mobile mt-2">
                        <div class="footer-title">
                            <h4 class="text-math">Contact Us</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-details contact-us-align">
                                <li><i class="fa fa-map-marker"></i>
                                    <span class="footer-font-color text-start address-align">Block No 8,&nbsp;2nd
                                        Floor, B-Wing, Mangalwari Complex, Sadar, Nagpur.
                                    </span>
                                </li>
                                <li><i class="fa fa-phone"></i>
                                    <span class="footer-font-color ml-phone">+91-712-351-5205</span>
                                </li>
                                <li><i class="fa fa-envelope-o"></i>
                                    <span class="mt-email">
                                        <a [href]="'https://mail.google.com/mail/?view=cm&fs=1&to=admin@Alzohar.com'"
                                            target="_blank" class="footer-font-color gmail">
                                            admin@shopdial.in
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="sub-footer darker-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }} shopdial.in. All
                            rights reserved.
                            <a [routerLink]="'/onboarding/terms'" class="text-primary custom-hover-color">Terms & Conditions
                            </a> | 
                            <a
                                [routerLink]="'/onboarding/faq'" class="text-primary custom-hover-color">FAQs
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/visa.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/mastercard.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/paypal.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/american-express.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <img src="assets/images/icon/discover.png" alt="">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->