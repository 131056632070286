import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-shopwaala-thanks',
  templateUrl: './shopwaala-thanks.component.html',
  styleUrls: ['./shopwaala-thanks.component.scss']
})
export class ShopwaalaThanksComponent implements OnInit {


  @Input() themeLogo: string = 'assets/images/footer-logo.png';

  constructor(private location: Location) { }

  ngOnInit(): void {

    //back button
    App.addListener('backButton', () => {
      this.location.back();
    });
  }

  ngOnDestroy(): void {
    // back button
    App.removeAllListeners();
  }
}
