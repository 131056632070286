import { Component, OnInit, OnDestroy, ViewChild, TemplateRef,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnDestroy {
  
  @ViewChild("videoModal", { static: false }) VideoModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public generatedShopId: any;
  public documents: any;
  public doc: any;
  public filteredVideoDocuments: any;


  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, private docSrv: ShopdocumentService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.VideoModal, { 
        size: 'lg',
        ariaLabelledBy: 'Video-Modal',
        centered: true,
        windowClass: 'modal fade video-modal' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredVideoDocuments = this.documents.filter((document: any) => {
        if(document.identifier === 'shop-video'){
          this.doc= document;
          return this.doc;
        }
      });
    });
  }

  getEncodedVideoUrl(url: string): string {
    return `('${encodeURI(url)}')`;
  }
  getSanitizedVideoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
