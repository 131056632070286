import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OnboardingService } from 'src/app/shared/services/client-onboarding.service';
import { TenantRequestSpaceValidator } from 'src/app/shared/services/tenant-request-validation.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-shopwaala-onboarding',
  templateUrl: './shopwaala-onboarding.component.html',
  styleUrls: ['./shopwaala-onboarding.component.scss'],
})

export class ShopwaalaOnboardingComponent implements OnInit {

  public themeLogo: string = 'assets/images/footer-logo.png';
  public isSubmited: boolean = false;
  public createOnboardingForm: FormGroup;
  public errorResponse: any
  public errorEamilResponse: any;

  constructor(private formBuilder: FormBuilder, private onboardingSrv: OnboardingService, private toaster: ToastrService, private router: Router, private location: Location) {
    this.createOnboardingForm = this.formBuilder.group({
      tenantId: [''],
      tenantName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), TenantRequestSpaceValidator.cannotContainSpace]],
      userName: [''],
      password: [''],
      email: ['', [Validators.required, Validators.email]],
      domains: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20),]],
      status: ['New'],
      comment: ['New Request'],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(12), Validators.pattern(environment.numberPattern)]],
    })
  }

  ngOnInit(): void {
    this.createOnboardingForm.patchValue(this.email);

    //back button
    App.addListener('backButton', () => {
      this.location.back();
    });
  }

  patchEmailAndSetToUserName() {
    const emailFormControl = this.createOnboardingForm.get('email');
    if (emailFormControl && emailFormControl.value) {
      this.createOnboardingForm.patchValue({
        userName: emailFormControl.value
      });
    }
  }

  public onTenantNameBlur() {
    const tenantNameControl = this.createOnboardingForm.get('tenantName');
    if (tenantNameControl && tenantNameControl.dirty && tenantNameControl.valid) {
      this.onboardingSrv.validateRequest(tenantNameControl.value, null).subscribe((data: any) => {
        if (data.status === 400) {
          const errorMessage = "Business Owner Name already exists"; // Modify the error message here
          tenantNameControl.setErrors({ alreadyExist: true });
          this.errorResponse = { message: errorMessage }; // Set the modified error message
        } else {
          this.errorResponse = null;
        }
      });
    }
  }

  ngOnDestroy(): void {
    // back button
    App.removeAllListeners();
  }

  public onEmailBlur() {
    const tenantEmailControl = this.createOnboardingForm.get('email');
    if (tenantEmailControl && tenantEmailControl.dirty && tenantEmailControl.valid) {
      this.onboardingSrv.validateRequest(null, tenantEmailControl.value).subscribe((data: any) => {
        if (data.status === 400) {
          const errorEmailMessage = "Business Owner Email already exists";
          tenantEmailControl.setErrors({ emailExist: true });
          this.errorEamilResponse = { message: errorEmailMessage };
          return tenantEmailControl.markAsTouched();
        } else {
          this.errorEamilResponse = null;
        }
      });
    }
  }

  public onSubmit(form: any): void {
    if (form.valid) {
      this.isSubmited = true;
      this.patchEmailAndSetToUserName();
      this.generateRandomPassword();
      this.handleOnboarding(this.createOnboardingForm.value); 1
    } else {
      this.validForm(form);
      // this.handleOnboarding(this.createOnboardingForm.value);
      this.toaster.error('Form is not Valid.')
    }
  }

  public handleOnboarding(onboarding: any): void {
    if (onboarding.id != null && onboarding.id != "") {
    } else {
      this.createOnboarding(onboarding);
    }
  }

  public validForm(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field];
      if (control instanceof FormControl || control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched({ onlySelf: true });
      } else {
        this.validForm(form);
      }
    })
  }

  public createOnboarding(onboarding: any): void {
    this.onboardingSrv.addOnboarding(onboarding).subscribe((data: any) => {
      this.toaster.success('Request register successfully ')
      this.router.navigateByUrl("/onboarding/thanks");
      this.validForm(onboarding);
      this.createOnboardingForm.reset();
    }, (error: any) => {
      this.toaster.error('Request Creation failed')
    });
  }

  generateRandomPassword(): void {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!#$^*()_+=<>"; // Alphanumeric characters
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    this.createOnboardingForm.get("password").setValue(password);
  }

  hasError(name: string) {
    const field = this.createOnboardingForm.get(name);
    return (field?.invalid && field?.touched && field?.errors);
  }

  get form() {
    return this.createOnboardingForm.controls;
  }


  get tenantName() {
    return this.form['tenantName'];
  }
  get userName() {
    return this.form['userName'];
  }
  get email() {
    return this.form['email'];
  }
  get domains() {
    return this.form['domains'];
  }
  get status() {
    return this.form['status'];
  }

  get template() {
    return this.form['template'];
  }
  get phoneNumber() {
    return this.form['phoneNumber'];
  }
  get comment() {
    return this.form['comment'];
  }

}



