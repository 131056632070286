<app-header-home [sticky]="true"></app-header-home>
<!-- <section> -->
<form [formGroup]="createOnboardingForm" autocomplete="off" class="onboarding">
    <div class="container col-lg-8 col-md-11 col-xl-8">
        <div class="card card0 border-0 ">
            <div class="row d-flex ">
                <div class="col-lg-5">
                    <div class="card1 pb-3">
                        <div class="row px-3">
                        </div>
                        <div class="row px-3 justify-content-center mt-4 mb-5">
                            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                </ol>
                                <div class="carousel-inner">
                                    <div class="carousel-item active card border-0 card-0">
                                        <div class="text-center">
                                            <img src="assets/images/UserIcon3.png" class="img-fluid profile-pic">
                                        </div>
                                        <h5 class="font-weight-bold mt-5 onboard-heading tab-head-24">
                                            <strong>Go Global with Shopdial</strong>
                                        </h5>
                                        <hr width="100%">
                                        <p class="content mt-2">Set up your online business in just a few simple steps.</p>
                                        <div>
                                            <ul class="progress-bar">
                                                <li class="active" style="color: black;">
                                                    <span class="bar-adjest text-sm">Sign up with Shopdial </span>
                                                </li>
                                                <li class="active" style="color: black;">
                                                    <span class="bar-adjest text-sm">Complete business verification</span>
                                                </li>
                                                <li class="active" style="color: black;">
                                                    <span class="bar-adjest text-sm">Set up your shop</span>
                                                </li>
                                                <li class="active" style="color: black;">
                                                    <span class="bar-adjest text-sm">Start selling online</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="socal-media onboard-heading">
                            <ul>
                                <li>
                                    <a><i class="fa fa-facebook ff-social" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a><i class="fa fa-google-plus ff-social" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a><i class="fa fa-twitter ff-social" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a><i class="fa fa-instagram ff-social" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a><i class="fa fa-rss ff-social" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- input fields -->
                <div class="col-lg-7">
                    <div class="card2 card border-0 px-4 px-sm-5 py-3">
                        <h3 class="fw500 color111">Sign up to Shopdial</h3>
                        <p class="text-sm">Create account and start your own ecommerce business globally
                        </p>
                        <!-- tennat Name -->
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label for="tenantName" class="mb-0">
                                    <h6 class="mb-0 text-sm"> Business owner name</h6>
                                </label>
                                <input class="form-control" id="tenantName" type="text" formControlName="tenantName"
                                    (blur)="onTenantNameBlur()" required="alreadyExist">
                                <div *ngIf="hasError('tenantName')" [class]="hasError('tenantName') ? 'error' : ''">
                                    <div class="errorMargin-20" *ngIf="tenantName?.errors">
                                        <span *ngIf="tenantName?.errors?.['required']"> Business owner name is required
                                            field</span>
                                        <span *ngIf="tenantName?.errors?.['minlength']"> Business owner name should min
                                            5 char long</span>
                                        <span *ngIf="tenantName?.errors?.['maxlength']"> Business owner name should be
                                            max 20 char long</span>
                                        <span *ngIf="tenantName?.errors?.['cannotContainSpace']"> Business owner name
                                            can not contain space.</span>
                                        <span *ngIf="tenantName?.errors?.alreadyExist"> {{errorResponse.message}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Email -->
                        <div class="row  form-group">
                            <!-- Email -->
                            <div class="col-md-6">
                                <label for="email" class="mb-0">
                                    <h6 class="mb-0 text-sm">Email</h6>
                                </label>
                                <input class="form-control" id="email" type="text" name="email" formControlName="email"
                                    (blur)="onEmailBlur()" required="emailExist">
                                <div *ngIf="hasError('email')" [class]="hasError('email') ? 'error' : ''">
                                    <div class="errorMargin-20" *ngIf="email?.errors">
                                        <span *ngIf="email?.errors?.['required']">Email is a required field</span>
                                        <span *ngIf="email?.errors?.['email']">Email should be email type</span>
                                        <span
                                            *ngIf="email?.errors?.['emailExist']">{{errorEamilResponse.message}}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Phone Number -->
                            <div class="col-md-6 form-group">
                                <label for="phoneNumber" class="mb-0">
                                    <h6 class="mb-0 text-sm">Contact number</h6>
                                </label>
                                <input class="form-control" id="phoneNumber" type="text" name="phoneNumber"
                                    formControlName="phoneNumber" maxlength="10">
                                <div *ngIf="hasError('phoneNumber')" [class]="hasError('phoneNumber') ? 'error' : ''">
                                    <div class="errorMargin-20" *ngIf="phoneNumber?.errors">
                                        <span *ngIf="phoneNumber?.errors?.['required']">Contact number is a required
                                            field</span>
                                        <span
                                            *ngIf="!phoneNumber?.errors?.['required'] && !phoneNumber?.errors?.['pattern'] && phoneNumber?.errors?.['minlength']">Contact
                                            number should min 10 digit</span>
                                        <span *ngIf="phoneNumber?.errors?.['pattern']">Please enter numbers only</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- domain -->
                        <div class="row px-3 form-group input-group">
                            <label for="domains" class="marginb-2">
                                <h6 class="text-sm" style="margin-left: -19px;">Domain name</h6>
                            </label>
                            <div class="input-group mb-3">
                                <input style="margin-left: -16px;" type="text" class="form-control" name="domains"
                                    formControlName="domains" aria-label="Recipient's username"
                                    aria-describedby="basic-addon2">
                                <span class="input-group-text shopin-text" id="basic-addon2">shopdial.in</span>
                            </div>
                            <div *ngIf="hasError('domains')" [class]="hasError('domains') ? 'error' : ''">
                                <div *ngIf="domains?.errors" style="margin-top: -36px; margin-left: -15px;">
                                    <span *ngIf="domains?.errors?.['required']"> Domain is a required field</span>
                                    <span *ngIf="domains?.errors?.['minlength']"> Domain should min 10 char long</span>
                                    <span *ngIf="domains?.errors?.['maxlength']"> Domain should min 12 char long</span>
                                </div>
                            </div>
                        </div>
                        <div class="row px-3 mb-3">
                            <small class="text-muted terms-service-text">By signing up, you
                                agree our <a [routerLink]="'/onboarding/terms'" class="text-primary"> Terms & Conditions</a>
                                     and 
                                     <a [routerLink]="'/onboarding/faq'" class="text-primary"> FAQ's</a>
                            </small>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-6 ">
                                <button class="btn btn-blue text-center mb-1 py-2" type="button"
                                    (click)="onSubmit(createOnboardingForm)">Create Account</button>
                            </div>
                        </div>
                        <div class="row px-3 mb-4">
                            <div class="line"></div>
                            <small class="text-muted or text-center">OR</small>
                            <div class="line"></div>
                        </div>
                        <div class="row text-center">
                            <div class="col-sm-6">
                                <p class="social-connect"><span class="fa fa-facebook-square"></span><small
                                        class="pl-3 pr-1">Sign up with facebook</small></p>
                            </div>
                            <div class="col-sm-6">
                                <p class="social-connect"><span class="fa fa-google-plus"></span><small
                                        class="pl-3 pr-1">Sign up with google+</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- </section> -->

<app-home-footer [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-home-footer>