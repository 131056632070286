<app-header-three [themeLogo]="themeLogo"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"></app-header-three>

<!-- Home slider start-->
<section class="p-0 service_slide"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <app-slider [class]="'text-white'" [sliders]="sliders" [textClass]="'text-center p-center'" [category]="'marijuana'"
    [buttonClass]="'btn-green'" [buttonText]="'shop now'">
  </app-slider>

</section>
<!-- Home slider End -->


<!-- service section start -->
<section class="banner-padding absolute-banner pb-0 tools-service"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
  <div class="container absolute-bg">
    <div class="service p-0 ">
      <app-services></app-services>
    </div>
  </div>
</section>
<!-- service section end -->

<!-- About section start -->
<section *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="title3">
          <h4>about us</h4>
          <h2 class="title-inner3">welcome to {{shop.name}}</h2>
          <div class="line"></div>
        </div>
        <!-- <a class="fa-pull-right" *ngIf="isEditable()" (click)="openSectionForm()">
          <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true" style="color:#717386;margin-left: 600%;
          margin-top: -10%;"></i>
        </a> -->
        <div class="about-text">
          <p>
            {{shop?.description | stripHtml}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About section end -->

<!-- collection banner start-->
<div class="container mt-3" *ngIf="!issectionFormOpen">
  <div class="row partition2">
    <div *ngIf="filteredOfferDocumentsOne?.length > 0 ;then content_one else default_content_one"></div>
    <ng-template class="pb-0" #content_one>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenTwo">
          <div class="collection-banner text-center p-left" *ngFor="let offer of filteredOfferDocumentsOne">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img [src]="offer.urls" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div>
                <h2>{{offer?.textTittle}}</h2>
                <h4 class="fontColor">{{offer?.textSubTittle}}</h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="pb-0" #default_content_one>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenTwo">
          <div class="collection-banner text-center p-left">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormOne()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img src="assets/images/food-offers-1.png" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div>
                <h2>Veggies</h2>
                <h4 class="fontColor">save 50%</h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenOne">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferOne($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerOne" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerOne,f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitOffer(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close-->
    <div *ngIf="filteredOfferDocumentsTwo?.length > 0 ;then content_two else default_content_two"></div>
    <ng-template class="pb-0" #content_two>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner text-center p-right" *ngFor="let offer of filteredOfferDocumentsTwo">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img [src]="offer.urls" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div>
                <h2>{{offer?.textTittle}}</h2>
                <h4 class="fontColor">{{offer?.textSubTittle}}</h4>
              </div>
            </div>

          </div>
        </a>
      </div>
    </ng-template>
    <ng-template class="pb-0" #default_content_two>
      <div class="col-md-6">
        <a *ngIf="!isOfferBannerFormOpenOne">
          <div class="collection-banner text-center p-right" *ngIf="!isOfferBannerFormOpenOne">
            <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openOfferFormTwo()">
              <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
            </a>
            <img src="assets/images/food-offers-2.png" class="img-fluid" alt="banner">
            <div class="contain-banner">
              <div>
                <h2>Fruits</h2>
                <h4 class="fontColor">save 20%</h4>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
    <!-- Popup Form -->
    <div class="popup-form" *ngIf="isOfferBannerFormOpenTwo">
      <div class="confirmation-content" [formGroup]="documentForm">
        <!-- Add a small tag for displaying messages at the top -->
        <small class="text-danger">{{ errorMessageOffer }}</small>
        <div class="container mt-3">
          <div class="row">
            <div class="card-body dropzone-custom p-0">
              <ngx-dropzone class="dropzone-border" (change)="onSelectOfferTwo($event)">
                <ngx-dropzone-label>
                  <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                    <small class="mt-14">Only JPG, JPEG, PNG files are allowed, Maximum file size of
                      672px X 310px.</small>
                  </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadOfferBannerTwo" [removable]="true"
                  (removed)="onRemoveItem(uploadOfferBannerTwo,f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
            <div class="row justify-content-around mt-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text
                  Tittle
                </label>
                <input class="form-control" id="textTittle" type="text" name="textTittle" formControlName="textTittle">
              </div>
              <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                <label class="col-form-label pt-0">Text Subtittle</label>
                <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                  formControlName="textSubTittle">
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-success pull-right" type="submit"
            (click)="onSubmitOffer(documentForm)">Confirm</button>
          <button class="btn btn-primary pull-right" (click)="closeOfferForm()">Cancel</button>
        </div>
      </div>
    </div>
    <!-- Popup Form Close-->
  </div>
</div>
<!-- collection banner End -->

<!-- product slider start -->
<div *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo">
  <div class="title3 section-t-space" *ngIf="!isBackgroundBannerFormOpen && !issectionFormOpen">
    <h4>special offer</h4>
    <h2 class="title-inner3">new products</h2>
    <div class="line"></div>
  </div>
</div>
<section class="p-t-0 section-b-space tools-grey border-box"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
  <div class="container" *ngIf="!isBackgroundBannerFormOpen">
    <div class="row">
      <div class="col">
        <owl-carousel-o [options]="ProductSliderConfig" class="product-5 product-m no-arrow">
          <ng-container *ngFor="let product of products">
            <ng-template carouselSlide>
              <div class="product-box product-wrap">
                <app-product-box-three [product]="product" [currency]="productService?.Currency">
                </app-product-box-three>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>
<!-- product slider end -->

<!-- Parallax banner start-->
<div *ngIf="filtereBackgroundDocuments?.length > 0 ;then bG_content else defualt_content"></div>
<ng-template #bG_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
    <div class="full-banner parallax tools-parallax-product tools-grey border-box text-center p-left"
      *ngFor="let bgDoc of filtereBackgroundDocuments" [style.backgroundImage]="getEncodedImageUrl(bgDoc?.urls)">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <div class="container">
        <div class="row">
          <div class="col theme-tab">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
              <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                <a ngbNavLink>
                  {{ collection }}
                </a>
                <ng-template ngbNavContent>
                  <div class="no-slider row">
                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                      <div class="product-box">
                        <app-product-box-three [product]="product" [currency]="productService?.Currency">
                        </app-product-box-three>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                      </h4>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveBackground(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="mt-3">
                <button class="btn btn-success pull-right" type="submit"
                  (click)="onSubmitBackground(documentForm)">Confirm</button>
                <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner End -->

<!-- Parallax banner start-->
<ng-template #defualt_content>
  <section class="p-0" *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !issectionFormOpen">
    <div class="full-banner parallax-banner1 parallax text-center p-left"
      [ngStyle]="{'background-image': 'url(assets/images/marijuwana-back-1.jpg)'}">
      <a class="fa-pull-right edit-icon" *ngIf="isEditable()" (click)="openBackgroundForm()">
        <i class="fa fa-pencil-square-o fa-2x" aria-hidden="true"></i>
      </a>
      <div class="container">
        <div class="row">
          <div class="col theme-tab">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="tabs tab-title justify-content-center nav nav-pills">
              <li *ngFor="let collection of productCollections" [ngbNavItem]="collection">
                <a ngbNavLink>
                  {{ collection }}
                </a>
                <ng-template ngbNavContent>
                  <div class="no-slider row">
                    <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:4">
                      <div class="product-box">
                        <app-product-box-three [product]="product" [currency]="productService?.Currency">
                        </app-product-box-three>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>

      <!-- Popup Form -->
      <div class="popup-form" *ngIf="isBackgroundBannerFormOpen">
        <div class="confirmation-content" [formGroup]="documentForm">
          <!-- Add a small tag for displaying messages at the top -->
          <small class="text-danger">{{ errorMessageOffer }}</small>
          <div class="container mt-3">
            <div class="row">
              <div class="card-body dropzone-custom p-0">
                <ngx-dropzone class="dropzone-border" (change)="onSelectBackgroundImage($event)">
                  <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                      <i class="fa fa-cloud-upload"></i>
                      <h4 class="mb-0 f-w-600">Drop files here or click to upload.
                      </h4>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of uploadBackgroundBanner" [removable]="true"
                    (removed)="onRemoveBackground(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div>
              <div class="row justify-content-around mt-4">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6"><label class="col-form-label pt-0">Text Tittle
                  </label>
                  <input class="form-control" id="textTittle" type="text" name="textTittle"
                    formControlName="textTittle">
                </div>
                <div class="cool-12 col-sm-12 col-md-6 col-lg-6">
                  <label class="col-form-label pt-0">Text Subtittle</label>
                  <input class="form-control" id="textSubTittle" type="text" name="textSubTittle"
                    formControlName="textSubTittle">
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success pull-right" type="submit"
              (click)="onSubmitBackground(documentForm)">Confirm</button>
            <button class="btn btn-primary pull-right" (click)="closeBackgroundForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
<!-- Parallax banner End -->

<!-- blog section start-->
<section class="blog pt-0 section-b-space"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section>
<!-- blog section End -->

<!--  logo section start -->
<section class="bg-grey section-b-space small-section"
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logos"></app-logo>
      </div>
    </div>
  </div>
</section>
<!--  logo section End -->

<app-alert-popup
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"></app-alert-popup>
<app-footer-two
  *ngIf="!isOfferBannerFormOpenOne && !isOfferBannerFormOpenTwo && !isBackgroundBannerFormOpen && !issectionFormOpen"
  [class]="'footer-5'" [themeLogo]="themeFooterLogo"></app-footer-two>