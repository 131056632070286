import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductSlider } from '../../../shared/data/slider';
import { ProductService } from '../../../shared/services/product.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fashion-three',
  templateUrl: './fashion-three.component.html',
  styleUrls: ['./fashion-three.component.scss']
})
export class FashionThreeComponent implements OnInit, OnDestroy {

  public products: any[] = [];
  public productCollections: any[] = [];
  public isBackgroundBannerFormOpen: boolean = false;
  public uploadBackgroundBanner: any;
  public documentForm: FormGroup;
  public generatedShopId: string;
  public filtereBackgroundDocuments: any;
  public errorMessageOffer: string;
  public documents: any;
  public active;


  constructor(public productService: ProductService, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: [''],
      textTittle: [''],
      textSubTittle: [''],
      linkTittle: [''],
      linkSubTittle: ['']
    });
  }

  public ProductSliderConfig: any = ProductSlider;

  public sliders = [{
    title: '',
    subTitle: '',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/21.jpg'
  }, {
    title: 'For Kids',
    subTitle: 'Spring Collection',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/22.jpg'
  }];

  // Collection banner
  public collections1 = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/kids/2.jpg',
    save: '',
    title: ''
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/kids/1.jpg',
    save: '',
    title: ''
  }];

 // Blog
 public blogs = [{
  image: 'assets/images/mar-blog-1.jpg',
  date: '25 January 2018',
  title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
  by: 'John Dio'
}, {
  image: 'assets/images/mar-blog-2.jpg',
  date: '26 January 2018',
  title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
  by: 'John Dio'
}, {
  image: 'assets/images/mar-blog-1.jpg',
  date: '27 January 2018',
  title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
  by: 'John Dio'
}, {
  image: 'assets/images/mar-blog-2.jpg',
  date: '28 January 2018',
  title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
  by: 'John Dio'
}];

  ngOnInit(): void {
    document.body.classList.add('box-layout-body');
    this.generatedShopId = sessionStorage.getItem('shopId');

    this.getDocumentByShopId();
  }

  ngOnDestroy() {
    document.body.classList.remove('box-layout-body');
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item
      }
    })
  }

  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      // Filter the documents based on the background-banner identifier
      this.documents = data;
      this.filtereBackgroundDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'background-banner';
      });
    });
  }

  openBackgroundForm() {
    this.isBackgroundBannerFormOpen = true;
  }

  closeBackgroundForm() {
    this.isBackgroundBannerFormOpen = false;
  }

  onRemoveBackground(event) {
    const indexToRemove = this.uploadBackgroundBanner.indexOf(event);
    if (indexToRemove !== -1) {
      this.uploadBackgroundBanner.splice(indexToRemove, 1);
    }
  }

  onSelectBackgroundImage = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    // Check if exactly two images are selected
    if (selectedFiles.length !== 1) {
      this.errorMessageOffer = 'Please select One Image only .';
      return; 
    }

    // Reset error message for a fresh validation
    this.errorMessageOffer = '';
    let validFileCount = 0;

    for (const file of Array.from(selectedFiles)) {
      const image = new Image();

      image.onload = () => {
        if (image.width === 1920 && image.height === 1200) {
          if (!this.uploadBackgroundBanner) {
            this.uploadBackgroundBanner = [];
          }
          this.uploadBackgroundBanner.push(file);
          validFileCount++;
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 1920px and height 1200px`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
    // Check the number of valid files after the loop
    if (validFileCount !== 1) {
      this.errorMessageOffer = 'Please upload an image with width 1920px and height 1200px';
      this.uploadBackgroundBanner = []; // Clear the array if the count is not equal to 2
    }
  }

  public onSubmitBackground(form: any): void {
    this.documentForm.get('identifier').setValue("background-banner");
    if (form.valid && this.uploadBackgroundBanner.length > 0) {
      this.handleBackgroundDocument(this.documentForm.value);
    }
  }

  public handleBackgroundDocument(document: any): void {
    this.addBackgroundDocument(document, this.uploadBackgroundBanner);
  }

  public addBackgroundDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addDiscountBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  getEncodedImageUrl(url: string): string {
    return `url('${encodeURI(url)}')`;
  }

}

