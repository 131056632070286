// shopwaala-data.ts

export const carouselItems = [
    {
      title: 'Image 1',
      imageUrl: 'assets/images/homepage-banner676-380/fashion-default.png'
    },
    {
      title: 'Image 2',
      imageUrl: 'assets/images/homepage-banner676-380/restaurant-default.png'
    },
    {
      title: 'Image 3',
      imageUrl: 'assets/images/homepage-banner676-380/Shoes-default.png'
    },
    {
      title: 'Image 4',
      imageUrl: 'assets/images/homepage-banner676-380/watches-default.png'
    },
    {
      title: 'Image 5',
      imageUrl: 'assets/images/homepage-banner676-380/gym_default_banner2.png'
    },
    {
      title: 'Image 6',
      imageUrl: 'assets/images/homepage-banner676-380/cosmatics_banner_defaullt.png'
    },
    {
      title: 'Image 7',
      imageUrl: 'assets/images/homepage-banner676-380/food_banner_default_2.png'
    }
  ];
  
  export const shopCategories = [
    { title: 'Gym', imageURL: 'assets/images/categories/gym-cat.png' },
    { title: 'Food', imageURL: 'assets/images/categories/food-cat.png' },
    { title: 'Furniture', imageURL: 'assets/images/categories/furniture-cat.png' },
    { title: 'Homedecor', imageURL: 'assets/images/categories/homedecor-cat.png' },
    { title: 'Bags', imageURL: 'assets/images/categories/Bag-cat.png' },
    { title: 'Fashion', imageURL: 'assets/images/categories/fashion-cat.png' },
    { title: 'Shoes', imageURL: 'assets/images/categories/shoes-cat.png' },
    { title: 'Electronics', imageURL: 'assets/images/categories/electronic-cat.png' },
    { title: 'Automobile', imageURL: 'assets/images/categories/AutoMobile & Part.png' },
    { title: 'Pets', imageURL: 'assets/images/categories/pets-cat.png' },
    { title: 'Beauty', imageURL: 'assets/images/categories/beauty-cat.png' },
    { title: 'Flower', imageURL: 'assets/images/categories/flower-cat.png' },
    { title: 'Vegetable', imageURL: 'assets/images/categories/vegitable-cat.png' },
    { title: 'Watch', imageURL: 'assets/images/categories/watch-cat.png' },
    { title: 'Games', imageURL: 'assets/images/categories/game-cat.png' },
    { title: 'Mobile', imageURL: 'assets/images/categories/mobile-cat.png' },
    { title: 'EyeWear', imageURL: 'assets/images/categories/eyeglass-cat.png' },
    { title: 'Jewellery', imageURL: 'assets/images/categories/jewellery-cat.png' },
    { title: 'Bakery', imageURL: 'assets/images/categories/bakery-cat.png' },
    { title: 'Grocery', imageURL: 'assets/images/categories/Grocery-cat.png' },
    { title: 'Toys', imageURL: 'assets/images/categories/Toys-cat.png' },
    { title: 'Perfumes', imageURL: 'assets/images/categories/Perfume-cat.png' },
    { title: 'Bicycle', imageURL: 'assets/images/categories/bicycle-cat.png' },
    { title: 'Medical', imageURL: 'assets/images/categories/medicle-cat.png' },
    { title: 'Music', imageURL: 'assets/images/categories/music-cat.png' },
    { title: 'Cleaning', imageURL: 'assets/images/categories/clean-cat.png' },
    { title: 'Stationary', imageURL: 'assets/images/categories/stationary-cat.png' }
  ];
  
  export const serviceCategories = [
    { title: 'Automobile', imageURL: 'assets/images/categories/food (1).png' },
    { title: 'Mobile', imageURL: 'assets/images/categories/mobile&accessories.png' },
    { title: 'Furniture', imageURL: 'assets/images/categories/furniture.png' }
  ];
  
  export const dailyNeedsCategories = [
    { title: 'Food', imageURL: 'assets/images/categories/food.png' },
    { title: 'Vegetable', imageURL: 'assets/images/categories/vegetable.png' },
    { title: 'Grocery', imageURL: 'assets/images/categories/hkim_grocery.webp' }
  ];
  
  export const logos = [
    { image: 'assets/images/logos/9.png' },
    { image: 'assets/images/logos/10.png' },
    { image: 'assets/images/logos/11.png' },
    { image: 'assets/images/logos/12.png' },
    { image: 'assets/images/logos/13.png' },
    { image: 'assets/images/logos/14.png' },
    { image: 'assets/images/logos/15.png' },
    { image: 'assets/images/logos/16.png' }
  ];
  