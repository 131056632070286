import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { VideoModalComponent } from "../../shared/components/modal/video-modal/video-modal.component";
import { ProductSlider } from '../../shared/data/slider';
import { ProductService } from '../../shared/services/product.service';
import { TenantService } from 'src/app/tenant/tenant.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShopdocumentService } from 'src/app/shared/services/shopdocument.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-beauty',
  templateUrl: './beauty.component.html',
  styleUrls: ['./beauty.component.scss']
})
export class BeautyComponent implements OnInit, OnDestroy {

  public themeLogo: string = 'assets/images/icon/logo-7.png'; // Change Logo
  public products: any[] = [];
  public aboutus: any;

  public isEditableMode: boolean = false;
  public documentForm: FormGroup;
  public sectionForm: FormGroup;
  public generatedShopId: any;
  public documents: any;
  public isDiscountBannerFormOpen: boolean = false;
  public isOfferBannerFormOpen: boolean = false;
  public isVideoFormOpen: boolean = false;
  public isAboutUsFormOpen: boolean = false;
  public errorMessage: string;
  public errorMessageOffer: string;
  public filesToUpload: any;
  public uploadOfferBanner: any;
  public videoToUpload: any;
  public videoPreviewToUpload: any;
  public filteredOfferDocuments: any;
  public filteredVideoDocuments: any;
  public sections: any;
  public url: any;
  public format: any;
  public shop: any;
  public Editor = ClassicEditor;
  public issectionFormOpen: boolean = false;
  public websiteSectionForm: FormGroup;
  public sectionId: any;

  public ProductSliderConfig: any = ProductSlider;

  @ViewChild("videoModal") VideoModal: VideoModalComponent;
  constructor(public productService: ProductService, private tenantSrv: TenantService, private _sanitizer: DomSanitizer, private userSrv: UserService, private docSrv: ShopdocumentService, private fb: FormBuilder, private toaster: ToastrService) {
    this.productService.getProducts.subscribe(response => {
      this.products = response;
    });

    //document form
    this.documentForm = this.fb.group({
      id: [''],
      shopId: [''],
      identifier: ['']
    });

    this.sectionForm = this.fb.group({
      id: [''],
      shopId: [''],
      description: [''],
    });
  }

  // Sliders
  public sliders = [{
    title: 'save upto 20%',
    subTitle: 'creative & decor',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/34.jpg'
  }, {
    title: 'save upto 10%',
    subTitle: 'pre-made & custom',
    image: 'http://themes.pixelstrap.com/multikart/assets/images/home-banner/35.jpg'
  }];

  // Blogs
  public blogs = [{
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/3.jpg',
    date: '25 January 2022',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/4.jpg',
    date: '26 January 2023',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Mike Stark'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/1.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Scot Lang'
  }, {
    image: 'http://themes.pixelstrap.com/multikart/assets/images/beauty/blog/2.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'Mark Hanri'
  }]

  ngOnInit(): void {
    // Change color for this layout
    document.documentElement.style.setProperty('--theme-deafult', '#f0b54d');

    this.shop = JSON.parse(sessionStorage.getItem('shopInfo'));
    this.generatedShopId = sessionStorage.getItem('shopId');
    this.getDocumentByShopId();
    this.getSectionByShopId();
  }

  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
  }

  public getAboutUs() {
    this.tenantSrv.getAboutUsByShopId().subscribe(data => {
      this.aboutus = data;
    });
  }

  edit() {
    this.isEditableMode = !this.isEditableMode;
  }
  isEditable(): boolean {
    return this.userSrv.isLoggedIn() && this.userSrv.getRoles().includes('ROLE_TENANT_ADMIN');
  }

  public getDocumentByShopId() {
    this.docSrv.getDocByShopId(this.generatedShopId).subscribe((data: any) => {
      this.documents = data;
      // Filter the documents based on the identifier
      this.filteredOfferDocuments = this.documents.filter((document: any) => {
        return document.identifier === 'offer-banner';
      });

      this.filteredVideoDocuments = this.documents.filter((document: any) => {
        if (document.type === 'jpg' && document.identifier === 'shop-video') {
          return document.identifier === 'shop-video';
        }
      });
    });
  }

  openOfferForm() {
    this.isOfferBannerFormOpen = true;
  }

  openVideoForm() {
    this.isVideoFormOpen = true;
  }

  openAboutForm() {
    this.isAboutUsFormOpen = true;
  }

  closePopUpForm() {
    this.isOfferBannerFormOpen = false;
    this.isVideoFormOpen = false;
  }

  closeAboutUsForm() {
    this.isAboutUsFormOpen = false;
  }

  onRemoveItem(array, event) {
    const indexToRemove = array.indexOf(event);
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1);
    }
  }

  onSelectOffer = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;
    let validFileCount = 0;
    for (const file of Array.from(selectedFiles)) {
      const image = new Image();
      image.onload = () => {
        if (image.width === 350 && image.height === 337) {
          if (!this.uploadOfferBanner) {
            this.uploadOfferBanner = [];
          }
          this.uploadOfferBanner.push(file);
          validFileCount++;
          if (validFileCount === selectedFiles.length) {
            this.errorMessageOffer = ''; // Clear the error message when all images are valid
          }
        } else {
          this.errorMessageOffer = `Invalid dimensions for ${file.name}. Please upload an image with width 350 and height 337.`;
        }
      };
      // Set the source of the image to the selected file
      image.src = URL.createObjectURL(file);
    }
  }

  onSelectVideo = (event: any): void => {
    const selectedFiles: FileList = event.addedFiles;

    // Assuming videoToUpload is an array declared in your class
    // If not, make sure to initialize it before using
    this.videoToUpload = this.videoToUpload || [];

    for (const file of Array.from(selectedFiles)) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      // Check if the file is a video
      if (file.type.indexOf('video') > -1) {
        this.format = 'video';
        this.videoToUpload.push(file);
      }

      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
        this.videoToUpload.push(file);
      }

      // Set the URL only for the last file (remove this if you want URLs for all files)
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      };
    }
  };

  public onSubmitForm(form: any): void {
    if (this.uploadOfferBanner && this.uploadOfferBanner.length > 0) {
      this.documentForm.get('identifier').setValue("offer-banner");
      if (form.valid && this.uploadOfferBanner.length > 0) {
        this.handleDocument(form.value , 'offer');
      }
    } else if (this.videoToUpload && this.videoToUpload.length > 0) {
      this.documentForm.get('identifier').setValue("shop-video");
      if (form.valid && this.videoToUpload.length > 0) {
        this.handleDocument(form.value, 'video');
      }
    }
  }

  public handleDocument(document: any, documentType: string): void {
    switch (documentType) {
      case 'offer':
        this.addOfferDocument(document, this.uploadOfferBanner);
        break;
      case 'video':
        this.addOfferDocument(document, this.videoToUpload);
        break;
      default:
        // Handle unknown document types or log an error
        console.error('Unknown document type:', documentType);
        break;
    }
  }

  public addOfferDocument(document: any, files: any): void {
    // consume add Shop api
    this.docSrv.addOfferBannerDocument(document, this.generatedShopId, files).subscribe((data: any) => {
      this.toaster.success('Document Uploaded Successfully!');
      this.getDocumentByShopId();
      this.closePopUpForm();
      this.documentForm.reset();
      this.removeDocumentAfterUpload();
    }), (error: any) => {
      this.toaster.error('Document not Uploaded, try again.');
    };
  }

  //section update
  public getSectionByShopId() {
    this.docSrv.findSectionByShopId(this.generatedShopId).subscribe((data: any) => {
      this.sectionId = data.id
      this.sections = data;
    })
  }

  public updateSection(section: any): void {
    // consume add Shop api
    this.docSrv.updateSection(this.generatedShopId, section).subscribe((data: any) => {
      this.toaster.success('Section created Successfully!');
      window.location.reload();
    }), (error: any) => {
      this.toaster.error('Shop not created, try again.');
    };
  }

  public handleSection(section: any): void {
    this.updateSection(section);
  }

  public onSectionSubmit(form: any): void {
    this.websiteSectionForm.get("id").setValue(this.sectionId);
    this.handleSection(this.websiteSectionForm.value);
  }

  // remove document after upload
  public removeDocumentAfterUpload(){
    this.uploadOfferBanner= [];
    this.videoToUpload= [];
  }

}
