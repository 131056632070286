import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
    providedIn: 'root'
})

export class ShopTypeService {

    shopUrl = environment.base_url_shop_service;

    constructor(private httpClient: HttpClient) { }

    public getShopByShoptype(name: string): any {
        return this.httpClient.get(`${this.shopUrl}/shoptypes/shops?name=${name}`);
    }

    public getAllShoptype(): any {
        return this.httpClient.get(`${this.shopUrl}/shoptypes?page=0&size=50`);
    }

    public getIconConfig() {
        return this.httpClient.get<any>('assets/data/shoptype.json');
    }
    public getCategoryConfig() {
        return this.httpClient.get<any>('assets/data/sub-category.json');
    }
}
