import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docfilter'
})
export class DocumentFilterPipe implements PipeTransform {

  transform(values: any[], ...args: unknown[]): any[] {
    var type = args[0];
    var filterDoc = values?.filter(v =>v?.identifier == type);
    return filterDoc
  }

}