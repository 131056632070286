<app-header-home [sticky]="true"></app-header-home>

<div class="container">
    <div class="card">

        <div class="email">
            <img class="image" src="assets/images/email-template.png">
        </div>
        <h2 class="heading mt-3 subHeading">Successfully Submitted <b style="color: #eb690a;">Onboarding</b>  Request</h2>

            <div>
                <p class="paragraph">Thank you for choosing Shopdial, we are excited to have you onboard, 
                    your acount activation is in progrees. <br> Please check your email for verification.
                    For any query reach out to Shopdial support team at <b style="color: #eb690a;">support@shopdial.in</b> </p>
            </div>
        </div>
    </div>
<app-home-footer [class]="'sticky-footer'" [newsletter]="false" [themeLogo]="themeLogo"></app-home-footer>