import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  public themeLogo: string = 'assets/images/footer-logo.png';

  constructor(private location: Location) { }

  ngOnInit(): void {

    //back button
    App.addListener('backButton', () => {
      this.location.back();
    });
  }

  ngOnDestroy(): void {
    
    // back button
    App.removeAllListeners();
  }
}
