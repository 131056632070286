import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from '../shared/services/metadata.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  public selectedshopType: any;
  public data: any;

  constructor(private activated: ActivatedRoute, private metaDataService: MetadataService) {
    this.data = this.activated.snapshot.data['data'];
    this.metaDataService.updateMetaInfo(this.data.shopInfo.name, this.data.shopInfo.description);
    
    sessionStorage.setItem("TENANTID", this.data.tenantInfo['tenantId']);
    sessionStorage.setItem("TENANTNAME", this.data.tenantInfo['tenantName']);
    sessionStorage.setItem("shopId", this.data.shopInfo.id);
    sessionStorage.setItem("SHOP_STATUS",this.data.shopInfo.status)
    sessionStorage.setItem("shopInfo", JSON.stringify(this.data.shopInfo));
    this.selectedshopType = this.data.shopInfo['shopType'].name;
  }

  ngOnInit(): void { }

}  
