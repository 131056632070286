import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { EcomService } from 'src/app/shared/services/ecom.service';
import { ReviewService } from 'src/app/shared/services/review-products.service';


@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: any = [];
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string
  reviewsData: any[] = []; // Assuming your reviewsData is an array
  averageRating: number = 0;

  constructor(private productService: ProductService, private reviewSrv: ReviewService) { }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.getAllReviewsByShopIdAndProductId(this.product.id);
  }


  getAllReviewsByShopIdAndProductId(productId: any): void {
    this.reviewSrv.getAllReview(productId).subscribe((data: any) => {
      this.reviewsData = data;
      // Check if there are reviews before calculating the average rating
      if (this.reviewsData && this.reviewsData.length > 0) {
        const totalRating = this.reviewsData.reduce((sum, review) => sum + review.rating, 0);
        this.averageRating = totalRating / this.reviewsData.length;
      } else {
      }
    });
  }


  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        this.product.images.map((img) => {
          if (img.image_id === item.id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  getRouterLink(): string[] {
    return ['/shop/product/', this.product?.urlPath || ''];
  }
}
