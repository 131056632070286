import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class HomepageService {

    public shopSearch = new BehaviorSubject<any>("");

    shopUrl = environment.base_url_shop_service;


    constructor(private httpClient: HttpClient) { }

    public getAllShops(): any {
        return this.httpClient.get(`${this.shopUrl}/shops/active`);
    }

    public shopByName(name: string): any {
        return this.httpClient.get(`${this.shopUrl}/shop?name=${name}`);
    }

    public searchShopByName(shopName: string) {
        this.httpClient.get(`${this.shopUrl}/shop?name=${shopName}`).subscribe(
            (res: any) => {
                this.shopSearch.next(res)
            }
        )
    }

    public getShopByRankNumber() {
        return this.httpClient.get(`${this.shopUrl}/shops/top/active`);
    }

    public getAllShopsByName(): any {
        return this.httpClient.get(`${this.shopUrl}/shops`).pipe(
            map((response: []) => response.map(data => data['name']))
        );
    }

    public getShopById(shopId: any) {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}`)
    }

    public getVerificationByShopId(shopId: any): any {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/verifications`);
    }

    public getLocationByShopId(shopId: any): any {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/locations`);
    }

    public getSocialMediaByShopId(shopId: any): any {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/socialmedia`);
    }

    public getAllSocialMedia() {
        return this.httpClient.get(`${this.shopUrl}/shops/socialmedia`);
    }

    public findDocShopId(shopId: any) {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/documents`)
    }

    public createShopInquiry(inquiry: any, shopId: any) {
        return this.httpClient.post(`${this.shopUrl}/shop/inquiry/${shopId}`, inquiry);
    }

    public createShopReview(review: any, shopId: any) {
        return this.httpClient.post(`${this.shopUrl}/shop/reviews/${shopId}`, review);
    }

    public findReviewByShopId(shopId: any) {
        return this.httpClient.get(`${this.shopUrl}/shops/reviews/${shopId}`)
    }

    public createShopReport(report: any, shopId: any) {
        return this.httpClient.post(`${this.shopUrl}/shop/reports/${shopId}`, report);
    }

    public getShopByUrlPath(urlPath: any) {
        return this.httpClient.get(`${this.shopUrl}/shops/url-path?urlPath=${urlPath}`)
    }

    // get all question by shop id
    public getQuestionpByShopId(shopId: any) {
        return this.httpClient.get(`${this.shopUrl}/shops/${shopId}/questions`)
    }

    // create shop question
    public createShopQuestion(question: any, shopId: any) {
        return this.httpClient.post(`${this.shopUrl}/shops/questions/${shopId}`, question);
    }
}