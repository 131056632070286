<owl-carousel-o [options]="CollectionSliderConfig" class="category-m no-arrow">
    <ng-container *ngFor="let shop of rank">
        <ng-template carouselSlide>
            <!-- <div> -->
            <div class="product-box">
                <div class="img-wrapper">
                    <div class="img-block">
                        <div class="lable-block">
                            <span class="lable3">new</span>
                            <!-- <span class="lable4" >on sale</span> -->
                        </div>
                        <div class="front" *ngFor="let doc of shop.documents | docfilter : 'home-img'; index as i">
                            <a *ngIf="doc.identifier == 'home-img'" [routerLink]="['/shops/view', shop?.urlPath]">
                                <ng-container *ngIf="i == 0">
                                    <img *ngIf="imageLoaded" [src]="doc.urls" (load)="imageLoaded = true"
                                        class="img-fluid" alt="">
                                    <img *ngIf="!imageLoaded" src="assets/images/product/placeholder.jpg"
                                        class="img-fluid" alt="">
                                </ng-container>
                                <div class="back" *ngIf="i == 1">
                                    <ng-container *ngIf="imageLoaded">
                                        <img [src]="doc.urls" class="img-fluid" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="!imageLoaded">
                                        <img src="assets/images/product/placeholder.jpg" class="img-fluid" alt="">
                                    </ng-container>
                                </div>
                            </a>
                        </div>
                        <div class="cart-info cart-wrap cart-up">
                            <button title="Add to cart">
                                <i class="ti-shopping-cart"></i>
                            </button>
                            <a href="javascript:void(0)" title="Add to Wishlist">
                                <i class="ti-heart" aria-hidden="true"></i>
                            </a>
                            <a href="javascript:void(0)" title="Quick View">
                                <i class="ti-search" aria-hidden="true"></i>
                            </a>
                            <a href="javascript:void(0)" title="Compare">
                                <i class="ti-reload" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <h3 class="mt-3 font-20" style="text-align: center; color: black;">
                    {{shop.name}} </h3>
                <!-- Rating -->
                <div class="d-flex justify-content-center">
                    <h4 class="rating">
                        <bar-rating class="text-center pro-rating" [max]="5" [rate]="shop?.shopdialRank" [readOnly]="true">
                        </bar-rating>
                    </h4>
                </div>
                <div class="socal-media">
                    <ul>
                        <li>
                            <a href={{shop?.socialMedia?.facebookUrl}}><i class="fa fa-facebook ff-social"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href={{shop?.socialMedia?.googleUrl}}><i class="fa fa-google-plus ff-social"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href={{shop?.socialMedia?.twitterUrl}}><i class="fa fa-times ff-social"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href={{shop?.socialMedia?.instaUrl}}><i class="fa fa-instagram ff-social"
                                    aria-hidden="true"></i></a>
                        </li>
                        <li>
                            <a href={{shop?.socialMedia?.linkedinUrl}}><i class="fa fa-rss ff-social"
                                    aria-hidden="true"></i></a>
                        </li>
                    </ul>
                </div>
                <!-- View -->
                <div class="d-flex justify-content-center">
                    <a [routerLink]="['/shops/view',shop?.urlPath]" class="btn btn-outline viewButton">
                    View More
                    </a>
                </div>
                
            </div>
            <!-- </div> -->
        </ng-template>
    </ng-container>
</owl-carousel-o>
<div *ngIf="!contentLoaded">
    <app-loading [inputSourceLayout]="'home-collection.component'"></app-loading>
</div>